import {useEffect, useMemo, useCallback, useRef, useState} from 'react';

import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';

import {SHOPPING_CART_DELIVERY_TYPE} from '~/shared/utils/restaurants/deliveryOptions';
import {getLocalizationService} from '~/shared/services/localisationService';
import PreOrderAlert from '~/shared/components/PreOrderAlert';
import actions, {setShouldAvoidPaymentsRefetch} from '~/shared/store/actions';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {toFixedNum, scrollWindowTop} from '~/shared/utils/general';
import {flipOnLTR, media} from '~/shared/theme/utils';
import {
  selectCurrentRestaurant,
  selectCurrentAddress,
  selectOrderDontWantCutlery,
  selectCheckoutPaymentsIfExists,
  selectUserData,
  selectOrderError,
  selectIsShoppingCartHasAgeRestrictionDishOrSub,
  selectCategoriesListLoading,
  selectNotesForClient,
  selectShouldAvoidPaymentsRefetch,
  selectIsAvailablePaymentsLoading,
  selectIsGroceriesStore,
  selectIsCurrentRestaurantOfficeSupplies,
  selectCurrentRestaurantShoppingCartDeliveryType,
  selectIsMinLargeMobile,
  selectCurrentCoupon,
  selectShoppingCartBillingLinesWithDiscounts,
  selectCurrentModalName,
  selectShoppingCartBillingLines,
  selectCurrentRestaurantOrderRule,
} from '~/shared/store/selectors';
import store from '~/shared/store';
import {navigateToDefaultStartPage, navigateToMenuOrDishPage} from '~/shared/services/navigation';
import initManager from '~/shared/managers/initManager';
import {createLogger} from '~/shared/logging';
import BillingLines from '~/common/components/BillingLines';
import AgeRestrictionDisclaimer from '~/shared/components/AgeRestrictionDisclaimer';
import useCheckoutAddress from '~/common/hooks/useCheckoutAddress';
import useCheckoutSubmittion from '~/common/hooks/useCheckoutSubmittion';
import {DeliveryMethods} from '~/shared/consts/restaurantConsts';
import ShoppingCartHeader from '~/shared/components/ShoppingCart/components/ShoppingCartHeader';
import FutureOrderContextProvider from '~/common/FutureOrderContextProvider';
import {OrderTypeMenuButton, MwOrderTypeMenu} from '~/shared/components/MwOrderTypeMenu';
import {RestaurantBusinessTypeName} from '~/shared/store/models';
import TotalSavedTag from '~/shared/components/TotalSavedTag';
import {calculateTotalSavedAmount} from '~/shared/utils/calculateTotalSavedAmount';
import {isDeliveryRuleModel} from '~/shared/utils/restaurant';

import {ItemWrapper} from '../shared';

import AttachPaymentsCallOut from './AttachPaymentsCallOut';
import ModalHeader from './ModalHeader';
import CheckoutError from './CheckoutError';
import CheckoutTip from './CheckoutTip';
import CheckoutCoupons from './CheckoutCoupons';
import CheckoutAddress from './CheckoutAddress';
import CheckoutPayments from './CheckoutPayments';
import CutleryOption from './CheckoutCutleryOption';
import CheckoutSubmit from './CheckoutSubmit';
import CheckoutAddPaymentsState from './CheckoutAddPaymentsState';
import CheckoutAddressLabel from './CheckoutAddressLabel';
import CheckoutItem from './CheckoutItem';
import DinnigRoomOptions from './DinnigRoomOptions';
import DiningRoomSelection from './DiningRoomSelection';
import OrderRemarks from './OrderRemarks';

const logger = createLogger('Checkout');

const RemarkError = styled.label`
  display: block;
  font-weight: bold;
  color: ${({theme}) => theme.colors.error};
  padding: 0 16px;
  ${media.minLargeTablet`
    padding: 0;
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  ${flipOnLTR`
    text-align: right;
  `}
`;

const DeliveryRuleTogglerWrapper = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: ${({isFutureOnly}) => (isFutureOnly ? '4px' : '8px')};
  ${media.minLargeMobile`
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  `}
`;

const CheckoutContainer = styled.div`
  padding-top: ${({showHeader, theme}) => showHeader && `${theme.checkout.header.height}px`};
  overflow: auto;
`;

const BillingLinesCheckoutItem = styled(CheckoutItem)`
  padding: ${({theme}) => theme.checkout.elements.ItemBodyBillingLines.padding};
`;

const StyledBillingLines = styled(BillingLines)`
  padding: 8px 0;
`;

const StyledCheckoutItem = styled(CheckoutItem)`
  display: flex;
  align-items: center;
`;

const ModifiedTotalSavedTag = styled(TotalSavedTag)`
  ${({isCheckoutModal}) => (isCheckoutModal && `
    margin-top: 16px;
  `)}
`;

const Checkout = ({
  returnToCheckoutOnClose,
  showHeader,
  closeModalOnBack,
  centerSubmitBtn,
  showAddressLabelPrefix,
  hidePaymentSummary,
  openAddPaymentsStep,
  isAgeRestrictionDisclaimerNeeded,
}) => {
  const {t} = getLocalizationService();
  const dispatch = useDispatch();
  const addressFormValuesRef = useRef();
  const addressRef = useRef();
  const [isInitialized, setIsInitialized] = useState(false);
  const hasInitializationStarted = useRef(false);
  const currentModalName = useSelector(selectCurrentModalName);
  const isCheckoutModal = currentModalName === 'checkout_modal';

  const userData = useSelector(selectUserData);
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const shouldAvoidPaymentsRefetch = useSelector(selectShouldAvoidPaymentsRefetch);
  const orderErrors = useSelector(selectOrderError);
  const notesForClient = useSelector(selectNotesForClient);
  const isGroceriesStore = useSelector(selectIsGroceriesStore);
  const isOfficeSupplies = useSelector(selectIsCurrentRestaurantOfficeSupplies);
  const shoppingCartDeliveryType = useSelector(selectCurrentRestaurantShoppingCartDeliveryType);
  const currentCoupon = useSelector(selectCurrentCoupon);
  const isMinLargeMobile = useSelector(selectIsMinLargeMobile);
  const currentRule = useSelector(selectCurrentRestaurantOrderRule);
  const isVoucher = currentRestaurant?.isVoucherEnabled;
  const isVoucherCard = currentRestaurant?.businessType === RestaurantBusinessTypeName.VoucherCard;
  const isFutureOnly = shoppingCartDeliveryType === SHOPPING_CART_DELIVERY_TYPE.FUTURE;

  const {isPickupMode, deliveryMethod, currentAddressKey, currentAddress} = useCheckoutAddress();
  
  const showDeliveryRuleToggler =
    (shoppingCartDeliveryType !== SHOPPING_CART_DELIVERY_TYPE.DEFAULT || deliveryMethod === DeliveryMethods.PICKUP) &&
    !currentRestaurant?.isVoucherEnabled && !isVoucherCard;

  const {
    isSubmitting,
    preSubmitErrors,
    orderFailure,
    subTotalAmount,
    showPaymentsRemarksError,
    paymentRemarkConfiguration,
    paymentsRemarksValues,
    handleCheckoutSubmit,
    handleTipChange,
    setPreSubmitErrors,
    setPaymentsRemarks,
    orderRemarkValue,
    setOrderRemarkValue,
    validationErrors,
  } = useCheckoutSubmittion({
    addressRef,
    addressFormValuesRef,
  });

  const goBack = useCallback(() => {
    if (!currentRestaurant) {
      navigateToDefaultStartPage();
      return;
    }

    navigateToMenuOrDishPage({
      deliveryMethod,
      restaurantId: currentRestaurant.id,
      restaurantName: currentRestaurant.name,
    });

    if (closeModalOnBack) {
      store.dispatch(actions.setCurrentModal(null));
    }
  }, [currentRestaurant, deliveryMethod, closeModalOnBack]);

  useEffect(() => {
    scrollWindowTop({disabledAnimation: true});
  }, []);

  useEffect(() => {
    if (hasInitializationStarted.current) {
      return;
    }

    // New payments should be fetched only if the component wasn't properly initialised yet and a fetching shouldn't be
    // avoided
    hasInitializationStarted.current = true;
    if (!shouldAvoidPaymentsRefetch && !isInitialized) {
      dispatch(actions.clearOrderViewResponse());

      (async () => {
        try {
          await initManager.getIsInitializedPromise();
          await ManagerProvider.changeAddress({
            address: selectCurrentAddress(store.getState()),
          });
          const result = await ManagerProvider.chooseAndSetBestDiscountCouponValueInOrder({includeUserCoupons: true});
          const newDiscountId = result?.data?.discountCoupon?.id;
          if (newDiscountId && currentCoupon && currentCoupon.id !== newDiscountId) {
            dispatch(actions.setIsCouponAutoChanged(true));
          } else {
            dispatch(actions.setIsCouponAutoChanged(false));
          }
          setIsInitialized(true);
        } catch (error) {
          logger.error('failed to initialize', {error});
        }
      })();

      return;
    }

    // To prevent the shouldAvoidPaymentsRefetch blocking necessary requests,
    // if the fetch wasn't invoked once the component was mounted, shouldAvoidPaymentsRefetch flag needs to be removed
    if (shouldAvoidPaymentsRefetch) {
      dispatch(setShouldAvoidPaymentsRefetch(false));
    }
    if (!isInitialized) {
      setIsInitialized(true);
    }
  }, [dispatch, shouldAvoidPaymentsRefetch, isInitialized, currentCoupon]);

  const isAvailablePaymentsLoading = useSelector(selectIsAvailablePaymentsLoading);
  const checkoutPayments = useSelector(selectCheckoutPaymentsIfExists);
  const dontWantCutlery = useSelector(selectOrderDontWantCutlery);
  const pureBillingLines = useSelector(selectShoppingCartBillingLines);
  const billingLines = useSelector(selectShoppingCartBillingLinesWithDiscounts);
  const isShoppingCartHasAgeRestrictionDishOrSub = useSelector(selectIsShoppingCartHasAgeRestrictionDishOrSub);
  const isMenuLoading = useSelector(selectCategoriesListLoading);

  const totalToCharge = useMemo(
    () => billingLines?.find(({type}) => type === 'TotalToCharge')?.amount || 0,
    [billingLines],
  );

  const totalSaved = useMemo(() => calculateTotalSavedAmount(pureBillingLines, isDeliveryRuleModel(currentRule) ? {
    deliveryFeeBeforeDiscount: currentRule.deliveryFeeBeforeDiscount,
    deliveryFee: currentRule.deliveryFee,
  } : undefined), [pureBillingLines, currentRule]);

  const hasNoPaymentsMethods = useMemo(() => isEmpty(checkoutPayments), [checkoutPayments]);

  const differenceFromOrderSum = useMemo(() => {
    const paymentsSum = checkoutPayments?.reduce((total, payment) => total + (payment.isDisabled ? 0 : payment.sum), 0);
    const totalAmount = billingLines?.find(({type}) => type === 'TotalToCharge')?.amount || 0;
    return toFixedNum(totalAmount - paymentsSum, 2);
  }, [checkoutPayments, billingLines]);

  const tipEnabled = currentRestaurant?.isTipEnabled && deliveryMethod === DeliveryMethods.DELIVERY;

  const handleAddPaymentError = useCallback(
    () => setPreSubmitErrors([{errorDesc: t('paypal_general_error')}]),
    [setPreSubmitErrors, t],
  );

  const shouldShowErrorBox = showPaymentsRemarksError || !isEmpty(preSubmitErrors || orderFailure || orderErrors);
  const shouldShowTotalSavedTag = Boolean(totalSaved && isInitialized);

  const errorsRef = useRef();
  useEffect(() => {
    if (shouldShowErrorBox) {
      errorsRef.current?.focus();
    }
  }, [shouldShowErrorBox]);

  const checkoutSubmitProps = useMemo(() => {
    if (showPaymentsRemarksError) {
      return {
        label: t('payment_remark_case'),
        isDisabled: true,
      };
    }

    if (hasNoPaymentsMethods) {
      return {
        label: t('place_order'),
        isDisabled: true,
      };
    }

    if (differenceFromOrderSum !== 0) {
      return {
        label: t('check_payment_method_line'),
        isDisabled: true,
      };
    }

    if (isSubmitting) {
      return {
        label: t('submitting_order'),
        isDisabled: true,
      };
    }

    return {
      label: t('place_order'),
      isDisabled: false,
    };
  }, [differenceFromOrderSum, hasNoPaymentsMethods, isSubmitting, showPaymentsRemarksError, t]);

  const showAgeRestrictionDisclaimer = isAgeRestrictionDisclaimerNeeded && isShoppingCartHasAgeRestrictionDishOrSub;
  const userRemarksError = validationErrors && validationErrors.find(error => error.id === 'user_remarks');

  return (
    <Wrapper>
      {showHeader && <ModalHeader isActive titleKey="payment" onClick={goBack} />}

      <CheckoutContainer showHeader={showHeader}>
        {showAgeRestrictionDisclaimer && <AgeRestrictionDisclaimer />}
        <AttachPaymentsCallOut />

        <ItemWrapper ref={errorsRef}>
          {shouldShowErrorBox && (
            <CheckoutItem>
              <CheckoutError
                orderFailure={orderFailure}
                showPaymentsRemarksError={showPaymentsRemarksError}
                customPaymentRemarkError={paymentRemarkConfiguration?.customErrorMessage}
                preSubmitErrors={preSubmitErrors}
              />
            </CheckoutItem>
          )}

          {showDeliveryRuleToggler && (
            <StyledCheckoutItem title={isPickupMode ? t('pickup_time') : t('time_to_deliver')}>
              <DeliveryRuleTogglerWrapper isFutureOnly={isFutureOnly}>
                <FutureOrderContextProvider>
                  {isMinLargeMobile ? (
                    <ShoppingCartHeader isCheckout showHeader={showHeader} businessType={currentRestaurant?.businessType}/>
                  ) : (
                    <>
                      <OrderTypeMenuButton isCheckout />
                      <MwOrderTypeMenu isCheckout />
                    </>
                  )}
                </FutureOrderContextProvider>
              </DeliveryRuleTogglerWrapper>
            </StyledCheckoutItem>
          )}

          <CheckoutItem title={t(isPickupMode ? 'checkout_pickup_title' : 'checkout_delivery_title')}>
            <CheckoutAddressLabel
              isLoading={!currentAddress}
              isPickupMode={isPickupMode}
              restaurantName={currentRestaurant?.name}
              restaurantAddress={currentRestaurant?.address}
              currentAddress={currentAddress}
              showAddressLabelPrefix={showAddressLabelPrefix}
            />

            {Boolean(!isPickupMode && currentAddressKey) && (
              <CheckoutAddress ref={addressRef} addressFormValuesRef={addressFormValuesRef} />
            )}

            {Boolean(currentRestaurant?.showPackingOption) && <DinnigRoomOptions />}

            {currentRestaurant?.orderRemarks?.isVisible && (
              <>
                {userData.userEnabledFeatures.includes('ShowDiscountDiningRoomSelection') ? (
                  <DiningRoomSelection />
                ) : (
                  <OrderRemarks
                    userRemarksError={userRemarksError}
                    setOrderRemarkTextareaValue={setOrderRemarkValue}
                    orderRemarkTextareaValue={orderRemarkValue}
                  />
                )}
                {userRemarksError?.errorDesc && (
                  <RemarkError id="remarks-error-message">{userRemarksError?.errorDesc}</RemarkError>
                )}
              </>
            )}

          </CheckoutItem>

          {tipEnabled && (
            <CheckoutItem title={t('add_tip_to_delivery')}>
              <CheckoutTip
                subTotalAmount={subTotalAmount}
                tipAmount={billingLines?.find(({type}) => type === 'Tip')?.amount}
                onChange={handleTipChange}
                tipEnabled={tipEnabled}
                isPickupMode={isPickupMode}
                isLoading={!isInitialized}
              />
            </CheckoutItem>
          )}

          <CheckoutItem title={t('checkout_payment_title')}>
            <CheckoutCoupons isCheckoutModal={isCheckoutModal} isInitialized={isInitialized}/>

            <CheckoutPayments
              isLoading={!isInitialized || isAvailablePaymentsLoading}
              setPaymentsRemarks={setPaymentsRemarks}
              paymentsRemarks={paymentsRemarksValues}
              paymentRemarkConfiguration={paymentRemarkConfiguration}
            />

            <CheckoutAddPaymentsState
              isLoading={!isInitialized}
              returnToCheckoutOnClose={returnToCheckoutOnClose}
              onError={handleAddPaymentError}
              openAddPaymentsStep={openAddPaymentsStep}
            />

            {isCheckoutModal && shouldShowTotalSavedTag && (
              <ModifiedTotalSavedTag
                amount={totalSaved}
                fullWidth
                isCheckoutModal
              />
            )}
          </CheckoutItem>

          <BillingLinesCheckoutItem title={t('checkout_summary_title')} withBottom={false}>
            <>
              {!hidePaymentSummary && (
                <StyledBillingLines disableTotalDesign billingLines={billingLines} isLoading={!isInitialized} currentCoupon={currentCoupon} />
              )}

              {!isCheckoutModal && shouldShowTotalSavedTag && (
                <ModifiedTotalSavedTag
                  amount={totalSaved}
                />
              )}

              {!isGroceriesStore && !isOfficeSupplies && !isVoucher && !isVoucherCard && <CutleryOption value={dontWantCutlery} />}

              {notesForClient && (
                <PreOrderAlert texts={[notesForClient]}/>
              )}

              {userData?.isGovCompany && <PreOrderAlert texts={[t('gov_pre_order_alert_line')]} isGov />}
            </>
          </BillingLinesCheckoutItem>

          <CheckoutItem withBottom={false}>
            <CheckoutSubmit
              totalToCharge={totalToCharge}
              centerSubmitBtn={centerSubmitBtn}
              onCheckoutSubmit={handleCheckoutSubmit}
              isLoading={!isInitialized}
              isDisabled={checkoutSubmitProps.isDisabled || isMenuLoading}
              label={checkoutSubmitProps.label}
            />
          </CheckoutItem>
        </ItemWrapper>
      </CheckoutContainer>
    </Wrapper>
  );
};

export default Checkout;
