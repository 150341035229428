import {api} from '~/shared/services/bffService';
import {selectCurrentAddress} from '~/shared/store/selectors';
import {Address, RestaurantFromSearch, RestaurantFromGet} from '~/shared/store/models';
import {convertRestaurant} from '~/shared/utils/restaurant';
import {SHOPPING_CART_DELIVERY_TYPE} from '~/shared/utils/restaurants/deliveryOptions';

import {makeActionCreator, makeThunkAsyncActionCreator} from '../../redux-toolbelt';

import {CurrentRestaurantLastFetchInfo, CurrentRestaurantState} from './currentRestaurantReducer';

export const setCurrentRestaurantsLastFetchInfo = makeActionCreator(
  'setCurrentRestaurantsLastFetchInfo',
  (address?: Address): CurrentRestaurantLastFetchInfo | Record<never, never> => ({
    fetchedForAddress: address?.addressKey,
    fetchedTime: new Date().getTime(),
  }),
);

export const fetchRestaurantFromBff = makeThunkAsyncActionCreator<{id: number}, RestaurantFromGet>(
  'fetchRestaurantFromBff',
  async ({id}, {store}) => {
    if (!id) {
      throw new Error('no restaurantId was provided to fetchRestaurant');
    }

    const address = selectCurrentAddress(store.getState()) as Address | undefined;

    const response = await api.fetchRestaurant({
      id,
      addressId: address?.addressId,
      longitude: address?.longitude,
      latitude: address?.latitude,
    });

    store.dispatch(setCurrentRestaurantsLastFetchInfo(address));

    return response;
  },
);

export const setRestaurantFromSearch = makeActionCreator(
  'setRestaurantFromSearch',
  (restaurantFromSearch: RestaurantFromSearch) => {
    // Restaurant has 2 types
    // 1. given from Search (Restaurant)
    // 2. given from GetRestaurant (RestaurantFromGet)
    // filling the gap with the data from SearchRes til GetRes is completed.

    if (!restaurantFromSearch) {
      return null;
    }

    // converting from Restaurant to RestaurantFromGet
    return convertRestaurant(restaurantFromSearch);
  },
);

export const clearCurrentRestaurant = makeActionCreator<'clearCurrentRestaurant', never>('clearCurrentRestaurant');

export const setAboutRestaurant = makeThunkAsyncActionCreator<{restaurantId: number}, SetAboutRestaurantReturn>(
  'setAboutRestaurant',
  async ({restaurantId}, {apiService}) => {
    const aboutResponse = await apiService.getRestaurantDescriptionAndFeatures({
      restaurantId,
    });
    return aboutResponse.data;
  },
);

type SetAboutRestaurantReturn = StateRemotelyFetched['about']['data'];

export const getRestaurantReviews = makeThunkAsyncActionCreator<{restaurantId: number}, GetRestaurantReviewsReturn>(
  'getRestaurantReviews',
  async ({restaurantId}, {apiService}) => {
    const reviewsResponse = await apiService.getRestaurantReviews({restaurantId});
    return reviewsResponse.data;
  },
);
type GetRestaurantReviewsReturn = StateRemotelyFetched['reviews']['data'];

export const getRestaurantCouponFullInfo = makeThunkAsyncActionCreator<
  {restaurantId: number; Authorization?: string},
  GetRestaurantCouponFullInfo
>('getRestaurantCouponFullInfo', async ({restaurantId}, {apiService}) => {
  const coupon = await apiService.getRestaurantDiscounts({
    restaurantId,
  });

  return coupon.data;
});

export const setCurrentRestaurantShoppingCartDeliveryType = makeActionCreator<
  'setCurrentRestaurantShoppingCartDeliveryType',
  SHOPPING_CART_DELIVERY_TYPE
>('setCurrentRestaurantShoppingCartDeliveryType');

type GetRestaurantCouponFullInfo = StateRemotelyFetched['coupons']['data'];

type StateRemotelyFetched = CurrentRestaurantState['remotelyFetched'];
