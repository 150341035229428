import {memo, useContext} from 'react';

import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {getEstimatedPooledDeliveryTimeRangeString, getAsapDeliveryTimeRanges} from '~/shared/utils/restaurants';
import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';

import {AsapPooledTogglerContext} from './context';
import OptionButton from './OptionButton';

const TITLE_COLON = ':';

const ButtonsWrapper = styled.div<{noPadding?: boolean}>`
  height: 53px;
  justify-content: space-between;
  display: flex;
  max-width: 424px;
`;

const Title = styled.div<{bold?: boolean}>`
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  color: ${({theme}) => theme.colors.charcoal80};
  font-weight: ${({bold}) => (bold ? 700 : 400)};
`;

const AsapPooledTogglerView = () => {
  const {
    onRuleChange,
    isAsap,
    asapDeliveryTimeInMinutes,
    poolDeliveryTime,
    asapDeliveryFee,
    pooledDeliveryFee,
    imageAsap,
    imagePool,
    hideTitle,
    isCheckout,
    hideIcons,
    pooledDeliveryFeeBeforeDiscount,
    asapDeliveryFeeBeforeDiscount,
  } = useContext(AsapPooledTogglerContext);

  const {t} = getLocalizationService();

  return (
    <>
      {!hideTitle && <Title bold={isCheckout}>{t('time_to_deliver')}{!isCheckout && TITLE_COLON}</Title>}

      <ButtonsWrapper>
        <OptionButton
          title={t('delivered_in_xxx', getAsapDeliveryTimeRanges(asapDeliveryTimeInMinutes))}
          isActive={isAsap}
          onClick={() => onRuleChange(DELIVERY_RULES.ASAP)}
          imagePath={imageAsap}
          deliveryFee={asapDeliveryFee}
          deliveryFeeBeforeDiscount={asapDeliveryFeeBeforeDiscount}
          isCheckout={Boolean(isCheckout)}
          hideIcon={hideIcons}
          isAsap
        />

        <OptionButton
          title={t('delivered_between_xxx', {
              estimatedPoolDeliveryTime: poolDeliveryTime
                ? getEstimatedPooledDeliveryTimeRangeString(poolDeliveryTime)
                : '',
            })}
          isActive={!isAsap}
          onClick={() => onRuleChange(DELIVERY_RULES.POOL)}
          imagePath={imagePool}
          deliveryFee={pooledDeliveryFee}
          deliveryFeeBeforeDiscount={pooledDeliveryFeeBeforeDiscount}
          isCheckout={Boolean(isCheckout)}
          hideIcon={hideIcons}
        />
      </ButtonsWrapper>
    </>
  );
};

export default memo(AsapPooledTogglerView);
