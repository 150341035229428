import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

import {flexCenterVertically} from '~/shared/theme/FlexLayout';
import {Link as _Link, getCurrentLocation, removeQueries} from '~/shared/router';
import whiteLogoUrl from '~/assets/images/white-logo.svg';
import squareLogoUrl from '~/assets/images/square-logo.svg';
import govLogoUrl from '~/assets/images/white-gov-logo.svg';
import {media} from '~/shared/theme/media';
import {getLocalizationService} from '~/shared/services/localisationService';
import {selectUiMobile, selectIsGovCompanyUser, selectUserData} from '~/shared/store/selectors';
import {useIsMinLargeTablet, useIsMinTablet} from '~/shared/hooks/deviceInfo';
import {defaultStartPage} from '~/shared/services/navigation';
import ArrowIcon from '~/assets/images/icons/mobile_back.svg?react';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {body16Bold} from '~/shared/theme/typography';
import Button from '~/shared/components/Button';
import {rotateOnRTL} from '~/shared/theme/utils';
import actions from '~/shared/store/actions';
import {MINI_FEED_QUERIES_LIST} from '~/shared/consts/restaurantConsts';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
 
import UserStatus from '../UserStatus';
import {Logo} from '../../styled';
import LanguageSelector from '../LanguageSelector';

import PageHeaderActionMenu from './PageHeaderActionMenu';
import {PageHeaderPrimaryBar as PrimaryBar, PageHeaderSecondaryBar as SecondaryBar} from './styled.pageHeader';

const Link = styled(_Link)`
  width: 50px;
  ${flexCenterVertically}
  ${media.minLargeTablet`
    height: 40px;
    width: 108px;
  `};
`;

const desktopLogoUrl = process.env.TENBIS_DESKTOP_LOGO_URL || whiteLogoUrl;
const WhiteLogo = styled(Logo).attrs({
  src: desktopLogoUrl,
})``;

const mobileLogoUrl = process.env.TENBIS_MOBILE_LOGO_URL || squareLogoUrl;
const SquareLogo = styled(ImageWithAlt).attrs({
  src: mobileLogoUrl,
  width: 37,
  height: 48,
})`
  cursor: pointer;
`;

const GovLogo = styled(ImageWithAlt).attrs({
  src: govLogoUrl,
})`
  width: 101px;
  height: 30px;
  cursor: pointer;
  ${media.minMobile`
    width: 135px;
    height: 40px;
  `};
`;

const BackButtonWrapper = styled(Button)`
  display: flex;
  align-items: center;
  width: 55px;
`;

const StyledArrowIcon = styled(ArrowIcon)`
  ${rotateOnRTL}

  g {
    stroke: ${({theme}) => theme.colors.primaryText};
  }
`;

const BackButtonText = styled.span`
  color: ${({theme}) => theme.colors.primaryText};
  ${body16Bold}
  ${flipOnLTR`
    margin-right: 8px;
  `}
`;

const DefaultHeader = ({isHardLogoClick = false}) => {
  const {t} = getLocalizationService();
  const isGovCompanyUser = useSelector(selectIsGovCompanyUser);
  const userData = useSelector(selectUserData);
  const isMinLargeTablet = useIsMinLargeTablet();
  const dispatch = useDispatch();
  const startPageLink = defaultStartPage();
  const isMinTablet = useIsMinTablet();
  const {query} = getCurrentLocation();
  const {collectionName, cuisine, showStores} = query;
  const hasBackButton = (collectionName || cuisine || showStores) && !isMinTablet;
  const {showRestaurantsFilters: isFilterBoxOpen} = useSelector(selectUiMobile);

  const shouldShowExit = !!userData;

  const onBackButtonClick = () => {
    if (isFilterBoxOpen) {
      dispatch(actions.toggleRestaurantsFiltersFullScreen());
    }
    removeQueries(MINI_FEED_QUERIES_LIST);
  };

  return (
    <>
      <PrimaryBar>
        {hasBackButton ? (
          <BackButtonWrapper onClick={onBackButtonClick}>
            <StyledArrowIcon/>
            <BackButtonText >{t('back')}</BackButtonText>
          </BackButtonWrapper>
        ) : (
          <Link data-id="main-header-logo" href={startPageLink} hard={isHardLogoClick}>
            {isGovCompanyUser ? (
              <GovLogo alt={t('10bis')} />
          ) : (
            <>
              {isMinLargeTablet && <WhiteLogo alt={t('10bis')} />}
              {!isMinLargeTablet && <SquareLogo alt={t('10bis')} />}
            </>
          )}
          </Link>
        )}
      </PrimaryBar>
      <SecondaryBar>
        {userData ? <PageHeaderActionMenu shouldShowExit={shouldShowExit} /> : <UserStatus />}
      </SecondaryBar>
      <LanguageSelector />
    </>
  );
};

export default DefaultHeader;
