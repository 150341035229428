import {MoneycardAndCreditcard, PaymentMethodTypes} from '~/shared/consts/paymentConsts';

import {CheckoutPayment, Payment} from '../store/models';

// this typeguard is a workaround for redux-toolbelt wrong dispatch type
export const isAvailablePayments = (data: unknown): data is {availablePayments: Payment[]} =>
  !!data && typeof data === 'object' && 'availablePayments' in data;

const createPaymentMethodTypePredicate =
  (methodType: EnumValueType<typeof PaymentMethodTypes>) =>
    ({paymentMethod, assigned}: Payment) =>
      paymentMethod === methodType && !assigned;

export const constructCheckoutPayments = (
  availablePayments: Payment[] = [],
  currentCheckoutPayments: CheckoutPayment[],
  checkoutPaymentExtraProps?: Omit<CheckoutPayment, keyof Payment | 'isDisabled'>,
): CheckoutPayment[] => {
  const defaultAvailablePayments =
    currentCheckoutPayments?.length > 0
      ? availablePayments.map(payment => {
        const paymentExistsInCheckout = currentCheckoutPayments.find(
          checkoutPayment => checkoutPayment.cardId === payment.cardId && !checkoutPayment.isDisabled,
        );
        return {...payment, assigned: !!paymentExistsInCheckout};
      })
      : availablePayments;

  const assignedPayment = defaultAvailablePayments.filter(({assigned}) => assigned);

  const firstCreditCard = defaultAvailablePayments
    .filter(createPaymentMethodTypePredicate(PaymentMethodTypes.CREDIT_CARD))
    .sort((a, b) => b.cardId - a.cardId)[0];
  const payPalPayment = defaultAvailablePayments.find(createPaymentMethodTypePredicate(PaymentMethodTypes.PAYPAL));
  // When switching delivery rule type between Asap and Pooled, cash option will be excluded from the current available
  // payments as there is no cash in pool orders, the cash payment should be selected from getAvailablePayments response
  const cashPayment = availablePayments.find(createPaymentMethodTypePredicate(PaymentMethodTypes.CASH));
  const hasAssignedCash = assignedPayment.some(payment => payment.paymentMethod === PaymentMethodTypes.CASH);

  const additionalCards = [firstCreditCard, payPalPayment]
    .filter(isDefinedPayment)
    .filter(p => !wasPaymentRemovedByUser(p, currentCheckoutPayments as Payment[]));

  if (cashPayment && !hasAssignedCash) {
    additionalCards.push(cashPayment);
  }

  return [
    ...assignedPayment.map<CheckoutPayment>(p => ({...p, isDisabled: false})),
    ...additionalCards.map<CheckoutPayment>(p => ({...p, isDisabled: true})),
  ].map<CheckoutPayment>(i => ({...i, ...(checkoutPaymentExtraProps || {})}));
};

function isDefinedPayment(p?: Payment): p is Payment {
  return !!p;
}

function wasPaymentRemovedByUser(p: Payment, currentCheckoutPayments: Payment[]) {
  return (
    !!currentCheckoutPayments.length &&
    !currentCheckoutPayments.find(cp => p.paymentMethod === cp.paymentMethod && p.cardId === cp.cardId)
  );
}

export const getMoneyCard = (payment: Payment): payment is Payment<PaymentMethodTypes.MONEY_CARD> =>
  payment.paymentMethod === PaymentMethodTypes.MONEY_CARD && !payment.isTenbisCredit;

const isCreditcard = (card: Payment): card is Payment<PaymentMethodTypes.CREDIT_CARD> =>
  card.paymentMethod === PaymentMethodTypes.CREDIT_CARD;

export const getMoneyCardAndCreditCardFromPayments = (paymentsProcessedData: MoneycardAndCreditcard, payment: Payment): MoneycardAndCreditcard => {
  if (paymentsProcessedData[PaymentMethodTypes.CREDIT_CARD] && paymentsProcessedData[PaymentMethodTypes.MONEY_CARD]) {
    return paymentsProcessedData;
  }

  if (getMoneyCard(payment) && !paymentsProcessedData[PaymentMethodTypes.MONEY_CARD]) {
    return {
      ...paymentsProcessedData,
      [PaymentMethodTypes.MONEY_CARD]: payment,
    };
  }

  return {
    ...paymentsProcessedData,
    [PaymentMethodTypes.CREDIT_CARD]: isCreditcard(payment) ? payment : null,
  };
};
