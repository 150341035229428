import {flattenDeep} from 'lodash';

import {UnavailableDishId} from '~/shared/services/shoppingCart';
import store from '~/shared/store';
import {setCurrentModal, setMissingDishesIds} from '~/shared/store/actions';

import {RestaurantCategories, ShoppingCartDish} from '../../models';

export const filterMissingDishesAndSubs = (
  categories: RestaurantCategories[],
  shoppingCartDishes: ShoppingCartDish[] | null,
) => {
  const allDishes = flattenDeep(categories.map(({dishes}) => dishes || []));

  return shoppingCartDishes
    ?.map(shoppingCartDish => {
      const foundDish = allDishes.find(dish => dish.id === shoppingCartDish.dishId);
      if (!foundDish) {
        return shoppingCartDish.shoppingCartDishId;
      }

      const {choices: _scDishChoices} = shoppingCartDish;
      const {choices: _foundDishChoices} = foundDish;
      const _scSubs = flattenDeep(_scDishChoices.map(({subsChosen}) => flattenDeep(subsChosen) || []));
      const _foundDishSubs = flattenDeep(_foundDishChoices.map(({subs}) => flattenDeep(subs) || []));

      const isSubMissing = !!_scSubs.find(shoppingCartSub => {
        return !_foundDishSubs.find(_foundDishSub => _foundDishSub.id === shoppingCartSub.subId);
      });
      if (isSubMissing) {
        return shoppingCartDish.shoppingCartDishId;
      }
      return null;
    })
    .filter(Boolean) as UnavailableDishId[];
};

export const shouldOpenMissingDishesModal = (unavailableDishesIds: UnavailableDishId[]) => {
  if (!unavailableDishesIds || !unavailableDishesIds.length) {
    return;
  }
  store.dispatch(setMissingDishesIds(unavailableDishesIds));
  store.dispatch(
    setCurrentModal('futureOrderMissingDishModal', {
      titleKey: 'unavailable_items_title',
      descriptionKey: 'address_change_unavailable_items_description',
      unavailableDishesIds,
      buttons: {
        cancel: false,
        confirm: {
          labelKey: 'confirm',
        },
      },
    }),
  );
};
