/* eslint-disable class-methods-use-this */
import moment from 'moment-timezone';
import {isEmpty} from 'lodash';

import {
  getCurrentRestaurantShoppingCartDeliveryType,
  isSDFORestaurant,
} from '~/shared/utils/restaurants/deliveryOptions';
import {trackEvent} from '~/shared/services/analytics';
import {isCurrentTimeKeyAsap} from '~/shared/utils/general';
import {CollectionOrderTypeViewTags} from '~/shared/store/models/Carousel';
import {is401Error} from '~/shared/services/apiErrorService';

import {createLogger} from '../../logging';
import store from '../../store';
import {
  clearCurrentOrderDateAndTime,
  clearMenuOrderHistory,
  fetchMenuOrderHistory,
  fetchRestaurantFromBff,
  fetchRestaurantMenu,
  getDishAssignedUsers,
  getRestaurantReviews,
  getUserAddresses,
  restrictedSharedActions,
  searchRestaurantsIfNeeded,
  setCurrentRestaurantShoppingCartDeliveryType,
  setInitialOrder,
  setIsReorder,
  setJustAddedDishToShoppingCart,
  setMenuLoading,
} from '../../store/actions';
import {AddressFromServer, RestaurantFromGet} from '../../store/models';
import {
  selectCurrentAddressKey,
  selectCurrentDeliveryMethod,
  selectCurrentRestaurant,
  selectCurrentRestaurantId,
  selectCurrentRestaurantIsLoading,
  selectDishAssignedUsers,
  selectFutureDeliveryDefaultTime,
  selectFutureOrderAvailableDatesAndTimes,
  selectInitialOrderData,
  selectIsCurrentRestaurantWithActivePool,
  selectOrderDeliveryRule,
  selectUserData,
} from '../../store/selectors';
import {RestaurantsState} from '../../store/storeModules/restaurants/restaurantsReducer';
import {DELIVERY_RULES, DeliveryMethods, FUTURE_ORDER_DATETIME_FORMAT} from '../../consts/restaurantConsts';
import {setDeliveryRule} from '../../store/storeModules/order/orderActions';
import {getDateTimeString} from '../../utils/dates';
import {handleRefreshToken} from '../../services/auth';
import {
  selectCurrentAddress,
  selectIsRestaurantFetchedForCurrentAddressKey,
} from '../../store/selectors/globalSelectors';
import OrderManager from '../OrderManager';
import ManagerProvider from '../ManagerProvider';

import {getDeliveryRule, shouldChangeRestaurant} from './RestaurantManagerHelper';

const logger = createLogger('RestaurantManager');
const RestaurantManager = {
  changeRestaurant: async (
    restaurantId: number,
    force = false,
    forceKeepDishes = false,
    restaurantQuery?: {
      allowDeliveryRuleChange?: boolean;
      orderViewTag?: CollectionOrderTypeViewTags;
    },
  ) => {
    const state = store.getState();
    const currentRestaurant = selectCurrentRestaurant(state);
    const currentRestaurantId = selectCurrentRestaurantId(state);
    const currentAddress = selectCurrentAddress(state);
    const isSameAddressKey = selectIsRestaurantFetchedForCurrentAddressKey(state);
    const isCurrentResLoading = selectCurrentRestaurantIsLoading(state);
    const currentDeliveryRule = selectOrderDeliveryRule(state);

    const isDifferentRestaurantId = String(currentRestaurantId) !== String(restaurantId);
    const {allowDeliveryRuleChange, orderViewTag} = restaurantQuery || {};

    if (!shouldChangeRestaurant({
      currentRestaurant,
      isCurrentRestaurantLoading: isCurrentResLoading,
      currentAddress,
      currentDeliveryRule,
      isDifferentRestaurantId,
      orderViewTag,
      force,
      allowDeliveryRuleChange,
      isDifferentAddress: !isSameAddressKey && !isCurrentResLoading,
    })) {
      return;
    }

    const shouldClearDishes = !forceKeepDishes && !!currentRestaurantId && !!restaurantId;

    if (shouldClearDishes) {
      store.dispatch(setJustAddedDishToShoppingCart(false));
    }

    const currentDishList = state.shoppingCart.dishes;

    logger.verbose('changing restaurant', {currentRestaurantId, restaurantId, force, shouldClearDishes});

    if (shouldClearDishes) {
      store.dispatch(restrictedSharedActions.clearOrderData());
      store.dispatch(setIsReorder(false));
      if (currentDishList.length > 0) {
        trackEvent('hasResetShoopingCart');
      }
      store.dispatch(clearMenuOrderHistory());
    }

    if (isDifferentRestaurantId) {
      store.dispatch(clearCurrentOrderDateAndTime());
    }

    store.dispatch(setMenuLoading(true));

    const newRestaurant = await store.dispatchThunk<ReturnType<typeof fetchRestaurantFromBff>, RestaurantFromGet>(
      fetchRestaurantFromBff({id: restaurantId}),
    );

    if (!newRestaurant) {
      logger.error('failed to fetch new restaurant');
      return;
    }

    // initial order data should be reset each time user enters a new restaurant
    if (selectInitialOrderData(store.getState())?.restaurantId !== newRestaurant.id) {
      store.dispatch(setInitialOrder({
        isInitialOrder: true,
        restaurantId: newRestaurant.id,
      }));
    }

    const deliveryRuleType = getDeliveryRule({
      newRestaurant,
      currentAddress,
      orderViewTag,
    });

    // setRestaurantInOrder doesnt support Future order rule
    // if the rule is not Pool, we should send ASAP
    const setRestaurantInOrderResponse = await OrderManager.setRestaurantInOrder(restaurantId, {
      deliveryRuleType: deliveryRuleType === DELIVERY_RULES.FUTURE ? DELIVERY_RULES.ASAP : deliveryRuleType,
    });

    if (setRestaurantInOrderResponse) {
      store.dispatch(setDeliveryRule(deliveryRuleType));
      store.dispatch(setCurrentRestaurantShoppingCartDeliveryType(getCurrentRestaurantShoppingCartDeliveryType(newRestaurant, currentAddress)));
    }

    const currentOrderDateAndTime = await RestaurantManager.setDefaultFutureOrderForRestaurant({orderViewTag});

    let dateTime: string | undefined = moment().format(FUTURE_ORDER_DATETIME_FORMAT);

    if (currentOrderDateAndTime && !isEmpty(currentOrderDateAndTime)) {
      dateTime = getDateTimeString(
        currentOrderDateAndTime.orderTime,
        currentOrderDateAndTime?.orderDate?.date,
      );
    }

    await store.dispatchThunk<ReturnType<typeof fetchRestaurantMenu>, RestaurantFromGet>(
      fetchRestaurantMenu({
        restaurant: newRestaurant,
        dateTime,
        addressId: (currentAddress as AddressFromServer)?.addressId,
      }),
    );

    RestaurantManager.fetchRestaurantReviewsData();

    return {
      shouldClearDishes,
      newRestaurant, // Todo: remove once we're not using events anymore
    };
  },

  userRelatedUpdateUponRestaurantChange: async (restaurantId: number) => {
    const state = store.getState();
    if (selectUserData(state)) {
      store.dispatchThunk(getUserAddresses(restaurantId));
      store.dispatchThunk(fetchMenuOrderHistory({resId: restaurantId}));
      if (!selectDishAssignedUsers.length) {
        logger.verbose('no dishAssignedUsers for a logged in user. fetching...', {restaurantId});
        store.dispatchThunk(getDishAssignedUsers()).catch(() => {
          logger.warn('failed to getDishAssignedUsers', {restaurantId});
        });
      }
    }
  },

  reSearchRestaurants: async (searchWaitFor: Promise<void>, force: boolean | undefined) => {
    logger.verbose('reSearchRestaurants', {force});

    const state = store.getState();

    const addressKey = selectCurrentAddressKey(state);
    const deliveryMethod = selectCurrentDeliveryMethod(state);

    const newRestaurantsData: RestaurantsState['main']['list']['data'] = await store.dispatchThunk(
      searchRestaurantsIfNeeded({deliveryMethod, addressKey, force, waitFor: searchWaitFor}),
    );
    return newRestaurantsData;
  },

  fetchRestaurantReviewsData: () => {
    const currentRestaurant = selectCurrentRestaurant(store.getState());

    if (!currentRestaurant) {
      logger.verbose('no restaurant on query', {currentRestaurant});
      return;
    }
    store.dispatch(getRestaurantReviews({restaurantId: currentRestaurant.id}));
  },

  setDefaultFutureOrderForRestaurant: async (options?: {orderViewTag?: CollectionOrderTypeViewTags}) => {
    try {
      const state = store.getState();
      const currentDeliveryMethod = selectCurrentDeliveryMethod(state);
      const currentRestaurant = selectCurrentRestaurant(state);
      const availableFutureDatesAndTimes = selectFutureOrderAvailableDatesAndTimes(state);
      const futureDeliveryDefaultTime = selectFutureDeliveryDefaultTime(state);
      const isActivePoolRestaurant = selectIsCurrentRestaurantWithActivePool(state);
      const address = selectCurrentAddress(state);

      const isFutureOrderEnabled = (availableFutureDatesAndTimes && availableFutureDatesAndTimes?.length > 0 && currentDeliveryMethod === DeliveryMethods.DELIVERY);
      if (isActivePoolRestaurant || !isFutureOrderEnabled) {
        return;
      }

      const shouldSetDefaultTime = futureDeliveryDefaultTime &&
        currentRestaurant &&
        isSDFORestaurant(currentRestaurant, address) &&
        (!currentRestaurant.deliveryRules.some(({type, isActiveNow}) =>
          type === DELIVERY_RULES.ASAP && isActiveNow,
        ) || options?.orderViewTag !== CollectionOrderTypeViewTags.Asap);

      const fallbackTime = availableFutureDatesAndTimes?.[0].times[0];
      const defaultOrderTime = shouldSetDefaultTime ?
        availableFutureDatesAndTimes?.[0].times.find(({timeKey}) =>
          (isCurrentTimeKeyAsap(timeKey) && isCurrentTimeKeyAsap(futureDeliveryDefaultTime)) ||
          timeKey === futureDeliveryDefaultTime,
        ) || fallbackTime :
        fallbackTime;

      // select default time:
      // currentOrderDateAndTime if any, first availableFutureDateAndTime if not
      const defaultDateAndTime = {
        orderDate: availableFutureDatesAndTimes?.[0],
        orderTime: defaultOrderTime,
      };
      await ManagerProvider.setOrderDateAndTime(defaultDateAndTime, true);

      return defaultDateAndTime;
    } catch (error) {
      if (is401Error(error)) {
        handleRefreshToken(error, RestaurantManager.setDefaultFutureOrderForRestaurant);
      }
    }
  },
};

export default RestaurantManager;
