import {isEmpty} from 'lodash';

import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {DeliveryMethod} from '~/shared/consts/restaurantConsts';

import {RestaurantList} from '../../models';
import {selectFilters} from '../index';

import filterRestaurantArr from './filterRestaurantArr';

export default function filterRestaurantList(
  data: RestaurantList | typeof EMPTY_OBJECT,
  uiFilters: ReturnType<typeof selectFilters>,
  deliveryMethod: DeliveryMethod,
  isComanyAddressAndRestaurantWithoutVoucher?: boolean,
): RestaurantList | typeof EMPTY_OBJECT {
  const isRestaurantList = (d: RestaurantList | typeof EMPTY_OBJECT): d is RestaurantList => !isEmpty(d);
  return isRestaurantList(data)
    ? {
        ...data,
        restaurantsList: filterRestaurantArr(data.restaurantsList, uiFilters, deliveryMethod, isComanyAddressAndRestaurantWithoutVoucher),
      }
    : EMPTY_OBJECT;
}
