import {useRef, useMemo, useState, useContext} from 'react';

import {Form, FormSpy} from 'react-final-form';
import {isEmpty, fromPairs} from 'lodash';

import VerificationForm from '~/shared/components/VerificationForm';
import AgreeToTermsSection from '~/shared/components/AgreeToTermsSection';
import ErrorBox from '~/shared/components/ErrorBox';
import {validators} from '~/shared/utils/validators';
import {toLowerCamelCase} from '~/shared/utils/general';
import {getLocalizationService} from '~/shared/services/localisationService';
import {useAutoFocus} from '~/shared/hooks';

import {
  FormContainer,
  InputsContainer,
  LogoutLink,
  RequiredFieldInfo,
  SubmitButton,
  InputField,
  MessageContainer,
  MessageHeader,
  MessageText,
  VerificationWrapper,
  ResetPasswordErrorBox,
} from './UpdateUser.styled';
import {UpdateUserContext} from './context';

export type UpdateUserDetailsFields = Record<string, boolean | string | undefined>;

const formValidator = (values: UpdateUserDetailsFields, fields?: {fieldName?: string}[], resetPassword?: boolean) => {
  const errors: {ConfirmNewPassword?: string; agreeToTerms?: string} = {};
  const hasField = (fieldToFind: string) => fields?.find(field => field.fieldName === fieldToFind);

  if (hasField('NewPassword') && hasField('ConfirmNewPassword')) {
    const confirmPasswordError = validators.confirmPasswordValidator({
      password: values.NewPassword as string,
      confirmPassword: values.ConfirmNewPassword as string,
    });

    if (confirmPasswordError) {
      errors.ConfirmNewPassword = confirmPasswordError;
    }
  }

  if (!resetPassword && !values.agreeToTerms) {
    errors.agreeToTerms = 'accepting_the_terms_is_required';
  }

  return errors;
};

const getInitValues = (
  mainFieldsFromServer: {fieldName?: string; fieldTextValue?: string}[] = [],
  resetPassword: boolean | undefined,
) => {
  return {
    wantPromotions: !resetPassword,
    ...fromPairs(mainFieldsFromServer.map(field => [field.fieldName, field.fieldTextValue || undefined])),
  };
};

const UpdateUser = () => {
  const [userDetailsEntries, setUserDetailsEntries] = useState<UpdateUserDetailsFields | null>(null); // used only for updateUserDetails for verification step.
  const [resErrors, setResErrors] = useState<string | null>(null);
  const focusElementRef = useRef();
  useAutoFocus({
    domEl: focusElementRef?.current,
  });

  const {
    userData,
    logger,
    logout,
    getActivationTokenAndSendActivationCodeToUser,
    sendAndHandleRequest,
    onTermsOfUseClick,
    onPrivacyPolicyClick,
  } = useContext(UpdateUserContext);
  const {t} = getLocalizationService();

  const {
    showLogOff,
    isUpdatePasswordAfterResetPopup: resetPassword,
    userInfoFields: mainFieldsFromServer,
    message,
  } = userData?.userRequiredActions?.updateUserDetailsForm || {};

  const currentValues = useRef<UpdateUserDetailsFields>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValuesFromServer = useMemo(
    () => getInitValues(mainFieldsFromServer, resetPassword),
    [mainFieldsFromServer, resetPassword],
  );

  const initialValues = currentValues.current || initialValuesFromServer;

  const onFormSpyChange = ({values}: {values: UpdateUserDetailsFields}) => {
    currentValues.current = values;
  };

  const onSubmit = async (userDetails: UpdateUserDetailsFields) => {
    // We transform the userDetails keys to lowerCamelCase as they arrive from server in PascalCase
    // TODO: remove the transform when we'll discontinue legacy.
    // TASK: not currently planning
    const transformedUserDetails = toLowerCamelCase(userDetails);
    const isResetPasswordType = !!resetPassword;

    if (isResetPasswordType) {
      sendAndHandleRequest({userDetails: transformedUserDetails, isResetPasswordType, setResErrors});
      return;
    }

    try {
      const {data: activationToken} = await getActivationTokenAndSendActivationCodeToUser({
        cellPhone: transformedUserDetails.cellNumber as string,
        email: (transformedUserDetails.email as string) || (userData?.email as string),
        isRegistrationMode: false,
      });

      const transformedUserDetailsWithActivationToken: UpdateUserDetailsFields = {
        ...transformedUserDetails,
        activationToken: activationToken as string,
      };
      // stepping over to verification form.
      setUserDetailsEntries(transformedUserDetailsWithActivationToken);
    } catch (e: any) {
      setResErrors(e?.message);
    }
  };

  const onSubmitVerification = async ({authenticationCode}: {authenticationCode?: string}) => {
    setResErrors(null);
    if (!authenticationCode) {
      logger.error('onSubmitVerification didnt receive an authenticationCode');
      return;
    }

    sendAndHandleRequest({
      userDetails: {
        ...userDetailsEntries,
        authenticationCode,
        authenticationToken: userDetailsEntries?.activationToken,
      },
      setResErrors,
    });
  };

  const updatePhoneNumber = () => {
    setUserDetailsEntries(null);
    currentValues.current = {...currentValues.current, CellNumber: ''};
    setTimeout(() => {
      const cellNumberEl = document.getElementById('CellNumber');
      cellNumberEl?.focus();
    }, 0);
  };

  if (isEmpty(userData)) {
    return null;
  }

  if (userDetailsEntries) {
    return (
      <>
        <VerificationWrapper>
          <VerificationForm
            onReSendCode={() => onSubmit(userDetailsEntries)}
            onSubmit={onSubmitVerification}
            email={userDetailsEntries.email as string}
            headerText={t('we_sent_you_an_sms_with_code_to_this_number_xxx', {
              phoneNumber: userDetailsEntries.cellNumber,
            })}
            setResErrors={setResErrors}
            resErrors={resErrors}
            toUpdatePhoneNumberScreen={updatePhoneNumber}
          />
        </VerificationWrapper>
        {showLogOff && <LogoutLink onClick={() => logout()}>{t('logout_from_system')}</LogoutLink>}
      </>
    );
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={values => {
          return formValidator(values, mainFieldsFromServer, resetPassword);
        }}
        initialValues={initialValues}
        render={({handleSubmit, submitting, submitError, submitFailed, dirtySinceLastSubmit, errors, touched}) => (
          <>
            <FormContainer noValidate onSubmit={handleSubmit}>
              <InputsContainer>
                {mainFieldsFromServer &&
                  mainFieldsFromServer.map(
                    ({
                      fieldName,
                      fieldText,
                      fieldType,
                      hidden,
                      readonlyField,
                      required,
                      placeholder,
                      fieldOptionsList,
                      ...rest
                    }) => (
                      <InputField
                        key={fieldName}
                        {...{
                          name: fieldName,
                          type: fieldType,
                          placeholder: placeholder || fieldText,
                          inputProps: {options: fieldOptionsList?.filter(option => option.text !== ''), ...rest},
                          required,
                          hidden,
                          readonlyField,
                        }}
                      />
                    ),
                  )}

                {!resetPassword && (
                  <AgreeToTermsSection
                    isUpdateUserDetails
                    onTermsOfUseClick={onTermsOfUseClick}
                    onPrivacyPolicyClick={onPrivacyPolicyClick}
                    errorText={touched?.agreeToTerms && errors?.agreeToTerms && t(errors.agreeToTerms)}
                  />
                )}
              </InputsContainer>
              <RequiredFieldInfo>{`*${t('required_field')}`}</RequiredFieldInfo>

              {!resetPassword && (message?.headerText || message?.bodyText) && (
                <MessageContainer>
                  <MessageHeader>{message.headerText}</MessageHeader>
                  <MessageText>{message.bodyText}</MessageText>
                </MessageContainer>
              )}

              {resetPassword && resErrors && (
                <ResetPasswordErrorBox withArrow={false}>{resErrors}</ResetPasswordErrorBox>
              )}

              <SubmitButton ref={focusElementRef} type="submit" disabled={submitting}>
                {t('update')}
              </SubmitButton>
              <ErrorBox withArrow={false}>{submitFailed && !dirtySinceLastSubmit && submitError}</ErrorBox>
            </FormContainer>
            <FormSpy subscription={{values: true}} onChange={onFormSpyChange} />
          </>
        )}
      />
      {showLogOff && <LogoutLink onClick={() => logout()}>{t('logout_from_system')}</LogoutLink>}
    </>
  );
};

UpdateUser.Context = UpdateUserContext;
UpdateUser.Provider = UpdateUserContext.Provider;

export default UpdateUser;
