import styled, {css} from 'styled-components';
import numeral from 'numeral';

import {flipOnLTR} from '~/shared/theme/utils';
import {BillingLineType, NumericFormats} from '~/shared/consts/checkoutConsts';

import NegativePriceLabel from './NegativePriceLabel';

type RootProps = {
  type?: BillingLineType;
  sign?: string;
  centered: boolean;
  margin?: number;
  bold?: boolean;
};

const Root = styled.div<RootProps>`
  display: flex;
  font-weight: ${({type, bold}) => (bold || type === BillingLineType.TotalToCharge ? 'bold' : 'normal')};
  margin-left: ${({sign}) => (sign ? -5 : 0)}px;
  ${({centered}) => centered && 'justify-content: center'};
  direction: ltr;
  ${({margin}) =>
    margin &&
    css`
      ${flipOnLTR`
      margin-right: ${margin}px;
    `}
    `};
  ${({sign}) =>
    sign &&
    `
    &:before {
      content: '${sign}';
    }
  `}
`;

export const generatePriceLabel = ({
  price,
  format = true,
  discount = false,
  currency = '₪',
  formatString = NumericFormats.showZeroDecimals,
}: PriceLabelInfoProps) => {
  const value = format ? numeral(price).format(formatString) : price;

  if (discount) {
    return `${value}%`;
  }

  return `${!discount && currency}${value}`;
};

type PriceLabelInfoProps = {
  price: PriceLabelProps['price'];
  format: PriceLabelProps['format'];
  discount?: PriceLabelProps['discount'];
  currency?: PriceLabelProps['currency'];
  formatString?: PriceLabelProps['formatString'];
};

const PriceLabelInfo = ({
  price,
  format = true,
  discount = false,
  currency = '₪',
  formatString = NumericFormats.showZeroDecimals,
}: PriceLabelInfoProps) => {
  const isNegativeNumber = Number(price) < 0;
  if (isNegativeNumber) {
    return (
      <NegativePriceLabel
        currency={currency}
        formatedPrice={numeral(Math.abs(price)).format(NumericFormats.showZeroDecimals)}
      />
    );
  }

  return <>{generatePriceLabel({price, format, discount, currency, formatString})}</>;
};

export type PriceLabelProps = {
  id?: string;
  className?: string;
  centered?: boolean;
  price: number;
  discount?: boolean;
  currency?: string;
  margin?: number;
  format?: boolean;
  sign?: string;
  type?: BillingLineType;
  formatString?: string;
  priceDataTestId?: string;
  bold?: boolean;
};

const PriceLabel = ({
  id,
  className,
  centered = true,
  price,
  discount = false,
  currency = '₪',
  margin,
  format = true,
  sign,
  type,
  formatString = NumericFormats.showZeroDecimals,
  priceDataTestId,
  bold = false,
}: PriceLabelProps) => {
  return (
    <Root {...{id, className, centered, margin, sign, type, bold}}>
      <div data-test-id={priceDataTestId}>
        <PriceLabelInfo
          price={price}
          format={format}
          discount={discount}
          currency={currency}
          formatString={formatString}
        />
      </div>
    </Root>
  );
};

export const PriceLabelWithStrikethrough = styled(PriceLabel)<{
  grey?: boolean;
  marginRight?: boolean;
  noMargin?: boolean;
}>`
  font-size: 12px;
  text-decoration: line-through;
  line-height: 19px;
  ${({grey, theme}) => grey && `color: ${theme.colors.text};`}
  ${flipOnLTR`
    ${({marginRight, noMargin}) => (noMargin ?
    '' :
    marginRight ? 'margin-right: 3px;' : 'margin-left: 3px;')}
  `}
`;

export default PriceLabel;
