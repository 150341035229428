import {memo} from 'react';

import {Form} from 'react-final-form';
import {useSelector} from 'react-redux';

import {selectUserError, selectUserErrorRequireCaptcha} from '~/shared/store/selectors';
import useFormAutoFocus from '~/shared/hooks/useFormAutoFocus';
import FormField from '~/shared/components/FormField';
import {
  FieldWrapper,
  FormWrapper,
  // ErrorText,
  LoginFormFieldsWrapper,
  // Label,
  SubmitButton,
  Email,
  StyledErrorText,
  BackButton,
  BackButtonSvg,
  LinkButtonsWrapper,
  BlueLinkButton,
} from '~/shared/components/loginStyled';

const PasswordForm = ({onBackClick, onSubmitLoginWithEmailAndPassword, emailEntry, onForgotPassword, pageView, t}) => {
  const userError = useSelector(selectUserError);
  const isRequireCaptcha = useSelector(selectUserErrorRequireCaptcha);
  const formWrapRef = useFormAutoFocus();

  return (
    <>
      <BackButton pageView={pageView} onClick={onBackClick} aria-label={t('back')}>
        <BackButtonSvg />
      </BackButton>
      <Form
        onSubmit={onSubmitLoginWithEmailAndPassword}
        validateOnBlur
        render={({handleSubmit}) => (
          <FormWrapper isLogin onSubmit={handleSubmit} ref={formWrapRef}>
            <Email>{emailEntry}</Email>
            <LoginFormFieldsWrapper>
              <FieldWrapper>
                <FormField
                  {...{
                    name: 'password',
                    placeholder: t('password'),
                    type: 'currentPassword',
                    required: true,
                    selectOnFocus: true,
                    className: 'white-border-on-contrast',
                  }}
                />
              </FieldWrapper>
              {isRequireCaptcha && <FormField name="reCaptchaResponse" type="recaptcha" />}
              {!!userError && <StyledErrorText>{t('entry_email_or_password_are_incorrect')}</StyledErrorText>}
            </LoginFormFieldsWrapper>
            <SubmitButton type="submit">{t('continue')}</SubmitButton>
          </FormWrapper>
        )}
      />
      <LinkButtonsWrapper>
        <BlueLinkButton onClick={onForgotPassword}>{t('forgot_password')}</BlueLinkButton>
      </LinkButtonsWrapper>
    </>
  );
};

export default memo(PasswordForm);
