import moment from 'moment-timezone';

import {bffApiSessionTokenTTL} from '~/shared/config';
import {LocalStorageKeys} from '~/shared/consts/localStorageConsts';

export const getUserSessionToken = () => {
  const sessionTokenObject = window.localStorage.getItem(LocalStorageKeys.USER_SESSION_TOKEN);
  const TTL = parseInt(bffApiSessionTokenTTL, 10);
  try {
    const {ts, token} = JSON.parse(sessionTokenObject) || {};

    if (!ts || !token) {
      return;
    }

    const now = moment();
    const timestamp = moment(ts);
    const diff = now.diff(timestamp, 'minutes');

    if (diff < TTL) {
      return token;
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const setUserSessionToken = token => {
  if (token) {
    const userSessionTokenStringified = JSON.stringify({ts: Date.now(), token});
    window.localStorage.setItem(LocalStorageKeys.USER_SESSION_TOKEN, userSessionTokenStringified);
  }
};
