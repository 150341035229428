const shadows = {
  shadow1: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
  shadow2: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
  shadow3: '2px 2px 6px 0 rgba(0, 0, 0, 0.2)',
  shadow4: '0 -2px 2px 0 rgba(40, 45, 72, 0.05), 0 4px 4px 0 rgba(63, 68, 97, 0.05)',
  shadow5:
    '0 -2px 2px 0 rgba(40, 45, 72, 0.05), 0 4px 4px 0 rgba(63, 68, 97, 0.05), 0 8px 8px 0 rgba(40, 45, 72, 0.05)',
  shadow6: '0 2px 22px 0 rgba(0, 0, 0, 0.15)',
  shadow7: '0px -1px 2px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.10), 0px 8px 8px 0px rgba(0, 0, 0, 0.10);',
  shadow8: '0px 4px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 12px -2px rgba(0, 0, 0, 0.08), 0px 3px 6px 0px rgba(0, 0, 0, 0.06)',
  menuBar: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
  couponShadow: '0 2px 4px 0 #d2d2d2',
  modalHeaderShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.1)',
  a11yShadow: '-4px 4px 10px 0 rgba(0, 0, 0, 0.5)',
  resListItemHover:
    '0 -1px 1px 0 rgba(40, 45, 72, 0.05), 0 2px 2px 0 rgba(63, 68, 97, 0.05), 0 4px 4px 0 rgba(40, 45, 72, 0.05)',
};
export default shadows;
