import {ApiResponse} from '~/shared/services/apiService';
import {makeThunkAsyncActionCreator} from '~/shared/store/redux-toolbelt';

import {Transaction} from '../models';

// #region order success
export const sendShareOrderLink = makeThunkAsyncActionCreator<{orderId: number; cellphone: string}, boolean>(
  'sendShareOrderLink',
  async (payload, {apiService}) => {
    try {
      const result = await apiService.sendShareOrderLink(payload);
      return result.data;
    } catch (error: unknown | any) {
      throw error.message;
    }
  },
);
// #endregion

// #region restarants
export const upsertRestaurantReview = makeThunkAsyncActionCreator<UpsertRestaurantIdPayload, ApiResponse<number>>(
  'upsertRestaurantReview',
  (review, {apiService}) => {
    return apiService.upsertRestaurantReview(review);
  },
);
interface UpsertRestaurantIdPayload {
  restaurantId: number;
  ranking: string;
  transactionId: string;
  transactionType: string;
  reviewId?: string;
  comments?: string;
}

export const validateInsertReview = makeThunkAsyncActionCreator<ValidateInsertReviewPayload, ApiResponse<boolean>>(
  'validateInsertReview',
  (payload, {apiService}) => {
    return apiService.validateInsertReview(payload);
  },
);
interface ValidateInsertReviewPayload {
  transactionId: string;
  transactionType: string;
  restaurantId: number;
}

export const getLastTransactionWithoutReview = makeThunkAsyncActionCreator<never, Transaction>(
  'getLastTransactionWithoutReview',
  async (_payload, {apiService}) => {
    const response = await apiService.getLastTransactionWithoutReview();
    return response.data;
  },
);
// #endregion

// #region user
export const resetPassword = makeThunkAsyncActionCreator<{emailAddress: string}, ApiResponse<boolean>>(
  'resetPassword',
  ({emailAddress}, {apiService}) => {
    return apiService.resetPassword({emailAddress});
  },
);
// #endregion
