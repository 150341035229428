import {times} from 'lodash';
import styled from 'styled-components';

import {Skeleton} from '~/shared/components/Loaders';
import {FlexColumn, FlexSpace} from '~/shared/theme/FlexLayout';

const Root = styled(FlexColumn)`
  background: ${({theme}) => theme.colors.surface};
  height: 100vh;
`;

const Image = styled.div`
  display: block;
  width: 100%;
  height: 150px;
`;

const DishInfo = styled(FlexColumn)`
  min-height: 150px;
  padding: 20px 20px 0 20px;
`;

const DishName = styled(Skeleton)`
  width: 60%;
  height: 26px;
`;

const DishDescription = styled(Skeleton)`
  width: 90%;
  height: 14px;
  margin-top: 15px;
`;

const ActionsContainer = styled(FlexSpace)`
  flex: 1;
  align-items: flex-end;
  padding: 15px 0;
  margin-top: 10px;
  border-top: 1px solid ${({theme}) => theme.colors.darkBackground};
`;

const AssignUserSkeleton = styled(Skeleton)`
  width: 160px;
  height: 40px;
`;

const AmountSkeleton = styled(Skeleton)`
  width: 86px;
  height: 40px;
`;

const Choices = styled.div`
  padding: 20px;
  flex: 1;
`;

const ChoiceTitleSkeleton = styled(Skeleton)`
  width: 200px;
  height: 16px;
  margin-bottom: 12px;
`;

const ChoiceSkeletonItem = styled.div`
  border-top: 1px solid ${({theme}) => theme.colors.darkBackground};
  padding: 12px 0;
`;

const ChoiceSkeleton = styled(Skeleton)<{width: number}>`
  width: ${({width}) => width}px;
  height: 16px;
`;

const ButtonSkeleton = styled(Skeleton)`
  width: 100%;
  height: 50px;
`;

const CHOICES_WIDTHS = [140, 120, 95, 84, 90, 80];

const DishLoader = () => (
  <Root>
    <Image>
      <Skeleton />
    </Image>

    <DishInfo>
      <DishName id="modal-title" />

      <DishDescription id="modal-content" />

      <ActionsContainer>
        <AssignUserSkeleton />

        <AmountSkeleton />
      </ActionsContainer>
    </DishInfo>

    <Choices>
      <ChoiceTitleSkeleton />
      {times(6, index => (
        <ChoiceSkeletonItem key={index}>
          <ChoiceSkeleton width={CHOICES_WIDTHS[index]} />
        </ChoiceSkeletonItem>
      ))}
    </Choices>

    <ButtonSkeleton />
  </Root>
);

export default DishLoader;
