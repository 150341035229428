import {createSelector} from 'reselect';

// @ts-expect-error config needs to be rewritten as ts
import {shoppingCartGuidExpirationMinutes} from '~/shared/config';
import {isTimestampExpired} from '~/shared/utils/time';
import {BillingLineType} from '~/shared/consts/checkoutConsts';

import {AppState} from '../../configStore';
import {selectCurrentRestaurantDishesByDishId} from '../menu/menuSelectors';

import {ShoppingCartState} from './shoppingCartReducer';

export const selectShoppingCart = (state: AppState) => state.shoppingCart;

export const selectIsShoppingCartDirty = createSelector(selectShoppingCart, shoppingCart => shoppingCart.isDirty);

export const selectShoppingCartDishes = createSelector(selectShoppingCart, shoppingCart => shoppingCart.dishes);
export const selectMissingDishIds = createSelector(selectShoppingCart, shoppingCart => shoppingCart.missingDishesIds);

export const selectShoppingCartDishesCounter = createSelector(selectShoppingCart, shoppingCart => {
  return shoppingCart.dishes.reduce((idCountMap, dish) => {
    const dishId = dish.dishId.toString();
    idCountMap[dishId] = (idCountMap[dishId] || 0) + dish.quantity;
    return idCountMap;
  }, {} as Record<string, number>);
});

export const selectShoppingCartBillingLines = createSelector(
  selectShoppingCart,
  shoppingCart => shoppingCart.billingLines,
);

export const selectShoppingCartDeliveryFeeFromBilling = createSelector(
  selectShoppingCartBillingLines,
  billingLines => billingLines?.find(bl => bl.type === BillingLineType.DeliveryFee)?.amount || 0,
);

export const selectShoppingCartGuidTimestamp = createSelector(
  selectShoppingCart,
  shoppingCart => shoppingCart.shoppingCartGuidTimestamp,
);

export const selectIsShoppingCartGuidExpired = createSelector(selectShoppingCart, shoppingCart => {
  const timestamp = shoppingCart.shoppingCartGuidTimestamp;
  return isTimestampExpired(timestamp, shoppingCartGuidExpirationMinutes);
});

export const selectShoppingCartDishesByDishId = createSelector(selectShoppingCartDishes, shoppingCartDishes => {
  return shoppingCartDishes.reduce<Record<number, _Dish>>((dishesObject, dish) => {
    dishesObject[dish.shoppingCartDishId] = dish;
    return dishesObject;
  }, {});
});

// Todo: refactor - not actual selectors. selectors should only be passed the state!
export const selectDishByIdFromCurrentRestaurant: _Dish | Record<never, never> = (state: AppState, dishId: number) => {
  const currentRestaurantDishesById = selectCurrentRestaurantDishesByDishId(state);
  if (!currentRestaurantDishesById || Object.keys(currentRestaurantDishesById).length === 0) {
    return {};
  }
  return currentRestaurantDishesById[dishId];
};

export const selectDishByShoppingCartDishIdFromShoppingCart: _Dish | Record<never, never> = (
  state: AppState,
  shoppingCartDishId: number,
) => {
  if (!shoppingCartDishId) {
    return {};
  }
  const dishesByShoppingCartDishId = selectShoppingCartDishesByDishId(state);
  return dishesByShoppingCartDishId[shoppingCartDishId];
};

type _Dish = ShoppingCartState['dishes'][number];
