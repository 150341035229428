import {ComponentType, MouseEvent, ReactNode, useCallback, useMemo} from 'react';

import styled from 'styled-components';
import URI from 'urijs';
import {isBoolean, escapeRegExp, mapValues, omit, pick} from 'lodash';
// eslint-disable-next-line no-restricted-imports
import {Redirect, Router, Switch, Route, matchPath, useLocation as useLocationBase} from 'react-router-dom';

// @ts-expect-error needs to be rewritten with ts
import {basedir} from '~/shared/config';
import history from '~/shared/router/browserHistory';
import {secureOpenWindow, clickOnEnterProps} from '~/shared/utils/general';
import {DEFAULT_LANGUAGE_KEY, SupportedLanguageType} from '~/shared/consts/commonConsts';
import {routes} from '~/shared/routes';
import {createLogger} from '~/shared/logging';
import {eventNames, raise} from '~/shared/events';

import {supportedQueriesParams, SupportedQueryParamsType, SupportedQueryParamsKeys} from './supportedQueryParams';
import {
  saveLocationSession,
  setAppInitiatedRefresh,
  getIsAppInitiatedRefresh,
  getCurrentPathRefreshed,
} from './routerSessionStorage';

export {Switch, Route, Redirect};

const logger = createLogger('router');

let currentLanguageKey: SupportedLanguageType['key'];

export function RouterProvider({children}: {children: ReactNode}) {
  return <Router history={history}>{children}</Router>;
}
export const keepQueriesKeys = (queryParams: SupportedQueryParamsType, queriesKeysToKeep: SupportedQueryParamsKeys = []) => {
  if (!queriesKeysToKeep.length) {
    return queryParams;
  }
  return pick(queryParams, queriesKeysToKeep);
};

const removePathnameCache: Record<SupportedLanguageType['key'], RegExp> | Record<string, never> = {};

export function removePathnamePrefix(rawPathname: string) {
  if (!removePathnameCache[currentLanguageKey]) {
    const lngSegment = currentLanguageKey === DEFAULT_LANGUAGE_KEY ? '' : `${currentLanguageKey}/?`;
    removePathnameCache[currentLanguageKey] = new RegExp(`^${escapeRegExp(basedir)}${lngSegment}`, 'gi');
  }

  const replacePathNameRegex = removePathnameCache[currentLanguageKey];
  return rawPathname.replace(replacePathNameRegex, '/');
}

export function addPathnamePrefix(path: string, {keepAllQueries = false, keepQueries = [] as SupportedQueryParamsKeys} = {}) {
  if (path.startsWith('http')) {
    logger.error('unexpected usage of addPathnamePrefix with absolute pathname!', {path});
    return path;
  }

  if (!path.startsWith('/')) {
    logger.error('unexpected usage of addPathnamePrefix with pathname that doesnt start with "/"!', {path});
    // eslint-disable-next-line no-param-reassign
    path = `/${path}`;
  }
  const keepQueriesInRoute = keepAllQueries || keepQueries.length;
  let pathWithUpdatedQuery = path;
  // eslint-disable-next-line no-restricted-properties
  if (keepQueriesInRoute && window.location.search) {
    const [pathWithoutQuery, search] = path.split('?');
    const pathQuery = URI.parseQuery(search);
    // eslint-disable-next-line no-restricted-properties
    const currentQuery = URI.parseQuery(window.location.search) as SupportedQueryParamsType;
    const filteredQuery = keepAllQueries ? currentQuery : keepQueriesKeys(currentQuery, keepQueries);
    pathWithUpdatedQuery = URI.buildQuery({
      ...filteredQuery,
      ...pathQuery,
    });
    pathWithUpdatedQuery = [pathWithoutQuery, pathWithUpdatedQuery].join('?');
  }

  const lngSegment = currentLanguageKey === DEFAULT_LANGUAGE_KEY ? '' : `/${currentLanguageKey}`;

  const newPathname = `${basedir.slice(0, -1)}${lngSegment}${pathWithUpdatedQuery}`;

  return newPathname;
}

export type ProcessedLocation = {
  /** A route from routes in routes.js */
  route: Record<string, any>;
  /** The name of the route from routes.js */
  routeName: string;
  /** The matched params in the route */
  routeParams: Record<string, any>;
  /** parsed query from the list of supported queries where ?a=a becomes {a: 'a'}
   * ** to add queries add them to SupportedQueryParamsType **
   */
  query: SupportedQueryParamsType;
  /** used internally to bypass the query proxy (for example, when removing query params) */
  _internalQueryParams: SupportedQueryParamsType;
  /** The path without query and hash, basedir and language prefix.
   * The **bad name** for this is derived from the native "window.location.pathname"*/
  pathname: string;
  /** Url path including ?query and #hash */
  path: string;
  /** The raw ?query string */
  search: string;
  /** The raw #hash string */
  hash: string;
  port: string;
  protocol: string;
  hostname: string;
  /**  Was the app recently refreshed on this location */
  getIsRecentlyRefreshedOnLocation(): boolean;
  /** Was the app refreshed on this location? */
  refreshedOnLocation: boolean;
  /** Was the app refreshed on this location by an internal mechanism? (for example, on logout or refresh modal) */
  internallyRefreshedOnLocation: boolean;
};

export function parseRawLocation(location: Location): ProcessedLocation | undefined {
  const {pathname: rawPathname, search, hash} = location;

  const pathname = decodeURI(removePathnamePrefix(rawPathname));

  const result = routes
    .map(route => {
      const match = matchPath(pathname, route);

      return (
        match && {
          matchedParams: mapValues(match.params, param =>
            (typeof param !== 'undefined' ? decodeURIComponent(param) : undefined),
          ),
          matchedRoute: route,
        }
      );
    })
    .filter(Boolean)[0];

  if (!result) {
    logger.error('Critical Error! Encountered a non-existing route!');
    return;
  }

  const {matchedRoute, matchedParams} = result;

  const searchWithoutDuplicates = URI.buildQuery(URI.parseQuery(search));

  const path = `${pathname}${search}${hash}`;

  const getIsRecentlyRefreshedOnLocation = () => {
    return getCurrentPathRefreshed({
      path,
      pathLandTime: new Date().getTime(),
    });
  };

  const refreshedOnLocation = getIsRecentlyRefreshedOnLocation();

  const internallyRefreshedOnLocation = refreshedOnLocation && getIsAppInitiatedRefresh();

  let query = URI.parseQuery(searchWithoutDuplicates) as SupportedQueryParamsType;

  const _internalQueryParams = query;

  if (process.env.BROWSERSLIST_ENV === 'development') {
    query = new Proxy(query, {
      get(target, requestedQueryName) {
        const nativeProperty = Object.prototype.hasOwnProperty.call(target, requestedQueryName);
        if (nativeProperty && !(requestedQueryName in supportedQueriesParams)) {
          throw new Error(`The query "${String(requestedQueryName)}" doesn't appear in "supportedQueriesParams".`);
        }

        return target[requestedQueryName as keyof SupportedQueryParamsType];
      },
    });
  }

  return {
    route: matchedRoute,
    routeName: matchedRoute.name,
    routeParams: matchedParams,
    pathname,
    path,
    search,
    query,
    _internalQueryParams,
    hash,
    hostname: window.location.hostname, // eslint-disable-line no-restricted-properties
    protocol: window.location.protocol, // eslint-disable-line no-restricted-properties
    port: window.location.port, // eslint-disable-line no-restricted-properties
    getIsRecentlyRefreshedOnLocation,
    refreshedOnLocation,
    internallyRefreshedOnLocation,
    // locationLandTime,
  };
}

let prevLocation: ProcessedLocation | undefined;
let prevRouteLocation: ProcessedLocation | undefined;
let currentLocation: ProcessedLocation | undefined;

history.listen((newLocation: unknown) => {
  prevLocation = currentLocation;
  currentLocation = parseRawLocation(newLocation as Location);

  logger.log('navigated', {from: prevLocation?.path, _to_: currentLocation?.path});

  saveLocationSession(currentLocation);

  raise(eventNames.locationChanged, {prevLocation, currentLocation});

  const routeChanged = !prevRouteLocation || currentLocation?.routeName !== prevLocation?.routeName;
  if (routeChanged) {
    prevRouteLocation = prevLocation;
    raise(eventNames.routeChanged, {prevRouteLocation, currentLocation});
  }
});

export function getCurrentLocation(): ProcessedLocation {
  return currentLocation as ProcessedLocation;
}

export function getPrevRouteLocation(): ProcessedLocation {
  return prevRouteLocation as ProcessedLocation;
}

export function useLocation(): ProcessedLocation {
  // trigger re-render when location changes
  useLocationBase();

  // but still use the customly parsed currentLocation
  return currentLocation as ProcessedLocation;
}

export function useRouteQuery(): SupportedQueryParamsType {
  /* to add queries add them to SupportedQueryParamsType */
  return useLocation().query;
}

export function useRouteParams<T>(): T {
  return useLocation().routeParams as T;
}

export function withLocation(InnerComponent: ComponentType<React.PropsWithChildren<any>>) {
  const ComponentWithLocation = (props: Record<string, unknown>) => {
    const location = useLocation();
    return <InnerComponent {...props} location={location} />;
  };

  ComponentWithLocation.displayName = `withLocation(${InnerComponent.displayName})`;

  return ComponentWithLocation;
}

/**
 * @description
 * Navigates to a new path.
 *
 * @param {string} pathname the new pathname
 * @param {object} options
 * @param {boolean} options.hard *USE WITH CAUTION* this would re-run the application's initialization logic-
 *                               should the application reload when navigating to the new route
 * @param {boolean} options.keepAllQueries should the current query be added to the query of the new path
 * @param {SupportedQueryParamsKeys} options.keepQueries should keep only the necessary queries
 */
export function pushRoute(pathname: string, {hard = false, keepAllQueries = true, root = false, keepQueries = [] as SupportedQueryParamsKeys} = {}) {
  const path = pathname.startsWith('http') || root ? pathname : addPathnamePrefix(pathname, {keepAllQueries, keepQueries});
  if (hard) {
    // eslint-disable-next-line no-restricted-properties
    window.location.assign(path);
  } else {
    history.push(path);
  }
  return true;
}

const ensureEncoded = (url: string) => encodeURI(decodeURI(url));

/**
 * @description
 * Replaces the current route with a new one so that clicking on "back"
 * would *NOT* navigate to the current route but to the one before that.
 *
 * @param {string} requestedPathname the new pathname
 * @param {object} options
 * @param {boolean} options.permanent *USE WITH CAUTION* a permanent route replace means google would consider
 *                  the previous and new routes identical where the new one is a newer version of the older one -
 *                  should the replace take place as permanent (301 redirect) when google navigates to it or not (302)
 * @param {boolean} options.hard *USE WITH CAUTION* this would re-run the application's initialization logic-
 *                               should the application reload when replacing the route
 * @param {boolean} options.keepAllQueries should the current query be added to the query of the new path
 * @param {SupportedQueryParamsKeys} options.keepQueries should keep only the necessary queries
 * @returns {boolean} did the function replaced the route or was it the same as the current one
 */
export function replaceRoute(requestedPathname: string, {permanent = false, hard = false, keepAllQueries = true, keepQueries = [] as SupportedQueryParamsKeys} = {}) {
  if (!isBoolean(permanent)) {
    logger.errorDialog('replaceRoute must specify if a replace route is permanent or not.');
    throw new Error('replaceRoute must specify if a replace route is permanent or not.');
  }

  const finalPath = requestedPathname.startsWith('http')
    ? requestedPathname
    : addPathnamePrefix(requestedPathname, {keepAllQueries, keepQueries});

  if (hard) {
    // eslint-disable-next-line no-restricted-properties
    window.location.replace(finalPath);
    return true;
  }

  const pathChanged = removePathnamePrefix(finalPath) !== currentLocation?.path;

  if (pathChanged) {
    document.getElementById('prerender-status-code')?.setAttribute('content', `${permanent ? 301 : 302}`);
    document.getElementById('prerender-header')?.setAttribute('content', `location: ${ensureEncoded(finalPath)}`);
    history.replace(finalPath);
    return true;
  }

  return false;
}

export function refreshPage() {
  setAppInitiatedRefresh();

  // eslint-disable-next-line no-restricted-properties
  window.location.reload();
}

export function removeQueries(queriesToRemove = [] as string[]) {
  const {pathname, search, _internalQueryParams} = getCurrentLocation();

  if (search) {
    // We omit from _internalQueryParams not to trigger the proxy get handler, in case the query includes external params that are not in the "suported" list
    const newQuery = omit(_internalQueryParams, queriesToRemove);
    const newQueryStr = URI.buildQuery(newQuery);
    const newSearch = newQueryStr ? `?${newQueryStr}` : '';
    replaceRoute(`${pathname}${newSearch}`, {permanent: false, keepAllQueries: false});
  }
}

export const A = styled.a.attrs(({tabIndex = 0}) => ({
  tabIndex,
  ...clickOnEnterProps,
}))`
  text-decoration: none;

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
`;

// Todo: add proper props
export const Link = ({href, hard, children, onClick, target, keepAllQueries, innerRef, ...rest}: Record<string, any>) => {
  // TODO: keepAllQueries isn't relevant for all cases
  // we should have keepQueries=['query1', 'query2'] to keep only those queries
  // should resolve #135890

  const realHref = useMemo(() => {
    if (!href) {
      return href;
    }
    const isAbsolute = href && new URI(href).is('absolute');
    return isAbsolute ? href : addPathnamePrefix(href);
  }, [href]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (target === '_blank') {
        return;
      }

      e.preventDefault();

      if (onClick) {
        e.stopPropagation();
        const onClickResult = onClick(e);
        if (onClickResult === false) {
          return;
        }
      }

      if (href) {
        pushRoute(href, {keepAllQueries: !!keepAllQueries, hard});
      }
    },
    [hard, href, keepAllQueries, onClick, target],
  );

  return (
    <A href={realHref} onClick={handleClick} rel="noopener" ref={innerRef} target={target} {...rest}>
      {children}
    </A>
  );
};

export function openRouteInNewTab(pathname: string) {
  const path = pathname.startsWith('http') ? pathname : addPathnamePrefix(pathname);
  secureOpenWindow(path, '_blank');
}

export function init({currentLanguageKey: newLanguageKey}: {currentLanguageKey: SupportedLanguageType['key']}) {
  currentLanguageKey = newLanguageKey;
  // eslint-disable-next-line no-restricted-properties
  currentLocation = parseRawLocation(window.location);
  saveLocationSession(currentLocation);
}

export function goBack() {
  history.goBack();
}
