import {useContext} from 'react';

import styled, {css} from 'styled-components';
import {useDispatch} from 'react-redux';

import {getLocalizationService} from '~/shared/services/localisationService';
import {media} from '~/shared/theme/media';
import {flexBottomVertically, FlexCenterHorizontally, flexColumn, flexStart} from '~/shared/theme/FlexLayout';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {body14Bold, body14Normal, body16Bold} from '~/shared/theme/typography';
import {PriceLabel, PriceLabelWithStrikethrough} from '~/shared/components/PriceLabels';
import Button from '~/shared/components/Button';
import AmountCounter from '~/shared/components/AmountCounter';
import AgeRestrictionBadge from '~/shared/components/AgeRestrictionBadge';
import {getCurrentLocation, removeQueries} from '~/shared/router';
import {routeNames} from '~/shared/routes';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import noDishImage from '~/assets/images/icons/logo.svg';
import {useIsMinLargeMobile} from '~/shared/hooks/deviceInfo';
import {selectCheckoutErrors, selectCurrentRestaurantId} from '~/shared/store/selectors';
import store from '~/shared/store';
import {setCurrentModal} from '~/shared/store/actions';
import {navigateToMenuOrDishPage} from '~/shared/services/navigation';
import {getDishPriceWithDiscount} from '~/shared/utils/priceDiscountCalculator';
import {Coupon} from '~/shared/store/models';

import ShoppingCart from '../ShoppingCart';

const DishContainer = styled.div<{isFirstChild: boolean}>`
  flex: 1 0 auto;
  ${flexColumn};
  border-bottom: 1px solid ${({theme}) => theme.colors.darkBackground};
  padding-bottom: 16px;
  padding-top: ${({isFirstChild}) => (isFirstChild ? 0 : 15)}px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: 0;
    padding-bottom: 0;
  }
`;

const DishContainerInner = styled.div`
  ${flexColumn};
  flex: 1 0 auto;
`;

const DishRow = styled.div`
  display: flex;
  flex: 1 0 auto;
  line-height: 14px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const nameBaseCss = css`
  cursor: pointer;
  ${body14Bold};
  line-height: 1.3;
`;

const dishNameCss = css`
  ${nameBaseCss};
  display: flex;
  line-height: 18px;
  flex: 1;
  ${flipOnLTR`
    margin-left: 5px;
    text-align: right;
  `}
`;

const dishNameButton = css`
  ${nameBaseCss};
  color: ${({theme}) => theme.checkout?.dish.name.textColor || theme.colors.secondary};
  ${flipOnLTR`
    text-align: right;
  `}
`;

const DishNameContainer = styled.div`
  display: flex;
  flex-shrink: 1;
  ${flipOnLTR`
    margin-left: 8px;
  `}
`;

const Price = styled(PriceLabel)<{noMargin?: boolean}>`
  font-weight: normal;
  line-height: 18px;
  ${({noMargin}) => !noMargin && flipOnLTR`
     margin-right: auto;
  `}
`;

const DishNameLabelButton = styled(Button)`
  ${dishNameButton};
  line-height: 18px;
`;

const DishAdditions = styled.div`
  ${body14Normal};
  line-height: 14px;
  color: ${({theme}) => theme.colors.text};
  margin-top: 4px;
`;

const DishOwnerName = styled.div`
  ${dishNameCss};
  cursor: initial;
  margin-top: 4px;
`;

const RemoveDish = styled(Button)`
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  cursor: pointer;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 9px;
  ${media.minLargeMobile`
    margin-bottom: 0;
  `}
`;

const AmountContainer = styled.div`
  ${body16Bold};
  ${flexBottomVertically};
  margin-top: 16px;
  align-items: center;
  color: ${({theme}) => theme.menu.dishModal.amountText};
  flex: 1 0 auto;
  justify-content: flex-end;
  ${media.minLargeMobile`
    flex: 1 0 auto;
    ${flexColumn};
    align-items: initial;
    min-height: initial;
    border: 0;
  `};
`;

const StyledAmountCounter = styled(AmountCounter)<{
  quantity?: number;
  increaseQuantity?: () => void;
  decreaseQuantity?: () => void;
  itemName: any;
}>`
  font-size: ${({theme}) => theme.shoppingCart.amountCounterFontSize}px;
`;

const SubChoicesForMediumAndAbove = styled.div`
  display: flex;
  flex: 1;
`;

const DishOwnerNameForMediumAndAbove = styled.div`
  display: flex;
  flex: 1;
`;

const DishName = styled.div`
  display: inline;
  ${flipOnLTR`
    margin-left: 8px;
  `}
`;

const StyledAgeRestrictionBadge = styled(AgeRestrictionBadge)`
  flex-shrink: 0;
  align-self: flex-start;
  margin-top: 6px;
  display: inline;
  height: min-content;
  ${flipOnLTR`
    margin-right: unset;
  `}

  * {
    display: inline-flex;
  }
`;

const DishImage = styled(ImageWithAlt).attrs({})`
  ${flipOnLTR`
    margin-left: 16px;
  `}
  cursor: pointer;
  height: 48px;
  width: 48px;
  object-fit: cover;
`;

const ContentContainer = styled.div`
  ${flexColumn}
  ${flexStart}
  flex: 1;
`;
const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  ${flipOnLTR`
    margin-right: 16px;
  `}
`;

const PriceLabelWithDiscountContainer = styled(FlexCenterHorizontally)`
  ${flexColumn}
  align-items: flex-end;
`;

const PriceLabelWithDiscount = ({dishPrice, currentCoupon}: {dishPrice: number; currentCoupon: Coupon | null}) => {
  const discountData = getDishPriceWithDiscount(dishPrice, currentCoupon);

  if (!discountData) {
    return (
      <Price price={dishPrice} priceDataTestId="dishPrice" />
    );
  }

  return (
    <PriceLabelWithDiscountContainer>
      <Price price={discountData.priceAfterDiscount} priceDataTestId="dishPriceAfterdiscount" noMargin />
      <PriceLabelWithStrikethrough price={discountData.price} priceDataTestId="dishPrice" grey noMargin />
    </PriceLabelWithDiscountContainer>
  );
};

type DishProps = {
  dishId: number;
  dishName?: string;
  dishPrice: number;
  quantity: number;
  dishIndex?: number;
  shoppingCartDishId: number;
  ownerName?: string | null;
  subChoices?: [] | string[];
  categoryId?: number;
  dishSinglePrice: number;
  ageRestricted: boolean;
  restrictionAge?: string;
  dishImageUrl?: string;
  showOwnerName?: boolean;
  shouldShowAgeConfirm?: boolean;
};

const DishItem = ({
  showOwnerName,
  dishId,
  dishName,
  dishPrice,
  ownerName,
  dishImageUrl,
  quantity,
  shoppingCartDishId,
  subChoices,
  dishIndex,
  categoryId,
  dishSinglePrice,
  ageRestricted,
  shouldShowAgeConfirm,
}: DishProps) => {
  const {t} = getLocalizationService();
  const {categoriesList, openDish, incrementDishQuantity, decrementDishQuantity, removeDish, isIncreaseQuantityDisbled, currentCoupon} = useContext(
    ShoppingCart.Context,
  );
  const category = categoriesList.find(item => item.categoryId === categoryId);
  const isMinLargeMobile = useIsMinLargeMobile();
  const dispatch = useDispatch();

  const onCloseDish = (isCheckoutPage: boolean) => {
    if (isCheckoutPage) {
      const restaurantId = selectCurrentRestaurantId(store.getState());
      const errors = selectCheckoutErrors(store.getState());
      if (errors) {
        const {errorMessageCodes, showServiceRepresentativeLink} = errors;

        dispatch(setCurrentModal('checkout_error_message_modal', {
          errorMessageCodes,
          showServiceRepresentativeLink,
          shouldShowAgeConfirm,
          modalOptions: {
            closeModal: () => {
              removeQueries(['dishId']);
              navigateToMenuOrDishPage({restaurantId, restaurantName: false});
            },
          },
        }));
      }
    }

  };
  const onDishClick = () => {
    const {routeName} = getCurrentLocation();
    const isCheckoutPage = routeName === routeNames.checkout;

    openDish({
      dishId,
      shoppingCartDishId,
      onClose: () => onCloseDish(isCheckoutPage),
    });
  };

  const showSubChoices = subChoices && subChoices.length > 0;
  const onRemoveDish = removeDish({
    shoppingCartDishId,
    dishId,
    dishSinglePrice,
    dishName,
    category,
    ageRestricted,
    quantity,
  });

  return (
    <DishContainer isFirstChild={dishIndex === 0} data-test-id={`shoppingCartDish-${dishIndex}`}>
      <DishContainerInner>
        <DishRow>
          <DishImage alt={dishName} data-test-id={`shoppingcart_image_${dishId}`} src={dishImageUrl || noDishImage} onClick={onDishClick} />

          <ContentContainer>
            <DishNameContainer>
              <DishNameLabelButton data-test-id={`shoppingCartDish-${dishIndex}`} aria-label={t('modify_selected_dish', {dishName})} onClick={onDishClick}>
                <DishName>{dishName}</DishName>
                {ageRestricted && <StyledAgeRestrictionBadge />}
              </DishNameLabelButton>
            </DishNameContainer>
            {showSubChoices && (
              <DishRow>
                <SubChoicesForMediumAndAbove>
                  {subChoices && <DishAdditions>{subChoices.join(', ')}</DishAdditions>}
                </SubChoicesForMediumAndAbove>
              </DishRow>
                )}

            {showOwnerName && (
              <DishOwnerNameForMediumAndAbove>
                <DishOwnerName data-test-id="dishOwnerName">{ownerName}</DishOwnerName>
              </DishOwnerNameForMediumAndAbove>
            )}

            <AmountContainer>
              <StyledAmountCounter
                {...{
                    fontSize: 14,
                    quantity,
                    isIncreaseQuantityDisbled,
                    size: !isMinLargeMobile ? 'big' : 'small',
                    increaseQuantity: incrementDishQuantity({
                      shoppingCartDishId,
                      dishId,
                      dishSinglePrice,
                      dishName,
                      category,
                      ageRestricted,
                      quantity,
                    }),
                    decreaseQuantity: decrementDishQuantity({
                      shoppingCartDishId,
                      dishId,
                      dishSinglePrice,
                      dishName,
                      category,
                      ageRestricted,
                      quantity,
                    }),
                    itemName: dishName,
                  }}
              />
            </AmountContainer>
          </ContentContainer>

          <PriceContainer>
            <PriceLabelWithDiscount dishPrice={dishPrice} currentCoupon={currentCoupon} />
            
            <RemoveDish
              onClick={onRemoveDish}
              aria-label={`${t('remove')} ${dishName}`}
              data-test-id="removeDishButton"
            >
              {t('remove')}
            </RemoveDish>
          </PriceContainer>
        </DishRow>
      </DishContainerInner>
    </DishContainer>
  );
};
export default DishItem;
