import styled from 'styled-components';
import {useSelector} from 'react-redux';

import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {flipOnLTR, rotateOnLTR} from '~/shared/theme/flipOnLTR';
import {media} from '~/shared/theme/media';
import {getLocalizationService} from '~/shared/services/localisationService';
import {convertGapToMargin} from '~/shared/theme/convertGapToMargin';
import {selectIsBenefitsHighlightEnabled} from '~/shared/store/selectors';
import LogoIcon from '~/assets/images/icons/10bisPlusIcon.svg?react';

import {body12Normal} from '../theme/typography';
import {flexCenter} from '../theme/FlexLayout';

import FreeShippingBadge from './FreeShippingBadge/FreeShippingBadge';

const TextDisclaimer = styled.p`
  ${body12Normal}
  line-height: normal;
  color: ${({theme}) => theme.colors.text});
`;

const DeliveryPrice = styled.span<{isDiscount?: boolean}>`
  height: 20px;
  display: flex;
  font-style: normal;
  align-items: center;
  font-size: 12px;
  text-align: right;
  color: ${({isDiscount, theme}) => (isDiscount ? theme.tagColorsForeground.DeliveryFeeWithDiscount : theme.colors.charcoal80)};
  font-weight: ${({isDiscount}) => (isDiscount ? 700 : 400)};
`;

const ImageContainer = styled.div<{isAsap?: boolean; isCheckout?: boolean}>`
  ${flexCenter}
  ${flipOnLTR`
    margin-left: 8px;
  `}
  ${({isAsap}) => (isAsap ? rotateOnLTR : '')}

  img {
    display: flex;
    width: 30px;
    height: 30px;
    ${media.minLargeMobile`
      width: 36px;
      height: 36px;
    `}
    ${({isCheckout}) => media.minDesktop`
      ${!isCheckout && `
        width:  28px;
        height: 28px;
      `}
    `}
    ${({isCheckout}) => media.minLargeDesktop`
      width: ${isCheckout ? '42px' : '36px'};
      height: ${isCheckout ? '42px' : '36px'};
    `}
  }

  ${media.minLargeDesktop`
    ${flipOnLTR`
      margin-left: 12px;
    `}
  `}
`;

const Title = styled.div<{isHorizontalDescription?: boolean}>`
  text-align: start;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  color: inherit;
  margin-bottom: 2px;
`;

const DiscountDeliveryPrice = styled.div`
  text-decoration: line-through;
  ${flipOnLTR`
    margin-right: 4px;
  `}
`;

const Container = styled.div<{inlineRow?: boolean}>`
  display: flex;
`;

const Content = styled.div<{inlineRow?: boolean}>`
  ${({inlineRow, theme}) => inlineRow && `
    display: flex;
    align-items: center;
    ${convertGapToMargin('0 8px', theme.isLTR)}
  `}
`;

const StyledLogoIcon = styled(LogoIcon)`
  transform: scale(0.9);
  ${flipOnLTR`
    margin-left: 2px;
  `}
`;

type DeliveryETABoxProps = {
  hideIcon?: boolean;
  hidePickupDisclaimer?: boolean;
  isAsap?: boolean;
  isCheckout?: boolean;
  imagePath?: string;
  title: string;
  deliveryFee?: number | null;
  deliveryFeeBeforeDiscount?: number;
  isPickup?: boolean;
  isClosed?: boolean;
  isTempClosed?: boolean;
  inlineRow?: boolean;
};

export const DeliveryPriceLabel = ({deliveryFee, isAsap, deliveryFeeBeforeDiscount}: Pick<DeliveryETABoxProps, 'deliveryFee' | 'isAsap' | 'deliveryFeeBeforeDiscount'>) => {
  const {t} = getLocalizationService();
  const isBenefitsHighlightEnabled = useSelector(selectIsBenefitsHighlightEnabled);
  const showIcon = Boolean(isBenefitsHighlightEnabled && deliveryFeeBeforeDiscount);
  if (typeof deliveryFee !== 'number') {
    return null;
  }

  return deliveryFee ? (
    <DeliveryPrice data-test-id={isAsap ? 'ASAP_DELIVERY_FEE' : 'POOL_DELIVERY_FEE'} isDiscount={!!deliveryFeeBeforeDiscount}>
      {showIcon && <StyledLogoIcon/>}
      {`${t('delivery')} ₪${deliveryFee}`}
      {!!deliveryFeeBeforeDiscount && (
        <DiscountDeliveryPrice>{`(₪${deliveryFeeBeforeDiscount})`}</DiscountDeliveryPrice>
      )}
    </DeliveryPrice>
  ) : (
    <FreeShippingBadge>
      {isBenefitsHighlightEnabled && <StyledLogoIcon/>}
    </FreeShippingBadge>
  );
};

export const ETAInfoBox = ({
  hideIcon,
  isAsap,
  isCheckout,
  imagePath,
  title,
  deliveryFee,
  isPickup,
  isClosed,
  isTempClosed,
  deliveryFeeBeforeDiscount,
  inlineRow = false,
  hidePickupDisclaimer,
}: DeliveryETABoxProps) => {
  const {t} = getLocalizationService();

  const infoContent = (() => {
    if (isPickup) {
      if (!hidePickupDisclaimer) {
        return isClosed ?
          <Title>{isTempClosed ? t('pickup_temp_closed') : t('pickup_closed')}</Title> :
          <TextDisclaimer>{t('pickup_time_disclaimer')}</TextDisclaimer>;
      }
      
      return null;
    }

    return !isClosed ? (
      <DeliveryPriceLabel
        isAsap={isAsap}
        deliveryFee={deliveryFee}
        deliveryFeeBeforeDiscount={deliveryFeeBeforeDiscount}
      />
    ) : null;
  })();

  return (
    <Container inlineRow={inlineRow}>
      {!hideIcon && (
        <ImageContainer
          isCheckout={isCheckout}
          isAsap={Boolean(isAsap)}
          data-test-id={isPickup ? 'PICKUP_ICON' : isAsap ? 'ASAP_ICON' : 'POOL_ICON'}
        >
          <ImageWithAlt src={imagePath} alt={isPickup ? t('pickup') : t('delivery')} />
        </ImageContainer>
      )}
      <Content inlineRow={inlineRow}>
        <Title data-test-id={isPickup ? 'PICKUP_ETA' : isAsap ? 'ASAP_ETA' : 'POOL_ETA'}>
          {isClosed && !isPickup ? isTempClosed ? t('delivery_temp_closed') : t('delivery_closed') : title}
        </Title>
        <div>{infoContent}</div>
      </Content>
    </Container>
  );
};
