import {isEqual, sortBy} from 'lodash';

import {DeliveryMethod} from '~/shared/consts/restaurantConsts';
import {createLogger} from '~/shared/logging';
import store from '~/shared/store';
import {IShoppingCart, RestaurantFromGet} from '~/shared/store/models';
import {
  selectCurrentCoupon,
  selectCurrentDeliveryMethod,
  selectCurrentRestaurantDishesByDishId,
  selectDishesAdditionalDetails,
  selectCurrentRestaurantOrderRule,
  selectShoppingCartBillingLines,
} from '~/shared/store/selectors';
import {recalculateBillingLines} from '~/shared/utils/billingLinesCalculation';

const logger = createLogger('OrderManagerHelper');

const OrderManagerHelper = {
  getFinalDeliveryMethod: (newDeliveryMethodFromArgs: string, force: boolean | undefined) => {
    const newDeliveryMethod = newDeliveryMethodFromArgs.toLowerCase() as DeliveryMethod;
    if (newDeliveryMethodFromArgs !== newDeliveryMethod) {
      logger.error(
        new Error(
          'Bad delivery method casing was received in changeDeliveryMethod and casted to the right one. Please report about it immediately.',
        ),
        {
          newDeliveryMethod,
          newDeliveryMethodFromArgs,
        },
      );
    }

    const currentDeliveryMethod = selectCurrentDeliveryMethod(store.getState());
    const isDifferentDeliveryMethod = currentDeliveryMethod !== newDeliveryMethod;
    if (!(force || isDifferentDeliveryMethod)) {
      logger.verbose('delivery method was not changed because the provided one is like the current one', {
        currentDeliveryMethod,
        newDeliveryMethod,
      });
      return;
    }

    return newDeliveryMethod;
  },

  // eslint-disable-next-line class-methods-use-this
  getIsBillingsUpdateNeeded: (billingLines: IShoppingCart['billingLines']) => {
    const currentBillingLines = selectShoppingCartBillingLines(store.getState());
    return !isEqual(sortBy(billingLines, 'priority'), sortBy(currentBillingLines, 'priority'));
  },

  // eslint-disable-next-line class-methods-use-this
  getCalculatedBillingLines: () => {
    const state = store.getState();
    const deliveryMethod = selectCurrentDeliveryMethod(state);
    const dishListWithDetails = selectDishesAdditionalDetails(state) || [];
    const currentCoupon = selectCurrentCoupon(state);
    const currentBillingLines = selectShoppingCartBillingLines(state);
    const tipBillingLine = currentBillingLines.find(({type}) => type === 'Tip');
    const currentRestaurantDishesByDishId = selectCurrentRestaurantDishesByDishId(state);
    const restaurantOrderRule = selectCurrentRestaurantOrderRule(state) as RestaurantFromGet['deliveryRules'][0];

    return recalculateBillingLines({
      dishList: dishListWithDetails,
      deliveryMethod,
      deliveryFee: restaurantOrderRule?.deliveryFee || 0,
      coupon: currentCoupon,
      minOrderTotalForDeliveryFeeDiscount: restaurantOrderRule?.minimumOrderForDeliveryFeeDiscount || 0,
      discountedDeliveryFee: restaurantOrderRule?.deliveryFeeAfterDiscount || 0,
      currentRestaurantDishesByDishId,
      tip: tipBillingLine?.amount || 0,
    });
  },
};

export default OrderManagerHelper;
