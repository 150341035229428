import {useSelector} from 'react-redux';
import styled, {css} from 'styled-components';

import {selectCurrentRestaurant, selectActiveFilters} from '~/shared/store/selectors';
import {InnerWidthContainer as _InnerWidthContainer} from '~/shared/components/styled';
import {media} from '~/shared/theme/utils';
import {useIsMaxDesktop} from '~/shared/hooks/deviceInfo';
import {flexSpace} from '~/shared/theme/FlexLayout';
import {useRouteQuery} from '~/shared/router';

import SmallScreenMenuPageHeader from './SmallScreenMenuPageHeader';
import DefaultHeader from './PageHeaderDefault';

const Container = styled.header<{withBorder?: boolean; withoutShadow?: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  ${({theme, withoutShadow}) =>
    (withoutShadow
      ? css`
          box-shadow: none;
        `
      : css`
          box-shadow: ${theme.shadows.shadow4};
        `)};
  z-index: ${({theme}) => theme.zIndex.header};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.shouldHideHeader && 'display: none'};
  ${media.minDesktop`
    box-shadow: ${({theme}) => theme.shadows.shadow4};
  `};
`;

const HeaderBody = styled.div`
  height: ${({theme}) => theme.header.height}px;
  ${flexSpace};
  align-items: stretch;
  color: ${({theme}) => theme.header.textColor};
  background-color: ${({theme}) => theme.header.backgroundColor};
  background-repeat: repeat;
  background-position-x: center;
  background-position-y: 100%;
  transition: height 300ms;
  transform: translateZ(0);
`;

const ShowOnMobileAndTabletOnlyFlex = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const HideOnMobileAndTabletOnlyFlex = styled.div`
  display: flex;
  flex: 1;
`;

export const InnerWidthContainer = styled(_InnerWidthContainer)`
  padding-right: ${({theme}) => theme.MobileHeaderSidesPadding}px;
  padding-left: ${({theme}) => theme.MobileHeaderSidesPadding}px;
  ${media.minLargeMobile`
    padding-right: 0px;
    padding-left: 0px;
  `}
  ${media.minMobile`
    align-items: center;
  `};
  overflow: visible;
`;

type PageHeaderProps = {
  onBackClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  children?: JSX.Element | JSX.Element[];
  isMenuPage?: boolean;
  isHardLogoClick?: boolean;
};

const PageHeader = ({children, onBackClick, isMenuPage, isHardLogoClick}: PageHeaderProps) => {
  const query = useRouteQuery();
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const isMaxDesktop = useIsMaxDesktop();
  const activeFiltersWithLastActive = useSelector(selectActiveFilters);
  const withoutShadow = !!activeFiltersWithLastActive?.cuisines && !query.cuisine && !query.showStores;

  return (
    <Container withoutShadow={withoutShadow}>
      <HeaderBody>
        <InnerWidthContainer>
          {isMenuPage && currentRestaurant ? (
            <>
              {isMaxDesktop ? (
                <ShowOnMobileAndTabletOnlyFlex>
                  <SmallScreenMenuPageHeader onBackClick={onBackClick} />
                </ShowOnMobileAndTabletOnlyFlex>
              ) : (
                <HideOnMobileAndTabletOnlyFlex>
                  <DefaultHeader isHardLogoClick={isHardLogoClick} />
                </HideOnMobileAndTabletOnlyFlex>
              )}
            </>
          ) : (
            <DefaultHeader isHardLogoClick={isHardLogoClick} />
          )}
        </InnerWidthContainer>
      </HeaderBody>
      {children}
    </Container>
  );
};

export default PageHeader;
