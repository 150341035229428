import {ChangeEventHandler, Dispatch, Fragment, memo, SetStateAction} from 'react';

import styled from 'styled-components';
import {times} from 'lodash';

import {media} from '~/shared/theme/utils';

const Container = styled.div`
  display: flex;
  margin: auto;
  flex-direction: ${({theme}) => (theme.direction === 'ltr' ? 'row' : 'row-reverse')};
  ${media.minTablet`
    padding-left: 100px;      
  `}
`;

const Input = styled.input<{cardInputError: boolean}>`
  height: 40px;
  width: calc(25%);
  border: ${({theme, cardInputError}) =>
    `1px solid ${cardInputError ? theme.activeMoneyCardModal.input.error : theme.activeMoneyCardModal.input.border}`};
  border-radius: 1px;
  background-color: ${({theme}) => theme.activeMoneyCardModal.input.background};
  text-align: center;
  margin: 10px 5px;
  font-weight: bold;
  color: ${({theme}) => theme.activeMoneyCardModal.input.textColor};
  margin-bottom: 20px;
  direction: ltr;
  ${media.minMobile`
    margin: 10px;
    width: 91px;      
  `}
`;

const Spacer = styled.div`
  margin-top: 20px;
`;
interface ICreditCardNumberInput {
  setSubmitError: Dispatch<
    SetStateAction<{
      show: boolean;
      errorMsg: string;
    }>
  >;
  cardNumber: string[];
  setCardNumber: Dispatch<SetStateAction<string[]>>;
  cardInputError: boolean;
  setCardInputError: Dispatch<SetStateAction<boolean>>;
}

const CreditCardNumberInput = ({
  setSubmitError,
  cardNumber,
  setCardNumber,
  cardInputError,
  setCardInputError,
}: ICreditCardNumberInput) => {
  const updateCardDigitsByIndex = ({index, value}: {index: string; value: string}) => {
    const position = Number(index);
    cardNumber[position] = value;
    setCardNumber(cardNumber);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    setCardInputError(false);
    setSubmitError({show: false, errorMsg: ''});
    const containsOnlyNumber = /^\d+$/.test(event.target.value);
    if (!containsOnlyNumber || event.target.value.length > 4) {
      event.target.value = event.target.value.slice(0, -1);
    }
    updateCardDigitsByIndex({index: event.target.id, value: event.target.value});
    if (event.target.value.length === 4 && event.target.nextSibling) {
      const nextNextSibling = event.target.nextSibling.nextSibling as HTMLInputElement;
      nextNextSibling?.focus();
    }
  };

  return (
    <Container>
      {times(4, index => (
        <Fragment key={index}>
          <Input type="tel" cardInputError={cardInputError} id={index.toString()} onChange={handleInputChange} />
          {index !== 3 && <Spacer>-</Spacer>}
        </Fragment>
      ))}
    </Container>
  );
};
export default memo(CreditCardNumberInput);
