import {mapValues} from 'lodash';

export const eventNames = {
  initialized: 'initialized',
  restaurantChanged: 'restaurantChanged',
  login: 'login',
  routeChanged: 'routeChanged',
  locationChanged: 'locationChanged',
  addressChanged: 'addressChanged',
  deliveryMethodChanged: 'deliveryMethodChanged',
};

const cbs = mapValues(eventNames, () => new Set());

export function on(eventNameOrNames, cb) {
  const onEventNames = (Array.isArray(eventNameOrNames) ? eventNameOrNames : [eventNameOrNames]);
  const offs = onEventNames.map(eventName => {
    if (!cbs[eventName]) {
      throw new Error('Event doesnt exists');
    }
    cbs[eventName].add(cb);
    return function off() {
      cbs[eventName].delete(cb);
    };
  });

  return () => {
    offs.forEach(off => off());
  };
}

export function once(eventNameOrNames, cb) {
  const off = on(eventNameOrNames, (...args) => {
    cb(...args);
    off();
  });

  return off;
}

export function raise(eventName, eventData) {
  if (!cbs[eventName]) {
    throw new Error('Event doesnt exists');
  }
  cbs[eventName].forEach(cb => cb(eventData, eventName));
}
