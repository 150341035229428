import {memo} from 'react';

import styled from 'styled-components';

import TagImage from '~/assets/images/tag.svg?react';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flexCenterHorizontally} from '~/shared/theme/FlexLayout';

const TagComponent = styled(TagImage)`
  ${flipOnLTR`
    margin-left: 4px;
  `}
`;

const Root = styled.div<{fullWidth?: boolean}>`
  ${flexCenterHorizontally}
  align-items: center;
  border-radius: 4px;
  background-color: ${({theme}) => theme.colors.yellow};
  line-height: 20px;
  font-weight: 400;
  padding: 2px 8px;
  ${({fullWidth}) => (fullWidth ? 'width: 100%;' : 'width: fit-content;')}
`;

const TotalSavedTagView = ({fullWidth, amount, className}: {
  amount: number;
  className?: string;
  fullWidth?: boolean;
}) => {
  const {t} = getLocalizationService();

  return (
    <Root className={className} fullWidth={fullWidth}>
      <TagComponent />
      {t('discounts_total_saved_tag', {amount})}
    </Root>
  );
};

export default memo(TotalSavedTagView);
