import {createSelector} from 'reselect';

import {ScooberJobStatuses} from '~/shared/consts/scooberConsts';

import {AppState} from '../../configStore';

export const selectUserLastOrderScooberJob = (state: AppState) => state.userLastOrderScooberJob.data;

const selectTrakcerUrl = createSelector(selectUserLastOrderScooberJob, result => result?.trackerUrl);
const selectStatus = createSelector(selectUserLastOrderScooberJob, result => result?.status);

export const selectIsScooberJobCanceled = createSelector(
  selectStatus,
  status => status === ScooberJobStatuses.Canceled,
);

export const selectIsTrackerUrlEmpty = createSelector(
  selectTrakcerUrl,
  trackerUrl => !trackerUrl,
);
