import {useSelector} from 'react-redux';

import renderingForRobot from '~/shared/services/renderingForRobot';
import {setHasInitialScrollHappenedAction} from '~/shared/store/actions';
import {selectHasInitialScrollHappened} from '~/shared/store/storeModules/hasInitialScrollHappened/hasInitialScrollHappenedSelectors';

export function trackInitialScroll({store}) {
  const setInitialScrollHappened = () => {
    window.removeEventListener('scroll', setInitialScrollHappened);
    setTimeout(() => {
      store.dispatch(setHasInitialScrollHappenedAction(true));
    });
  };

  if (window.scrollY > 0 || renderingForRobot) {
    setInitialScrollHappened();
  } else {
    window.addEventListener('scroll', setInitialScrollHappened);
  }
}

export const useGetInitialScrollTracking = () => useSelector(selectHasInitialScrollHappened);
