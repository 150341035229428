import {memo, useCallback, useEffect} from 'react';

import styled from 'styled-components';

import {attachModal} from '~/shared/components/ReduxModal';
import {getLocalizationService} from '~/shared/services/localisationService';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import closedDialogButtonUrl from '~/assets/images/dialogs/closeDialogButton.svg';
import {trackEvent} from '~/shared/services/analytics';
import store from '~/shared/store';
import {selectCurrentRestaurantId} from '~/shared/store/selectors';

import {
  ClosedRestaurantModalRoot,
  DialogHeader,
  DialogHeaderText,
  ContentWrapper,
  RestaurantExplanationText,
  ToAllRestaurantsWrapper,
  CloseButton,
  ToAllRestaurantsButton,
  LineWrapper,
  Line,
  OrText,
  ToRestaurantMenuButtonWrapper,
  ToRestaurantMenuButton,
} from './NotAvailableRestaurantsModals.styled';

const ClosePopupButtonSvg = styled(ImageWithAlt).attrs({
  src: closedDialogButtonUrl,
})``;

const GeneralErrorModal = ({
  closeModal,
  args: {rootDataId, closeButtonDataId, onClose, headerText, contentText, buttons, trackEventName, analyticsData},
}) => {
  useEffect(() => {
    if (!trackEventName) {
      return;
    }

    trackEvent(trackEventName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentResId = selectCurrentRestaurantId(store.getState());
    if (!currentResId || !analyticsData) {
      return;
    }
    trackEvent('handleCloseRestaurantModal', {eventName: analyticsData.eventName, resId: currentResId});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {t} = getLocalizationService();

  const onCloseClick = useCallback(() => {

    if (analyticsData) {
      trackEvent('handleCloseRestaurantModal', {eventName: analyticsData.clickEventName, resId: analyticsData.resId, clickOptions: 'X'});
    }

    if (onClose) {
      onClose();
    }

    closeModal();
  }, [analyticsData, closeModal, onClose]);

  return (
    <ClosedRestaurantModalRoot data-id={rootDataId}>
      <DialogHeader>
        <CloseButton data-id={closeButtonDataId} onClick={onCloseClick}>
          <ClosePopupButtonSvg alt={t('close')} />
        </CloseButton>
        <DialogHeaderText id="modal-title">{headerText}</DialogHeaderText>
      </DialogHeader>
      <ContentWrapper>
        {contentText && <RestaurantExplanationText id="modal-content">{contentText}</RestaurantExplanationText>}
        <ToAllRestaurantsWrapper onClick={() => buttons[0].onClick(closeModal)}>
          <ToAllRestaurantsButton>{buttons[0].label}</ToAllRestaurantsButton>
        </ToAllRestaurantsWrapper>
        {buttons[1] && (
          <>
            <LineWrapper>
              <Line>
                <OrText>{t('or')}</OrText>
              </Line>
            </LineWrapper>
            <ToRestaurantMenuButtonWrapper onClick={() => buttons[1].onClick(closeModal)}>
              <ToRestaurantMenuButton>{buttons[1].label}</ToRestaurantMenuButton>
            </ToRestaurantMenuButtonWrapper>
          </>
        )}
      </ContentWrapper>
    </ClosedRestaurantModalRoot>
  );
};

const EnhancedGeneralErrorModal = memo(GeneralErrorModal);
EnhancedGeneralErrorModal.hideCloseButton = true;
EnhancedGeneralErrorModal.isErupt = true;

attachModal('generalErrorModal', EnhancedGeneralErrorModal);
