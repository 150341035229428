export enum MeasurementEnum {
  GRAM = 'Gram',
  MILLILITER = 'Milliliter',
  UNIT = 'Unit',
  KILOGRAM = 'Kilogram',
  NONE = 'None',
}

export type MeasurementType = EnumValueType<typeof MeasurementEnum>;

export default interface Dish {
  dishId: number;
  dishName: string;
  dishDescription: string;
  dishPrice: number;
  categoryId: number;
  choices: DishChoice[];
  dishImageUrl: string;
  isPopularDish: boolean;
  dishPopularityScore: number;
  enableComment: boolean;
  resId: number;
  shoppingCartDishId: number;
  quantity?: number;
  ageRestricted: boolean;
  restrictionAge: string;
  name: string;
  description: string;
  pricePerMeasurementUnit?: number;
  measurementUnitScale?: number;
  measurementUnit?: MeasurementType;
}

export interface DishChoice {
  choiceId: number;
  isForced: boolean;
  textBeforeChoosing: string;
  maxPicksAllowed: number;
  subs: DishChoiceSub[];
  subsChosen?: DishChoiceSub[];
}

export interface DishChoiceSub {
  subId: number;
  price: number;
  name: string;
  catalogId: string;
  ageRestricted: boolean;
  restrictionAge: string;
}
