import {memo, useCallback, useState, useRef, useEffect} from 'react';

import styled, {css} from 'styled-components';

import {media} from '~/shared/theme/utils';
import ErrorSection from '~/shared/components/ErrorSection';
import {AddCreditCardModes} from '~/shared/consts/checkoutConsts';
import {getLocalizationService} from '~/shared/services/localisationService';
import apiService from '~/shared/services/apiService';
import coloredamexIconUrl from '~/assets/images/icons/cards/coloredamex.svg';
import dinersclubIconUrl from '~/assets/images/icons/cards/diners-club.svg';
import mastercardIconUrl from '~/assets/images/icons/cards/mastercard.svg';
import visaIconUrl from '~/assets/images/icons/cards/visa_icon.svg';
import defaultIconUrl from '~/assets/images/icons/cards/default.svg';
import useIsMounted from '~/shared/hooks/useIsMounted';
import {safeJsonParse} from '~/shared/utils/general';
import {handleRefreshToken} from '~/shared/services/auth';
import {is401Error} from '~/shared/services/apiErrorService';

const IFrameMinimal = styled.iframe`
  height: 194px;
  width: 100%;
  border: none;
`;

const IFrameOneLine = styled.iframe`
  height: 120px;
  border: none;
  width: 100%;
  right: auto;
  ${({is3dStep}) =>
    is3dStep &&
    css`
      height: 420px;
      width: 375px;
      right: -15px;
      ${media.minLargeTablet`
      &&& {
        width: 100%;
        right: auto;
      }
    `}
    `}
`;

const IFrameExtended = styled(IFrameMinimal)`
  height: 470px;
`;

const IFRAMES_BY_TYPE = {
  [AddCreditCardModes.ONE_LINE]: IFrameOneLine,
  [AddCreditCardModes.EXTENDED]: IFrameExtended,
  [AddCreditCardModes.MINIMAL]: IFrameMinimal,
};

// const mockIframeCall = () =>
//   new Promise(resolve => {
//     // staging telepay
//     const oneLineStagingFormId = 'ff2d66b2-cd3d-ea11-b811-ecebb8951f7e';
//     // const oldFormStagingFormId = '303ca681-1540-ea11-b811-ecebb8951f7e';
//     const formId = oneLineStagingFormId;
//     const orderId = '096e5c3b-4255-41cf-a8bc-44fe1a1f552a';
//     setTimeout(
//       () =>
//         resolve({
//           data: `https://redirect.telepay.co.il/?formId=${formId}&orderId=${orderId}&moneycardId=0&websiteId=10bis&domainId=10bis`,
//         }),
//       500,
//     );
//   });

const AddCreditCardIFrame = ({mode, onSuccess}) => {
  const [is3dStep, setIs3dStep] = useState(false);
  const [creditCardResponseData, setCreditCardResponseData] = useState();
  const [iframeUrl, setIframeUrl] = useState();
  const iframeUrlRequestSent = useRef(false);

  const isMounted = useIsMounted();

  const iframeRef = useRef();
  const lastHealthCheck = useRef();

  const {t, currentLanguageDirection} = getLocalizationService();

  const setIframeData = useCallback(() => {
    const strings = {
      monthLabel: t('month'),
      yearLabel: t('year'),
      cvvLabel: mode === AddCreditCardModes.EXTENDED ? t('cvv_text') : t('cvv'),
      personalIdLabel: t('add_credit_card_personal_id_field'),
      cardOwnerLabel: t('add_credit_card_owner_name'),
      textAlign: currentLanguageDirection === 'ltr' ? 'left' : 'right',
      creditcardError: t('creditcardError'),
      expirationError: t('expirationError'),
      requiredField: t('required_field'),
      creditCardImgAlt: t('credit_card'),
      creditCardNumberLabelAndAlt: t('credit_card_number'),
      creditCardExpirationDateLabelAndAlt: t('credit_card_expiration_date'),
      submitButton: t('save_card_details'),
    };

    const imageUrls = {
      'american-express': coloredamexIconUrl,
      'diners-club': dinersclubIconUrl,
      mastercard: mastercardIconUrl,
      visa: visaIconUrl,
      default: defaultIconUrl,
    };

    iframeRef?.current?.contentWindow?.postMessage(
      {type: 'setData', strings, imageUrls},
      'https://redirect.telepay.co.il/',
    );
  }, [currentLanguageDirection, t, mode]);

  const receiveMessage = useCallback(
    async event => {
      const weCanTrustTheSender = [
        'https://www.10bis.co.il',
        'https://redirect.telepay.co.il',
        'https://staging.10bis.co.il',
        'https://10bis-spa-server-release-candidate-staging.10bis.co.il',
        'https://10bis-spa-server-release-candidate-dev.10bis.co.il',
        'https://10bis-spa-server-staging.tenbis.cloud',
        'https://tenbis-webapplication-staging.tenbis.cloud', // not sure if the rest are necessary.
        'https://10bis-spa-server-dev.azurewebsites.net',
        'https://10bis-spa-server-dev-staging.azurewebsites.net',
        'https://10bis-spa-server-staging.azurewebsites.net',
        'https://10bis-spa-server-release-candidate-staging.azurewebsites.net',
        'https://10bis-spa-server-release-candidate-dev.azurewebsites.net',
      ].includes(event.origin);

      if (!weCanTrustTheSender || !isMounted() || !event.data) {
        return;
      }

      const response = typeof event.data === 'string' ? safeJsonParse(event.data?.replace(/&quot;/g, '"')) : event.data;
      if (!response) {
        return;
      }

      if (setIs3dStep && response.message === 'healthCheck') {
        // the iframe sends every healthCheck message every 1 sec.
        // we use it to know when the user finished the filling proccess.
        const now = new Date().getTime();
        setTimeout(() => {
          if (!isMounted()) {
            return;
          }

          if (now - lastHealthCheck.current >= 0) {
            setIs3dStep(true);
          }
        }, 1000);
        lastHealthCheck.current = now;
      }

      if (response.message === 'iframeLoaded') {
        return setIframeData();
      }

      const isSuccessMessage = 'isSuccessMessage' in response;
      if (!isSuccessMessage) {
        return;
      }

      setCreditCardResponseData(null);

      if (!response.isSuccessMessage) {
        setCreditCardResponseData(response.contentText);
        const {data: newIframeUrl} = await apiService.getInsertNewCreditCardIframeUrl({creditCardFormType: mode});

        setIframeUrl(newIframeUrl);
        return;
      }

      onSuccess(response);
    },
    [isMounted, onSuccess, setIframeData, mode],
  );

  const insertNewCreditCardIframe = useCallback(async () => {
    try {
      if (!isMounted()) {
        return;
      }

      const {data: resIframeUrl} = await apiService.getInsertNewCreditCardIframeUrl({creditCardFormType: mode});

      setIframeUrl(resIframeUrl);
    } catch (e) {
      if (is401Error(e)) {
        await handleRefreshToken(e, insertNewCreditCardIframe);
      }
    }
  }, [isMounted, mode]);

  useEffect(() => {
    if (!iframeUrlRequestSent.current) {
      iframeUrlRequestSent.current = true;
      insertNewCreditCardIframe();
    }
  }, [insertNewCreditCardIframe]);

  useEffect(() => {
    window.addEventListener('message', receiveMessage);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [receiveMessage]);

  const IframeByType = iframeUrl && mode && IFRAMES_BY_TYPE[mode];

  return (
    <>
      {creditCardResponseData && !creditCardResponseData.isSuccessMessage && (
        <ErrorSection error={creditCardResponseData} />
      )}
      {IframeByType && (
        <IframeByType
          is3dStep={is3dStep}
          key={iframeUrl}
          ref={iframeRef}
          src={iframeUrl}
          aria-label={t('press_tab_to_continue_into_the_credit_card_information_form')}
        />
      )}
    </>
  );
};

export default memo(AddCreditCardIFrame);
