import {memoize} from 'lodash';

import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';
import {Address, LocalAddress, RestaurantFromGet} from '~/shared/store/models';
import {PickupRuleModel} from '~/shared/store/models/Restaurant/RestaurantFromGet';
import {isCurrentTimeKeyAsap} from '~/shared/utils/general';

type RuleData = {
  ruleId: number;
  deliveryFee: number;
  deliveryFeeBeforeDiscount: number;
};

export type ActiveDeliveryRulesData = Record<
  DELIVERY_RULES,
  | RuleData[]
  | null
>;

export const EMPTY_DELIVERY_RULES_DATA: ActiveDeliveryRulesData = {
  [DELIVERY_RULES.ASAP]: null,
  [DELIVERY_RULES.POOL]: null,
  [DELIVERY_RULES.FUTURE]: null,
};

const isValidFutureRule = (restaurant: RestaurantFromGet, id: number, type: DELIVERY_RULES) => {
  if (type !== DELIVERY_RULES.FUTURE) {
    return false;
  }

  const ruleTimes = restaurant.availableFutureDatesAndTimes?.find(({ruleId}) => ruleId === id);
  return (ruleTimes?.times && ruleTimes?.times.length > 1) || !isCurrentTimeKeyAsap(ruleTimes?.times[0]);
};

// second arg address is needed for memoize key resolver
export const getActiveDeliveryRules = memoize((restaurant: RestaurantFromGet, _?: Address | LocalAddress | undefined): ActiveDeliveryRulesData => {
  return restaurant.deliveryRules.reduce<ActiveDeliveryRulesData>(
    (activeDeliveryRulesData, {isActiveNow, type, deliveryFee, deliveryFeeBeforeDiscount, id}) => {
      // Future rule is always inactive. If presented, should be added as active rule
      if (isActiveNow || isValidFutureRule(restaurant, id, type)) {
        const ruleData: RuleData = {
          ruleId: id,
          deliveryFee,
          deliveryFeeBeforeDiscount,
        };

        activeDeliveryRulesData[type] = [...(activeDeliveryRulesData[type] || []), ruleData];
      }

      return activeDeliveryRulesData;
    }, {...EMPTY_DELIVERY_RULES_DATA});
}, (restaurant, address) => `${restaurant.id}-${address?.addressKey || 'no-address'}`);

export type ActiveRulesData = ActiveDeliveryRulesData & {
  pickup: PickupRuleModel | null;
};

export const EMPTY_RULES_DATA: ActiveRulesData = {
  ...EMPTY_DELIVERY_RULES_DATA,
  pickup: null,
};

export const getCurrentRestaurantActiveRules = (currentRestaurant: RestaurantFromGet, address: Address | LocalAddress | undefined): ActiveRulesData => {
  const activeDeliveryRules = getActiveDeliveryRules(currentRestaurant, address);

  return {
    ...activeDeliveryRules,
    pickup: currentRestaurant?.pickupRule?.isActiveNow ? currentRestaurant.pickupRule : null,
  };
};
