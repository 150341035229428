import {useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import styled from 'styled-components';

import {flexColumn} from '~/shared/theme/FlexLayout';
import {attachModal} from '~/shared/components/ReduxModal';
import actions from '~/shared/store/actions';
import {CentralLoading} from '~/shared/components/Loaders';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {selectCurrentRestaurant, selectCurrentRestaurantAbout} from '~/shared/store/selectors';
import {body14Normal, subheader22Bold} from '~/shared/theme/typography';
import {AboutRestaurantSection, FeaturesSection, DeliverySection} from '~/shared/components/restaurantAbout';

const Root = styled.div`
  background-color: ${({theme}) => theme.colors.surface};
  padding: 30px;
  border-radius: 10px;
`;

const ModalHeader = styled.div`
  display: flex;
`;

const RestaurantImage = styled(ImageWithAlt)`
  height: 80px;
  width: 80px;
  border-radius: 3px;
  ${flipOnLTR`
    margin-left: 10px;
  `};
`;

const RestaurantDetails = styled.div`
  ${flexColumn};
  flex-grow: 1;
  ${flipOnLTR`
    text-align: right;
  `}
`;

const RestaurantName = styled.h4`
  ${subheader22Bold};
  line-height: 24px;
  color: ${({theme}) => theme.menu.details.nameColor};
  margin-bottom: 7px;
`;

const RestaurantDetailsBody = styled.div`
  ${flexColumn};
`;

const RestaurantDetailsLine = styled.p`
  color: ${({theme}) => theme.colors.gray000};
  ${body14Normal};
  line-height: 20px;
`;

const ModalBody = styled.div`
  margin-top: 30px;
  max-height: 360px;
  overflow-y: auto;
`;

const RestaurantAboutModal = () => {
  const dispatch = useDispatch();
  const restaurantData = useSelector(selectCurrentRestaurant);
  const aboutRestaurantData = useSelector(selectCurrentRestaurantAbout);

  useEffect(() => {
    if (restaurantData.id && !aboutRestaurantData) {
      dispatch(actions.setAboutRestaurant({restaurantId: restaurantData.id}));
    }
  }, [dispatch, restaurantData.id, aboutRestaurantData]);

  return (
    <>
      {isEmpty(restaurantData) || isEmpty(aboutRestaurantData) ? (
        <CentralLoading />
      ) : (
        <Root>
          <ModalHeader>
            <RestaurantImage src={restaurantData.logoUrl} alt={restaurantData.name} />
            <RestaurantDetails>
              <RestaurantName id="modal-title">{restaurantData.name}</RestaurantName>
              <RestaurantDetailsBody id="modal-content">
                <RestaurantDetailsLine>{restaurantData.address}</RestaurantDetailsLine>
                <RestaurantDetailsLine>{restaurantData.phoneNumber}</RestaurantDetailsLine>
              </RestaurantDetailsBody>
            </RestaurantDetails>
          </ModalHeader>
          <ModalBody>
            <AboutRestaurantSection />
            <FeaturesSection />
            <DeliverySection />
          </ModalBody>
        </Root>
      )}
    </>
  );
};

attachModal('restaurant_about_modal', RestaurantAboutModal);
