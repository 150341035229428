import {memo} from 'react';

import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {flipOnLTR, media} from '~/shared/theme/utils';
import {getLocalizationService} from '~/shared/services/localisationService';
import {Link} from '~/shared/router';
import useOpenSignIn from '~/shared/hooks/useOpenSignIn';
import {selectIsBenefitsHighlightEnabled, selectUserData} from '~/shared/store/selectors';
import {body12Normal, body14Bold, body14Normal, body16Bold, body16Normal, body18Normal} from '~/shared/theme/typography';
import {flexCenter, FlexCenterVertically, flexColumn} from '~/shared/theme/FlexLayout';
import ProfileIcon from '~/assets/images/icons/profileIcon.svg?react';
import {convertGapToMargin} from '~/shared/theme/convertGapToMargin';
import headerTriangleButtonUrl from '~/assets/images/header_triangle_button.svg';
import {useIsMaxMobile} from '~/shared/hooks/deviceInfo';

import ImageWithAlt from '../ImageWithAlt';

const Root = styled.div`
  ${flexColumn}
  ${flexCenter}
  ${body16Normal}
  height: auto;
  line-height: 18px;
  ${media.minMobile`
    ${body18Normal}
    line-height: 27px;
  `};
`;

const MenuTriangle = styled(ImageWithAlt).attrs({
  src: headerTriangleButtonUrl,
})`
  display: block;
  cursor: pointer;
  width: 11px;
  height: 6px;
  ${flipOnLTR`
    margin-right: 8px;
    ${media.maxMobile`
      margin-right: 5px;
    `};
  `}
  ${media.maxMobile`
    margin-top: 1px;
  `};
`;

const UserText = styled.div`
  color: ${({color}) => color};
  ${body16Bold}
  ${media.maxMobile`
    ${body14Bold}
  `};
`;

const UserBenefitText = styled.div`
  color: ${({color}) => color};
  ${body14Normal}
  margin-top: 3px;
  ${media.maxMobile`
    ${body12Normal}
    margin-top: 0px;
  `}
  ${flipOnLTR`
    text-align: right;
  `}
`;

const LoginLink = styled(Link)`
  color: ${({theme}) => theme.colors.primaryText};
  ${body14Normal}
  cursor: ${({onClick}) => (onClick ? 'pointer' : 'inherit')};
  line-height: 14px;
  ${media.minMobile`
    ${body16Normal}
    line-height: 16px;
  `};
`;

const UserStatusContainer = styled.div`
  ${flexCenter}
  ${({theme}) => convertGapToMargin('0 10px', theme.isLTR)}
  ${media.minMobile`
    line-height: 1;
  `};
`;

const IconTextContainer = styled(FlexCenterVertically)`
  ${({theme}) => convertGapToMargin('0 6px', theme.isLTR)}
`;

const ProfileIconMobile = styled(ProfileIcon)<{$isUserBenefitsHighlight: boolean}>`
  margin-top: ${({$isUserBenefitsHighlight}) => ($isUserBenefitsHighlight ? '1px' : '0')};
`;

const UserStatus = () => {
  const {t} = getLocalizationService();
  const userData = useSelector(selectUserData);
  const isUserBenefitsHighlight = useSelector(selectIsBenefitsHighlightEnabled);
  const {openLogin} = useOpenSignIn();
  const isMaxMobile = useIsMaxMobile();
  
  return (
    <Root>
      {userData ? (
        <UserStatusContainer>
          {!isMaxMobile && <ProfileIcon />}
          <div>
            <FlexCenterVertically>
              <IconTextContainer>
                {isMaxMobile && <ProfileIconMobile $isUserBenefitsHighlight={isUserBenefitsHighlight} height={14} width={14} viewBox="0 0 34 34" />}
                <UserText>{t('hi_xxx', {name: userData.firstName})} </UserText>
              </IconTextContainer>
              <MenuTriangle noAlt/>
            </FlexCenterVertically>
            {isUserBenefitsHighlight && <UserBenefitText>{t('10Bis_card_holder')}</UserBenefitText>}
          </div>
        </UserStatusContainer>
      ) : (
        <LoginLink onClick={openLogin}>{t('login')}</LoginLink>
      )}
    </Root>
  );
};

export default memo(UserStatus);
