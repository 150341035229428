import {memoize} from 'lodash';

import {RestaurantFromSearch} from '~/shared/store/models';
import {
  compareActivePoolRule,
  compareRestaurantsBeforeExceedingSum,
  compareRestaurantsHaveOrders,
  compareRestaurantsOrderSum,
  compareScooberDelivery,
} from '~/shared/store/selectors/selectorUtils/restaurantsListSort/sharedFunctions';
import {
  bothRestaurantsExceededMinimalPrice, bothRestaurantsHaveActivePoolRule,
  bothRestaurantsHaveNoOrders,
} from '~/shared/store/selectors/selectorUtils/restaurantsListSort/helpers';

const sortOpenGroup = (list: RestaurantFromSearch[]) => {
  return [...list].sort((a: RestaurantFromSearch, b: RestaurantFromSearch) => {
    const poolOrderCompareResult = compareActivePoolRule(a, b);

    if (poolOrderCompareResult) return poolOrderCompareResult;

    if (bothRestaurantsHaveActivePoolRule(a, b)) {
      const haveOrdersCompareResult = compareRestaurantsHaveOrders(a, b);

      if (haveOrdersCompareResult) return haveOrdersCompareResult;

      if (!bothRestaurantsHaveNoOrders(a, b)) {
        const scooberDeliveryResult = compareScooberDelivery(a, b);
        const orderSumCompareResult = compareRestaurantsOrderSum(a, b);

        if (orderSumCompareResult) return orderSumCompareResult;

        if (!bothRestaurantsExceededMinimalPrice(a, b)) {
          const exceedingSumCompareResult = compareRestaurantsBeforeExceedingSum(a, b);
          if (exceedingSumCompareResult) return scooberDeliveryResult || exceedingSumCompareResult;
        }

        return scooberDeliveryResult;
      }
    }

    return 0;
  });
};

export default memoize(sortOpenGroup);
