import {createContext} from 'react';

import {SHOPPING_CART_DELIVERY_TYPE} from '~/shared/utils/restaurants/deliveryOptions';
import {DeliveryMethod} from '~/shared/consts/restaurantConsts';
import {OnCheckoutOpenProps} from '~/shared/services/shoppingCart';
import {Coupon, MenuCategory, RestaurantFromGet, ShoppingCartDish, User} from '~/shared/store/models';
import {DishAssignedUser} from '~/shared/store/models/Dish/DishAssignedUsers';
import {MutatedFutureOrderAvailableDatesAndTimes} from '~/shared/store/models/FutureOrderAvailableDatesAndTimes';
import Permits from '~/shared/store/models/Order/OrderPermits';
import {BillingLineWithDiscountsData} from '~/shared/store/models/ShoppingCart/IShoppingCart';
import {DishWithSub} from '~/shared/store/selectors';
import {ACTION_MADE_FROM_ENUM} from '~/shared/utils/ageRestriction';

export interface DishActionProps {
  shoppingCartDishId: number;
  dishId: number;
  dishSinglePrice: number;
  dishName: string | undefined;
  category: MenuCategory | undefined;
  ageRestricted: boolean;
  quantity?: number;
}

export interface IShoppingCartContext {
  currentRestaurant: RestaurantFromGet | undefined;
  dishes:
    | (ShoppingCartDish & {
        totalPrice: number;
      })[]
    | null;
  userData:
    | (User & {
        moneycardActivationRequired: boolean;
      })
    | null;
  billingLines: BillingLineWithDiscountsData[];
  deliveryMethod: DeliveryMethod;
  currentCoupon: Coupon | null;
  isDeliveringToCurrentAddress: boolean | 0;
  shouldOpenModal: boolean;
  isContrastActive: boolean;
  futureOrderAvailableDatesAndTimes: MutatedFutureOrderAvailableDatesAndTimes[];
  isFutureOrderEnabled: boolean;
  dishesWithSubs: DishWithSub;
  isShoppingCartHasAgeRestrictionDishOrSub: boolean;
  isAgeConfirmed: boolean;
  currentModalName: string;
  isMinLargeMobile: boolean;
  assignedUsers: DishAssignedUser[];
  isDirtyShoppingCart: boolean;
  categoriesList: MenuCategory[];
  isMaxHeight: boolean;
  isMenuLoading: boolean;
  permits: Permits;
  isReorder?: boolean | undefined;
  incrementDishQuantity: (dishInfo: DishActionProps) => () => void;
  decrementDishQuantity: (dishInfo: DishActionProps) => () => void;
  removeDish: (dishInfo: DishActionProps) => () => void;
  openDish: (payload: {
    dishId: number;
    shoppingCartDishId: number;
    isCheckoutPage?: boolean;
    onClose?: () => void;
  }) => void;
  setCurrentModal: (name: string, args: any) => void;
  setActionMadeFrom: (actionMadeFrom: ACTION_MADE_FROM_ENUM) => void;
  onCheckoutOpen: (props: OnCheckoutOpenProps) => any;
  removeQueries: (queriesToRemove?: string[]) => void;
  hideAsapPooledToggler?: boolean;
  isMinLargeTablet: boolean;
  shoppingCartDeliveryType: SHOPPING_CART_DELIVERY_TYPE | null;
  openOrderTypeMenuWithError: () => void;
  isInitialOrder: boolean;
  showSkeletonLoader?: boolean;
  isIncreaseQuantityDisbled?: boolean;
  isUserBenefitsHighlightEnabled?: boolean;
}

export const ShoppingCartContext = createContext<IShoppingCartContext>({} as IShoppingCartContext);
