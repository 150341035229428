import {round} from 'lodash';

import {BillingLineType} from '~/shared/consts/checkoutConsts';
import {IShoppingCartBillingLine} from '~/shared/store/models/ShoppingCart/IShoppingCart';

export const calculateTotalSavedAmount = (billingLines?: IShoppingCartBillingLine[], {
  deliveryFee,
  deliveryFeeBeforeDiscount,
}: {
  deliveryFee?: number;
  deliveryFeeBeforeDiscount?: number;
} = {}) => {
  const initialDeliveryDiscount = (() => {
    if (deliveryFee !== undefined && deliveryFeeBeforeDiscount !== undefined && deliveryFeeBeforeDiscount > deliveryFee) {
      return deliveryFeeBeforeDiscount - deliveryFee;
    }

    return 0;
  })();

  const totalSaved =
    (billingLines?.reduce((currentSaved, {type, amount}) => {
      if (type === BillingLineType.DiscountCoupon || type === BillingLineType.DeliveryDiscount) {
        return currentSaved + Math.abs(amount);
      }

      return currentSaved;
    }, 0) || 0) + initialDeliveryDiscount;
  return round(totalSaved, 2);
};
