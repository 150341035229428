import evaluateRestriction from './evaluateRestriction';
import calculateFractions from './calculateFractions';
import {LogicDetail, Rule, Variant} from './config';
import {Context, Modes} from './settings';

const sortVariantsByWeight = (variants: Variant[]) => {
  return variants.sort((a: Variant, b: Variant) => b.weight - a.weight);
};

function getFirstRuleMatch(detail: LogicDetail, context: Context) {
  return detail.rules.find((rule: Rule) => evaluateRestriction(rule.restrictions, context)) || null;
}

function getId(detail: LogicDetail, context: Context): string {
  if (detail.bucketBy === 'AnonUserId') return context.anonUserId;

  throw new Error('Unexpected bucketBy ', detail.bucketBy);
}

const checkForEnabledVariant = (variant: Variant): Variant | null => {
  const variantWeight = +variant.weight.toFixed(1);
  return variantWeight === 1 ? variant : null;
};

function getVariant(rule: Rule, fraction: number): Variant {
  if (fraction > 1) throw new Error(`Variant fraction must not be > 1, was ${fraction}.`);

  const totalWeight = rule.variants.reduce((partialSum, v) => partialSum + v.weight, 0);

  // works through the variants until we have reached the "variantFraction" for the user
  let cumulativeFraction = 0;
  const variant = rule.variants.find(v => {
    cumulativeFraction += v.weight / totalWeight;
    return cumulativeFraction >= fraction;
  });

  // This should never happen since the cumulative fraction is always <= 1.
  if (!variant) throw new Error('No matching variant found.');
  return variant;
}

function evaluateIdHashLogic(detail: LogicDetail, context: Context): Variant | null {
  const matchingRule = getFirstRuleMatch(detail, context);
  if (!matchingRule) return null;

  if (context.mode && context.mode() === Modes.Disabled) {
    if (matchingRule.variants) {
      const sortedVariants = sortVariantsByWeight(matchingRule.variants);
      return checkForEnabledVariant(sortedVariants[0]); // if a weighting of 1 is found return variant else null for default bucketing
    }
    return null; // default bucketing
    
  }

  if (matchingRule.audienceFraction === 0) {
    return null;
  }

  // Calculate the audienceFraction and variantFraction
  const key = detail.prefix + getId(detail, context);
  const {audienceFraction, variantFraction} = calculateFractions(key);

  // User is not bucketed to the experiment
  if (matchingRule.audienceFraction < audienceFraction) return null;

  // Return the variant
  return getVariant(matchingRule, variantFraction);
}

export default evaluateIdHashLogic;
