import styled, {css} from 'styled-components';

export const flexColumnCenterVertically = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FlexColumnCenterVertically = styled.div`
  ${flexColumnCenterVertically};
`;
