import {createContext} from 'react';

import {ApiError} from '~/shared/services/apiErrorService';
import {User} from '~/shared/store/models';

export interface VerificationFormContextProps {
  userError:
    | (ApiError & {
        data: User;
      })
    | undefined;
  isRequireCaptcha?: boolean;
}

export const VerificationFormContext = createContext<VerificationFormContextProps>({} as VerificationFormContextProps);
