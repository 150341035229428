import {memo} from 'react';

import {DeleteAccountWarning} from '~/deleteAccount';
import {attachModal} from '~/shared/components/ReduxModal';

const RemoveAccountModal = ({closeModal}) => {
  return <DeleteAccountWarning onCancel={closeModal} onContinue={closeModal} />;
};

attachModal('remove_account_modal', memo(RemoveAccountModal));
