import {useMemo} from 'react';

import {useSelector} from 'react-redux';

import {
  selectCurrentAddress,
  selectCurrentDeliveryMethod,
  selectCurrentRestaurant,
  selectCurrentRestaurantAvailableDeliveryRules,
  selectIsCurrentRestaurantActiveNow,
  selectIsInitialOrderForCurrentRes,
  selectOrderDeliveryRule,
} from '~/shared/store/selectors';
import {DELIVERY_RULES, DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {getLocalizationService} from '~/shared/services/localisationService';
import {isFutureOnlyRestaurant, isSDFORestaurant} from '~/shared/utils/restaurants/deliveryOptions';
import {getOrderTypeString} from '~/shared/services/analytics/utils';

const useCurrentRuleData = ({isCheckout}: {
  isCheckout?: boolean;
}) => {
  const {t} = getLocalizationService();

  const address = useSelector(selectCurrentAddress);
  const isActiveNow = useSelector(selectIsCurrentRestaurantActiveNow);
  const activeRules = useSelector(selectCurrentRestaurantAvailableDeliveryRules);
  const currentMethod = useSelector(selectCurrentDeliveryMethod);
  const currentDeliveryRule = useSelector(selectOrderDeliveryRule);
  const isInitialOrder = useSelector(selectIsInitialOrderForCurrentRes);
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const orderTypeString = getOrderTypeString(currentMethod, currentDeliveryRule);

  const isOnlyFuture = currentRestaurant && isFutureOnlyRestaurant(currentRestaurant, address);

  const isButtonEnabled = useMemo(() => {
    if (currentRestaurant?.tempClosedReason) {
      return false;
    }

    if (currentMethod === DeliveryMethods.PICKUP) {
      return !isCheckout &&
        (!!activeRules.Asap || !!activeRules.Future || !!activeRules.Pool);
    }

    return !!activeRules.Future ||
      (!!activeRules.pickup && !isCheckout) ||
      (activeRules.Asap && activeRules.Pool);
  }, [currentRestaurant, activeRules, currentMethod, isCheckout]);

  const arrowText = useMemo(() => {
    if (isActiveNow) {
      return null;
    }

    if (currentMethod === DeliveryMethods.PICKUP && !activeRules.pickup) {
      return t('order_delivery');
    }

    if (
      currentMethod === DeliveryMethods.DELIVERY &&
      !activeRules.Asap &&
      !activeRules.Pool &&
      !activeRules.Future &&
      isButtonEnabled
    ) {
      return t('order_pickup');
    }

    return null;
  }, [isActiveNow, isButtonEnabled, currentMethod, activeRules, t]);

  const etaBoxText = useMemo(() => {
    return (isInitialOrder && !isCheckout && currentMethod !== DeliveryMethods.PICKUP) ?
      t('choose_delivery_time') :
      undefined;
  }, [isInitialOrder, currentMethod, t, isCheckout]);

  const showFutureOrderDisclaimer =
    currentRestaurant &&
    isCheckout &&
    currentMethod === DeliveryMethods.DELIVERY &&
    currentDeliveryRule === DELIVERY_RULES.FUTURE &&
    isOnlyFuture &&
    !isSDFORestaurant(currentRestaurant, address);

  return {
    isActiveNow,
    isButtonEnabled,
    arrowText,
    etaBoxText,
    isTempClosed: Boolean(currentRestaurant?.tempClosedReason),
    orderTypeString,
    showFutureOrderDisclaimer,
  };
};

export default useCurrentRuleData;
