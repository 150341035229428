import styled from 'styled-components';

import {flipOnLTR} from '~/shared/theme/utils';

const Textarea = styled.textarea.attrs({
  maxLength: 500,
})<{error?: boolean}>`
  ${flipOnLTR`
    text-align: right;
  `};
  width: 100%;
  height: 50px;
  margin: 0;
  border: 1px solid ${({theme, error}) => (error ? theme.colors.error : theme.colors.gray500)};
`;

export default Textarea;
