import {createLogger} from '~/shared/logging';

import {makeReducer} from '../../redux-toolbelt';

import {setCurrentModal} from './currentModalActions';

const logger = createLogger('currentModalReducer');

export type CurrentModalState = {
  name: string;
  args?: Record<string, any>;
} | null;

export default makeReducer<CurrentModalState>(
  setCurrentModal,
  (currentState, {payload: requestedModal}: ReturnType<typeof setCurrentModal>) => {
    const currentModalName = currentState?.name;
    const requestedModalName = requestedModal?.name;

    if (currentModalName === 'update_user_details' && requestedModalName !== 'successModal') {
      logger.warn('currentModal\'s reducer prevented new modal opening', {currentModalName, requestedModalName});
      return currentState;
    }

    return requestedModal;
  },
);
