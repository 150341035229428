import {memo} from 'react';

import styled from 'styled-components';

import {HiddenSpan} from '~/shared/components/styled';

const Span = styled.span`
  padding: 0;
  margin: 0;
`;

const PriceWrapper = styled.div`
  white-space: nowrap;
`;

const NegativePriceLabel = ({currency, formatedPrice}) => {
  return (
    <PriceWrapper>
      <Span aria-hidden="true">{currency}</Span>
      <Span aria-hidden="true">&minus;</Span>
      <HiddenSpan>&minus;</HiddenSpan>
      <HiddenSpan>{currency}</HiddenSpan>
      {formatedPrice}
    </PriceWrapper>
  );
};

export default memo(NegativePriceLabel);
