import {memo} from 'react';

import styled from 'styled-components';

import {media} from '~/shared/theme/media';
import {getLocalizationService} from '~/shared/services/localisationService';

import {SubmitButton} from './Styled';
import {MessageStatuses} from './shared';

const AvailableBalance = styled.div<{validationError: boolean}>`
  width: 100%;
  padding-top: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    ${({theme, validationError}) => validationError && `color: ${theme.colors.error}`};
  }
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${({theme}) => theme.colors.text};
`;

const Symbol = styled.div`
  position: absolute;
  color: ${({theme}) => theme.colors.text2};
  height: 100%;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  left: -24px;
`;

const BalanceInput = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  position: relative;
  height: 48px;
  width: 180px;
`;

const Input = styled.input<{validationError: boolean}>`
  &&& {
    font-size: 32px;
    font-weight: bold;
    width: 100%;
  }

  color: ${({theme}) => theme.colors.text2};
  direction: ltr;
  height: 100%;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
  border: none;
  ${({theme, validationError}) =>
    (validationError
      ? `
        border-bottom: 2px solid ${theme.colors.error}`
      : `
        border-bottom: 2px solid ${theme.colors.surfaceTitle}
      `)};
`;

const FormContent = styled.div`
  background: transparent;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  ${media.minMobile`
    background-color: ${({theme}) => theme.colors.surface};
  `}
`;

const Title = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: ${({theme}) => theme.colors.text2};
`;

const DisclamierBlock = styled.div`
  margin-top: 32px;
`;

const Disclaimer = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${({theme}) => theme.colors.text};
`;

const ChargeForm = ({
  availableBalance,
  validationError,
  amount,
  disabled,
  handleInputChange,
  toVerifyStatus,
}: {
  availableBalance: number | string;
  validationError: boolean;
  amount: number | string;
  disabled: boolean;
  handleInputChange: (value: string) => void;
  toVerifyStatus: () => void;
  status: MessageStatuses;
}) => {
  const {t} = getLocalizationService();
  return (
    <>
      <FormContent>
        <Title>{t('how_much_to_load')}</Title>
        <BalanceInput>
          <InputContainer>
            <Input
              type="text"
              validationError={validationError}
              autoFocus
              value={amount}
              onChange={e => handleInputChange((e.target as HTMLInputElement)?.value)}
            />
            <Symbol>₪</Symbol>
          </InputContainer>
          <AvailableBalance validationError={validationError}>
            <Text>{t('available_credit_balance__xxx', {availableBalance})}</Text>
          </AvailableBalance>
        </BalanceInput>
        <DisclamierBlock>
          <Disclaimer>{t('charge_disclaimer')}</Disclaimer>
          <Disclaimer>{t('charge_disclaimer2')}</Disclaimer>
        </DisclamierBlock>
        <SubmitButton disabled={disabled} onClick={toVerifyStatus}>
          {t('continue')}
        </SubmitButton>
      </FormContent>
    </>
  );
};

export default memo(ChargeForm);
