import {chunk} from 'lodash';
import {createSelector} from 'reselect';

import {EMPTY_ARRAY} from '~/shared/consts/commonConsts';
import {RESTAURANTS_ROW_HEIGHT, SPACE_BETWEEN_ROWS} from '~/shared/utils/restaurantListUtils';
import {RestaurantsGroupTypes} from '~/shared/consts/restaurantConsts';

import {AppState} from '../../configStore';
import {selectUserDevice} from '../selectors.index';

const selectChainsBase = (state: AppState) => state.chains;

export const itemsInRow = {
  isMinLargeDesktop: 4,
  isMinDesktop: 3,
  isMinTablet: 2,
  isMobile: 1,
};

export const selectChains = createSelector(selectChainsBase, ({loading, data}) =>
  (loading || !data ? EMPTY_ARRAY : data),
);

export const selectSortedChains = createSelector(selectChains, selectUserDevice, (chains, userDevice) => {
  if (!chains) {
    return [];
  }

  const createChainRows = (numberOfChunks = 4) => {
    const chunked = chunk(chains, numberOfChunks);
    const data = chunked.map((rowItems, idx) => {
      const res = {
        groupId: RestaurantsGroupTypes.CHAIN,
        height: RESTAURANTS_ROW_HEIGHT + SPACE_BETWEEN_ROWS,
        items: rowItems,
        key: `chain_${idx}`,
      };

      return res;
    });
    return data;
  };

  if (userDevice?.isMinLargeDesktop) {
    return createChainRows(itemsInRow.isMinLargeDesktop);
  }

  if (userDevice?.isMinDesktop) {
    return createChainRows(itemsInRow.isMinDesktop);
  }

  if (userDevice?.isMinTablet) {
    return createChainRows(itemsInRow.isMinTablet);
  }

  return createChainRows(itemsInRow.isMobile);
});

export const selectChainsLoading = createSelector(selectChainsBase, chains => chains.loading);
export const selectChainsLoaded = createSelector(selectChainsBase, chains => chains.loaded);
