import {combineReducers} from 'redux';

import {RestaurantsListContext, RestaurantsListContexts} from '~/shared/consts/restaurantConsts';
import {ApiResponse} from '~/shared/services/apiService';
import {
  getRestaurantsByGroupIdIfNeeded,
  searchCityRestaurantsIfNeeded,
  searchRestaurantsIfNeeded,
  setLastSearchRestaurantsPromise,
  setRestaurantsByGroupIdLastFetchInfo,
  setSearchCityRestaurantsLastFetchInfo,
  setSearchRestaurantsLastFetchInfo,
  setRestaurantListContext,
  setCurrentCollectionName,
} from '~/shared/store/storeModules/restaurants/restaurantsActions';

import {makeReducer, makeAsyncReducer, StateAsyncProperty} from '../../redux-toolbelt';
import {RestaurantGroupRestaurants, RestaurantList} from '../../models';
import {Carousel} from '../../models/Carousel';

export interface RestaurantsState {
  main: RestaurantStateBlock<RestaurantList>;
  group: RestaurantStateBlock<RestaurantGroupRestaurants>;
  city: RestaurantStateBlock<RestaurantList>;
  lastSearchRestaurantsPromise: Promise<ApiResponse<RestaurantList>>;
  restaurantListContext: RestaurantsListContext;
  currentCollectionName: Carousel['name'] | null;
}

export default combineReducers<RestaurantsState>({
  main: combineReducers({
    list: makeAsyncReducer(searchRestaurantsIfNeeded, {shouldDestroyData: false}),
    lastFetchInfo: makeReducer(setSearchRestaurantsLastFetchInfo, {defaultState: {}}),
  }),
  group: combineReducers({
    list: makeAsyncReducer(getRestaurantsByGroupIdIfNeeded, {shouldDestroyData: false}),
    lastFetchInfo: makeReducer(setRestaurantsByGroupIdLastFetchInfo, {defaultState: {}}),
  }),
  city: combineReducers({
    list: makeAsyncReducer(searchCityRestaurantsIfNeeded, {shouldDestroyData: false}),
    lastFetchInfo: makeReducer(setSearchCityRestaurantsLastFetchInfo, {defaultState: {}}),
  }),
  lastSearchRestaurantsPromise: makeReducer(setLastSearchRestaurantsPromise),
  restaurantListContext: makeReducer<RestaurantsListContext, RestaurantsListContext>(setRestaurantListContext, {
    defaultState: RestaurantsListContexts.MAIN,
  }),
  currentCollectionName: makeReducer(
    setCurrentCollectionName,
    (_, {payload}) => payload || null,
    {defaultState: null as RestaurantsState['currentCollectionName']},
  ),
});

interface RestaurantStateBlock<T> {
  list: StateAsyncProperty<T>;
  lastFetchInfo: RestaurantsLastFetchInfo;
}

export type RestaurantsLastFetchInfo = {fetchedFor: string; fetchedTime: string} | Record<string, never>;
