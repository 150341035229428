import {createSelector} from 'reselect';

import {EMPTY_ARRAY, EMPTY_OBJECT} from '~/shared/consts/commonConsts';

import {AppState} from '../../configStore';
import {RestaurantBusinessTypeName, RestaurantFromSearch} from '../../models';

const selectRestuarants = (state: AppState) => state.restaurants;

export const selectLastSearchRestaurantsPromise = createSelector(
  selectRestuarants,
  restaurants => restaurants.lastSearchRestaurantsPromise,
);

const selectRestaurantsMain = createSelector(selectRestuarants, restaurants => restaurants.main);
export const selectRestaurantsMainListData = createSelector(
  selectRestaurantsMain,
  main => main.list.data || EMPTY_OBJECT,
);
export const selectSecondOrderedByConfigUsed = createSelector(
  selectRestaurantsMain,
  main => main.list.data?.secondOrderedByConfigUsed,
);
// TODO: - selectRestaurantsMainListIsLoaded always returns true, probably needs to be refactored
export const selectRestaurantsMainListIsLoaded = createSelector(selectRestaurantsMainListData, listData => !!listData);
export const selectRestaurantsMainListIsLoadedFlag = createSelector(selectRestaurantsMain, main => main.list.loaded);
export const selectRestaurantsMainListIsLoading = createSelector(selectRestaurantsMain, main => main.list.loading);

export const selectRestaurantsMainListIsReady = createSelector(
  selectRestaurantsMainListIsLoading,
  selectRestaurantsMainListIsLoadedFlag,
  (isLoading, isLoaded) => !isLoading && isLoaded,
);

export const selectRestaurantsMainLastFetchInfo = createSelector(selectRestaurantsMain, main => main.lastFetchInfo);
export const selectRestaurantsMainList = createSelector(
  selectRestaurantsMainListData,
  listData => listData.restaurantsList || EMPTY_ARRAY,
);

export const selectRestaurantsGroceryStoresAvailable = createSelector(
  selectRestaurantsMainListIsReady,
  selectRestaurantsMainList,
  (isReady, restaurants) =>
    !isReady || restaurants.some(({businessTypeName}) => businessTypeName === RestaurantBusinessTypeName.GroceryStore),
);

const selectRestaurantsCity = createSelector(selectRestuarants, restaurants => restaurants.city);
export const selectRestaurantsCityListData = createSelector(
  selectRestaurantsCity,
  city => city.list.data || EMPTY_OBJECT,
);
export const selectRestaurantsCityListIsLoaded = createSelector(selectRestaurantsCityListData, listData => !!listData);
export const selectRestaurantsCityListIsLoading = createSelector(selectRestaurantsCity, city => city.list.loading);
export const selectRestaurantsCityLastFetchInfo = createSelector(selectRestaurantsCity, city => city.lastFetchInfo);
export const selectRestaurantsCityList = createSelector(
  selectRestaurantsCityListData,
  listData => listData.restaurantsList || EMPTY_ARRAY,
);

const selectRestaurantsGroup = createSelector(selectRestuarants, restaurants => restaurants.group);
export const selectRestaurantsGroupListData = createSelector(
  selectRestaurantsGroup,
  group => group.list.data || EMPTY_OBJECT,
);
export const selectRestaurantsGroupListIsLoaded = createSelector(selectRestaurantsGroup, group => !!group.list.data);
export const selectRestaurantsGroupListIsLoading = createSelector(selectRestaurantsGroup, group => group.list.loading);
export const selectRestaurantsGroupLastFetchInfo = createSelector(selectRestaurantsGroup, group => group.lastFetchInfo);
export const selectRestaurantsGroupListRestaurants = createSelector(
  selectRestaurantsGroupListData,
  listData => listData?.restaurants || EMPTY_ARRAY,
);
export const selectRestaurantsGroupLogo = createSelector(
  selectRestaurantsGroupListData,
  listData => listData?.profileImage,
);
export const selectRestaurantsGroupName = createSelector(selectRestaurantsGroupListData, listData => listData?.name);

export const selectRestaurantById = createSelector(selectRestaurantsMainList, restaurantsList => {
  return restaurantsList.reduce<Record<RestaurantFromSearch['restaurantId'], RestaurantFromSearch>>((restObject, restaurant) => {
    restObject[restaurant.restaurantId] = restaurant;
    return restObject;
  }, {});
});

export const selectRestaurantListContext = createSelector(
  selectRestuarants,
  ({restaurantListContext}) => restaurantListContext,
);

export const selectCurrentCollectionName = createSelector(
  selectRestuarants,
  ({currentCollectionName}) => currentCollectionName,
);

export const selectCollectionsList = createSelector(
  selectRestuarants,
  restaurants => restaurants?.main?.list?.data?.collectionsList,
);
