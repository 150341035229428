import {memo} from 'react';

import styled from 'styled-components';

import {flipOnLTR, rotateOnLTR} from '~/shared/theme/utils';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import searchIconUrl from '~/assets/images/icons/search.svg';
import {getLocalizationService} from '~/shared/services/localisationService';

import {body14Normal, body16Normal} from '../theme/typography';

const SearchContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Input = styled.input`
  opacity: 1;
  border: 0;
  border-bottom: 1px solid;
  border-color: ${({theme}) => theme.colors.gray500};
  background-color: transparent;

  &:hover {
    border-color: ${({theme}) => theme.searchDropDown.separator};
  }

  &:focus,
  &:active {
    background-color: ${({theme}) => theme.searchDropDown.backgroundColor};
    border-color: ${({theme}) => theme.colors.gray500};
  }

  color: ${({theme}) => theme.searchDropDown.textColor};
  ${body16Normal}
  line-height: normal;
  width: 100%;
  outline: none;
  border-radius: 0;
  padding-bottom: 7px;
  padding-top: 10px;
  transition: all 0.3s ease;
  ${flipOnLTR`
    padding-right: 30px;      
  `};

  &::placeholder {
    color: ${({theme}) => theme.colors.gray500};
    ${body14Normal};
  }
`;

const SearchIcon = styled(ImageWithAlt).attrs({
  src: searchIconUrl,
})`
  position: absolute;
  pointer-events: none;
  top: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin: 5px 5px;
  ${flipOnLTR`
    right: 0px;      
  `};
  ${rotateOnLTR}
`;

interface IBasicSearchInput {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ariaLabel: string;
}

const BasicSearchInput = ({value, onChange, ariaLabel}: IBasicSearchInput) => {
  const {t} = getLocalizationService();

  return (
    <SearchContainer>
      <Input value={value} onChange={onChange} aria-label={ariaLabel} />
      <SearchIcon alt={t('search')} />
    </SearchContainer>
  );
};

export default memo(BasicSearchInput);
