import styled from 'styled-components';
import {GoogleOAuthProvider} from '@react-oauth/google';

import {getLocalizationService} from '~/shared/services/localisationService';
// @ts-expect-error config needs to be rewritten as ts
import {googleLoginClientKey} from '~/shared/config';

import {LoginWithSectionHeader} from '../loginStyled';

import GoogleSection, {GoogleSectionProps} from './GoogleSection';
import FacebookSection, {FacebookSectionProps} from './FacebookSection';

const Root = styled.div``;

interface OAtuhSectionProps {
  openFacebook: FacebookSectionProps['onClick'];
  onSubmitWithGoogleConnect: GoogleSectionProps['onSubmit'];
  openProviderScreen: GoogleSectionProps['openProviderScreen'];
  openLoginScreen?: GoogleSectionProps['openLoginScreen'];
  isRedirectedToStepRef: GoogleSectionProps['isRedirectedToStepRef'];
}

const OAtuhSection = ({openFacebook, onSubmitWithGoogleConnect, openProviderScreen, openLoginScreen, isRedirectedToStepRef}: OAtuhSectionProps) => {
  const {t} = getLocalizationService();

  return (
    <Root>
      <LoginWithSectionHeader>{t('or')}</LoginWithSectionHeader>
      <GoogleOAuthProvider clientId={googleLoginClientKey}>
        <GoogleSection
          openLoginScreen={openLoginScreen}
          openProviderScreen={openProviderScreen}
          onSubmit={onSubmitWithGoogleConnect}
          isRedirectedToStepRef={isRedirectedToStepRef}
        />
      </GoogleOAuthProvider>
      <FacebookSection onClick={openFacebook} />
    </Root>
  );
};

export default OAtuhSection;
