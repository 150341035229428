import {useEffect} from 'react';

import {useSelector} from 'react-redux';
import styled, {ThemeProvider} from 'styled-components';

import {selectCurrentRestaurant, selectCurrentRestaurantId, selectCurrentRestaurantIsLoading, selectIsFutureOrderEnabled, selectUserData, selectUserLoading} from '~/shared/store/selectors';
import {navigateToDefaultStartPage} from '~/shared/services/navigation';
import {getLocalizationService} from '~/shared/services/localisationService';
import {trackPageview} from '~/shared/services/analytics';
import {useIsMinLargeTablet} from '~/shared/hooks/deviceInfo';
import {PageHeader} from '~/shared/components/header';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {media} from '~/shared/theme/media';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import ShoppingCart from '~/common/components/ShoppingCart';
import {getMenuOrDishPagePath} from '~/shared/services/urlService';
import {pushRoute, removeQueries} from '~/shared/router';
import store from '~/shared/store';
import {runWhenInitialized} from '~/shared/managers/initManager/initManager';

import useCheckoutEmptyState from '../hooks/useCheckoutEmptyState';
import useCheckoutTheme from '../hooks/useCheckoutTheme';

import CheckoutLoaderComponent from './Checkout/CheckoutLoaderComponent';
import Checkout from './Checkout';

const extraSpaceFromTop = 20;

const Root = styled.div`
  margin-top: ${({theme}) => theme.header.height}px;
  background-color: ${({theme}) => theme.colors.darkBackground};
  min-height: ${({theme}) => `calc(100vh - ${theme.header.height}px)`};
`;

const shoppingCartWidth = '412px';

const StyledShoppingCart = styled(ShoppingCart)`
  overflow: auto;
`;

const Body = styled.div`
  width: 100%;
  ${flexColumn};
  ${media.minLargeTablet`
    margin: 0 auto;
    width: 1062px;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: ${extraSpaceFromTop}px;
  `}
`;

const CheckoutBase = styled.div`
  ${media.minLargeTablet`
    display: flex;
    width: calc(1062px - ${shoppingCartWidth});
    ${flipOnLTR`
      margin-left: 22px;
    `}
  `}
`;

const ShoppingCartWrapper = styled.div`
  width: 100%;
  margin-bottom: 8px;
  ${media.minLargeTablet`
    max-width: ${shoppingCartWidth};
    background-color: ${({theme}) => theme.colors.primaryText};
    height: auto;
    box-shadow: ${({theme}) => theme.shadows.shadow4};
    position: sticky;
    top: ${({theme}) => theme.header.height + extraSpaceFromTop}px;
    height: 100%;
  `}
`;

const CheckoutPage = () => {
  const {currentLanguageKey} = getLocalizationService();
  const theme = useCheckoutTheme();
  const isMinLargeTablet = useIsMinLargeTablet();
  const isRestaurantPreOrder = Number(useSelector(selectIsFutureOrderEnabled));
  const currentRestaurant = useSelector(selectCurrentRestaurant);

  useCheckoutEmptyState();

  runWhenInitialized(() => {
    const state = store.getState();

    if (!selectUserData(state) && !selectUserLoading(state)) {
      navigateToDefaultStartPage();
      return;
    }

    if (!selectCurrentRestaurantId(state) && !selectCurrentRestaurantIsLoading(state)) {
      navigateToDefaultStartPage();
    }
  });

  useEffect(() => {
    trackPageview({
      currentLanguageKey,
      pageGroup: 'checkout',
      eventPayload: {
        restaurantData: {isRestaurantPreOrder},
      },
    });
  }, [currentLanguageKey, isRestaurantPreOrder]);

  const onBackClick = () => {
    removeQueries(['openCheckout', 'dishId']);

    const currentRestaurantUrl = getMenuOrDishPagePath({
      restaurantId: currentRestaurant.id,
      restaurantName: currentRestaurant.name,
    });
    pushRoute(currentRestaurantUrl);
  };

  return (
    <Root>
      <PageHeader onBackClick={onBackClick} isMenuPage />
      <Body>
        <ThemeProvider theme={theme}>
          <ShoppingCartWrapper>
            <StyledShoppingCart
              loaderComponent={CheckoutLoaderComponent}
              showRestaurantHeader={isMinLargeTablet}
              showTitle={isMinLargeTablet}
              isMaxHeight={false}
              isCheckoutComponent
              hideAsapPooledToggler
            />
          </ShoppingCartWrapper>
          <CheckoutBase>
            <Checkout />
          </CheckoutBase>
        </ThemeProvider>
      </Body>
    </Root>
  );
};

export default CheckoutPage;
