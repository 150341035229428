import styled from 'styled-components';

import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {flexCenter} from '~/shared/theme/FlexLayout';

export const PaymentContainer = styled.div`
  width: ${({fullWidth}) => (fullWidth ? '100%' : '48%')};
  height: 40px;
  ${flexCenter};
  margin: ${({theme}) => theme.checkout.elements.paymentButton.margin};
  padding: 0;
`;

const PaymentButton = styled.button`
  border: ${({theme, showAsLink, showBorder}) =>
    (showAsLink || !showBorder ? 'none' : `1px solid ${theme.colors.gray500}`)};
  border-radius: ${({borderRadius}) => borderRadius && '4px'};
  color: ${({theme, showAsMainCTA}) => (showAsMainCTA ? theme.colors.primaryText : theme.colors.surfacePrimaryAction)};
  background-color: ${({theme, showAsMainCTA}) =>
    (showAsMainCTA ? theme.colors.surfacePrimaryAction : theme.colors.primaryText)};
  font-weight: normal;
  ${flexCenter};
  width: 100%;
  height: 100%;
  padding: 0;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const AddPaymentButtonWithAvailablePayments = styled(PaymentButton)`
  font-weight: normal;
  height: 14px;
  width: auto;
  ${flipOnLTR`
    margin-right: auto;
  `}
`;

const PaymentButtonLogo = styled(ImageWithAlt)`
  padding: 8px;
`;

const PaymentButtonLabel = styled.div`
  padding: 0;
`;

const IconComponentWrapper = styled.div`
  padding: 8px 8px 0 8px;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
`;

export const AddPaymentButton = ({
  icon,
  IconComponent,
  label,
  onClick,
  fullWidth,
  showAsLink = false,
  showAsMainCTA,
  showBorder = true,
  borderRadius = false,
  children,
}) => {
  return (
    <PaymentContainer fullWidth={fullWidth}>
      <PaymentButton
        onClick={onClick}
        showAsLink={showAsLink}
        showAsMainCTA={showAsMainCTA}
        showBorder={showBorder}
        borderRadius={borderRadius}
      >
        {icon && <PaymentButtonLogo src={icon} noAlt />}
        {IconComponent && !showAsLink && (
          <IconComponentWrapper>
            <IconComponent />
          </IconComponentWrapper>
        )}
        <PaymentButtonLabel>{label}</PaymentButtonLabel>
        {children}
      </PaymentButton>
    </PaymentContainer>
  );
};
