import {createLogger} from '~/shared/logging';

import {makeThunkAsyncActionCreator} from '../../redux-toolbelt';

import {CuisinesState} from './cuisinesReducer';

const logger = createLogger('cuisines actions');

export const getCuisines = makeThunkAsyncActionCreator<never, CuisinesState['data']>(
  'getCuisine',
  async (_payload, {apiService}) => {
    try {
      const result = await apiService.getRestaurantCuisineTypes();
      return result.data.cuisineTypes.map(cuisine => ({
        ...cuisine,
        id: cuisine.id.toLowerCase(),
      }));
    } catch (error: unknown | any) {
      logger.error('[getCuisine]: failed to fetch cuisines', {error});
      throw error?.message;
    }
  },
);
