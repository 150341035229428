import {combineReducers} from 'redux';

import * as sharedActions from '../restrictedSharedActions';
import {composeReducers, makeReducer, makeAsyncReducer, StateAsyncProperty} from '../../redux-toolbelt';
import {Coupon} from '../../models';

import {getAvailableCouponsForOrder, setCurrentCoupon, setIsCouponAutoChanged} from './couponsActions';

// Todo: consider moving inside order module

export type CouponsState = {
  currentCoupon: Coupon | null;
  availableCoupons: StateAsyncProperty<Coupon[]>;
  isCouponAutoChanged: boolean;
};

const couponsDefaultState: CouponsState = {
  currentCoupon: null,
  availableCoupons: {loaded: false, loading: false},
  isCouponAutoChanged: false,
};

export default composeReducers<CouponsState>(
  makeReducer<CouponsState>(sharedActions.clearOrderData, () => undefined, {defaultState: couponsDefaultState}),
  combineReducers<CouponsState>({
    // might include a coupon not from available coupons
    currentCoupon: makeReducer<CouponsState['currentCoupon']>(
      {
        [setCurrentCoupon.TYPE]: (_state, {payload: newCoupon}: ReturnType<typeof setCurrentCoupon>) => newCoupon,
      },
      {defaultState: couponsDefaultState.currentCoupon},
    ),
    availableCoupons: makeAsyncReducer(getAvailableCouponsForOrder, {
      shouldDestroyData: true,
      defaultData: [],
    }),
    isCouponAutoChanged: makeReducer(setIsCouponAutoChanged, {defaultState: false as boolean}),
  }),
);
