/* eslint-disable max-len */

import {memo, useEffect, useState} from 'react';

import styled from 'styled-components';
import {times} from 'lodash';
import {useSelector} from 'react-redux';

import {flexColumn, flexSpace} from '~/shared/theme/FlexLayout';
import {media} from '~/shared/theme/utils';
import Button from '~/shared/components/Button';
import {selectA11yContrast} from '~/shared/store/selectors';

import {body12Normal, body15Normal} from '../theme/typography';
import {getLocalizationService} from '../services/localisationService';
import {useIsMaxMobile} from '../hooks/deviceInfo';

const NUMBER_OF_STARS = 5;

const getStarSvg = size => `
  <svg width="${size}px" height="${size}px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="elements/icons/14x14/star-grey" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M6.62850252,0.928743705 C6.83367491,0.41581272 7.16366839,0.409170985 7.37149748,0.928743705 L9,5 L13.0001925,5 C13.5523709,5 13.7082422,5.33285284 13.340204,5.75273047 L10.578,8.904 L11.7325327,13.0414814 C11.8802508,13.5708566 11.6076584,13.764595 11.1404694,13.4842816 L7,11 L2.85953064,13.4842816 C2.38482498,13.769105 2.1419005,13.5829729 2.32208832,13.0534244 L3.734,8.904 L0.684873152,5.71605377 C0.306628155,5.32058819 0.443716645,5 0.999807492,5 L5,5 L6.62850252,0.928743705 Z"/>
    </g>
  </svg>
`;

const regularStarSvg = getStarSvg(44);
const mobileStarSvg = getStarSvg(33);

const StarIcon = ({onClick, isMaxMobile}) => {
  const starSvg = isMaxMobile ? mobileStarSvg : regularStarSvg;
  return (
    // eslint-disable-next-line react/no-danger,jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <Button onClick={onClick} dangerouslySetInnerHTML={{__html: starSvg}} />
  );
};

const Root = styled.div`
  ${flexSpace};
  max-width: 220px;
  ${media.minTablet`
    max-width: inherit;
  `}
  ${({theme: {isLTR}}) =>
    isLTR &&
    `
    flex-direction: row-reverse;
  `};
`;

const StarContainer = styled.div`
  ${({isFilled, theme, isContrastMode, isEditDisabled}) =>
    (isFilled
      ? `
    svg > g > path {
      fill: ${theme.restaurantReview.highlightedStarAndLabelColor};
    }

    ${
      isContrastMode &&
      `
      &&& {
        svg > g > path {
          fill: ${theme.colors.contrast} !important;
          stroke: none!important;
        }
      }
    `
      }
  `
      : `
    svg > g > path {
      fill: ${theme.restaurantReview.dimmedStarAndLabelColor};
    }
    ${
      !isEditDisabled &&
      `
      &:hover svg > g > path {
      fill: ${theme.restaurantReview.highlightedStarAndLabelColor};
      }
      &:hover ~&& svg > g > path {
        fill: ${theme.restaurantReview.highlightedStarAndLabelColor};
      }
    `
      }
  `)}
  ${flexColumn};
  cursor: pointer;
  text-align: center;
  width: 60px;
`;

const starsLabelMap = {
  1: 'can_be_better',
  5: 'very_delicious',
};

const Label = styled.div`
  text-align: center;
  color: ${({orangeColor, theme}) =>
    (orangeColor ? theme.restaurantReview.highlightedStarAndLabelColor : theme.restaurantReview.dimmedStarAndLabelColor)};
  ${body12Normal};
  ${media.minTablet`
    ${body15Normal}
  `}
  line-height: 16px;
`;

const Star = ({index, rating, onChange, isEditDisabled}) => {
  const {t} = getLocalizationService();
  const isContrastMode = useSelector(selectA11yContrast);
  const isMaxMobile = useIsMaxMobile();

  const [starValue, setStarValue] = useState();
  const [isActive, setIsActive] = useState();

  const onClick = () => {
    if (!isEditDisabled) {
      onChange(starValue);
    }
  };

  useEffect(() => {
    setStarValue(index + 1);
  }, [index]);

  useEffect(() => {
    setIsActive(rating >= starValue);
  }, [rating, starValue]);

  return (
    <StarContainer isFilled={isActive} isContrastMode={isContrastMode} isEditDisabled={isEditDisabled}>
      <StarIcon onClick={onClick} isMaxMobile={isMaxMobile} />
      <Label orangeColor={isActive && rating === starValue}>{t(starsLabelMap[starValue])}</Label>
    </StarContainer>
  );
};

const StarsRating = ({rating, onChange, isEditDisabled}) => (
  <Root>
    {times(NUMBER_OF_STARS, val => {
      const reversedVal = NUMBER_OF_STARS - val;
      return (
        <Star
          key={reversedVal - 1}
          index={reversedVal - 1}
          rating={rating}
          onChange={onChange}
          isEditDisabled={isEditDisabled}
        />
      );
    })}
  </Root>
);

export default memo(StarsRating);
