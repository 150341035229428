/* eslint-disable eqeqeq, class-methods-use-this */

import {AxiosInstance} from 'axios';

import {logger} from './logger';
import {Config, FeatureConfigs, FeatureConfig} from './config';
import {CdnSettings, OnUpdatedFn} from './settings';

const versionIdHeader = 'x-amz-version-id'; // See https://docs.aws.amazon.com/AmazonS3/latest/API/RESTCommonResponseHeaders.html

let interval: ReturnType<typeof setTimeout>;

export class FeatureLookup {
  private featureLookup: FeatureConfigs;

  private configCreatedAt: string;

  private versionId: string;

  constructor() {
    this.featureLookup = {};
    this.configCreatedAt = '';
    this.versionId = '';
  }

  async fetchAndUpdate(
    url: string,
    httpClient: AxiosInstance,
    onUpdated: OnUpdatedFn = () => {},
  ): Promise<Config> {
    const response = await httpClient.get(url);
    const headers = response?.headers;
    if (headers[versionIdHeader]) {
      this.versionId = headers[versionIdHeader];
    }

    this.init(response.data, onUpdated);
    return response.data;
  }

  async loadFromCdn(
    cdnSettings: CdnSettings,
    httpClient: AxiosInstance,
    onUpdated?: OnUpdatedFn,
  ): Promise<Config> {
    const url = this.buildUrl(cdnSettings);
    const configAsJson = await this.fetchAndUpdate(url, httpClient, onUpdated);
    clearInterval(interval);

    if (cdnSettings.poll) {
      const pollInterval = cdnSettings.pollInterval || 30000;
      interval = setInterval(() => {
        this.fetchAndUpdate(url, httpClient, onUpdated);
      }, pollInterval);
    }

    return configAsJson;
  }

  init(config: string | Config, onUpdated = () => {}): void {
    const newConfig: Config = typeof config === 'string' ? JSON.parse(config) : config;

    if (this.configCreatedAt >= newConfig.createdAt) return;

    this.configCreatedAt = newConfig.createdAt;

    if (!this.configCreatedAt) return;

    logger.logInfo(`Loading new feature config - created at: ${this.configCreatedAt}`);

    this.featureLookup = {};

    newConfig.features.forEach(feature => {
      this.featureLookup[feature.key] = feature;
    });

    onUpdated();
  }

  getFeature(fullKey: string): FeatureConfig {
    return this.featureLookup[fullKey];
  }

  getFeatureKeys(): FeatureConfigs {
    return this.featureLookup;
  }

  getFeatureKeysByTags(tags: string[] = []): FeatureConfigs {
    if (tags.length == 0) {
      return this.getFeatureKeys();
    }

    const features = Object.values(this.featureLookup);
    const filteredFeatures = features.filter(
      feature => feature.tags && feature.tags.some(tag => tags.includes(tag)),
    );
    const filteredFeatureLookup: FeatureConfigs = {};
    filteredFeatures.forEach(feature => {
      filteredFeatureLookup[feature.key] = feature;
    });

    return filteredFeatureLookup;
  }

  getFilteredConfigByTags(tags: string[] = []): Config {
    let features = Object.values(this.featureLookup);
    if (tags.length > 0) {
      features = features.filter(
        feature => feature.tags && feature.tags.some(tag => tags.includes(tag)),
      );
    }

    const createdAt = this.configCreatedAt;
    return {features, createdAt};
  }

  getVersionId(): string {
    return this.versionId;
  }

  private buildUrl(cdnSettings: CdnSettings): string {
    const host = cdnSettings.host || 'https://features.api.justeattakeaway.com';
    const suffix = cdnSettings.key ? `-${cdnSettings.key}` : '';
    const versionIdQueryParameter = this.isNullOrWhiteSpace(cdnSettings.versionId)
      ? ''
      : `?versionId=${cdnSettings.versionId}`;
    const url = `${host}/config/v1/${cdnSettings.scope}/${cdnSettings.environment}${suffix}${versionIdQueryParameter}`;
    return url;
  }

  private isNullOrWhiteSpace(str: string | undefined): boolean {
    return str === null || str === undefined || str.trim() === '';
  }
}
