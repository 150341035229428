import {useMemo} from 'react';

import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {media, flipOnLTR} from '~/shared/theme/utils';
import {getLocalizationService} from '~/shared/services/localisationService';
import {
  body16Bold,
  body14Normal,
  body18Bold,
  subheader22Bold,
  body20Bold,
  body16Normal,
  header32Bold,
} from '~/shared/theme/typography';
import {FlexColumn} from '~/shared/theme/FlexLayout';
import {
  isCollectionNameInCollectionsList,
} from '~/shared/consts/customGroupsConsts';
import {RestaurantsGroupTypes} from '~/shared/consts/restaurantConsts';
import {
  selectCollectionsList,
  selectCurrentCuisineId,
  selectMainRestaurantsCuisinesCounts,
  selectSelectedFilters,
} from '~/shared/store/selectors';
import storesIconUrl from '~/assets/images/icons/stores.svg';
import useResolvedMiniFeedQuery from '~/shared/hooks/useResolvedMiniFeedQuery';
import {convertGapToMargin} from '~/shared/theme/convertGapToMargin';
import ImageWithAlt from '~/shared/components/ImageWithAlt';

import {ICarouselItem, IGroupItem} from '../models/RestaurantsListItemType';

export const MARKETING_BANNER_ROW_HEIGHT_MOBILE = 232;
export const MARKETING_BANNER_ROW_HEIGHT_DESKTOP = 298;

export const ItemGroupSubheaderHeight = 22;

export const ItemGroupHeight = {
  mobile: 45,
  rest: 90,
};

export const ItemCustomGroupHeight = {
  mobile: {
    totalPadding: 24 + 8,
    singleLineHeight: 28,
  },
  rest: 60,
};

export const getGroupItemRootHeight = ({
  hasSubheader,
  isCustomGroup,
  isMinLargeMobile,
}: {
  hasSubheader: boolean;
  isCustomGroup?: boolean;
  isMinLargeMobile?: boolean;
}) => {
  const groupHeights = isCustomGroup ? ItemCustomGroupHeight : ItemGroupHeight;
  if (isMinLargeMobile) {
    return hasSubheader ? groupHeights.rest + ItemGroupSubheaderHeight : groupHeights.rest;
  }

  const mobileHeight = typeof groupHeights.mobile === 'number' ?
    groupHeights.mobile :
    groupHeights.mobile.totalPadding + groupHeights.mobile.singleLineHeight;

  return hasSubheader ? mobileHeight + ItemGroupSubheaderHeight : mobileHeight;
};

const groupItemTitleKeysMapping = {
  new: 'new_and_recommended_restaurants',
  pooled: 'pooled_order_restaurant',
  open: 'all_restaurants',
  closed: 'closed_restaurant',
  discountCoupons: 'restaurant_with_discount_in_your_area',
  notPooledRestaurants: 'additional_restaurant_without_pooled_order',
  closedWithFutureOrderStartingTomorrow: 'closed_with_future_order_starting_tomorrow',
};

const Root = styled.div<{hasSubheader: boolean; isCustomGroup?: boolean}>`
  padding: 0 16px;
  display: flex;
  align-items: ${({isCustomGroup}) => (isCustomGroup ? 'flex-start' : 'center')};
  width: 100%;
  margin-top: 12px;
  height: ${({hasSubheader, isCustomGroup}) => getGroupItemRootHeight({hasSubheader, isCustomGroup})}px;
  ${({hasSubheader, isCustomGroup}) => media.minLargeMobile`
    height: ${getGroupItemRootHeight({hasSubheader, isCustomGroup, isMinLargeMobile: true})}px;
    margin-top: ${isCustomGroup ? 0 : '8px'};
  `}
  ${media.minLargeMobile`
    padding: unset;
  `}
`;

const Headers = styled(FlexColumn)`
  ${flipOnLTR`
    text-align: right;
    margin-right: 0;
  `}
  ${media.minLargeTablet`
    ${flipOnLTR`
      margin-right: 21px;
    `}
  `}
`;

const Header = styled.div<{isCustomGroup: boolean}>`
  display: flex;
  ${({theme}) => convertGapToMargin('0 6px', theme.isLTR)}
  ${({isCustomGroup}) => (isCustomGroup ? `
    ${body20Bold}
    line-height: 28px;
  ` : `
    ${body18Bold}
    line-height: 20px;
  `)}
  color: ${({theme}) => theme.restaurants.itemGroup.textColor};
  ${media.minLargeMobile`
    ${({isCustomGroup}) => (isCustomGroup ? `
      ${header32Bold}
      max-width: initial;
    ` : subheader22Bold)}
    line-height: 28px;
  `}
`;

const Subheader = styled.p<{isCustomGroup: boolean}>`
  height: ${ItemGroupSubheaderHeight}px;
  color: ${({theme}) => theme.colors.secondary};
  margin-top: ${({isCustomGroup}) => (isCustomGroup ? 8 : 4)}px;
  ${flipOnLTR`
    text-align: right;
  `};
  ${({isCustomGroup}) => (isCustomGroup ? body16Bold : body14Normal)}
  line-height: 18px;
  ${media.minLargeMobile`
    ${({isCustomGroup}) => (isCustomGroup ? body16Bold : body16Normal)}
    margin-top: ${({isCustomGroup}) => (isCustomGroup ? 8 : 4)}px;
    line-height: 20px;
  `}
`;

const Icon = styled(ImageWithAlt).attrs({noAlt: true})`
  width: 32px;
`;

export type TGroupItem = IGroupItem & Partial<{
  className: string;
  listTopOffset: number;
}> & Partial<ICarouselItem>;

const GroupItem = ({className, count, groupId, subTitleArgs, subTitleKey, title, subtitle, iconUrl}: TGroupItem) => {
  const {t, currentLanguageKey} = getLocalizationService();
  const collectionsList = useSelector(selectCollectionsList);
  const resolvedMiniFeedQuery = useResolvedMiniFeedQuery();
  const isCustomGroup = isCollectionNameInCollectionsList(collectionsList, groupId);

  const {showStores} = useSelector(selectSelectedFilters);

  const cuisines = useSelector(selectMainRestaurantsCuisinesCounts);
  const activeCuisineId = useSelector(selectCurrentCuisineId);
  const activeCuisine = useMemo(() => {
    if (activeCuisineId) {
      return cuisines.find(({id}) => id === activeCuisineId) || null;
    }

    return null;
  }, [cuisines, activeCuisineId]);

  const isMiniFeedTitle = groupId === 'miniFeedTitle';

  const minifeedIconUrl = useMemo(() => {
    if (resolvedMiniFeedQuery && isMiniFeedTitle) {
      return activeCuisine ?
        activeCuisine.iconImageUrl :
        showStores ?
          storesIconUrl :
          null;
    }

    if (iconUrl) {
      return iconUrl;
    }

    return null;
  }, [iconUrl, activeCuisine, showStores, resolvedMiniFeedQuery, isMiniFeedTitle]);

  const headerText = useMemo(() => {
    if (isCustomGroup) {
      return title;
    }

    if (resolvedMiniFeedQuery && isMiniFeedTitle) {
      return activeCuisine ? activeCuisine.names[currentLanguageKey] :
        showStores ?
          t('shops') :
          '';
    }

    const headerTextKey = groupItemTitleKeysMapping[groupId as keyof typeof groupItemTitleKeysMapping];
    return t(headerTextKey, {postProcess: 'interval', count});
  }, [
    count,
    groupId,
    isCustomGroup,
    t,
    currentLanguageKey,
    title,
    activeCuisine,
    showStores,
    resolvedMiniFeedQuery,
    isMiniFeedTitle,
  ]);

  const subTitleText = useMemo(() => {
    if (groupId === 'closed') {
      return null;
    }

    if (isCustomGroup) {
      return t(subTitleKey, {...subTitleArgs as Record<string, unknown>});
    }

    return subtitle;
  }, [groupId, isCustomGroup, subtitle, subTitleArgs, subTitleKey, t]);

  const isCustomGroupStyle = isCustomGroup || (!!resolvedMiniFeedQuery && isMiniFeedTitle);

  return (
    <Root
      className={className}
      id={groupId}
      hasSubheader={!!subTitleText}
      isCustomGroup={isCustomGroupStyle}
    >
      <Headers>
        <Header
          isCustomGroup={isCustomGroupStyle}
          data-test-id={groupId === RestaurantsGroupTypes.OPEN && `open_${headerText}`}
        >
          <div>{headerText}</div>
          {minifeedIconUrl && <Icon src={minifeedIconUrl} />}
        </Header>
        {(subTitleText || subtitle) && <Subheader isCustomGroup={isCustomGroup}>{subTitleText || subtitle}</Subheader>}
      </Headers>
    </Root>
  );
};

export default GroupItem;
