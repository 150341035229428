import {goBack, pushRoute} from '~/shared/router';

import CancelOrderWarning from './components/CancelOrderWarning';

const CancelOrderWarningPage = () => {
  const handleOnApprove = () => {
    pushRoute('/user-report', {hard: true, keepAllQueries: false});
  };
  return <CancelOrderWarning isFullSize onApprove={handleOnApprove} onCancel={goBack} />;
};

export default CancelOrderWarningPage;
