import {useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {createLogger} from '~/shared/logging';
import {selectUser} from '~/shared/store/selectors';
import {navigateToDefaultStartPage} from '~/shared/services/navigation';
import actions from '~/shared/store/actions';
import {useRouteParams} from '~/shared/router';
import {handleRefreshToken} from '~/shared/services/auth';
import {is401Error} from '~/shared/services/apiErrorService';

import OrderSummaryView from './components/OrderSummaryView';

const logger = createLogger('OrderSummary');

const OrderSummaryPage = () => {
  const dispatch = useDispatch();
  const {orderId} = useRouteParams<{orderId: number}>();
  const user = useSelector(selectUser);

  useEffect(() => {
    (async function initPage() {
      logger.log('*** initializing order summary page ***');

      if (!user.loaded) {
        logger.log('User not yet loaded');
        return;
      }

      if (!orderId) {
        navigateToDefaultStartPage({replace: true});
        return;
      }

      try {
        await dispatch(actions.fetchOrderSummaryIfNeeded({orderId}));
      } catch (e: any) {
        if (is401Error(e)) {
          await handleRefreshToken(e, initPage);
          return;
        }

        const error = 'Error while trying to fetchOrderData';
        logger.error(error, {e: e?.message, orderId, fingerprint: error});
        navigateToDefaultStartPage({replace: true});
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, user.loaded]);

  return <OrderSummaryView isFullSize />;
};

export default OrderSummaryPage;
