import {isEqual} from 'lodash';
import {combineReducers} from 'redux';

import {RestaurantFromGet} from '~/shared/store/models';
import {clearOrderData} from '~/shared/store/storeModules/restrictedSharedActions';
import {SHOPPING_CART_DELIVERY_TYPE} from '~/shared/utils/restaurants/deliveryOptions';

import {RestaurantAbout, RestaurantCoupons, RestaurantReviews} from '../../models/Restaurant';
import {composeReducers, makeAsyncReducer, makeReducer, StateAsyncProperty} from '../../redux-toolbelt';

import {
  fetchRestaurantFromBff,
  setAboutRestaurant,
  getRestaurantReviews,
  getRestaurantCouponFullInfo,
  setRestaurantFromSearch,
  setCurrentRestaurantsLastFetchInfo,
  clearCurrentRestaurant,
  setCurrentRestaurantShoppingCartDeliveryType,
} from './currentRestaurantActions';

export interface CurrentRestaurantState {
  id: number;
  remotelyFetched: FetchedRestaurant;
  restaurantFromSearch?: RestaurantFromGet;
  lastFetchInfo: CurrentRestaurantLastFetchInfo | null;
  shoppingCartDeliveryType: SHOPPING_CART_DELIVERY_TYPE | null;
}

const LoadingState = {
  loaded: false,
  loading: false,
};

const initialCurrentRestaurantState: CurrentRestaurantState = {
  id: 0,
  remotelyFetched: {
    ...LoadingState,
    about: LoadingState,
    reviews: LoadingState,
    coupons: LoadingState,
  },
  restaurantFromSearch: undefined,
  lastFetchInfo: null,
  shoppingCartDeliveryType: null,
};

const currentRestaurantReducer = combineReducers<CurrentRestaurantState>({
  id: makeReducer(fetchRestaurantFromBff, (_, {payload}: ReturnType<typeof fetchRestaurantFromBff>) => payload?.id),
  remotelyFetched: composeReducers(
    makeAsyncReducer(fetchRestaurantFromBff, {shouldDestroyData: true}),
    makeReducer(fetchRestaurantFromBff, (state, {payload}: ReturnType<typeof fetchRestaurantFromBff>) => {
      const shouldDestroyData = isEqual(state.data, payload);
      if (shouldDestroyData) {
        return {
          ...state,
          data: undefined,
        };
      }

      return state;
    }),
    {
      about: makeAsyncReducer(setAboutRestaurant),
      reviews: makeAsyncReducer(getRestaurantReviews, {defaultData: {}}),
      coupons: makeAsyncReducer(getRestaurantCouponFullInfo, {defaultData: {}}),
    },
  ),
  restaurantFromSearch: makeReducer(setRestaurantFromSearch),
  lastFetchInfo: makeReducer(setCurrentRestaurantsLastFetchInfo),
  shoppingCartDeliveryType: composeReducers(
    makeReducer(clearOrderData, () => initialCurrentRestaurantState.shoppingCartDeliveryType, {
      defaultState: initialCurrentRestaurantState.shoppingCartDeliveryType,
    }),
    makeReducer(setCurrentRestaurantShoppingCartDeliveryType, {
      defaultState: initialCurrentRestaurantState.shoppingCartDeliveryType,
    }),
  ),
});

export default composeReducers<CurrentRestaurantState>(currentRestaurantReducer, makeReducer(clearCurrentRestaurant, () => initialCurrentRestaurantState, {
  defaultState: initialCurrentRestaurantState,
}));

export interface FetchedRestaurant extends StateAsyncProperty<RestaurantFromGet> {
  about: StateAsyncProperty<RestaurantAbout>;
  reviews: StateAsyncProperty<RestaurantReviews>;
  coupons: StateAsyncProperty<RestaurantCoupons>;
}

export type CurrentRestaurantLastFetchInfo = {
  fetchedForAddress?: string;
  fetchedTime: string;
};
