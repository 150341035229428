import styled, {css} from 'styled-components';

export const flexBottomVertically = css`
  display: flex;
  align-items: flex-end;
`;

export const FlexBottomVertically = styled.div`
  ${flexBottomVertically};
`;
