import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {media} from '~/shared/theme/media';
import {getArrivalText} from '~/shared/utils/dates';
import {Section} from '~/shared/components/OrderCompleteSections/parts';
import {defaultPaddingCss} from '~/shared/components/OrderCompleteSections/orderSummaryStyle';

import {OrderCompleteHeader, OrderCompleteSubheader} from './styled';

const OrderCompleteWrapper = styled(Section)`
  ${defaultPaddingCss};
  ${({mapDisplayed}) => (mapDisplayed && 'top: 392px;')}
  ${flexColumn};
  margin-top: 0;
  ${({borderTop, theme}) =>
    borderTop &&
    `
    border-top: 1px solid ${theme.colors.background};
  `};
  ${media.minLargeDesktop`
    top: ${({mapDisplayed}) => mapDisplayed && '442px'};
  `}
`;

const modes = {
  pickup: {
    header: 'your_order_has_been_sent_to_the_restaurant',
    subHeader: 'now_all_thats_left_for_you_to_do_is_to_pickup_the_food_from_the_restaurant',
  },
  delivery: {
    header: 'your_order_has_been_sent_to_the_restaurant',
  },
  futureDelivery: {
    header: 'you_have_just_placed_a_future_delivery_order',
    subHeader: 'your_order_has_been_sent_to_the_restaurant',
  },
  barCodeOrder: {
    header: 'coupon_order_was_successful',
  },
};

const getMode = ({isBarcodeOrder, isDelivery, isFutureOrder, isCurrentAddressIsDinningRoom}) => {
  if (isCurrentAddressIsDinningRoom || !isDelivery) {
    return 'pickup';
  }

  if (isBarcodeOrder) {
    return 'barCodeOrder';
  }

  if (isFutureOrder) {
    return 'futureDelivery';
  }

  return 'delivery';
};

const OrderComplete = ({
  isFutureOrder,
  isOrderIsDelivery,
  isBarcodeOrder,
  mapDisplayed = false,
  arrivalOrderDate,
  arrivalOrderTime,
  isScoober,
  isCurrentAddressIsDinningRoom,
  isDonation,
}) => {
  const {t} = getLocalizationService();

  const mode = getMode({isBarcodeOrder, isDelivery: isOrderIsDelivery, isFutureOrder, isCurrentAddressIsDinningRoom});

  const shouldShowSubheader = !isBarcodeOrder && modes[mode]?.subHeader;
  const shouldShowArrival = arrivalOrderDate && ((isScoober && isFutureOrder) || !isScoober);
  const arrivalText = arrivalOrderDate && getArrivalText(arrivalOrderDate, 'DD/MM/YYYY');

  return (
    <OrderCompleteWrapper mapDisplayed={mapDisplayed}>
      <OrderCompleteHeader>{isDonation ? t('order_success_donation_title') : t(modes[mode]?.header)}</OrderCompleteHeader>
      {!isDonation && shouldShowSubheader && <OrderCompleteSubheader>{t(modes[mode]?.subHeader)}</OrderCompleteSubheader>}
      {!isDonation && shouldShowArrival && (
        <div>
          {t('expected_delivery_time')}:{' '}
          <b>
            {arrivalText}, {arrivalOrderTime}
          </b>
        </div>
      )}
    </OrderCompleteWrapper>
  );
};

export default OrderComplete;
