import {AppStore} from './configStore';

const err = () => {
  throw new Error('redux store is not initialized yet.');
};

const store: AppStore = {
  [Symbol.observable]: err,
  getState: err,
  dispatch: err,
  dispatchThunk: err,
  observe: err,
  subscribe: err,
  replaceReducer: err,
};

export const setStore = (newStore: AppStore) => {
  Object.assign(store, newStore);
};

export default store;
