/* eslint-disable @typescript-eslint/no-unused-vars */
import {memo, useEffect} from 'react';

import {useDispatch} from 'react-redux';
import styled from 'styled-components';

import {ActionButton} from '~/shared/components/Button';
import {getLocalizationService} from '~/shared/services/localisationService';
import {FlexCenterHorizontally, flexColumnCenter, flexColumnCenterHorizontally} from '~/shared/theme/FlexLayout';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import ageRestrictedImg from '~/assets/images/ageRestrictionModalImg.svg';
import {body12Normal, body14Normal, body16Bold} from '~/shared/theme/typography';
import {useIsMinLargeTablet} from '~/shared/hooks/deviceInfo';
import AgeRestrictionBadge from '~/shared/components/AgeRestrictionBadge';
import {media} from '~/shared/theme/media';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {setIsAgeConfirmed} from '~/shared/store/actions';
import {RESTRICTION_AGE} from '~/shared/consts/restaurantConsts';
import {trackEvent} from '~/shared/services/analytics';

const Root = styled.div`
  text-align: center;
  background-color: ${({theme}) => theme.modal.backgroundColor};
  box-shadow: ${({theme}) => theme.shadows.shadow1};
  ${media.minLargeTablet`
    top: auto;
  `}

  &&& {
    height: 0;
    width: 100vw;
    ${media.minLargeTablet`
      height: auto;
      width: 505px;
    `}
  }
`;

const TitleContainer = styled.div`
  background-color: ${({theme}) => theme.colors.surface};
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${media.minLargeTablet`
    ${flexColumnCenter};
    height: 80px;
  `}
`;

const Title = styled.div`
  ${body16Bold};
  color: ${({theme}) => theme.colors.secondary};
  ${flipOnLTR`
      margin-right: 16px;
  `}
  align-items: center;
  display: flex;
  ${media.minLargeTablet`
  line-height: 28px;
  margin: 0 auto;
`}
`;

const ButtonsContainer = styled(FlexCenterHorizontally)`
  flex-direction: column-reverse;
  ${media.minLargeTablet`
    flex-direction: column;
  `}
`;

const ApproveButton = styled(ActionButton)`
  border-radius: 1px;
  box-shadow: none;
  width: 328px;
  margin: 16px 0 16px 0;
  ${media.minLargeTablet`
    margin: 16px 0 0 0;
    width: 345px;
  `}
`;

const CancelButton = styled(ApproveButton)`
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  background-color: transparent;
  border: 1px solid ${({theme}) => theme.colors.gray500};
  margin-bottom: 18px;

  &:hover {
    background-color: transparent;
  }

  ${media.minLargeTablet`
    margin-bottom: 32px;
  `}
`;

const ContentWrapper = styled.div`
  ${flexColumnCenterHorizontally};
  justify-items: flex-start;
  margin: 0 auto;
  padding-bottom: 18px;
  ${media.minLargeTablet`
    padding-bottom: 40px;
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 120px 0;
  ${media.minLargeTablet`
    margin: 40px 0 32px 0;
  `}
`;

const Message = styled.p`
  line-height: 20px;
  margin-top: 15px;
  width: 328px;
  ${body14Normal}
`;

const ImgContainer = styled.div`
  position: relative;
  height: 100px;
  width: 128px;
`;

const Img = styled(ImageWithAlt).attrs({
  src: ageRestrictedImg,
})`
  top: 0;
  left: 0;
  height: 100px;
  width: 128px;
`;

const StyledAgeRestrictionBadge = styled(AgeRestrictionBadge)<{restrictionAge?: string}>`
  position: absolute;
  top: 0;
  left: -15px;

  & div {
    width: 43px;
    height: 23px;
  }

  & p {
    ${body12Normal}
    ${media.minLargeTablet`
      ${body14Normal}
    `}
  }
`;

interface AgeConfirmProps {
  onApprove: () => void;
  onCancel: () => void;
}

const AgeConfirm = ({onApprove, onCancel}: AgeConfirmProps) => {
  const dispatch = useDispatch();
  const {t} = getLocalizationService();

  useEffect(() => {
    trackEvent('hasViewedAlcoholPopUp');
  }, []);

  const onApproveClick = () => {
    trackEvent('hasClickedAlcoholPopUp', {linkType: 'continue'});
    dispatch(setIsAgeConfirmed(true));
    onApprove();
  };

  const onCancelClick = () => {
    trackEvent('hasClickedAlcoholPopUp', {linkType: 'cancel'});
    onCancel();
  };

  const isMinLargeTablet = useIsMinLargeTablet();
  const content = isMinLargeTablet
    ? t('age_restriction_modal_message_desktop_xxx', {restrictionAge: RESTRICTION_AGE})
    : t('age_restriction_modal_message_mobile_xxx', {restrictionAge: RESTRICTION_AGE});

  return (
    <Root>
      <TitleContainer>
        <Title id="modal-title">{t('age_restriction_modal_header_xxx', {restrictionAge: RESTRICTION_AGE})}</Title>
      </TitleContainer>
      <ContentWrapper>
        <ContentContainer>
          <ImgContainer>
            <Img noAlt />
            <StyledAgeRestrictionBadge />
          </ImgContainer>
          <Message>{content}</Message>
        </ContentContainer>
        <ButtonsContainer>
          <CancelButton onClick={onCancelClick}>{t('cancel')}</CancelButton>
          <ApproveButton onClick={onApproveClick}>
            {t('age_restriction_modal_confirm_button_xxx', {restrictionAge: RESTRICTION_AGE})}
          </ApproveButton>
        </ButtonsContainer>
      </ContentWrapper>
    </Root>
  );
};

export default memo(AgeConfirm);
