import {memo, useEffect, useState} from 'react';

import styled from 'styled-components';
import {useDispatch} from 'react-redux';

import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import {PopupModalBase} from '~/shared/components/Modals';
import {attachModal} from '~/shared/components/ReduxModal';
import {LocalStorageKeys} from '~/shared/consts/localStorageConsts';
import {trackEvent} from '~/shared/services/analytics';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flexCenter} from '~/shared/theme/FlexLayout';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {media} from '~/shared/theme/media';
import {body16Bold, body16Normal} from '~/shared/theme/typography';
import {CloseButtonSVGIcon} from '~/shared/components/styled';
import {setCurrentModal} from '~/shared/store/actions';

import {Button} from './SendDebitTransferRequestModal/styled';

const Root = styled(ClickOutsideDiv)`
  &&& {
    height: fit-content;
    width: 343px;
  }
 
  ${media.minTablet`
    &&& {
      height: fit-content;
      width: 440px;
    }
  `}
`;

const StyledInfoModal = styled(PopupModalBase)`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &&& {
    height: fit-content;
    width: fit-content;
  }
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  ${flipOnLTR`
    left: 16px;
  `}
  width: 32px;
  height: 32px;
  ${flexCenter};
  border-radius: 32px;
  cursor: pointer;
  z-index: 1;
  background-color: ${({theme}) => theme.colors.surface};

  &:hover {
    background-color: rgba(215, 215, 215, 0.5);
  }
`;

const Title = styled.h2`
  color: ${({theme}) => theme.colors.text2};
  display: flex;
  justify-content: center;
  margin: 56px 0 24px 0;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
`;

const ContentText = styled.p`
  color: ${({theme}) => theme.colors.text2};
  ${body16Normal};
  margin: 0 56px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentTextLink = styled.a`
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  ${body16Bold};
  line-height: 24px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

const DisclaimerContent = styled.p`
  margin: 0 8px 56px;
  color: ${({theme}) => theme.colors.text2};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const TooltipWrapper = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const Tooltip = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  background: ${({theme}) => theme.colors.charcoal80};
  color: ${({theme}) => theme.colors.surface};
  padding: 8px;
  border-radius: 4px;
  bottom: 55px;

  &::after {
    position: absolute;
    content: '';
    border-color: transparent;
    border-width: 5px;
    border-style: solid;
    border-right-color: ${({theme}) => theme.colors.charcoal80};
    border-bottom-color: ${({theme}) => theme.colors.charcoal80};
    bottom: -4px;
    transform: rotate(45deg);
  }
`;

const GuestFlowCouponModal = ({args}: {
  args: {
    coupon: string;
  };
}) => {
  const {coupon} = args;
  const dispatch = useDispatch();
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const {t} = getLocalizationService();

  const handleOutsideClick = () => {
    trackEvent('firstBisPopupClick', {
      click_option: 'window_x',
    });
  };

  const handleCloseModal = () => {
    trackEvent('firstBisPopupClick', {
      click_option: 'x',
    });
    dispatch(setCurrentModal(null));
  };

  const showTooltip = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(coupon);
      trackEvent('firstBisPopupClick', {
        click_option: 'copy_code',
      });

      setTooltipVisible(true);

      setTimeout(() => {
        setTooltipVisible(false);
      }, 5000);
    }
  };

  useEffect(() => {
    window.localStorage.setItem(LocalStorageKeys.GUEST_FLOW_COUPON_MODAL_EXP_DATE, JSON.stringify(Date.now()));
    trackEvent('firstBisPopupView');
  }, []);

  return (
    <Root onClickOutside={handleOutsideClick}>
      <CloseButton data-test-id="modalCloseButton" onClick={handleCloseModal} aria-label={t('close')}>
        <CloseButtonSVGIcon />
      </CloseButton>
      <StyledInfoModal>
        <Title id="modal-title">{t('wellcom_to_10bis')}</Title>
        <ContentText id="modal-content">{t('coupon_for_new_customers')}</ContentText>

        <TooltipWrapper>
          {isTooltipVisible && (
          <Tooltip>{t('coupon_code_copied')}</Tooltip>
        )}
          <ContentTextLink onClick={showTooltip}>{coupon}</ContentTextLink>
        </TooltipWrapper>
        <DisclaimerContent>{t('disclaimer_coupon')}</DisclaimerContent>
      </StyledInfoModal>
    </Root>
  );
};

const EnhanceSingleOrderViewModal = memo(GuestFlowCouponModal);

attachModal('guestFlowCouponModal', EnhanceSingleOrderViewModal);
