import {Cuisine} from '../../models';
import {GenericAction, makeAsyncReducer, StateAsyncProperty} from '../../redux-toolbelt';

import {getCuisines} from './cuisinesActions';

export type CuisinesState = StateAsyncProperty<Cuisine[]>;

export default makeAsyncReducer<CuisinesState>(getCuisines, {
  shouldDestroyData: false,
  dataGetter: ({data: currentData}, {payload: incomingData}: GenericAction<CuisinesState['data']>) =>
    (incomingData?.length ? incomingData : currentData),
});
