import {memo} from 'react';

import styled from 'styled-components';
import Barcode from 'react-jsbarcode';

import {getLocalizationService} from '~/shared/services/localisationService';
import {body14Bold} from '~/shared/theme/typography';

import {Section} from './parts';

const Root = styled(Section)``;

const StyledBarcode = styled(Barcode).attrs({
  className: 'avoid-fill-stroke',
})`
  margin: 24px auto;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 8px;
  justify-content: center;
`;

const Title = styled.div`
  color: ${({theme}) => theme.colors.text2};
  line-height: 20px;
  ${body14Bold}
  width: 240px;
  text-align: center;
`;

const BarcodeOrderIdArea = ({barcodeOrderId}: {barcodeOrderId: string}) => {
  const {t} = getLocalizationService();

  return (
    <Root>
      <TitleWrapper>
        <Title>{t('please_scan_the_bercode_or_enter_the_order_number_at_the_restaurant_kiosk')}</Title>
      </TitleWrapper>
      <StyledBarcode value={barcodeOrderId} />
    </Root>
  );
};

export default memo(BarcodeOrderIdArea);
