import {useRef, useEffect, ReactNode, HTMLAttributes} from 'react';

import styled from 'styled-components';

import {createLogger} from '~/shared/logging';

const logger = createLogger('ClickOutsideDiv');

const Div = styled.div``;

const ClickOutsideDiv = ({
  children,
  onClickOutside,
  ...rest
}: {
  children?: ReactNode;
  onClickOutside: (event: Event) => void;
  rest?: HTMLAttributes<HTMLDivElement>[];
}) => {
  const divContainerElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleTouchIfOutside = (e: Event) => {
      try {
        if (e.defaultPrevented) {
          return;
        }

        const isOutside = divContainerElRef.current && !divContainerElRef.current.contains(e.target as Node);

        if (isOutside) {
          onClickOutside(e);
        }
      } catch (err) {
        logger.error('handleTouchIfOutside', err);
      }
    };

    // we are using mousedown because mousedown also covers drags (#41035)
    document.addEventListener('mousedown', handleTouchIfOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleTouchIfOutside, true);
    };
  }, [divContainerElRef, onClickOutside]);

  return (
    <Div ref={divContainerElRef} {...rest}>
      {children}
    </Div>
  );
};

export default ClickOutsideDiv;
