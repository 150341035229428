export enum SHOPPING_CART_DELIVERY_TYPE {
  ASAP_ONLY = 'ASAP_ONLY',
  POOL_ONLY = 'POOL_ONLY',
  ASAP_AND_POOLED = 'AsapAndPooled',
  ASAP_AND_FUTURE = 'AsapAndFuture',
  FUTURE = 'Future',
  SDFO = 'SameDayFutureOrder',
  // Default used primary on closed or voucher restaurants
  // Doesnt have a shopping cart header, the Delivery rule for this option is "ASAP"
  DEFAULT = 'Default',
}
