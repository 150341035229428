import {AxiosInstance} from 'axios';

import {getContext as defaultGetContext} from './contextGetter';
import {setLogger} from './logger';
import {FeatureManager} from './featureManager';
import {FmSettings} from './settings';

export const createFeatureManager = (
  settings: FmSettings,
  httpClient?: AxiosInstance,
): FeatureManager => {
  if (settings.logger) {
    // eslint-disable-next-line @babel/no-unused-expressions
    settings.logger && setLogger(settings.logger);
  }

  if (!settings.getContext) {
    settings.getContext = defaultGetContext;
  }

  return new FeatureManager(settings, httpClient);
};
