import styled from 'styled-components';

import {flipOnLTR, rotateOnLTR} from '~/shared/theme/flipOnLTR';
import {media} from '~/shared/theme/media';
import {body17Bold, subheader26Bold} from '~/shared/theme/typography';
import orderSuccess from '~/assets/images/icons/desktopSuccess.svg';
import ImageWithAlt from '~/shared/components/ImageWithAlt';

const SuccessImageContainer = styled.div`
  overflow: hidden;
`;

const EmbeddedText = styled.div`
  ${subheader26Bold};
  line-height: 30px;
  position: absolute;
  bottom: 30px;
  ${flipOnLTR`
    right: 20px;
  `}
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  z-index: 10;
  ${media.maxMobile`
    bottom: 20px;
    ${body17Bold};
    ${flipOnLTR`
      right: 10px;      
    `}
  `};
`;

const SuccessImage = styled(ImageWithAlt).attrs({
  src: orderSuccess,
  noAlt: true,
})`
  height: 100%;
  background: ${({theme}) => theme.colors.surface};
  width: calc(100% + 40px);
  margin-top: 8px;
  padding-top: 20px;
  ${flipOnLTR`
    margin-right: -20px;
  `}
  ${media.minMobile`
    width: 100%;
    ${flipOnLTR`
      margin-right:0;
    `}
  `};
  ${rotateOnLTR}
`;

const SuccessImageWithText = ({text}) => (
  <SuccessImageContainer>
    <SuccessImage />
    <EmbeddedText>{text}</EmbeddedText>
  </SuccessImageContainer>
);

export default SuccessImageWithText;
