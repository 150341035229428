import {every, some} from 'lodash';

import {RestaurantFromSearch, RestaurantFromGet} from '~/shared/store/models';

import {isRestaurantFromGet} from '../restaurant';

export const RESTAURANT_STATUS_MAP = {
  CLOSED: 'closed',
  OPEN: 'open',
  CLOSED_WITH_FUTURE_ORDER_STARTING_TOMORROW: 'closedWithFutureOrderStartingTomorrow',
  OPEN_WITH_FUTURE_ORDER_STARTING_TODAY: 'openWithFutureOrderStartingToday',
};

export type RestaurantStatusType = EnumValueType<typeof RESTAURANT_STATUS_MAP>;

export const isRestaurantOpen = (restaurant?: RestaurantFromSearch | RestaurantFromGet) => {
  if (!restaurant) {
    return false;
  }

  if (isRestaurantFromGet(restaurant)) {
    return restaurant.pickupRule?.isActiveNow ||
      some(restaurant?.deliveryRules, 'isActiveNow') ||
      restaurant.availableFutureDatesAndTimes?.length;
  }

  return restaurant.isActive && restaurant.isOpenNow;
};

export const isVoucherRestaurant = (restaurant?: RestaurantFromGet | RestaurantFromSearch) => {
  if (!restaurant) {
    return false;
  }

  if (isRestaurantFromGet(restaurant)) {
    return restaurant.isVoucherEnabled;
  }

  return restaurant.voucherRestaurant;
};

export const getRestaurantStatus = (restaurant?: RestaurantFromSearch | RestaurantFromGet | null) => {
  if (!restaurant) {
    return;
  }

  if (isRestaurantFromGet(restaurant)) {
    if (
      restaurant.pickupRule?.isActiveNow ||
      some(restaurant?.deliveryRules, 'isActiveNow') ||
      restaurant.availableFutureDatesAndTimes?.length
    ) {
      return RESTAURANT_STATUS_MAP.OPEN;
    }
    if (
      !restaurant.pickupRule?.isActiveNow &&
      !every(restaurant?.deliveryRules, 'isActiveNow') &&
      restaurant.availableFutureDatesAndTimes?.length > 0
    ) {
      return RESTAURANT_STATUS_MAP.CLOSED_WITH_FUTURE_ORDER_STARTING_TOMORROW;
    }
    if (
      !restaurant.pickupRule?.isActiveNow &&
      !every(restaurant?.deliveryRules, 'isActiveNow') &&
      !restaurant.availableFutureDatesAndTimes?.length
    ) {
      return RESTAURANT_STATUS_MAP.CLOSED;
    }
  } else {
    if ((restaurant.isActive && restaurant.isOpenNow)) {
      return RESTAURANT_STATUS_MAP.OPEN;
    }

    if ((restaurant.futureOrderTodayBeginTime)) {
      return RESTAURANT_STATUS_MAP.OPEN_WITH_FUTURE_ORDER_STARTING_TODAY;
    }

    if (
      !restaurant.isOpenNow &&
      restaurant.isActive &&
      restaurant.futureOrdersAvailable &&
      !restaurant.isPooledOrderRestaurant
    ) {
      return RESTAURANT_STATUS_MAP.CLOSED_WITH_FUTURE_ORDER_STARTING_TOMORROW;
    }

    if (!restaurant.isActive || !(restaurant.isOpenNow && restaurant.futureOrdersAvailable)) {
      return RESTAURANT_STATUS_MAP.CLOSED;
    }
  }
};

type GetRestaurantOpenStatusForBI = (getStatusArguments: {
  isAnonymousWithoutAddress?: boolean;
  isPreOrder?: boolean;
  isOpen?: boolean;
  options?: {
    tempClosedReason?: string;
  };
}) => string | null;

export const getRestaurantOpenStatusForBI: GetRestaurantOpenStatusForBI = ({isAnonymousWithoutAddress, isPreOrder, isOpen, options}) => {
  if (isAnonymousWithoutAddress) {
    return null;
  }
  if (isPreOrder) {
    return 'Preorder';
  }
  if (isOpen) {
    return 'Open';
  }
  return `Closed-${options?.tempClosedReason || 'UnknownReason'}`;
};

export const isSDFOWithFreeDelivery = (restaurant: RestaurantFromSearch) => restaurant.futureOrderTodayBeginTime && restaurant.deliveryFee === 0;
