import {createLogger} from '~/shared/logging';
import {BillingLineWithDiscountsData, IShoppingCartBillingLine} from '~/shared/store/models/ShoppingCart/IShoppingCart';
import {BillingLineType as BillingLineTypeEnum} from '~/shared/consts/checkoutConsts';

import {DeliveryMethod} from '../consts/restaurantConsts';
import {Dish, Coupon, ShoppingCartDish} from '../store/models';
import {ChoiceToSubmit} from '../store/models/ShoppingCart/ShoppingCartDish';

const logger = createLogger('billingLinesCalculation');

const BillingLineType = {
  Tip: 'Tip',
  SubTotal: 'SubTotal',
  DeliveryFee: 'DeliveryCharge',
  DiscountCoupon: 'DiscountCoupon',
  TotalToCharge: 'TotalToCharge',
  DeliveryDiscount: 'DeliveryDiscount',
};

const BillingLinePriority = {
  SubTotal: 10,
  DiscountCouponOrderItems: 15,
  DeliveryFee: 30,
  DeliveryDiscount: 40,
  DiscountCouponNormal: 50,
  Tip: 60,
  TotalToCharge: 70,
};

const CouponAssignedType = {
  None: 'None',
  OrderTotal: 'OrderTotal',
  OrderItems: 'OrderItems',
  DeliveryCharge: 'DeliveryCharge',
  SingleDish: 'SingleDish',
  SingleCategory: 'SingleCategory',
};

const CouponValueType = {
  None: 'None',
  Percent: 'Percent',
  Amount: 'Amount',
};

export type DishFromCurrentRestaurant = {
  choices: Dish['choices'];
  dishPrice: number;
};

export const getDishTotalPrice = (
  dishFromCurrentRestaurant: DishFromCurrentRestaurant,
  choices: ChoiceToSubmit[],
  quantity = 1,
) => {
  if (!dishFromCurrentRestaurant?.choices) {
    logger.warn('no dishFromCurrentRestaurant.choices', {dishFromCurrentRestaurant});
    return 0;
  }

  let totalPrice = dishFromCurrentRestaurant.dishPrice;

  choices.forEach(choice => {
    const choiceFromRes = dishFromCurrentRestaurant.choices.find(c => c.choiceId === choice.choiceId);
    if (!choiceFromRes) {
      logger.warn('userChoice was not found in dishFromCurrentRestaurant choices', {
        dishFromCurrentRestaurant,
        choices,
        choice,
        choiceFromRes,
      });
      return;
    }

    choice.subsChosen?.forEach(sub => {
      const subFromRes = choiceFromRes.subs.find(s => s.subId === sub.subId);
      if (!subFromRes) {
        logger.warn('sub was not found in dishFromCurrentRestaurant subs', {
          dishFromCurrentRestaurant,
          choices,
          choice,
          choiceFromRes,
          sub,
          subFromRes,
        });
        return;
      }

      totalPrice += subFromRes.price;
    });
  });

  return quantity * totalPrice;
};

export const calculateTotalDishesAndMealDeals = (
  dishList: ShoppingCartDish[],
  currentRestaurantDishesByDishId: Record<number, Dish>,
) => {
  let totalDishesAndMealDealsPrice = 0;

  dishList.forEach(dish => {
    const dishFromCurrentRestaurant = currentRestaurantDishesByDishId[dish.dishId];
    const {choices, quantity} = dish;
    totalDishesAndMealDealsPrice += getDishTotalPrice(dishFromCurrentRestaurant, choices, quantity);
  });

  return totalDishesAndMealDealsPrice;
};

type CalculateSingleCouponDiscountType = {
  dishesPrice: number;
  deliveryFee: number;
  coupon: Coupon;
};

export const calculateSingleCouponDiscount = ({
  coupon,
  dishesPrice,
  deliveryFee,
}: CalculateSingleCouponDiscountType) => {
  let assignementWorth = 0;
  let discount = 0;

  if (!coupon.isActive) {
    return 0;
  }

  switch (coupon.assignmentType) {
    case CouponAssignedType.None:
      return 0;
    case CouponAssignedType.OrderTotal:
      assignementWorth = dishesPrice + deliveryFee;
      break;
    case CouponAssignedType.OrderItems:
      assignementWorth = dishesPrice;
      break;
    default:
      break;
  }

  switch (coupon.valueType) {
    case CouponValueType.None:
      discount = 0;
      break;
    case CouponValueType.Percent:
      discount = (assignementWorth * coupon.discountValue) / 100;
      break;
    case CouponValueType.Amount:
      discount = coupon.discountValue;
      break;
    default:
      discount = 0;
      break;
  }

  if (discount > assignementWorth) {
    discount = assignementWorth;
  }

  return discount;
};

type RecalculateBillingLinesType = {
  dishList: ShoppingCartDish[];
  deliveryMethod: DeliveryMethod;
  deliveryFee: number | 0;
  minOrderTotalForDeliveryFeeDiscount: number | 0;
  discountedDeliveryFee: number | 0;
  coupon: Coupon | null;
  tip: number;
  currentRestaurantDishesByDishId: Record<number, Dish> | null;
};
export const recalculateBillingLines = ({
  dishList,
  deliveryMethod,
  deliveryFee,
  minOrderTotalForDeliveryFeeDiscount,
  discountedDeliveryFee,
  coupon,
  tip: requestedTip,
  currentRestaurantDishesByDishId,
}: RecalculateBillingLinesType) => {
  const billingLines = [];
  if (currentRestaurantDishesByDishId) {
    const dishesPrice = calculateTotalDishesAndMealDeals(dishList, currentRestaurantDishesByDishId);
    let total = dishesPrice;

    billingLines.push({type: BillingLineType.SubTotal, amount: dishesPrice, priority: BillingLinePriority.SubTotal});
    let actualDeliveryFee = deliveryFee;
    if (deliveryMethod === 'delivery') {
      if (actualDeliveryFee > 0) {
        if (minOrderTotalForDeliveryFeeDiscount > 0 && total >= minOrderTotalForDeliveryFeeDiscount) {
          actualDeliveryFee = discountedDeliveryFee;
          billingLines.push({
            type: BillingLineType.DeliveryDiscount,
            amount: discountedDeliveryFee - deliveryFee,
            priority: BillingLinePriority.DeliveryDiscount,
          });
        }
        billingLines.push({
          type: BillingLineType.DeliveryFee,
          amount: deliveryFee,
          priority: BillingLinePriority.DeliveryFee,
        });
      }
      total += actualDeliveryFee;
    }

    if (coupon) {
      const discount = calculateSingleCouponDiscount({coupon, dishesPrice, deliveryFee: actualDeliveryFee});

      if (discount > 0) {
        const couponPriority = coupon.assignmentType
          ? coupon.assignmentType === CouponAssignedType.OrderItems
            ? BillingLinePriority.DiscountCouponOrderItems
            : BillingLinePriority.DiscountCouponNormal
          : BillingLinePriority.DiscountCouponNormal;

        billingLines.push({type: BillingLineType.DiscountCoupon, amount: discount * -1, priority: couponPriority});

        total -= discount;
      }
    }

    if (requestedTip) {
      const tip = requestedTip > dishesPrice ? Math.floor(dishesPrice) : requestedTip;
      billingLines.push({type: BillingLineType.Tip, amount: tip, priority: BillingLinePriority.Tip});
      total += tip;
    }

    billingLines.push({
      type: BillingLineType.TotalToCharge,
      amount: total,
      priority: BillingLinePriority.TotalToCharge,
    });

    return billingLines;
  }
};

type ComposableBillingLines = Exclude<BillingLineTypeEnum, 'DeliveryDiscount'>;
const isComposableBillingLine =
  (billingLineType: BillingLineTypeEnum): billingLineType is ComposableBillingLines =>
    billingLineType !== BillingLineTypeEnum.DeliveryDiscount;

export type ComposedBillingLinesMap = Record<
  ComposableBillingLines,
  BillingLineWithDiscountsData | null
>;

const COMPOSE_BILLING_LINE_BY_TYPE: Record<ComposableBillingLines, (params: {
  currentBillingLine: IShoppingCartBillingLine;
  billingLines: IShoppingCartBillingLine[];
  deliveryFeeBeforeDiscount?: number;
  deliveryFeeOnly?: boolean;
}) => BillingLineWithDiscountsData | null> = {
  DeliveryCharge: ({currentBillingLine, billingLines, deliveryFeeBeforeDiscount}) => {
    const deliveryDiscountBillingLine = billingLines.find(({type}) => type === BillingLineTypeEnum.DeliveryDiscount);

    return {
      ...currentBillingLine,
      discountsData: deliveryDiscountBillingLine ||
      (deliveryFeeBeforeDiscount && deliveryFeeBeforeDiscount !== currentBillingLine.amount) ? {
            price: deliveryFeeBeforeDiscount || currentBillingLine.amount,
            priceAfterDiscount: currentBillingLine.amount -
          Math.abs(deliveryDiscountBillingLine?.amount || 0),
          } : null,
    };
  },
  SubTotal: ({currentBillingLine, billingLines, deliveryFeeOnly}) => {
    const discountCouponBillingLine = billingLines.find(({type}) => type === BillingLineTypeEnum.DiscountCoupon);

    return {
      ...currentBillingLine,
      discountsData: !deliveryFeeOnly && discountCouponBillingLine ? {
        price: currentBillingLine.amount,
        priceAfterDiscount: currentBillingLine.amount - Math.abs(discountCouponBillingLine.amount),
      } : null,
    };
  },
  Tip: ({currentBillingLine}) => ({...currentBillingLine, discountsData: null}),
  TotalToCharge: ({currentBillingLine, billingLines, deliveryFeeOnly}) => {
    const discountCouponBillingLine = billingLines.find(({type}) => type === BillingLineTypeEnum.DiscountCoupon);
    const deliveryDiscountBillingLine = billingLines.find(({type}) => type === BillingLineTypeEnum.DeliveryDiscount);

    return {
      ...currentBillingLine,
      discountsData: !deliveryFeeOnly && (discountCouponBillingLine || deliveryDiscountBillingLine) ? {
        price: currentBillingLine.amount +
          Math.abs(discountCouponBillingLine?.amount || 0) +
          Math.abs(deliveryDiscountBillingLine?.amount || 0),
        priceAfterDiscount: currentBillingLine.amount,
      } : null,
    };
  },
  DiscountCoupon: ({currentBillingLine, deliveryFeeOnly}) => (deliveryFeeOnly ?
      ({...currentBillingLine, discountsData: null}) :
    null),
};

export const getComposedBillingLines = (billingLines: IShoppingCartBillingLine[], {
  deliveryFeeOnly,
  deliveryFeeBeforeDiscount,
}: {
  deliveryFeeOnly?: boolean;
  deliveryFeeBeforeDiscount?: number;
} = {}): BillingLineWithDiscountsData[] => {
  const composedBillingLinesMap = billingLines.reduce<ComposedBillingLinesMap>((
    composedBillingLinesMapAcc,
    currentBillingLine,
  ) => {
    if (!isComposableBillingLine(currentBillingLine.type)) {
      return composedBillingLinesMapAcc;
    }

    return {
      ...composedBillingLinesMapAcc,
      [currentBillingLine.type]: COMPOSE_BILLING_LINE_BY_TYPE[currentBillingLine.type]({
        currentBillingLine,
        billingLines,
        deliveryFeeBeforeDiscount: deliveryFeeBeforeDiscount || undefined,
        deliveryFeeOnly,
      }),
    };
  }, {
    DeliveryCharge: null,
    SubTotal: null,
    Tip: null,
    TotalToCharge: null,
    DiscountCoupon: null,
  });

  return [
    composedBillingLinesMap.SubTotal,
    composedBillingLinesMap.DiscountCoupon,
    composedBillingLinesMap.DeliveryCharge,
    composedBillingLinesMap.Tip,
    composedBillingLinesMap.TotalToCharge,
  ].filter(Boolean) as BillingLineWithDiscountsData[];
};
