import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
// eslint-disable-next-line no-restricted-imports
// import {makeThunkAsyncActionCreator as makeThunkAsyncActionCreatorOrig} from 'redux-toolbelt-thunk';

import {AppState, AppStore, ThunkExtraArgument} from './configStore';
import {Action, GenericAction, makeThunkAsyncActionCreatorBase} from './redux-toolbelt-10bis';

export * from './redux-toolbelt-10bis';

/**
 * Creates a thunk async action creator.
 * This is a generic function that MUST receive R type (A is Optional)
 * @template APayloadOrA Mandatory type - thunk action creator action payload type / action type.
 * @template R Mandatory type - thunk action creator return type
 * @template AType Optional type - thunk action creator action type type.
 * If a payload type is provided a GenericAction type with the payload will be inferred
 */
export function makeThunkAsyncActionCreator<
  APayloadOrA = unknown,
  R = never,
  AType extends string = string,
  InferredR extends R = R,
  A extends Action = InferredA<APayloadOrA, AType>,
>(action: AType, handler: ThunkActionHandler<NonNullable<A['payload']>, InferredR>) {
  return makeThunkAsyncActionCreatorBase<A, [any, ThunkArguments], InferredR>(
    action,
    (...args) => {
      const {extraThunkArg} = args.pop() as ThunkArguments;

      return handler(args[0], extraThunkArg);
    },
    undefined as unknown as makeThunkAsyncActionCreatorBase.ArgsMapperFN<A>,
    undefined as unknown as makeThunkAsyncActionCreatorBase.Options<A>,
  );
}

type InferredA<APayloadOrA, AType extends string> = [APayloadOrA] extends [never]
  ? GenericAction<APayloadOrA, AType>
  : APayloadOrA extends Action
  ? APayloadOrA
  : GenericAction<APayloadOrA, AType>;

export type EnhancedThunkExtraArgument = ThunkExtraArgument & {store: AppStore};

type ThunkActionHandler<P, R> = (payload: P, extraArgument: EnhancedThunkExtraArgument) => Promise<R>;

type ThunkArguments<E extends EnhancedThunkExtraArgument = EnhancedThunkExtraArgument, A extends Action = AnyAction> = {
  dispatch: ThunkDispatch<AppState, E, A>;
  getState: () => AppState;
  extraThunkArg: E;
};
