import {useMemo} from 'react';

import {useSelector} from 'react-redux';

import {selectCurrentAddressKey, selectAllAddresses, selectCurrentDeliveryMethod} from '~/shared/store/selectors';
import {findAddressByAddressKey, isAddressKeyContainAddressId} from '~/shared/utils/address';
import {DeliveryMethods} from '~/shared/consts/restaurantConsts';

const useCheckoutAddress = () => {
  const currentAddressKey = useSelector(selectCurrentAddressKey);
  const allAddresses = useSelector(selectAllAddresses);
  const currentDeliveryMethod = useSelector(selectCurrentDeliveryMethod);

  const currentAddress = useMemo(
    () => findAddressByAddressKey(allAddresses, currentAddressKey),
    [allAddresses, currentAddressKey],
  );

  const deliveryMethod = useMemo(() => currentDeliveryMethod || DeliveryMethods.DELIVERY, [currentDeliveryMethod]);

  const isPickupMode = useMemo(() => deliveryMethod === DeliveryMethods.PICKUP, [deliveryMethod]);

  const isAddressWithoutId = useMemo(
    () => currentAddressKey && !isAddressKeyContainAddressId(currentAddressKey),
    [currentAddressKey],
  );

  return {
    isPickupMode,
    deliveryMethod,
    currentAddressKey,
    currentAddress,
    isAddressWithoutId,
  };
};

export default useCheckoutAddress;
