import styled from 'styled-components';

import {flexSpace} from '~/shared/theme/FlexLayout';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';

const QuantityContainer = styled.div`
  width: 16px;
  font-weight: ${({fontBold}) => (fontBold ? 'bold' : 'normal')};
  ${flexSpace};
  ${flipOnLTR`
    margin-left: 5px;
  `}
`;

const Quantity = ({children, className} = {}) => {
  if (!children) {
    return null;
  }
  return <QuantityContainer className={className}>{children}</QuantityContainer>;
};

export default Quantity;
