import {createSelector} from 'reselect';

import {BillingLineWithDiscountsData} from '~/shared/store/models/ShoppingCart/IShoppingCart';
import {getComposedBillingLines} from '~/shared/utils/billingLinesCalculation';

import {AppState} from '../../configStore';

const selectOrder = (state: AppState) => state.order;
const selectRemotelyFetchedOrder = createSelector(selectOrder, order => order.remotelyFetched);
export const selectIsReorder = createSelector(selectOrder, order => order.isReorder);

export const selectCurrentOrderData = createSelector(
  selectRemotelyFetchedOrder,
  remotelyFetched => remotelyFetched.data?.data,
);
export const selectCurrentOrderLoading = createSelector(
  selectRemotelyFetchedOrder,
  remotelyFetched => remotelyFetched.loading,
);
export const selectOrderError = createSelector(selectRemotelyFetchedOrder, order => order?.error);

export const selectNotesForClient = createSelector(selectOrder, order => order?.notesForClient);
export const selectOrderRemarks = createSelector(selectOrder, order => order.remarks);
export const selectRemarksToPresent = createSelector(selectOrder, order => order.remarksToPresent);
export const selectOrderDontWantCutlery = createSelector(selectOrder, order => order.dontWantCutlery);
export const selectCurrentOrderDateAndTime = createSelector(selectOrder, order => order.dateAndTime);
export const selectDinningRoomNoPackingRequired = createSelector(
  selectOrder,
  order => order.dinningRoomNoPackingRequired,
);
export const selectOrderSuccessData = createSelector(selectOrder, order => order.successData.data);
export const selectOrderSuccessBillingLinesWithDiscounts = createSelector(
  selectOrderSuccessData,
  (orderSuccessData): BillingLineWithDiscountsData[] => {
    if (!orderSuccessData) {
      return [];
    }

    return getComposedBillingLines(orderSuccessData.billingLines, {
      deliveryFeeBeforeDiscount: orderSuccessData.deliveryFeeBeforeDiscounts,
      deliveryFeeOnly: orderSuccessData.orderDiscountType !== 'Percent',
    });
  },
);
export const selectOrderPermit = createSelector(selectOrder, order => order.permit);
export const selectOrderDeliveryRule = createSelector(selectOrder, order => order.deliveryRule);

export const selectDiningRoomBuilding = createSelector(selectOrder, order => order?.diningRoom?.building);
export const selectDiningRoomFloor = createSelector(selectOrder, order => order?.diningRoom?.floor);
export const selectInitialOrderData = createSelector(selectOrder, order => order?.initialOrder);
