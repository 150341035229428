import {Children} from 'react';

import styled from 'styled-components';

import {flexColumn, flexSpace} from '~/shared/theme/FlexLayout';
import {media} from '~/shared/theme/media';
import {body18Bold} from '~/shared/theme/typography';

const DetailsWrapper = styled.div`
  width: 100%;
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  background: ${({theme}) => theme.colors.surface};
  ${flexColumn};
  margin-top: 8px;
  padding: 16px 0;
  ${({borderTop, theme}) =>
    borderTop &&
    `
    border-top: 1px solid ${theme.colors.background};
  `};
`;
const DetailsHeader = styled.div`
  ${body18Bold};
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  margin-bottom: 6px;
  margin-top: ${({hasMargin}) => hasMargin && '19px'};
`;

export const DetailsLine = styled.div`
  ${flexSpace};
  flex-wrap: nowrap;
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  white-space: ${({noWrap}) => noWrap && 'nowrap'};
  margin-bottom: ${({orderSuccess}) => orderSuccess && '10px'};
  ${media.minMobile`
    ${({disabledOnDesktop}) =>
    disabledOnDesktop &&
      `
       pointer-events: none;
    `}
  `};
`;

export const OrderDetailsDivider = styled.div`
  border-top: 1px solid ${({theme}) => theme.colors.gray500};
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Section = ({children, className, titleHasMargin: hasMargin, title, showLastItemDivider} = {}) => {
  return (
    <DetailsWrapper className={className}>
      {title && <DetailsHeader hasMargin={hasMargin}>{title}</DetailsHeader>}
      {children &&
        Children.map(children, (child, i) => {
          const lastItem = i === children.length - 1;
          return (
            <>
              <DetailsLine>{child}</DetailsLine>
              {!lastItem && showLastItemDivider && <OrderDetailsDivider />}
            </>
          );
        })}
    </DetailsWrapper>
  );
};

export default Section;
