import styled, {css} from 'styled-components';

import Button from '~/shared/components/Button';
import {body14Normal} from '~/shared/theme/typography';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {flexColumn} from '~/shared/theme/FlexLayout';

const titleCss = css`
  ${body14Normal};
  line-height: 14px;
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  cursor: pointer;
`;

export const SectionTitle = styled.div`
  ${titleCss}
`;

export const SwitchCouponButton = styled(Button)`
  ${titleCss}
`;

export const TipTitleLegend = styled.legend`
  display: flex;
  ${body14Normal};
  line-height: 14px;
  color: ${({theme}) => theme.colors.secondary};
  padding: 0;
  ${media.maxLargeMobile`
    padding-inline: 16px;
  `}
`;

export const TipTitleLabel = styled.div`
  display: ${({theme}) => theme.checkout.elements.TipTitleLabel.display};
  font-weight: bold;
  ${flipOnLTR`
    padding-left: 4px;
  `}
`;

export const ItemWrapper = styled.div`
  background-color: ${({theme}) => theme.checkout.elements.itemWrapper.backgroundColor};
  padding: ${({theme}) => theme.checkout.elements.itemWrapper.padding};
`;

export const Item = styled.div`
  ${flexColumn};
  color: ${({theme}) => theme.colors.secondary};
  background-color: ${({theme}) => theme.colors.primaryText};
  border-bottom: ${({theme}) => theme.checkout.elements.item.borderBottom};
  padding: ${({theme}) => theme.checkout.elements.item.padding};
  margin-bottom: ${({theme}) => theme.checkout.elements.item.marginBottom};
  ${media.minLargeTablet`
    flex-direction: row;
    }
  `}
`;

export const ItemWithNoBottom = styled(Item)`
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
`;
