import {logger} from './logger';
import {Variant} from './config';
import {OnTrackFn} from './settings';

export const trackExperiment = (variant: Variant, onTrack?: OnTrackFn) => {
  try {
    const {experimentKey: key, experimentVariant: name} = variant;
    if (!key || !name) return;

    if (!onTrack) {
      logger.logWarn(
        `Experiment will not be tracked as no \`onTrack\` callback was provided (key: ${key}, variant: ${name})`,
      );
      return;
    }

    logger.logInfo(`Tracking experiment - key: ${key}, variant: ${name}`);
    onTrack(key, name);
  } catch (error: unknown) {
    logger.logError('An exception occurred while trying to send an experiment event', {error});
  }
};
