import styled from 'styled-components';

import ImageWithAlt from '~/shared/components/ImageWithAlt';
import getOnGoogleImageUrl from '~/assets/images/homepage/get-on-google-play.png';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flexColumnCenter} from '~/shared/theme/FlexLayout';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {media} from '~/shared/theme/media';
import {body12Normal} from '~/shared/theme/typography';
import {Link} from '~/shared/router';
import appStoreIcon from '~/assets/images/icons/appStore.svg';
import facebookIcon from '~/assets/images/icons/facebook-blue.svg';
import instagramIcon from '~/assets/images/icons/instagram.svg';
import {InfoWrapper, DetailsLine} from '~/shared/components/OrderCompleteSections/parts';

const AppLink = styled(Link)`
  height: 30px;
`;

const AppIconsWrapper = styled.div`
  display: flex;
  ${media.minMobile`
    ${flipOnLTR`
      margin-left: 20px;
    `}
  `};
`;

const SocialIcon = styled(ImageWithAlt).attrs(() => ({noAlt: true}))`
  height: 30px;
  width: ${({isApp}) => (isApp ? 100 : 30)}px;
  ${flipOnLTR`
    margin-left: 5px;
  `}
`;

const SocialWrapper = styled(InfoWrapper)`
  margin-left: 0;
  margin-right: inherit;
  overflow: visible;
  ${media.maxMobile`
    flex: 1;
  `};
  ${media.minLargeTablet`
    margin-left: auto;
    ${flipOnLTR`
      margin-right: auto;
    `}
  `};
`;

const FooterWrapper = styled(DetailsLine)`
  margin-top: ${({isEnlargeTopMargin}) => (isEnlargeTopMargin ? 26 : 10)}px;
  flex-wrap: wrap;
  ${flexColumnCenter};
  ${media.minLargeTablet`
    flex-direction: row;
    flex-wrap: nowrap;
`};
`;

const GetOnGoogle = styled(ImageWithAlt).attrs({
  alt: 'Get it on google play',
  src: getOnGoogleImageUrl,
})`
  width: 100px;
  height: 30px;
`;

const TextWrapper = styled.div`
  text-overflow: ellipsis;
  height: 30px;
  ${body12Normal};
  line-height: 12px;
  margin-top: 8px;
`;

const Footer = ({isEnlargeTopMargin}) => {
  const {t} = getLocalizationService();

  return (
    <FooterWrapper isEnlargeTopMargin={isEnlargeTopMargin}>
      <TextWrapper>{t('we_are_available_for_every_call_in_the_app_and_web')}</TextWrapper>
      <SocialWrapper>
        <AppIconsWrapper>
          <AppLink href="https://itunes.apple.com/us/app/tn-bys/id434368191?mt=8" rel="noopener" target="_blank">
            <SocialIcon isApp src={appStoreIcon} />
          </AppLink>
          <AppLink
            href="https://play.google.com/store/apps/details?id=com.tenbis.tbapp&hl=en"
            rel="noopener"
            target="_blank"
          >
            <GetOnGoogle />
          </AppLink>
        </AppIconsWrapper>
        <InfoWrapper>
          <AppLink href="https://www.instagram.com/10bis/" target="_blank" rel="noopener">
            <SocialIcon src={instagramIcon} />
          </AppLink>
          <AppLink href="https://www.facebook.com/10bis/" target="_blank" rel="noopener">
            <SocialIcon src={facebookIcon} />
          </AppLink>
        </InfoWrapper>
      </SocialWrapper>
    </FooterWrapper>
  );
};

export default Footer;
