import {MutableRefObject} from 'react';

import {Action, Dispatch} from 'redux';

import actions from '~/shared/store/actions';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import apiService from '~/shared/services/apiService';
import {createLogger} from '~/shared/logging';
import {splitOnFirstSpace} from '~/shared/utils/general';
import {
  LoginWithProvider,
  LoginWithPassword,
  SignUpCredentials,
} from '~/shared/store/storeModules/restrictedSharedActions';
import {i18nHelpersProps} from '~/shared/services/localisationService';
import {onSubmitEmailHandler} from '~/shared/components/SignIn/utils/authHelpers';

import {AuthFlowStep, LoginOrRegisterScreenKey, LoginProvider, ViewModes} from '../consts';

const logger = createLogger('FacebookLoginOrSignUpHelper');

export const onForgotPassword = ({dispatch, email}: {dispatch: Dispatch<Action>; email: string}) => {
  dispatch(actions.resetPassword({emailAddress: email}));
  dispatch(
    actions.setCurrentModal('successModal', {
      title: 'reset_password',
      text: 'an_email_to_complete_setting_new_password_process_was_sent_to_you',
      btnText: 'ok',
    }),
  );
};

type SubmitType = LoginWithProvider & LoginWithPassword;

interface onSubmitPasswordProps extends SubmitType {
  onSubmit: (args: SubmitType) => void;
}

export const onSubmitPassword = async ({
  email,
  facebookUserAccessToken,
  facebookUserId,
  googleUserId,
  googleToken,
  password,
  onSubmit,
}: onSubmitPasswordProps) => {
  onSubmit({email, password, facebookUserAccessToken, facebookUserId, googleToken, googleUserId});
};

type SubmitSignUpVerificationType = {
  authenticationCode?: string;
  setResErrors: (value: string | null) => void;
  signUpDetails: SignUpCredentials & {authenticationCode: string; authenticationToken: string; fullName: string};
  closeModal: () => void;
  dispatch: Dispatch<Action>;
};

export const onSubmitSignUpVerification = ({
  authenticationCode,
  setResErrors,
  signUpDetails,
  closeModal,
  dispatch,
}: SubmitSignUpVerificationType) => {
  if (!authenticationCode) {
    logger.error('onSubmitSignUpVerification didnt receives an authenticationCode');
    return;
  }

  setResErrors(null);
  dispatch(actions.clearUserWithLoaded());

  const [firstName, lastName] = splitOnFirstSpace(signUpDetails.fullName);

  ManagerProvider.registerUser({
    ...signUpDetails,
    firstName,
    lastName,
    authenticationCode,
  })
    .then(userData => {
      if (userData) {
        closeModal();
      }
    })
    .catch(e => {
      setResErrors(e.register?.message || e.message || 'activation_code_entry_is_wrong_please_try_again');
    });
};

interface submitVerificationProps {
  email: string;
  facebookUserAccessToken?: string;
  facebookUserId?: string;
  googleUserId?: string;
  googleCode?: string;
  authenticationToken: string;
  authenticationCode: string;
}
interface onSubmitVerificationProps extends submitVerificationProps {
  onSubmit: (args: submitVerificationProps) => void;
}

export const onSubmitVerification = ({
  onSubmit,
  authenticationCode,
  email,
  facebookUserAccessToken,
  facebookUserId,
  googleUserId,
  googleCode,
  authenticationToken,
}: onSubmitVerificationProps) => {
  onSubmit({email, facebookUserAccessToken, facebookUserId, authenticationCode, authenticationToken, googleCode, googleUserId});
};

type SignUpSubmitType = {
  entries: SignUpCredentials;
  setSignUpDetails: (value: SignUpCredentials & {authenticationToken: string}) => void;
  setAuthFlowStep: (value: AuthFlowStep) => void;
  setResErrors: (value: string[] | null) => void;
};

export const onSignUpSubmit = async ({
  entries,
  setSignUpDetails,
  setResErrors,
  setAuthFlowStep,
}: SignUpSubmitType) => {
  setResErrors(null);
  try {
    const {data} = await apiService.getActivationTokenAndSendActivationCodeToUser({
      cellPhone: entries.cellPhone,
      email: entries.email,
    });

    setSignUpDetails({authenticationToken: data as string, ...entries});
    setAuthFlowStep(AuthFlowStep.OTP);
  } catch (e: any) {
    setResErrors(e?.message || 'something_went_wrong');
  }
};

type ToStepVerificationModeType = {
  email: string;
  setEmailEntry: (value: string) => void;
  setAuthFlowStep: (value: AuthFlowStep) => void;
  setVerificationDetails: (value: {
    authenticationToken: string;
    isPhoneMethod: boolean;
    lastFourPhoneDigits: string;
  }) => void;
  setResErrors: (value: string[]) => void;
  t: i18nHelpersProps['t'];
};
export const toStepVerificationMode = async ({
  email,
  setEmailEntry,
  setAuthFlowStep,
  setVerificationDetails,
  setResErrors,
  t,
}: ToStepVerificationModeType) => {
  setEmailEntry(email);

  try {
    const {data}: Record<string, any> = await apiService.getUserAuthenticationDataAndSendAuthenticationCodeToUser({
      email,
    });
    const isPasswordScreen = data.authenticationMethod === 'Password';
    if (isPasswordScreen) {
      setAuthFlowStep(AuthFlowStep.PASSWORD);
      return;
    }

    const isPhoneMethod = data.codeAuthenticationData.sendingMethod === 'Phone';
    setVerificationDetails({
      authenticationToken: data.codeAuthenticationData.authenticationToken,
      isPhoneMethod,
      lastFourPhoneDigits: data.codeAuthenticationData.lastFourPhoneDigits,
    });

    setAuthFlowStep(AuthFlowStep.OTP);
  } catch (e: any) {
    const errorType = 'Error while fetching getUserAuthenticationDataAndSendAuthenticationCodeToUser';
    logger.error(errorType, {email, e, fingerprint: errorType});
    setResErrors(e?.message || t('something_went_wrong'));
  }
};

export const convertQueryOrPropToStartingScreenKey = (mode: string) => {
  if (mode === LoginProvider.FACEBOOK || mode === LoginProvider.GOOGLE) {
    return LoginOrRegisterScreenKey.PROVIDER;
  }

  return mode;
};

export const getStartingModeFromStartingScreenKey = (currentScreenKey: LoginOrRegisterScreenKey) => {
  if (currentScreenKey === LoginOrRegisterScreenKey.LOGIN) {
    return ViewModes.SIGN_IN_MODE;
  }

  if (currentScreenKey === LoginOrRegisterScreenKey.SIGN_UP) {
    return ViewModes.SIGN_UP_MODE;
  }

  if (currentScreenKey === LoginOrRegisterScreenKey.PROVIDER) {
    return ViewModes.SIGN_UP_MODE;
  }
};

export const openLoginScreenHandler = async ({
  email,
  getAuthCodeForProviderConnect,
  loginProvider,
  setLoginProvider,
  setProviderViewMode,
  setCurrentScreenKey,
  setInitialLoginEmail,
  setAuthFlowStep,
  setVerificationDetails,
  providerDetails,
  providerProps,
}: {
  email?: string;
  getAuthCodeForProviderConnect?: boolean;
  loginProvider: LoginProvider;
  setLoginProvider: (provider: LoginProvider) => void;
  setProviderViewMode: (viewMode: ViewModes) => void;
  setCurrentScreenKey: (screenKey: string) => void;
  setInitialLoginEmail: (email: string) => void;
  setAuthFlowStep: (step: AuthFlowStep) => void;
  setVerificationDetails?: (args: {
    authenticationToken: string;
    isPhoneMethod: boolean;
    lastFourPhoneDigits: string;
  }) => void;
  providerDetails: MutableRefObject<Record<string, string | number| undefined>>;
  providerProps?: Record<string, string | number | undefined>;
}) => {
  const authCodeResponse = getAuthCodeForProviderConnect ? await onSubmitEmailHandler({
    email,
    shouldSendTrackEvent: true,
    setVerificationDetails,
  }) : null;

  if (providerProps) {
    providerDetails.current = providerProps;
  }

  setLoginProvider(loginProvider);

  setProviderViewMode(ViewModes.SIGN_IN_MODE);

  setAuthFlowStep(authCodeResponse !== null ?
    authCodeResponse?.isPasswordScreen ?
      AuthFlowStep.PASSWORD : AuthFlowStep.OTP
    : AuthFlowStep.INITIAL,
  );
  if (loginProvider === LoginProvider.TENBIS) {
    setCurrentScreenKey(LoginOrRegisterScreenKey.LOGIN);
  }
  if (loginProvider !== LoginProvider.TENBIS) {
    setCurrentScreenKey(LoginOrRegisterScreenKey.PROVIDER);
  }
  if (email) {
    setInitialLoginEmail(email);
  }
};

export const openSignUpScreenHandler = ({loginProvider, setProviderViewMode, setCurrentScreenKey}: {
  loginProvider: LoginProvider;
  setLoginProvider: (loginProvider: LoginProvider) => void;
  setProviderViewMode: (loginProviderMode: ViewModes) => void;
  setCurrentScreenKey: (screenKey: string) => void;
}) => {
  setProviderViewMode(ViewModes.SIGN_UP_MODE);

  if (loginProvider === LoginProvider.TENBIS) {
    setCurrentScreenKey(LoginOrRegisterScreenKey.SIGN_UP);
  }
};

export const openProviderScreenHandler = ({
  setLoginProvider,
  setCurrentScreenKey,
  providerDetails,
  providerProps,
  nextLoginProvider,
  setProviderViewMode,
  nextScreen,
}: {
  setLoginProvider: (loginProvider: LoginProvider) => void;
  setCurrentScreenKey: (currentScreen: LoginOrRegisterScreenKey) => void;
  providerDetails: MutableRefObject<Record<string, string | number>>;
  providerProps: Record<string, string | number>;
  nextLoginProvider: LoginProvider;
  setProviderViewMode: (viewMode: ViewModes) => void;
  nextScreen: ViewModes;
}) => {
  setCurrentScreenKey(LoginOrRegisterScreenKey.PROVIDER);
  providerDetails.current = providerProps;
  setLoginProvider(nextLoginProvider);
  setProviderViewMode(nextScreen);
};
