import styled from 'styled-components';

import {flexCenter} from '~/shared/theme/FlexLayout';
import Button from '~/shared/components/Button';

const Root = styled(Button)`
  width: 80px;
  height: 100%;
  position: absolute;
  bottom: 0;
  ${flexCenter};
  background-color: ${({theme}) => theme.colors.surfacePrimaryAction};
  cursor: pointer;
  ${({theme: {isLTR}}) => (isLTR ? 'right: 0' : 'left: 0')};
`;

const Text = styled.div`
  color: ${({theme}) => theme.colors.surfacePrimaryActionText};
`;

const FormInputActionButton = ({actionButton, value}) => (
  <Root onClick={() => actionButton.onClick(value)}>
    <Text>{actionButton.text}</Text>
  </Root>
);

export default FormInputActionButton;
