const isDev = process.env.NODE_ENV !== 'production';
const BASE_CDN_URL = 'https://cdn.10bis.co.il';

const config = {
  releaseName: `10bis-next@${process.env.npm_package_version}`,
  environment: process.env.ENVIRONMENT || 'local',
  websiteUrl: 'https://www.10bis.co.il/',
  apiBaseUrl: process.env.API_BASE_URL || 'https://www.10bis.co.il/',
  bffApiBaseUrl: process.env.NEW_API_BASE_URL || 'https://tenbis-webapigateway-staging.tenbis.cloud/',
  bffApiSessionTokenTTL: process.env.SESSION_TOKEN_TTL || 20,
  legacySiteUrl: process.env.LEGACY_SITE_URL || 'https://www.10bis.co.il/',
  prerenderHost: process.env.PRE_RENDER_HOST || 'www.10bis.co.il',
  prerenderToken: process.env.PRE_RENDER_TOKEN,
  port: process.env.PORT || 3000,
  httpsPort: process.env.HTTPS_PORT || 443,
  isDev,
  isForceProxy: process.env.FORCE_PROXY === 'true' || isDev,
  isProxyBFF: process.env.PROXY_BFF === 'true' || isDev,
  isHTTP: process.env.isHTTP === 'true',
  basedir: process.env.BASEDIR || '/next-dev/',
  googleApisKey: process.env.GOOGLEMAPSKEY || 'AIzaSyDQDF682VfAeWY4dW3EELZwm3xltXlV-a4',
  facebookAppId: 353526365970,
  recaptchaKey: process.env.RECAPTCHA_KEY || '6LcpSC8UAAAAAC9ILkOodNLIMAe48Kp6mnhJDG0x',
  restaurantsMaxMorningPoolCloseTimeHours: process.env.restaurantsMaxMorningPoolCloseTimeHours || 17,
  restaurantsMaxMorningPoolCloseTimeMinutes: process.env.restaurantsMaxMorningPoolCloseTimeMinutes || 0,
  restaurantsEstimatedPoolDeliveryTimeMarginsEnd: process.env.restaurantsEstimatedPoolDeliveryTimeMarginsEnd || 60,
  restaurantsEstimatedPoolDeliveryTimeMarginsStart: process.env.restaurantsEstimatedPoolDeliveryTimeMarginsStart || 30,
  googlePlayLink: process.env.GOOGLE_PLAY_LINK || 'https://play.google.com/store/apps/details?id=com.tenbis.tbapp',
  iTunesLink: process.env.ITUNES_LINK || 'https://itunes.apple.com/us/app/tn-bys/id434368191',
  cdnUrl: process.env.CDN_URL,
  shoppingCartGuidExpirationMinutes: process.env.SHOPPING_CART_GUID_EXPIRATION_MINUTES || 720,
  storedAddressExpirationMinutes: process.env.STORED_ADDRESS_EXPIRATION_MINUTES || 120,
  shouldShowUserReport: process.env.SHOW_USER_REPORT === 'true',
  defaultCurrency: 'ILS',
  defaultCountry: 'Israel',
  paypalEnvironment: process.env.PAY_PAL_PRODUCTION === 'true' ? 'production' : 'sandbox',
  intercomAppId: process.env.INTERCOM_APP_ID || 'nzqx6uu6',
  enableMenuFetchFromCdn: process.env.ENABLE_MENU_FETCH_FROM_CDN === 'true',
  sentryDns: 'https://88e180ce469f405fa599dcd4dbfe134f@o408587.ingest.sentry.io/5755643',
  menuFetchFromCdnUrl: process.env.MENU_URL_CDN || `${BASE_CDN_URL}/restaurant-menu/`,
  enableCityDescriptionFetchFromCdn: process.env.ENABLE_CITY_SEO_FETCH_FROM_CDN === 'true',
  seoCityDescriptionFetchFromCdnUrl:
    process.env.CITY_SEO_TEXT_CDN || `${BASE_CDN_URL}/seo-for-cities-and-cuisine-types/`,
  renderRestaurantLogo: process.env.RENDER_RESTAURANT_LOGO === 'true' || false,
  baseCdnUrl: process.env.BASE_CDN_URL || BASE_CDN_URL,
  enableJetFm: true,
  googleLoginClientKey: process.env.GOOGLE_LOGIN_CLIENT_KEY,
};

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.info('current config (printed only on dev):', config);
}

module.exports = config;
