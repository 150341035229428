import {HTMLAttributes, memo, MouseEventHandler, useEffect, useRef, useState} from 'react';

import styled, {useTheme} from 'styled-components';

import {media, flipOnLTR} from '~/shared/theme/utils';
import Button from '~/shared/components/Button';
import {getLocalizationService} from '~/shared/services/localisationService';

const InputField = styled.input.attrs(({ariaLabel}: {ariaLabel?: string}) => ({
  'aria-label': ariaLabel || null,
}))`
  color: ${({theme, fontColor}) => fontColor || theme.colors.gray000};
  min-height: 30px;
  height: ${({height}) => (height ? `${height}px` : '100%')};
  border: 1px solid ${({theme}) => theme.colors.gray500};
  width: ${({mobileWidth, width}) => mobileWidth || width || '120px'};
  border-radius: 2px;
  margin: ${({margin}) => margin || '0'};
  ${flipOnLTR`
    text-align: right;
    padding-right: 5px;
  `};

  &&& {
    font-size: ${({fontSize}) => fontSize || '16'}px;
  }

  ::placeholder {
    color: ${({theme}) => theme.colors.gray500};
  }

  ${({type}) =>
    type === 'number' &&
    `
    ::-webkit-inner-spin-button, 
    ::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
    -moz-appearance: textfield;
  `}
`;

const InputWrapper = styled.div`
  height: 100%;
`;

const InputClearButtonSvg = ({
  clearButtonBackgroundColor,
  clearButtonImageColor,
  className,
}: {
  clearButtonBackgroundColor: string;
  clearButtonImageColor: string;
  className?: string;
}) => (
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <defs />
    <g id="Checkout" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Quick-Checkout-Breakdown-Full" transform="translate(-2121.000000, -274.000000)">
        <g id="Group-Copy" transform="translate(2073.000000, 99.000000)">
          <g id="Tip" transform="translate(40.000000, 131.000000)">
            <g id="Custome" transform="translate(0.000000, 35.000000)">
              <g id="Group-6" transform="translate(8.000000, 9.000000)">
                <path
                  className="avoid-fill"
                  d="M12,6 C12,9.31371429 9.31371429,12 6,12 C2.68628571,12 0,9.31371429 0,6 C0,2.68628571 2.68628571,0 6,0 C9.31371429,0 12,2.68628571 12,6 Z"
                  fill={clearButtonBackgroundColor}
                />
                <path
                  className="avoid-fill"
                  d="M5.25,5.25 L5.25,3 C5.25,2.58578644 5.58578644,2.25 6,2.25 C6.41421356,2.25 6.75,2.58578644 6.75,3 L6.75,5.25 L9,5.25 C9.41421356,5.25 9.75,5.58578644 9.75,6 C9.75,6.41421356
                  9.41421356,6.75 9,6.75 L6.75,6.75 L6.75,9 C6.75,9.41421356 6.41421356,9.75 6,9.75 C5.58578644,9.75 5.25,9.41421356 5.25,9 L5.25,6.75 L3,6.75 C2.58578644,6.75 2.25,6.41421356 2.25,6
                  C2.25,5.58578644 2.58578644,5.25 3,5.25 L5.25,5.25 Z"
                  fill={clearButtonImageColor}
                  transform="translate(6.000000, 6.000000) rotate(45.000000) translate(-6.000000, -6.000000) "
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const InputClearButton = styled(InputClearButtonSvg)`
  position: absolute;
  top: 50%;
  margin-top: -11.5px;
  ${flipOnLTR`
    left: 10px;
  `};
  width: 15px;
`;

const ClearButton = styled(Button)`
  width: 20px;
  position: absolute;
  ${flipOnLTR`
    left: 0;
  `};
  ${media.minMobile` 
    ${flipOnLTR`      
      left: 8px;
    `};
  `};
  top: calc(50% - 10px);
`;

const Input = ({
  inputType,
  onClearButtonClick,
  CustomClearComponent,
  isContrastActive,
  ...rest
}: {
  inputType: string;
  onClearButtonClick?: (e: MouseEventHandler<HTMLButtonElement>) => void;
  CustomClearComponent?: React.FC;
  isContrastActive: boolean;
  rest: HTMLAttributes<HTMLInputElement>;
}) => {
  const theme = useTheme();
  const {t} = getLocalizationService();

  const componentRef = useRef<React.FC | typeof InputClearButton>();
  const inputRef = useRef<HTMLInputElement>();
  const [Component, setComponent] = useState<React.FC | typeof InputClearButton>();

  const clearButtonBackgroundColor = isContrastActive ? theme.colors.surface : theme.colors.gray900;
  const clearButtonImageColor = isContrastActive ? theme.colors.gray000 : theme.colors.surface;
  const shouldUseCustomClearComponent = CustomClearComponent && onClearButtonClick;
  const shouldUseInitialClearComponent = !shouldUseCustomClearComponent && onClearButtonClick;

  useEffect(() => {
    if (shouldUseCustomClearComponent) {
      componentRef.current = CustomClearComponent;
    } else if (shouldUseInitialClearComponent) {
      componentRef.current = InputClearButton;
    }
    setComponent(componentRef.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUseCustomClearComponent, shouldUseInitialClearComponent]);

  const onFocus = ({disableAutoSelect}: {disableAutoSelect: boolean}) => {
    if (!disableAutoSelect) {
      inputRef?.current?.select();
    }
  };

  return (
    <InputWrapper>
      <InputField
        {...{
          type: inputType || 'text',
          ref: inputRef.current,
          onFocus,
          ...rest,
        }}
      />
      {Component && (
        <ClearButton aria-label={t('clear_tip')} onClick={onClearButtonClick}>
          <Component
            clearButtonBackgroundColor={clearButtonBackgroundColor}
            clearButtonImageColor={clearButtonImageColor}
            theme={theme}
          />
        </ClearButton>
      )}
    </InputWrapper>
  );
};

export default memo(Input);
