import {useState, useEffect, memo, useRef} from 'react';

import styled, {css} from 'styled-components';
import {uniqueId} from 'lodash';

import {SimpleSpinnerLoader} from '~/shared/components/Loaders';
import apiService from '~/shared/services/apiService';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flexCenter} from '~/shared/theme/FlexLayout';
import {media} from '~/shared/theme/media';

import {connectPayPalButton, useOnPaypalAuthorized} from './PayPalButtonHelper';

const Root = styled.div`
  ${flexCenter}
  z-index: 0;
  height: 100%;
  font-size: 50px;
  width: ${({variant}) => (variant === 'small' ? 'auto' : '100%')};
`;

const Loader = styled.div`
  position: absolute;
  ${flexCenter};
  top: 0;
  height: 100%;
  width: 100%;
`;

const PaypalBtn = styled.div`
  ${({variant}) =>
    (variant === 'small'
      ? css`
          height: 40px;
          width: 120px;
          ${media.minTablet`
            width: 150px;
            background-color: ${({theme}) => theme.colors.surface};
          `}
        `
      : css`
          width: 100%;
        `)}
`;

const PayPalButton = ({onSuccess, onError, variant = 'large'}) => {
  const {currentLanguageKey} = getLocalizationService();
  const paypalButtonRef = useRef(null);

  const [paypalLoaded, setPaypalLoaded] = useState(false);
  const [buttonId] = useState(() => `paypal-button-${uniqueId()}`);

  const onAuthorized = useOnPaypalAuthorized({onSuccess, onError});

  const onPaypalButtonRender = () => {
    setPaypalLoaded(true);
  };

  const handleError = err => {
    if (paypalButtonRef.current && onError) {
      onError(err?.message);
    }
  };

  useEffect(() => {
    if (paypalButtonRef.current && !paypalLoaded) {
      const generatePayPalClientToken = async () => {
        try {
          const clientToken = await apiService.generatePayPalClientToken();
          await connectPayPalButton({
            clientToken: clientToken.data,
            buttonId,
            onAuthorized,
            onError: handleError,
            variant,
            currentLanguageKey,
            onPaypalButtonRender,
          });
        } catch (error) {
          // ignoring error
        }
      };
      generatePayPalClientToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paypalButtonRef]);

  return (
    <Root variant={variant}>
      <PaypalBtn ref={paypalButtonRef} id={buttonId} variant={variant} />
      {!paypalLoaded && (
        <Loader>
          <SimpleSpinnerLoader />
        </Loader>
      )}
    </Root>
  );
};

export default memo(PayPalButton);
