import {SHOPPING_CART_DELIVERY_TYPE} from '~/shared/utils/restaurants/deliveryOptions/types';
import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';
import {RestaurantFromGet, RestaurantFromSearch} from '~/shared/store/models';
import {isRestaurantFromGet} from '~/shared/utils/restaurant';
import {ActiveDeliveryRulesData} from '~/shared/utils/restaurants/getActiveDeliveryRules';
import {CollectionOrderTypeViewTags} from '~/shared/store/models/Carousel';

type DefaultRuleGetter = (params: {
  restaurant: RestaurantFromSearch | RestaurantFromGet;
  activeRules: ActiveDeliveryRulesData;
  orderViewTag?: CollectionOrderTypeViewTags;
}) => DELIVERY_RULES;

const DEFAULT_RULE_BY_SHOPPING_CART_DELIVERY_TYPE: Record<SHOPPING_CART_DELIVERY_TYPE, DefaultRuleGetter> = {
  [SHOPPING_CART_DELIVERY_TYPE.ASAP_AND_FUTURE]: () => DELIVERY_RULES.ASAP,
  [SHOPPING_CART_DELIVERY_TYPE.ASAP_ONLY]: () => DELIVERY_RULES.ASAP,
  [SHOPPING_CART_DELIVERY_TYPE.POOL_ONLY]: () => DELIVERY_RULES.POOL,
  [SHOPPING_CART_DELIVERY_TYPE.FUTURE]: () => DELIVERY_RULES.FUTURE,
  [SHOPPING_CART_DELIVERY_TYPE.DEFAULT]: () => DELIVERY_RULES.ASAP,
  [SHOPPING_CART_DELIVERY_TYPE.ASAP_AND_POOLED]: ({restaurant, orderViewTag}) => {
    if (orderViewTag === CollectionOrderTypeViewTags.Asap) {
      return DELIVERY_RULES.ASAP;
    }

    if (orderViewTag === CollectionOrderTypeViewTags.Pooled) {
      return DELIVERY_RULES.POOL;
    }

    if (isRestaurantFromGet(restaurant)) {
      if (restaurant.defaultDeliveryRuleType) {
        return restaurant.defaultDeliveryRuleType;
      }

      return restaurant.poolOrder?.estimatedNumberOfOrdersSubmitted ?
        DELIVERY_RULES.POOL :
        DELIVERY_RULES.ASAP;
    }

    return restaurant.estimatedNumberOfOrdersInPoolOrder ?
      DELIVERY_RULES.POOL :
      DELIVERY_RULES.ASAP;
  },
  [SHOPPING_CART_DELIVERY_TYPE.SDFO]: ({restaurant, activeRules, orderViewTag}) => {
    if (isRestaurantFromGet(restaurant)) {
      if (orderViewTag === CollectionOrderTypeViewTags.Asap && !!activeRules.Asap) {
        return DELIVERY_RULES.ASAP;
      }

      return activeRules.Asap && !restaurant.futureDeliveryDefaultTime ?
        DELIVERY_RULES.ASAP :
        DELIVERY_RULES.FUTURE;
    }

    return restaurant.futureOrderTodayBeginTime ?
      DELIVERY_RULES.FUTURE :
      DELIVERY_RULES.ASAP;
  },
};

type GetDefaultRuleByShoppingCartDeliveryType = (
  shoppingCartDeliveryType: SHOPPING_CART_DELIVERY_TYPE,
  options: {
    restaurant: RestaurantFromSearch | RestaurantFromGet;
    activeRules: ActiveDeliveryRulesData;
    orderViewTag?: CollectionOrderTypeViewTags;
  }
) => DELIVERY_RULES;

const getDefaultRuleByShoppingCartDeliveryType: GetDefaultRuleByShoppingCartDeliveryType = (
  shoppingCartDeliveryType,
  {restaurant, activeRules, orderViewTag},
) => {
  return DEFAULT_RULE_BY_SHOPPING_CART_DELIVERY_TYPE[shoppingCartDeliveryType]({restaurant, activeRules, orderViewTag});
};

export default getDefaultRuleByShoppingCartDeliveryType;
