import {Address, LocalAddress, RestaurantFromGet, RestaurantFromSearch} from '~/shared/store/models';
import {getActiveDeliveryRules} from '~/shared/utils/restaurants/getActiveDeliveryRules';
import {isRestaurantFromGet} from '~/shared/utils/restaurant';
import {createLogger} from '~/shared/logging';

const logger = createLogger('isAsapOnlyRestaurant');

const isAsapOnlyRestaurant = (
  restaurant: RestaurantFromGet | RestaurantFromSearch,
  address?: Address | LocalAddress | undefined,
) => {
  if (isRestaurantFromGet(restaurant)) {
    if (!address) {
      logger.warn('No address was passed. When using RestaurantFromGet you should also pass the current address');
      return false;
    }

    const activeDeliveryRules = getActiveDeliveryRules(restaurant, address);

    return !!activeDeliveryRules?.Asap && !activeDeliveryRules?.Pool && !activeDeliveryRules?.Future;
  }

  return !restaurant.hasActivePoolRule && !restaurant.futureOrdersAvailable && restaurant.isOpenNow;
};

export default isAsapOnlyRestaurant;
