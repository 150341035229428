// eslint-disable-next-line no-restricted-imports
import moment, {MomentInput} from 'moment';

import {getLocalizationService} from '~/shared/services/localisationService';

import {DEFAULT_LANGUAGE_KEY} from '../consts/commonConsts';
import {FutureOrderAvailableDatesAndTimes} from '../store/models';

import {capitalize, isCurrentTimeKeyAsap} from './general';

export const getParsedDateDiff = (startDate?: MomentInput): string | undefined => {
  if (!startDate || !moment(startDate).isValid()) {
    return;
  }

  const {currentLanguageKey, t} = getLocalizationService();

  const start = moment(startDate);
  const end = moment().locale(currentLanguageKey);

  const isFutureDate: boolean = start.isAfter(end);
  const isTheSameDate: boolean = end.isSame(start, 'day');

  const diff = {
    inMinutes: isFutureDate ? start.diff(end, 'minutes') : end.diff(start, 'minutes'),
    inHours: isFutureDate ? start.diff(end, 'hours') : end.diff(start, 'hours'),
    inDays: Math.abs(end.dayOfYear() - start.dayOfYear()),
    inWeeks: isFutureDate ? start.diff(end, 'weeks') : end.diff(start, 'weeks'),
    inMonths: isFutureDate ? start.diff(end, 'months') : end.diff(start, 'months'),
  };

  if (diff.inMonths) {
    if (diff.inMonths === 2) {
      return isFutureDate ? t('two_months_ahead') : t('two_months_ago');
    }

    return diff.inMonths > 1
      ? t(isFutureDate ? 'x_months_ahead' : 'x_months_ago', {numberOfMonths: diff.inMonths})
      : t(isFutureDate ? 'one_month_ahead' : 'one_month_ago');
  }

  if (diff.inWeeks) {
    if (diff.inWeeks === 2) {
      return isFutureDate ? t('two_weeks_ahead') : t('two_weeks_ago');
    }
    if (diff.inWeeks === 4) {
      return isFutureDate ? t('one_month_ahead') : t('one_month_ago');
    }
    return diff.inWeeks > 1
      ? t(isFutureDate ? 'x_weeks_ahead' : 'x_weeks_ago', {numberOfWeeks: diff.inWeeks})
      : t(isFutureDate ? 'a_week_ahead' : 'a_week_ago');
  }

  if (diff.inDays) {
    if (diff.inDays === 2) {
      return isFutureDate ? t('two_days_ahead') : t('two_days_ago');
    }
    return diff.inDays !== 1
      ? t(isFutureDate ? 'x_days_ahead' : 'x_days_ago', {numberOfDays: diff.inDays})
      : t(isFutureDate ? 'for_tomorrow' : 'yesterday');
  }

  if (isTheSameDate) {
    return t('today');
  }
};

export const getArrivalText = (arrivalOrderDate: MomentInput, momentFormat?: string): string | undefined => {
  const {currentLanguageKey, t} = getLocalizationService();

  const now = moment();
  const givenDate = moment(arrivalOrderDate, momentFormat).locale(currentLanguageKey);

  if (now.isSame(givenDate, 'days')) {
    return capitalize(t('today'));
  }

  if (now.add(1, 'days').isSame(givenDate, 'days')) {
    return capitalize(t('tomorrow'));
  }

  const letterOfTheDay = givenDate.format('ddd');
  // 'ddd' actually returns the with `'` around the day name

  if (currentLanguageKey === DEFAULT_LANGUAGE_KEY) {
    return `יום ${letterOfTheDay}, ${arrivalOrderDate}`;
  }

  return `${letterOfTheDay}, ${arrivalOrderDate}`;
};

type SplitDate = {day: string; month: string; year: string};
export const getSplitStringDate = (sourceDate: string): SplitDate | undefined => {
  if (!sourceDate || sourceDate.split('/').length < 2) {
    return;
  }
  const [day, month, year] = sourceDate.split('/');
  return {day, month, year};
};

export const getDateTimeString = (
  orderTime?: {timeKey: string},
  orderDate?: FutureOrderAvailableDatesAndTimes['date'],
) => {
  if (!orderTime || !orderDate) {
    return;
  }

  const {day, month, year} = getSplitStringDate(orderDate) || {};
  const time = isCurrentTimeKeyAsap(orderTime.timeKey) ? moment().format('hh:mm') : orderTime.timeKey;
  return `${year}-${month}-${day}T${time}`;
};
