import {memo, useEffect, useState} from 'react';

import styled, {withTheme} from 'styled-components';

import {roundDecimal} from '~/shared/utils/general';
import {TipButtonValueTypes, TIP_BUTTONS} from '~/shared/consts/checkoutConsts';
import {getLocalizationService} from '~/shared/services/localisationService';
import {SkeletonLoader, Skeleton} from '~/shared/components/Loaders';
import {media} from '~/shared/theme/media';

import {TipTitleLegend, TipTitleLabel} from '../shared';

import TipButton from './TipButton';
import {RootFieldset, RulesLink, TipInput, CloseButton} from './CheckoutTip.styled';
import {TipsButtonsAndInputContainer, TipOptionsContainer} from './styled';

const SkeletonInput = styled(Skeleton)`
  height: ${({theme}) => theme.checkout.elements.tipInput.height};
  width: 134px;
  ${media.minLargeTablet`
    height: 32px;
  `}
`;

const Tip = withTheme(({theme, tipAmount, onChange, subTotalAmount, isLoading, t}) => {
  const [tipInputValue, setTipInputValue] = useState(String(tipAmount || ''));
  useEffect(() => {
    setTipInputValue(String(tipAmount || ''));
  }, [tipAmount]);

  const onInputTipChange = inputEvent => {
    const value = inputEvent?.target?.value.replace('₪', '') || '';
    onChange({
      value: roundDecimal(value.replace(/[^0-9.]/g, '')),
      isPercentage: false,
    });
    setTipInputValue(value);
  };

  const onSelectTipOption = (tipValue, isPercentage) => {
    onChange({
      value: roundDecimal(tipValue),
      isPercentage,
    });
  };

  const tipAmountLabel = tipAmount || '00.00';
  return (
    <TipOptionsContainer>
      <TipsButtonsAndInputContainer>
        {TIP_BUTTONS.map(tipButton => (
          <TipButton
            key={`${tipButton.amount}_${tipButton.isPercentage}`}
            value={tipButton.amount}
            isActive={
              tipButton.isPercentage
                ? tipAmount === roundDecimal(subTotalAmount * (tipButton.amount / 100))
                : tipButton.amount === tipAmount
            }
            valueType={tipButton.isPercentage ? TipButtonValueTypes.PERCENT : TipButtonValueTypes.AMOUNT}
            onClick={() => onSelectTipOption(tipButton.amount, tipButton.isPercentage)}
            isLoading={isLoading}
          />
        ))}
        <SkeletonLoader shouldShowLoader={isLoading} LoaderComponent={SkeletonInput}>
          <TipInput
            placeholder={t('other_tip_amount_in_nis')}
            ariaLabel={`${t('tip_amount_is')} ${tipAmountLabel} ${t('nis')}`}
            onChange={onInputTipChange}
            value={tipInputValue ? `₪${tipInputValue}` : ''}
            type="tel"
            fontColor={theme.tipTextField.color}
            onClearButtonClick={tipInputValue ? () => onInputTipChange(0) : null}
            CustomClearComponent={CloseButton}
            CustomClearComponentAlt={t('remove_tip')}
          />
        </SkeletonLoader>
      </TipsButtonsAndInputContainer>
    </TipOptionsContainer>
  );
});

const CheckoutTip = props => {
  const {isPickupMode} = props;
  const {t} = getLocalizationService();

  return (
    <RootFieldset>
      <TipTitleLegend
        aria-label={`${t(isPickupMode ? 'add_tip' : 'add_tip_to_delivery')} ${t('under_terms_and_conditions')}`}
      >
        <TipTitleLabel>{t(isPickupMode ? 'add_tip' : 'add_tip_to_delivery')}</TipTitleLabel>
        <RulesLink href="/terms-of-use" target="_blank">
          ({t('under_terms_and_conditions')})
        </RulesLink>
      </TipTitleLegend>
      <Tip t={t} {...props} />
    </RootFieldset>
  );
};

export default memo(CheckoutTip);
