import {memo, useCallback} from 'react';

import styled from 'styled-components';
import moment from 'moment-timezone';
import numeral from 'numeral';

import {getLocalizationService} from '~/shared/services/localisationService';
import {ActionButton} from '~/shared/components/Button';
import {createLogger} from '~/shared/logging';
import {UserVoucher} from '~/shared/store/models/User/UserVouchers';

import {User} from '../store/models';
import {body14Normal, body16Bold, body18Bold} from '../theme/typography';
import {EMPTY_OBJECT} from '../consts/commonConsts';
import printCoupon from '../utils/printCoupon';
import {NumericFormats} from '../consts/checkoutConsts';

const logger = createLogger('CouponBarCodeComponent');

const BoldInfoText = styled.div`
  ${body16Bold};
`;

const GeneralInfo = styled.div<{remarksNotExist?: boolean}>`
  background: ${({theme}) => theme.colors.surface};
  padding-right: 18px;
  padding-left: 18px;
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  padding-bottom: ${({remarksNotExist}) => remarksNotExist && '16px'};
`;

const CouponRemarks = styled.div`
  background: ${({theme}) => theme.colors.surface};
  padding-right: 18px;
  padding-left: 18px;
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  font-weight: bold;
  padding-bottom: 16px;
`;

const BarCodeSection = styled.div`
  padding-top: 32px;
  background: ${({theme}) => theme.colors.surface};
  margin-top: 8px;
`;

const BarCodeSubheaderSection = styled.div`
  ${body18Bold};
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  background: ${({theme}) => theme.colors.surface};
  padding-right: 18px;
  padding-left: 18px;
`;

const ButtonsContainer = styled.div`
  background: ${({theme}) => theme.colors.surface};
  text-align: center;
  padding-top: 10px;
  padding-bottom: 16px;
  width: 100%;
`;

const BarCodeNumber = styled.div`
  margin: auto;
  text-align: center;
  ${body14Normal};
  padding-top: 13px;
  color: ${({theme}) => theme.colors.text};
  background: ${({theme}) => theme.colors.surface};
`;

const ExplanationText = styled.div`
  text-align: center;
  padding: 18px 10px 10px 10px;
  color: ${({theme}) => theme.colors.secondary};
  background: ${({theme}) => theme.colors.surface};
`;

const BarCodeImg = styled.div.attrs(({imageUrl}: {imageUrl?: string}) => ({
  style: {backgroundImage: imageUrl && `url(${imageUrl})`},
}))<{imageUrl?: string}>`
  background-position: center;
  height: 57px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  width: 180px;
`;

const PrintButton = styled(ActionButton)`
  border-radius: 0;
  box-shadow: none;
  padding-top: 0;
  width: 252px;
`;

export interface IBarCodeCouponComponent {
  restaurantName: string;
  minLargeTablet: boolean;
  userData?: User;
  barCode?: UserVoucher;
}

const BarCodeCouponComponent = ({restaurantName, barCode, userData, minLargeTablet}: IBarCodeCouponComponent) => {
  const {currentLanguageDirection: direction, t} = getLocalizationService();
  const isLTR = direction === 'ltr';

  const {isGovCompany, firstName, lastName} = userData || EMPTY_OBJECT;
  const barcodeSum = numeral(barCode?.amount).format(NumericFormats.showZeroDecimals);
  const barcodeSumText = isLTR ? `₪${barcodeSum}` : `${barcodeSum}₪`;
  const print = useCallback(
    () => printCoupon({restaurantName, barCode, direction, barcodeSumText, isGovCompany, firstName, lastName, t}),
    [restaurantName, barCode, direction, barcodeSumText, isGovCompany, firstName, lastName, t],
  );

  if (!barCode) {
    logger.error('bar code is not available');
    return null;
  }

  const getTranslation = ({used, dateUsed, usedInBranchName: branchName, validDate}: UserVoucher) => {
    const date = dateUsed ? moment(dateUsed).format('DD.MM.YYYY') : null;
    let translated = t('order_success_coupon_remarks', {validDate});

    if (used) {
      translated = t('the_voucher_was_used');
      if (date && branchName) {
        translated = t('the_voucher_was_used', {context: 'both', date, branchName});
      } else if (date) {
        translated = t('the_voucher_was_used', {context: 'date', date});
      } else if (branchName) {
        translated = t('the_voucher_was_used', {context: 'branchName', branchName});
      }
    }
    return translated;
  };

  const voucherTranslation = getTranslation(barCode);

  return (
    <>
      <BarCodeSubheaderSection id="modal-title">{t('coupon_details')}</BarCodeSubheaderSection>
      <GeneralInfo remarksNotExist={isGovCompany} id="modal-content">
        {t('coupon_to_xxx_chain_in_the_amount_of_xxx', {
          chainName: restaurantName,
          totalAmount: barCode.amount,
        })}
      </GeneralInfo>
      {!isGovCompany && <CouponRemarks>{voucherTranslation}</CouponRemarks>}
      <GeneralInfo>
        {`${t('order_user_name')}:`}{' '}
        <b>
          {firstName} {lastName}
        </b>
      </GeneralInfo>
      <GeneralInfo>{t('voucher_is_personal_statement')}</GeneralInfo>

      <BarCodeSection>
        <BarCodeImg imageUrl={barCode.barCodeImgUrl} />
        <BarCodeNumber>{barCode.barCodeNumber}</BarCodeNumber>
      </BarCodeSection>
      <ExplanationText>
        <BoldInfoText>{t('bar_code_must_be_printed')}</BoldInfoText>
        <div>{`${t('list_of_chains_is_listed')} ${isGovCompany ? t('in_accordance_with') : ''} ${t(
          'this_order_can_not_be_canceled',
        )}`}
        </div>
      </ExplanationText>
      {minLargeTablet && (
        <ButtonsContainer>
          <PrintButton onClick={print}>
            <>{t('print_coupon')}</>
          </PrintButton>
        </ButtonsContainer>
      )}
    </>
  );
};

export default memo(BarCodeCouponComponent);
