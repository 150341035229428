import {DEFAULT_CULTURE, DEFAULT_LANGUAGE_KEY, EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {AppState} from '~/shared/store/configStore';
import {selectIsMaxLargeMobile, selectShoppingCart} from '~/shared/store/selectors';
import {createLogger} from '~/shared/logging';

import {getLocalizationService} from '../localisationService';
import {isApiError} from '../apiErrorService';

import {ApiServiceConfigParams} from './apiServiceConfig';

const logger = createLogger('apiServiceUtils');
export const getShoppingCartGuidParams = (state: AppState) => {
  const shoppingCartGuid = selectShoppingCart(state)?.shoppingCartGuid;
  return shoppingCartGuid ? {shoppingCartGuid} : {shoppingCartGuid: undefined};
};

export const getLocaleParams = (useDefaultLocale: boolean) => {
  if (useDefaultLocale) {
    return {culture: DEFAULT_CULTURE, uiCulture: DEFAULT_LANGUAGE_KEY};
  }
  const {culture: currentCulture, currentLanguageKey} = getLocalizationService();
  return {culture: currentCulture, uiCulture: currentLanguageKey};
};

export const getIsMobileDeviceParams = ({sendIsMobileDevice}: ApiServiceConfigParams, state: AppState) => {
  return sendIsMobileDevice
    ? {
        isMobileDevice: selectIsMaxLargeMobile(state),
      }
    : EMPTY_OBJECT;
};

export const getTimestampParams = ({noGetTimeStampParam, method}: ApiServiceConfigParams) => {
  return method === 'get' && !noGetTimeStampParam
    ? {
        timestamp: new Date().getTime(),
      }
    : EMPTY_OBJECT;
};

export const retryApiRequest = async <CallbackResponse>(
  callback: () => Promise<CallbackResponse>,
  attempt: number = 3,
  waitMs: number = 200,
  retryStatusCodes: number[] = [502, 503, 504],
): Promise<CallbackResponse> => {
  try {
    const callbackResponse = await callback();
    return callbackResponse;
  } catch (err: unknown) {
    const isErrorToRetry = isApiError(err) && (retryStatusCodes.includes(err.status) || err.error?.code === 'ERR_NETWORK');

    if (isErrorToRetry && attempt > 1) {
      await new Promise(resolve => setTimeout(resolve, waitMs));
      return retryApiRequest(callback, attempt - 1, waitMs, retryStatusCodes);
    }
    logger.error(`Failed retry api request ${callback.name}`, {err});
    throw err;
  }
};
