import {combineReducers} from 'redux';

import {Dish, IShoppingCart, ShoppingCartDish} from '~/shared/store/models';
import {BillingLineType} from '~/shared/consts/checkoutConsts';

import * as sharedActions from '../restrictedSharedActions';
import {makeReducer, composeReducers} from '../../redux-toolbelt';

import {setDirtyShoppingCart, setMissingDishesIds} from './shoppingCartActions';

export interface ShoppingCartState extends IShoppingCart {
  shoppingCartGuidTimestamp: number;
  isDirty: boolean;
  lastShoppingCartDishId: number;
  dishes: ShoppingCartDish[];
  missingDishesIds: Dish['dishId'][];
}

export default composeReducers<ShoppingCartState>(
  makeReducer<ShoppingCartState>(
    sharedActions.clearOrderData,
    state => ({
      shoppingCartGuid: state.shoppingCartGuid,
      shoppingCartGuidTimestamp: state.shoppingCartGuidTimestamp,
      isDirty: false,
      lastShoppingCartDishId: 0,
      dishes: [],
      billingLines: [],
      missingDishesIds: [],
    }),
    {
      defaultState: undefined as unknown as ShoppingCartState,
    },
  ),
  combineReducers<ShoppingCartState>({
    shoppingCartGuid: makeReducer(
      sharedActions.setShoppingCartGuid,
      (state, {payload}) => payload?.shoppingCartGuid || '',
      {defaultState: ''},
    ),
    shoppingCartGuidTimestamp: makeReducer(
      sharedActions.setShoppingCartGuid,
      (state, {payload}) =>
        (payload?.shoppingCartGuid ? payload?.shoppingCartGuidTimestamp || new Date().getTime() : 0),
      {defaultState: 0},
    ),
    billingLines: makeReducer(sharedActions.setBillingLines, {
      defaultState: [
        {type: BillingLineType.SubTotal, amount: 0},
        {type: BillingLineType.Tip, amount: 0},
        {type: BillingLineType.TotalToCharge, amount: 0},
      ] as IShoppingCart['billingLines'],
    }),
    missingDishesIds: makeReducer(setMissingDishesIds, {defaultState: [] as Dish['dishId'][]}),
    isDirty: makeReducer(setDirtyShoppingCart, {defaultState: false as boolean}),
    lastShoppingCartDishId: makeReducer(sharedActions.updateLastShoppingCartDishId, {defaultState: 0}),
    dishes: makeReducer(sharedActions.setDishes, {defaultState: [] as ShoppingCartDish[]}),
  }),
);
