import {MutableRefObject, useState} from 'react';

import {LayoutWithHeader} from '~/shared/components/LayoutWithHeader';
import apiService from '~/shared/services/apiService';
import {trackEvent} from '~/shared/services/analytics';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {BodyWrapper, LoginBody} from '~/shared/components/loginStyled';
import {splitOnFirstSpace} from '~/shared/utils/general';
import {FormNamesEnum} from '~/shared/services/analytics/analyticsModels';
import {User} from '~/shared/store/models';
import {isApiError} from '~/shared/services/apiErrorService';
import {createLogger} from '~/shared/logging';
import {FormValues} from '~/shared/components/SignIn/types';
import {BrandingPageTypes} from '~/shared/services/bffService/api/interfaces/payloads';
import {IFetchBrandingPageDataResponse} from '~/shared/services/bffService/api/interfaces/responses';

import {LoginProvider} from './consts';
import PhoneVerificationSection from './PhoneVerificationSection';
import SignUpForm from './SignUpForm';
import OAtuhSection from './OAtuhSection';

const logger = createLogger('SignUp');

type SignUpProps = {
  openFacebook?: () => void;
  onSubmitWithGoogleConnect?: () => void;
  backgroundColor?: string;
  onBackButtonClick?: () => void;
  pageView?: boolean;
  openLoginScreen?: (args: {email?: string; getAuthCodeForProviderConnect?: boolean}) => void;
  loginProvider?: LoginProvider;
  onSuccess?: (user?: User) => void;
  withTransparentBG?: boolean;
  disableExternalProvidersRegistration?: boolean;
  companyToken?: string;
  className?: string;
  emailValidationErrorKey?: string;
  openProviderScreen?: (options: {
    providerProps: Record<string, string | number | undefined>;
    nextLoginProvider: LoginProvider;
  }) => void;
  brandingPageData?: Record<
    BrandingPageTypes.REGISTRATION | BrandingPageTypes.OTP,
    IFetchBrandingPageDataResponse | undefined
  >;
  isRedirectedToStepRef?: MutableRefObject<boolean>;
};

const SignUp = ({
  openFacebook,
  backgroundColor: _backgroundColor,
  onBackButtonClick,
  pageView,
  openLoginScreen,
  loginProvider,
  onSuccess,
  withTransparentBG,
  disableExternalProvidersRegistration = false,
  companyToken,
  className,
  brandingPageData,
  emailValidationErrorKey,
  openProviderScreen,
  onSubmitWithGoogleConnect,
  isRedirectedToStepRef,
}: SignUpProps) => {

  const [activationModeProps, setActivationModeProps] = useState<{signUpCredentials: FormValues} | null>(null);
  const [sendActivationCodeError, setSendActivationCodeError] = useState('');
  const [registerFromValues, setRegisterFromValues] = useState<FormValues>({
    fullName: '',
    email: '',
    cellPhone: '',
    agreeToTerms: false,
    wantPromotion: true,
  });
  const [activationToken, setActivationToken] = useState<unknown>(null);
  const [activationCodeError, setActivationCodeError] = useState<string | null>(null);

  const onSendActivationCode = async (signUpCredentials: FormValues) => {
    try {
      const {data: activationCodeData} = await apiService.getActivationTokenAndSendActivationCodeToUser({
        cellPhone: signUpCredentials.cellPhone,
        email: signUpCredentials.email,
        token: companyToken,
      });
      if (activationCodeData) {
        setActivationModeProps({signUpCredentials});
        setActivationToken(activationCodeData);
        setSendActivationCodeError('');
      }
    } catch (error) {
      if (isApiError(error)) {
        trackEvent('formErrorMessageView', {
          formName: FormNamesEnum.REGISTRATION,
          errorType: error?.message,
        });
        if (error?.message) {
          setSendActivationCodeError(error?.message);
        } else {
          setSendActivationCodeError(
            'the_phone_number_you_entered_already_exists_please_login_or_signup_with_different_number',
          );
        }

        return;
      }

      logger.error('Unexpected Error');
    }
  };

  const onSubmitNewUser = (signUpCredentials: FormValues) => {
    trackEvent('hasSubmittedRegister');
    setRegisterFromValues(signUpCredentials);
    onSendActivationCode(signUpCredentials);
  };

  const clearActivationCodeErrorOnFocus = () => {
    setActivationCodeError('');
  };

  const onSubmitActivationCodeUser = (signUpCredentials: FormValues, onSubmitSuccess?: () => void) => {
    const [firstName, lastName] = splitOnFirstSpace(signUpCredentials.fullName);

    ManagerProvider.registerUser({firstName, lastName, ...signUpCredentials, token: companyToken}, !!companyToken)
      .then(userData => {
        if (userData) {
          setActivationCodeError('');
          if (onSuccess) {
            onSuccess(userData);
          }
        }
      })
      .catch(error => {
        trackEvent('formErrorMessageView', {
          formName: FormNamesEnum.OTP_REGISTRATION,
          errorType: error.message,
        });
        setActivationCodeError('activation_code_entry_is_wrong_please_try_again');
      })
      .finally(() => {
        onSubmitSuccess?.();
      });
  };

  const onCancelActivationProcess = () => {
    setActivationModeProps(null);
  };

  const backgroundColor = withTransparentBG ? 'transparent' : _backgroundColor;

  const phoneVerificationContent =
    activationModeProps && activationToken ? (
      <BodyWrapper id="signup_body_wrapper_activation" className={className} pageView={pageView} backgroundColor={backgroundColor}>
        <LoginBody className="login-body">
          <PhoneVerificationSection
            onSubmit={onSubmitActivationCodeUser}
            onCancel={onCancelActivationProcess}
            authenticationToken={activationToken}
            onSendActivationCode={onSendActivationCode}
            activationCodeError={activationCodeError}
            sendActivationCodeError={sendActivationCodeError}
            title={brandingPageData?.otp?.title}
            pageStyle={!!brandingPageData}
            withBorder={!!withTransparentBG}
            clearActivationCodeErrorOnFocus={clearActivationCodeErrorOnFocus}
            {...activationModeProps}
          />
        </LoginBody>
      </BodyWrapper>
    ) : null;

  return (
    <>
      {phoneVerificationContent ? (
        <>
          {!companyToken && (
            <LayoutWithHeader
              onClick={onBackButtonClick}
              titleKey="we_sent_you_activation_code_to_your_phone"
              showBackArrow={false}
            >
              {phoneVerificationContent}
            </LayoutWithHeader>
          )}
          {companyToken && phoneVerificationContent}
        </>
      ) : (
        <BodyWrapper
          id="register_tab_controls"
          aria-labelledby="register_tab_button"
          role="tabpanel"
          pageView={pageView}
          className={className}
          backgroundColor={backgroundColor}
        >
          <LoginBody className="login-body">
            <SignUpForm
              onSubmit={onSubmitNewUser}
              initialValues={registerFromValues}
              sendActivationCodeError={sendActivationCodeError}
              existingEmailAction={openLoginScreen ? email => openLoginScreen({email}) : undefined}
              borderedInputs={!!withTransparentBG}
              title={brandingPageData?.registration?.title}
              subtitle={brandingPageData?.registration?.subTitle}
              emailValidationErrorKey={emailValidationErrorKey}
            />
            {loginProvider === LoginProvider.TENBIS && !disableExternalProvidersRegistration && openProviderScreen && (
              <OAtuhSection
                onSubmitWithGoogleConnect={onSubmitWithGoogleConnect}
                openProviderScreen={openProviderScreen}
                openLoginScreen={openLoginScreen}
                openFacebook={openFacebook}
                isRedirectedToStepRef={isRedirectedToStepRef}
              />
            )}
          </LoginBody>
        </BodyWrapper>
      )}
    </>
  );
};

export default SignUp;
