import {memo} from 'react';

import styled from 'styled-components';

import {media} from '~/shared/theme/utils';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {body16Bold} from '~/shared/theme/typography';
import {getLocalizationService} from '~/shared/services/localisationService';

import FooterLink from './FooterLink';

const Root = styled.div`
  ${flexColumn};
  flex: 1;
`;

const FooterSectionTitle = styled.h3`
  ${body16Bold};
  line-height: 21px;
  color: ${({theme}) => theme.footer.color.linkColor};
  white-space: nowrap;
  margin-bottom: 8px;
`;

const FooterLinksWrapper = styled.div`
  ${flexColumn};
  flex-wrap: wrap;
  margin-bottom: 10px;
  ${({isRowDirection}) =>
    isRowDirection &&
    `
    width: 40%;
    flex-direction: row;
  `}
  ${media.minLargeTablet`
    width: inherit;
    ${flexColumn};
    margin-bottom: 0;
    height: 85px;
  `}
`;

const FooterSection = ({title, links}) => {
  const {t} = getLocalizationService();
  const isCityLinksSection = title === 'city_pages';
  return (
    <Root>
      <FooterSectionTitle>{t(title)}</FooterSectionTitle>
      <FooterLinksWrapper isRowDirection={isCityLinksSection}>
        {links.map(link => {
          return <FooterLink key={`${link.text}_${link.href}_${link.cityId}`} {...link} />;
        })}
      </FooterLinksWrapper>
    </Root>
  );
};

export default memo(FooterSection);
