import styled from 'styled-components';

import {body18Bold} from '~/shared/theme/typography';
import {media} from '~/shared/theme/utils';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';

import {Item as Wrapper, ItemWithNoBottom} from '../shared';

const titleWidth = 124;

const Title = styled.div`
  ${body18Bold};
  display: ${({theme}) => theme.checkout.elements.title.display};
  padding: ${({theme}) => theme.checkout.elements.title.padding};
  line-height: ${({theme}) => theme.checkout.elements.title.lineHeight};
  margin-bottom: ${({theme}) => theme.checkout.elements.title.marginBottom};
  ${media.minLargeTablet`
    width: ${titleWidth}px;
    margin-bottom: 0;
    ${flipOnLTR`
      margin-left: 16px;
    `}  
  `}
  ${media.maxLargeTablet`
    width: 100%;
  `}
`;

const Body = styled.div`
  flex: 1;
  padding: ${({theme}) => theme.checkout.elements.itemBody.padding};
`;

const CheckoutItem = ({title, children, withBottom = true, className}) => (
  <Wrapper as={withBottom ? undefined : ItemWithNoBottom}>
    <Title as="h2">{title}</Title>

    <Body className={className}>{children}</Body>
  </Wrapper>
);

export default CheckoutItem;
