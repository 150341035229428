import {makeActionCreator, makeThunkAsyncActionCreator} from '../../redux-toolbelt';

import {OrdersHistoryState} from './ordersHistoryReducer';

export const setOrderHistoryAddressId = makeActionCreator('setOrderHistoryAddressId');

let fetchingOrderHistoryForAddressId: string;

export const fetchOrdersHistoryIfNeeded = makeThunkAsyncActionCreator<string, OrdersHistoryState['data']>(
  'fetchOrdersHistoryIfNeeded',
  async (addressId, {apiService}) => {
    if (fetchingOrderHistoryForAddressId !== addressId) {
      fetchingOrderHistoryForAddressId = addressId;
    }
    try {
      const result = await apiService.getOrdersHistory({addressId});
      return result.data;
    } catch (error: unknown | any) {
      throw error?.message;
    }
  },
);
