import {useEffect, useMemo, useRef, useState} from 'react';

import styled from 'styled-components';
import {every, reduce} from 'lodash';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

import {attachModal} from '~/shared/components/ReduxModal';
import {fetchDiscountInfoById} from '~/shared/services/bffService/api';
import {getLocalizationService} from '~/shared/services/localisationService';
import {OrderData} from '~/shared/store/models';
import {body14Normal, body20Bold, body14Bold, body16Normal} from '~/shared/theme/typography';
import {IDiscountResponse} from '~/shared/services/bffService/api/interfaces/responses';
import {media} from '~/shared/theme/media';
import {createLogger} from '~/shared/logging';
import {handleRefreshToken} from '~/shared/services/auth';
import {SkeletonLoader, Skeleton} from '~/shared/components/Loaders';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import useWebpWithFallback from '~/shared/hooks/useWebpWithFallback';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {is401Error} from '~/shared/services/apiErrorService';

const logger = createLogger('Discounts');

const Root = styled.div`
  && {
    ${flexColumn}
    width: 100%;
    background-color: ${({theme}) => theme.colors.surface};
    ${media.minLargeMobile`
      width: 430px;
      border-radius: 4px;
    `}
  }
`;

const DiscountDetailsWrapper = styled.div`
  padding: 24px 24px 32px 24px;
  color: ${({theme}) => theme.colors.secondary};
`;

const Title = styled.div`
  ${body20Bold}
  line-height: 25px;
  margin-bottom: 8px;
`;

const CouponDetails = styled.div`
  ${body16Normal}
  line-height: 24px;
`;

const Section = styled.div`
  margin-bottom: 16px;
`;

const SectionTitle = styled.div`
  ${body14Bold}
  line-height: 20px;
`;
const SectionDescription = styled.div`
  ${body14Normal}
  line-height: 20px;
`;

const DisclaimerText = styled.div`
  ${body14Normal}
  line-height: 20px;
  color: ${({theme}) => theme.colors.gray200};
`;

const Img = styled(ImageWithAlt)`
  object-fit: cover;
  height: 200px;
`;

const FULL_DAY_START_TIME = '00:00';
const FULL_DAY_END_TIME = '23:59';

const ALWAYS_APPLICABLE_DAYS_NUMBER = 7;
const WEEK_DAYS_ONLY_DAYS_NUMBER = 5;

interface IDiscountModal {
  closeModal: () => void;
  args: {
    discountId: OrderData['discountCoupon']['id'];
  };
}

const DiscountLoader = () => {
  const {t} = getLocalizationService();

  return (
    <DiscountDetailsWrapper>
      <Title id="modal-title">{t('offer_details')}</Title>
      <Section>
        <Skeleton height={24} />
      </Section>

      <Section>
        <SectionTitle>{t('activity_time')}</SectionTitle>
        <Skeleton height={20} />
      </Section>
      <Section>
        <SectionTitle>{t('expiration_date')}</SectionTitle>
        <Skeleton height={20} />
      </Section>
      <Section>
        <Skeleton height={20} />
        <Skeleton height={20} />
        <Skeleton height={20} />
      </Section>
    </DiscountDetailsWrapper>
  );
};

const DiscountInfoModal = ({args}: IDiscountModal) => {
  const {t, currentLanguageKey} = getLocalizationService();
  const {discountId} = args;
  const isRtl = currentLanguageKey === 'he';
  const discountInfoRequested = useRef(false);

  const [discountInfo, setdiscountInfo] = useState<IDiscountResponse | null>(null);
  const discountImg = useWebpWithFallback('discounts/discount-modal.jpg') as string;

  const discountOperatingHours = useMemo(() => {
    if (!discountInfo?.activityTimes) {
      return null;
    }
    const discountFullDays = reduce(
      discountInfo?.activityTimes,
      (count, day) => {
        const isFullDay = every(
          day.ranges,
          range => range.startTime === FULL_DAY_START_TIME && range.endTime === FULL_DAY_END_TIME,
        );
        return isFullDay ? count + 1 : count;
      },
      0,
    );

    const isWeekdayOnly =
      discountFullDays === WEEK_DAYS_ONLY_DAYS_NUMBER &&
      discountFullDays === discountInfo.activityTimes.length &&
      discountInfo.activityTimes.map(day => day.dayOfWeek).every((day, index) => day === index + 1); // [1, 2, 3, 4, 5] match sunday - thursday

    if (isWeekdayOnly) {
      return t('valid_on_weekdays_only');
    }

    if (discountFullDays === ALWAYS_APPLICABLE_DAYS_NUMBER) {
      return t('always_applicable');
    }
    return discountInfo?.activityTimes?.map(({dayOfWeek, ranges}) => {
      const dayOfWeekLabel = moment()
        .locale(currentLanguageKey)
        .day(dayOfWeek - 1)
        .format('dddd');

      const rangesText = ranges.sort((a, b) => (isRtl ? b.startTime.localeCompare(a.startTime) : a.startTime.localeCompare(b.startTime))).map(range => (isRtl ? `${range.endTime} - ${range.startTime}` : `${range.startTime} - ${range.endTime}`)).join(', ');

      return <SectionDescription>{`${dayOfWeekLabel}: ${rangesText}`}</SectionDescription>;
    });
  }, [currentLanguageKey, discountInfo, t, isRtl]);

  useEffect(() => {
    if (discountInfoRequested.current && !discountId) {
      return;
    }

    const fetchDiscountData = async () => {
      try {
        const discountInfoResponse = await fetchDiscountInfoById({discountId});
        setdiscountInfo(discountInfoResponse);
      } catch (error) {
        if (is401Error(error)) {
          await handleRefreshToken(error, fetchDiscountData);
          return;
        }
        logger.error('failed to fetch discount by id', error);
      }
    };
    fetchDiscountData();

    discountInfoRequested.current = true;
  }, [discountId]);

  return (
    <Root>
      <Img src={discountImg} noAlt />
      <SkeletonLoader shouldShowLoader={!discountInfo} LoaderComponent={DiscountLoader}>
        <DiscountDetailsWrapper>
          <Title id="modal-title">{t('offer_details')}</Title>
          <Section>
            <CouponDetails>{discountInfo?.details}</CouponDetails>
          </Section>
          <Section>
            <SectionTitle>{t('activity_time')}</SectionTitle>
            <SectionDescription>{discountOperatingHours}</SectionDescription>
          </Section>
          <Section>
            <SectionTitle>{t('expiration_date')}</SectionTitle>
            <SectionDescription>{discountInfo?.expirationDate}</SectionDescription>
          </Section>
          <DisclaimerText>{t('discount_disclaimer_1')}</DisclaimerText>
          <DisclaimerText>{t('discount_disclaimer_2')}</DisclaimerText>
          <DisclaimerText>{t('discount_disclaimer_3')}</DisclaimerText>
        </DiscountDetailsWrapper>
      </SkeletonLoader>
    </Root>
  );
};

DiscountInfoModal.modalType = 'autoWidth';

attachModal('discountInfoModal', DiscountInfoModal);
