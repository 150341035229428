import {useMemo} from 'react';

import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';

import {
  selectAllCoupons,
  selectAllCouponsLoading,
  selectCurrentCoupon,
  selectIsRestaurantCouponAvailable,
  selectIsCouponAutoChanged,
} from '~/shared/store/selectors';
import {setIsCouponAutoChanged} from '~/shared/store/actions';
import {SkeletonLoader, Skeleton} from '~/shared/components/Loaders';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {useIsMounted} from '~/shared/hooks';

import CouponModes from './CouponsModes';

const CouponSkeleton = styled(Skeleton)`
  margin: 0 24px;
  width: 120px;
  height: 24px;
`;

const CheckoutCoupons = ({isCheckoutModal, isInitialized}) => {
  const dispatch = useDispatch();
  const allCoupons = useSelector(selectAllCoupons);
  const currentCoupon = useSelector(selectCurrentCoupon);
  const isRestaurantCouponAvailable = useSelector(selectIsRestaurantCouponAvailable);
  const allCouponsLoading = useSelector(selectAllCouponsLoading);
  const isCouponAutoChanged = useSelector(selectIsCouponAutoChanged);
  const isMounted = useIsMounted();

  const shouldShowLoader = useMemo(() => !isMounted() || allCouponsLoading || !isInitialized, [allCouponsLoading, isMounted, isInitialized]);

  const onSubmitCouponCode = value => {
    return ManagerProvider.changeCoupon({couponCode: value});
  };

  const resetCouponAutoChanged = () => {
    dispatch(setIsCouponAutoChanged(false));
  };

  const onCouponSelect = couponCode => {
    ManagerProvider.changeCoupon({couponCode});
    resetCouponAutoChanged();
  };

  return (
    <SkeletonLoader shouldShowLoader={shouldShowLoader} LoaderComponent={CouponSkeleton}>
      <CouponModes
        allCoupons={allCoupons}
        currentCoupon={currentCoupon}
        isRestaurantCouponAvailable={isRestaurantCouponAvailable}
        onCouponSelect={onCouponSelect}
        onSubmitCouponCode={onSubmitCouponCode}
        isCouponAutoChanged={isCouponAutoChanged}
        isCheckoutModal={isCheckoutModal}
        resetCouponAutoChanged={resetCouponAutoChanged}
      />
    </SkeletonLoader>
  );
};

export default CheckoutCoupons;
