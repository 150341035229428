import {reduce, isEqual, get} from 'lodash';
import moment from 'moment-timezone';

import {setIn} from '~/shared/utils/general';
import {makeActionCreator, makeReducer} from '~/shared/store/redux-toolbelt';
import {createLogger} from '~/shared/logging';

import {AppState, AppStore} from '../configStore';

import persistStoreConfig, {PersistentStoreSavedScopes} from './persistStoreConfig';

const logger = createLogger('persistStore');

const {localStorageKey, scopes} = persistStoreConfig;

export const mergeStorePersistDataAction = makeActionCreator('mergeStorePersistData');

export const persistStoreReducer = makeReducer<AppState, PersistentStoreSavedScopes>(
  mergeStorePersistDataAction,
  (state, {payload: persistentStoreSavedScopes}) =>
    (persistentStoreSavedScopes
      ? reduce(
        scopes,
        (currentState, {path}, scopeKey) => {
          const {data} = persistentStoreSavedScopes[scopeKey];
          const scopeData = get(currentState, path);

          return isEqual(scopeData, data) ? currentState : setIn(currentState, path, data);
        },
        state,
      )
      : state),
);

export const mergeStorePersistData = (store: AppStore) => {
  const persistentStoreSavedScopes: PersistentStoreSavedScopes = {};

  try {
    Object.assign(persistentStoreSavedScopes, JSON.parse(window.localStorage.getItem(localStorageKey) as string));
  } catch (e) {
    logger.warn('mergeStorePersistData error', {e});
    return;
  }

  const state = store.getState();
  const currentTime = moment();

  Object.keys(scopes).forEach((scopeKey: keyof typeof scopes) => {
    const {path, validFor, mergeWithInitialState} = scopes[scopeKey];
    const savedScope = persistentStoreSavedScopes[scopeKey];
    const initialData = get(state, path);

    if (!savedScope) {
      persistentStoreSavedScopes[scopeKey] = {
        timeStamp: currentTime.utc().format(),
        data: initialData,
      };
      return;
    }

    const {timeStamp, data: savedData} = savedScope;
    const isExpired = timeStamp && validFor && moment(timeStamp).add(validFor).isBefore(currentTime);

    if (isExpired) {
      persistentStoreSavedScopes[scopeKey] = {
        timeStamp: currentTime.utc().format(),
        data: initialData,
      };
      return;
    }

    if (mergeWithInitialState) {
      persistentStoreSavedScopes[scopeKey] = {
        timeStamp: currentTime.utc().format(),
        data: mergeWithInitialState({
          scopeInitialState: initialData,
          savedData,
          fullState: state,
          persistentStoreSavedScopes,
        }),
      };
    }
  });

  localStorage.setItem(localStorageKey, JSON.stringify(persistentStoreSavedScopes));
  store.dispatch(mergeStorePersistDataAction(persistentStoreSavedScopes));
};
