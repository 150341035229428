import {handleRefresh} from '~/shared/services/auth/auth';

class RefreshQueue {
  queue: (() => Promise<void>)[];

  refreshHandlingInProcess: boolean;

  constructor() {
    this.queue = [];
    this.refreshHandlingInProcess = false;
  }

  processQueue = async () => {
    const action = this.queue.shift()!;

    await action();

    if (!this.queue.length) {
      this.refreshHandlingInProcess = false;
    }

    if (this.queue.length) {
      this.processQueue();
    }
  };

  dequeue = async () => {
    if (!this.refreshHandlingInProcess && this.queue.length) {
      this.refreshHandlingInProcess = true;

      this.processQueue();
    }
  };

  enqueue = async (err: unknown, action?: (args: any) => any, args?: any) => new Promise(resolve => {
    this.queue.push(async () => {
      const refreshRes = await handleRefresh(err, action, args);
      resolve(refreshRes);
    });

    this.dequeue();
  });
}

const refreshQueueSingleton = new RefreshQueue();

export default refreshQueueSingleton;
