import {useState, useRef, useEffect, useCallback, memo, ReactNode, InputHTMLAttributes} from 'react';

import styled from 'styled-components';

import {createLogger} from '~/shared/logging';
import {media, flipOnLTR} from '~/shared/theme/utils';

import {Theme} from '../theme';
import {body14Normal} from '../theme/typography';

const logger = createLogger('RadioButton');

const radioSize = ({theme}: {theme: Theme}) => theme?.filters.section.body.radio.size;
const bulletSize = ({theme}: {theme: Theme}) => theme?.filters.section.body.radio.bulletSize;
const labelAttrs = ({isFocused, checked}: {isFocused: boolean; checked: boolean}) => ({
  className: `white-bg-on-contrast ${isFocused ? 'radio-label-focused' : ''} ${checked ? 'checked' : ''}`,
});
const Label = styled.label.attrs(labelAttrs)<{isFocused: boolean; checked: boolean}>`
  position: relative;
  width: 100%;
  ${flipOnLTR`
    text-align: right;
  `}
  display: inline-flex;
  cursor: pointer;
  padding: 10px 0;

  &:last-child {
    border-bottom: 0;
  }

  ${media.minLargeTablet`
    border-bottom: 0;
    padding: 0;
    width: auto;
    display: flex;
    position: relative;
    user-select: none;
    line-height: ${radioSize};
    &:last-child {
      margin-bottom: 0;
    }
  `}

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: ${radioSize};
    height: ${radioSize};
    top: calc(50% - (${radioSize} / 2));
    ${flipOnLTR`right: -20px;`}
    border-radius: 50%;
    border: 1px solid ${({theme}) => theme.colors.gray500};
  }

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    ${flipOnLTR`right: -16px;`}
    top: calc(calc(50% - (${radioSize} / 2)) + (${radioSize} - ${bulletSize}) / 2 + 1px);
    border-radius: 50%;
    width: ${bulletSize};
    height: ${bulletSize};
    ${({checked, theme}) => checked && `background: ${theme.filters.section.body.radio.bulletColor};`}
  }
`;

const Input = styled.input.attrs({
  type: 'radio',
})<{hideInput?: boolean}>`
  opacity: 0;
  position: ${({hideInput}) => hideInput && 'fixed'};
`;

const RadioButtonLabel = styled.span<{labelHorizontalMargin?: number}>`
  ${flipOnLTR`
    right: ${({labelHorizontalMargin}) => labelHorizontalMargin || 0}px;
  `};
  ${media.minLargeTablet`
    ${body14Normal};
    line-height: 14px;
    border-bottom: none;
    margin-top: 0;
  `}
`;

const RadioWrapper = styled.div<{display?: string}>`
  display: ${({display}) => display || 'flex'};
  flex-direction: row-reverse;
  justify-content: flex-end;
  height: 100%;
`;

export const HiddenStyledRadio = styled.input.attrs({
  type: 'radio',
})<{isKeyboardInUse: boolean}>`
  width: 0;
  height: 0;
  position: absolute;
  transition: all 0.8s ease;
  opacity: 0;

  &:focus ~ label {
    outline: ${({isKeyboardInUse, theme}) => `${isKeyboardInUse ? 1 : 0}px solid ${theme.colors.gray000}`};
  }

  &:checked ~ label {
    font-weight: bold;
  }
`;

export interface RadioButtonProps {
  name?: string;
  id: string;
  isChecked: boolean;
  children: ReactNode;
  value: any;
  onValueChange?: (value: any) => void;
  onChange?: () => void;
  display?: string;
  hideInput?: boolean;
  className?: string;
  labelComponent?: ReactNode;
  tabIndex?: number;
  inputProps?: InputHTMLAttributes<HTMLInputElement> & {type: 'radio'};
  labelHorizontalMargin?: number;
}

const RadioButton = ({
  name = 'radio-group',
  id,
  isChecked,
  children,
  value,
  onValueChange,
  onChange,
  display,
  hideInput,
  className,
  labelComponent,
  tabIndex,
  inputProps,
  labelHorizontalMargin,
  ...props
}: RadioButtonProps) => {
  const inputId = id || name;

  const [checkedValue, setCheckedValue] = useState(isChecked);
  const [isFocused, setIsFocused] = useState(false);
  const labelRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    setCheckedValue(isChecked);
  }, [isChecked]);

  if (!inputId) {
    logger.warn('no id/name was provided to the RadioButton', {className});
  }

  const handleChange = useCallback(() => {
    onValueChange?.(value);
    onChange?.();
  }, [onValueChange, value, onChange]);

  const defaultLabelComponent = (
    <Label
      isFocused={isFocused}
      checked={checkedValue}
      ref={labelRef}
      className={className}
      htmlFor={inputId}
      {...props}
    >
      <RadioButtonLabel labelHorizontalMargin={labelHorizontalMargin}>{children}</RadioButtonLabel>
    </Label>
  );
  return (
    <RadioWrapper display={display}>
      {labelComponent || defaultLabelComponent}
      <Input
        ref={inputRef}
        {...{name, id: inputId, ...inputProps}}
        checked={checkedValue}
        onChange={handleChange}
        onFocus={() => {
          setIsFocused(true);
        }}
        hideInput={hideInput}
        tabIndex={tabIndex}
      />
    </RadioWrapper>
  );
};

export default memo(RadioButton);
