import {memo} from 'react';

import {Helmet} from 'react-helmet';

import {getLocalizationService} from '~/shared/services/localisationService';
import MetaHeaders from '~/shared/components/MetaHeaders';

export const PersistentMetaHeaders = memo(() => {
  return (
    <Helmet>
      <meta charSet="utf-8"/>

      <meta property="og:type" content="website"/>

      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta name="mobile-web-app-capable" content="yes"/>
      <meta name="apple-mobile-web-app-capable" content="yes"/>
    </Helmet>
  );
});

export const DefaultHeaders = memo(() => {
  const {t} = getLocalizationService();

  return (
    <MetaHeaders
      title={t('10bis_tenbis_orders_restaurants_delivery_of_food_from_restaurants_pickup_10bis_card')}
      description={t('10bis_is_the_ideal_website_for_you_to_order_meals_from_restaurants_you_can_find_information_about_delivery_from_a_wide_variety_of_restaurants_all_over_the_country_delivery_menus_special_offers_and_much_more')}
      keywords={t('kosher_restaurants_kosher_site_kasher_delivery_food_delivery_meal_delivery_10bis_tenbis_restaurant_delivery_delivery_in_tel_aviv_delivery_people_from_restaurants_night_time_eating')}
    />
  );
});
