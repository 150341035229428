import axios, {AxiosInstance} from 'axios';

import {determineVariant} from './determineVariant';
import {getDefaultValue} from './getDefaultValue';
import {trackExperiment} from './trackExperiment';
import {logger} from './logger';
import {ExperimentObserver} from './experimentObserver';
import {FeatureOverride} from './featureOverride';
import {Config, Value, ValueOrNull, VersionedConfig} from './config';
import {FmSettings, GetContextFn, OnTrackFn} from './settings';
import {shouldTrack} from './utils/shouldTrack';
import {isStringType, isBoolType, isIntType} from './utils/getType';
import {FeatureLookup} from './featureLookup';

const logValueRequest = (func: {name: string}, key: string, value: Value) => {
  logger.logInfo(`${func.name}: ${key} => ${value}`);
};

export class FeatureManager {
  public readonly getContext: GetContextFn;

  public readonly settings: FmSettings;

  private readonly override: FeatureOverride | null;

  public readonly observer: ExperimentObserver;

  private readonly httpClient: AxiosInstance;

  private readonly onTrack?: OnTrackFn;

  public featureLookup: FeatureLookup;

  constructor(settings: FmSettings, httpClient?: AxiosInstance) {
    this.settings = settings;
    if (httpClient) {
      this.httpClient = httpClient;
    } else {
      let instanceHeaders = {};
      if (settings.cdn?.poll) {
        instanceHeaders = {
          headers: {
            'cache-control': 'no-cache',
          },
        };
      }

      this.httpClient = axios.create(instanceHeaders);
    }

    this.getContext = settings.getContext;
    this.onTrack = settings.onTrack;
    this.observer = new ExperimentObserver(this);
    this.override = settings.urlString ? new FeatureOverride(settings.urlString) : null;
    this.featureLookup = new FeatureLookup();
    if (this.settings.initialConfigAsJson) {
      this.featureLookup.init(this.settings.initialConfigAsJson);
    }
  }

  getQualifiedKey(key: string) {
    if (key.includes('::')) return key;

    const {keyPrefix} = this.settings;

    return keyPrefix ? `${keyPrefix}::${key}` : key;
  }

  getValue(key: string, tags: string[] = [], track = true): ValueOrNull {
    try {
      const feature = this.featureLookup.getFeature(this.getQualifiedKey(key));

      // If user specifies one or more tags, this indicates that the feature value
      // should only be returned if the feature has at least one of the specified tags.
      // Otherwise, a null value should be returned.
      const featureDoesNotHaveAnyRequiredTags =
        tags.length > 0 && feature.tags && !feature.tags.some((tag: string) => tags.includes(tag));

      if (featureDoesNotHaveAnyRequiredTags) {
        return null;
      }

      const overrideValueType = (feature && feature.valueType) || null;

      const overrideValue = this.override
        ? this.override.getOverride(key, overrideValueType)
        : null;

      if (overrideValue !== null) return overrideValue; // return override earlier than no feature

      if (!feature) return null;

      const context = this.getContext();

      if (!context) {
        logger.logError(
          'Unable to provide feature values as no context was provided. Check your settings for the plugin',
        );
        return null;
      }

      const variant = determineVariant(feature, context);

      if (variant) {
        if (shouldTrack(track, variant, context)) {
          trackExperiment(variant, this.onTrack);
        }

        return variant.value;
      }

      return getDefaultValue(feature, context);
    } catch (error: unknown) {
      const message = error instanceof Error ? error.message : 'Unknown error';
      logger.logError(message, {error});
    }

    return null;
  }

  getCompositeStringValue(key: string, compositeKey: string, tags: string[] = []): ValueOrNull {
    const value: ValueOrNull = this.getValue(key, tags);
    if (value === null || !compositeKey) return null;
    const compositeStringValue: Value = value[compositeKey as keyof typeof value];
    if (isStringType(compositeStringValue)) {
      logValueRequest(this.getStringValue, key, compositeStringValue);
      return compositeStringValue;
    }
    return null;
  }

  getCompositeBoolValue(key: string, compositeKey: string, tags: string[] = []): ValueOrNull {
    const value: ValueOrNull = this.getValue(key, tags);
    if (value === null || !compositeKey) return null;
    const compositeBoolValue: Value = value[compositeKey as keyof typeof value];
    if (isBoolType(compositeBoolValue)) {
      logValueRequest(this.getBooleanValue, key, compositeBoolValue);
      return Boolean(compositeBoolValue);
    }
    return null;
  }

  getCompositeIntValue(key: string, compositeKey: string, tags: string[] = []): ValueOrNull {
    const value: ValueOrNull = this.getValue(key, tags);
    if (value === null || !compositeKey) return null;
    const compositeIntValue: Value = value[compositeKey as keyof typeof value];
    if (isIntType(compositeIntValue)) {
      logValueRequest(this.getIntegerValue, key, compositeIntValue);
      return Number(compositeIntValue);
    }
    return null;
  }

  getBooleanValue(key: string, tags: string[] = []): boolean | null {
    const value = this.getValue(key, tags);

    if (value === null) return null;

    if (isBoolType(value)) {
      logValueRequest(this.getBooleanValue, key, value);
      return Boolean(value);
    }
    return null;
  }

  getIntegerValue(key: string, tags: string[] = []): number | null {
    const value = this.getValue(key, tags);

    if (value === undefined || value === null) return null;

    if (isIntType(value)) {
      logValueRequest(this.getIntegerValue, key, value);
      return Number(value);
    }
    return null;
  }

  getStringValue(key: string, tags: string[] = []): string | null {
    const value = this.getValue(key, tags);

    if (value === null) return null;

    if (isStringType(value)) {
      logValueRequest(this.getStringValue, key, value);
      return `${value}`;
    }
    return null;
  }

  async loadFromCdn(): Promise<Config> {
    return this.loadConfigFromCdn();
  }

  async loadFromCdnWithVersionId(): Promise<VersionedConfig> {
    const config: Config = await this.loadConfigFromCdn();
    const versionId = this.featureLookup.getVersionId();
    return {config, versionId};
  }

  private async loadConfigFromCdn(): Promise<Config> {
    if (!this.settings.cdn) throw new Error('CDN settings were not provided.');

    return this.featureLookup.loadFromCdn(
      this.settings.cdn,
      this.httpClient,
      this.settings.onUpdated,
    );
  }

  updateConfig(configAsJson: string | Config) {
    this.featureLookup.init(configAsJson);
  }
}
