import {memo} from 'react';

import {noop} from 'lodash';
import GoogleMap from 'google-map-react';

import useGoogleMapScript from '~/shared/hooks/useGoogleMapScript';
import restaurantMarkerIconUrl from '~/assets/images/icons/RestaurantMarker.svg';
import homeMarkerIconUrl from '~/assets/images/icons/HomeMarker.svg';
import mapMarkerIconUrl from '~/assets/images/icons/MapMarker.svg';
import ImageWithAlt from '~/shared/components/ImageWithAlt';

import grayScaleMapStyle from './grayScaleMapStyle';

const Marker = ({icon, onClick, width, height}) => (
  <ImageWithAlt src={icon} onClick={onClick} width={width} height={height} noAlt />
);

const Map = ({
  restaurantLatitude,
  restaurantLongitude,
  onRestaurantMarkerClick = noop,
  onUserMarkerClick = noop,
  userLatitude = 0,
  userLongitude = 0,
  showAllMarkers,
  isGrayScaleMapStyle,
  isBigIcon,
  onGoogleApiLoaded,
}) => {
  const isGoogleMapReady = useGoogleMapScript();

  const restNumberedLatLng = {lat: Number(restaurantLatitude), lng: Number(restaurantLongitude)};
  const userNumberedLatLng = {lat: Number(userLatitude), lng: Number(userLongitude)};
  const defaultCenter =
    restNumberedLatLng.lat === 0 || restNumberedLatLng.lng === 0 ? userNumberedLatLng : restNumberedLatLng;

  const isMapMissingRequiredProps =
    !isGoogleMapReady || ((!restaurantLatitude || !restaurantLongitude) && (!userLatitude || !userLongitude));
  if (isMapMissingRequiredProps) {
    return null;
  }

  return (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={defaultCenter}
      options={{
        styles: isGrayScaleMapStyle && grayScaleMapStyle,
      }}
      onGoogleApiLoaded={onGoogleApiLoaded}
    >
      <Marker
        {...restNumberedLatLng}
        onClick={onRestaurantMarkerClick}
        icon={showAllMarkers ? restaurantMarkerIconUrl : mapMarkerIconUrl}
        width={isBigIcon ? 40 : 25}
        height={isBigIcon ? 60 : 32}
      />
      {Boolean(userLatitude) && Boolean(userLongitude) && (
        <Marker
          {...userNumberedLatLng}
          onClick={onUserMarkerClick}
          icon={showAllMarkers ? homeMarkerIconUrl : mapMarkerIconUrl}
          width={isBigIcon ? 40 : 25}
          height={isBigIcon ? 60 : 32}
        />
      )}
    </GoogleMap>
  );
};

export default memo(Map);
