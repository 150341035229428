import {useIsSafari} from './deviceInfo';

const useWebpWithFallback = (fallbackFileNameWithExtension?: string) => {
  const isSafari = useIsSafari();
  if (!fallbackFileNameWithExtension) {
    return;
  }

  const shouldFallback = isSafari;
  const [fileNameWithoutExtension, extension] = fallbackFileNameWithExtension.split('.');
  // eslint-disable-next-line import/no-dynamic-require
  return require(`~/assets/images/${fileNameWithoutExtension}.${shouldFallback ? extension : 'webp'}`) as string;
};

export default useWebpWithFallback;
