import {DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {routeNames} from '~/shared/routes';
import {trackEvent} from '~/shared/services/analytics';
import store from '~/shared/store';
import {GetAvailablePaymentsReturnType, getAvailablePayments} from '~/shared/store/actions';
import {CheckoutPayment, Payment, ShoppingCartDish} from '~/shared/store/models';
import {
  selectShoppingCartDishes,
  selectCurrentRestaurantDishesByDishId,
  selectCheckoutPayments,
  selectShoppingCartBillingLines,
  selectCurrentRestaurant,
  selectCurrentDeliveryMethod,
} from '~/shared/store/selectors';

const ManagerProviderHelper = {
  ensureShoppingCartDishesInMenu: () => {
    const state = store.getState();
    const dishesInShoppingCart = selectShoppingCartDishes(state);
    const currentRestaurantDishesByDishId = selectCurrentRestaurantDishesByDishId(state);

    dishesInShoppingCart.forEach(dish => {
      if (!dish) {
        throw new Error('invalid dish in dishesInShoppingCart');
      }

      const dishFromMenu = currentRestaurantDishesByDishId?.[dish.dishId];
      if (!dishFromMenu) {
        throw new Error('dish in dishesInShoppingCart doesn\'t appear in currentRestaurantDishes');
      }

      const {choices} = dish;
      const {choices: menuChoices} = dishFromMenu;
      choices?.forEach(choice => {
        const menuChoice = menuChoices.find(c => c.choiceId === choice.choiceId);
        if (!menuChoice) {
          throw new Error('choice in dishesInShoppingCart doesn\'t appear in currentRestaurantDishes');
        }

        choice.subsChosen?.forEach(sub => {
          const menuSub = menuChoice.subs.find(s => s.subId === sub.subId);
          if (!menuSub) {
            throw new Error('sub in dishesInShoppingCart doesn\'t appear in currentRestaurantDishes');
          }
        });
      });
    });
  },

  getMissingCheckoutPaymentUponDishInsert: async (newDish: ShoppingCartDish): Promise<CheckoutPayment | undefined> => {
    const state = store.getState();
    const dishHasUserAssignedCheckoutPayments = selectCheckoutPayments(state).find(
      cp => cp.userId === newDish.assignedUserId && cp.assigned && !cp.isDisabled,
    );
    if (dishHasUserAssignedCheckoutPayments) return;

    const {availablePayments}: GetAvailablePaymentsReturnType = await store.dispatchThunk(getAvailablePayments());
    const userPayment = availablePayments?.find(p => p.userId === newDish.assignedUserId && p.assigned);
    return userPayment && {...userPayment, isDisabled: false, editMode: false};
  },

  getExcessPaymentUponDishRemove: async (
    oldDishes: ShoppingCartDish[],
    deletedShoppingCartDishId: ShoppingCartDish['dishId'],
  ): Promise<Payment | undefined> => {
    const state = store.getState();
    const deletedDish = oldDishes.find(d => d.shoppingCartDishId === deletedShoppingCartDishId);
    const dishAssignedToCheckoutPayment = selectCheckoutPayments(state).find(
      cp => cp.userId === deletedDish?.assignedUserId,
    );
    if (!(deletedDish && dishAssignedToCheckoutPayment)) return;

    const {availablePayments}: GetAvailablePaymentsReturnType = await store.dispatchThunk(getAvailablePayments());
    return availablePayments?.find(p => p.userId === deletedDish.assignedUserId && !p.assigned);
  },
};

let isAnalyticsReachedMinimumEventFired = false;
export const analyticsReachedRestaurantMinimum = () => {
  const state = store.getState();
  const currentRestaurant = selectCurrentRestaurant(state);
  const deliveryMethod = selectCurrentDeliveryMethod(state);

  if (!currentRestaurant) {
    return;
  }

  const billingLines = selectShoppingCartBillingLines(state);
  const subTotalAmount = billingLines?.find(({type}) => type === 'SubTotal')?.amount;
  const minimumPriceForOrder = deliveryMethod === DeliveryMethods.DELIVERY ? currentRestaurant.deliveryRules?.[0]?.minimumOrder : currentRestaurant.pickupRule?.minimumOrder;
  const isReachedRestaurantMinimum = subTotalAmount && subTotalAmount >= (minimumPriceForOrder || 0);

  if (!isReachedRestaurantMinimum) {
    isAnalyticsReachedMinimumEventFired = false;
    return;
  }

  if (!isAnalyticsReachedMinimumEventFired) {
    trackEvent('hasReachedMOV');
    isAnalyticsReachedMinimumEventFired = true;
  }
};

export const CHANGE_RESTAURANT_ON_INIT_ROUTES = [routeNames.confirmAge, routeNames.shoppingCart, routeNames.checkout];
export const CHANGE_RESTAURANT_IN_MENU_PAGE = [...CHANGE_RESTAURANT_ON_INIT_ROUTES, routeNames.menu];

export default ManagerProviderHelper;
