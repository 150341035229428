import {memo} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import RadioButton from '~/shared/components/RadioButton';
import {getLocalizationService} from '~/shared/services/localisationService';
import {setDinningRoomNoPackingRequired} from '~/shared/store/actions';
import {selectDinningRoomNoPackingRequired} from '~/shared/store/selectors';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {media} from '~/shared/theme/media';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  label {
    padding: 5px;
  }

  ${media.minIphoneX`
    margin-top: 20px;
    flex-direction: row;
    label {
      padding: inherit;
    }
  `}
  ${flipOnLTR`
    margin-right: 13px;
    ${media.minDesktop`
      margin-right: 0;
    `}
    & > div {
      &:first-child {
        margin-left: 28px;
      }
  `}
`;

const Label = styled.span<{isBold?: boolean}>`
  ${flipOnLTR`
    margin-right: 12px;
  `}
  top: 1px;
  font-weight: ${({isBold}) => (isBold ? 'bold' : 'normal')};
`;

const DinnigRoomOptions = () => {
  const {t} = getLocalizationService();
  const dispatch = useDispatch();
  const isDinningRoomNoPackingRequired = useSelector(selectDinningRoomNoPackingRequired);

  const setOption = (val: boolean) => {
    dispatch(setDinningRoomNoPackingRequired(val));
  };

  return (
    <Root>
      <RadioButton id="sitting" value isChecked={isDinningRoomNoPackingRequired} onValueChange={setOption}>
        <Label isBold={isDinningRoomNoPackingRequired}>{t('dinnig_room_sitting_option')}</Label>
      </RadioButton>
      <RadioButton id="packed" value={false} isChecked={!isDinningRoomNoPackingRequired} onValueChange={setOption}>
        <Label isBold={!isDinningRoomNoPackingRequired}>{t('dinnig_room_packing_option')}</Label>
      </RadioButton>
    </Root>
  );
};

export default memo(DinnigRoomOptions);
