import styled from 'styled-components';

import {media} from '~/shared/theme/utils';
import Button from '~/shared/components/Button';
import {flexColumn} from '~/shared/theme/FlexLayout';

export const PopupModalBase = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: ${({backgroundColor, theme}) => backgroundColor || theme.modal.diagonalBackgroundColor};
  ${media.maxTablet`
    ${flexColumn};
    border-radius: 0;
  `};
  ${({stripeImage}) => stripeImage && 'min-height: 110vh;'}

  &&& {
    overflow: hidden;
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
    max-height: calc(100vh - 40px);
  }

  ${media.minLargeTablet`
    &&& {
      overflow: hidden;
      width: 670px;
      height: 100%; 
    }
  `}
`;

export const LongButton = styled(Button)`
  background-color: ${({theme, darkMode}) =>
    (darkMode ? theme.actionButton.enabled.backgroundColorDark : theme.actionButton.enabled.backgroundColor)};
  border-color: ${({theme}) => theme.actionButton.enabled.backgroundColor};
  height: 50px;
  color: ${({theme}) => theme.actionButton.enabled.color};
  width: 100%;
  align-self: center;
  margin-top: auto;
  font-size: 14px;
  ${p => (p.darkMode ? null : 'border-radius: 5px; font-weight: bold;')};

  &:hover {
    background-color: ${({theme, darkMode}) =>
    (darkMode ? theme.actionButton.enabled.backgroundColor : theme.actionButton.enabled.hoverBg)};
  }

  &:disabled {
    background-color: ${({theme}) => theme.actionButton.disabled.backgroundColor};
    color: ${({theme}) => theme.actionButton.disabled.color};
    cursor: auto;
  }

  ${
  '' /* FIXME: this is here to hide the CenteredTextButtonWrapper in FacebookLogin -> forgotPassword. Once we refactor LoginOrRegister we should remove it */
}
  z-index: 1;
`;

export const ModalContent = styled.div`
  ${flexColumn};
  flex: 1 0 auto;
  text-align: center;
  width: calc(100% - 60px);
  margin: 0 30px 0 30px;
  padding-top: 24px;
  ${media.minTablet`
    padding-top: 0;
    margin: 30px;
  `}
`;
