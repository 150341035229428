import {MIN_RANK_TO_SHOW, MIN_REVIEWS_TO_DISPLAY_RATING} from '~/shared/consts/restaurantConsts';

import {TagsConfiguration, RenderingType, TagsTypes, RestaurantPropsType, TagsArray, formatTags} from './tags';

const MAX_COUNT_FIRST_ROW_POOLED = 4;
const MAX_COUNT_FIRST_ROW_DELIVERY = 3;
const MAX_COUNT_SECOND_ROW_DELIVERY = 3;
const MAX_COUNT_FIRST_ROW_DELIVERY_FREE = 3;
const MAX_COUNT_SECOND_ROW_DELIVERY_FREE = 3;
const MAX_COUNT_FIRST_ROW_PICKUP = 2;
const MAX_COUNT_SECOND_ROW_PICKUP = 3;

const DEFAULT_TAGS_RENDERING: RenderingType = {
  [TagsTypes.RATING]: {
    renderPlaceholder: true,
    condition: tagProps =>
      tagProps.numOfReviews > MIN_REVIEWS_TO_DISPLAY_RATING && tagProps.reviewsRankDecimal > MIN_RANK_TO_SHOW,
  },
  [TagsTypes.DISTANCE]: {
    renderPlaceholder: true,
    condition: tagProps => tagProps.deliveryMethod?.toLowerCase() === 'pickup',
  },
  [TagsTypes.DELIVERY_TIME]: {
    renderPlaceholder: tagProps => tagProps.deliveryMethod?.toLowerCase() === 'delivery',
    condition: tagProps => !tagProps.isPooledOrderRestaurant && tagProps.deliveryTimeInMinutes > 0,
  },
  [TagsTypes.DELIVERY_FEE]: {
    renderPlaceholder: true,
    condition: tagProps => !tagProps.isPooledOrderRestaurant,
  },
  [TagsTypes.MINIMUM_ORDER]: {
    renderPlaceholder: tagProps => tagProps.deliveryMethod?.toLowerCase() === 'delivery',
    condition: tagProps => !tagProps.isPooledOrderRestaurant && tagProps.minimumPriceForOrder > 0,
  },
  [TagsTypes.KOSHER]: {
    renderPlaceholder: false,
    condition: tagProps => tagProps.isKosher,
  },
  [TagsTypes.IS_NEW]: {
    renderPlaceholder: false,
    condition: tagProps => !!tagProps?.tags?.find(tag => tag.type === 'New'),
  },
  [TagsTypes.ENVIRONMENT_FRIENDLY]: {
    renderPlaceholder: false,
    condition: tagProps => tagProps.isEnvironmentFriendly,
  },
  [TagsTypes.POOLED_ORDER]: {
    renderPlaceholder: false,
    condition: () => false,
  },
};

type RowsConfigType = Pick<RestaurantPropsType, 'deliveryMethod' | 'deliveryFee' | 'isPooledOrderRestaurant'>;

const getOrder = (
  {deliveryMethod, deliveryFee, isPooledOrderRestaurant}: RowsConfigType,
  isMinOrderBeforeDeliveryFee: TagsConfiguration['isMinOrderBeforeDeliveryFee'],
) => {
  if (deliveryMethod?.toLowerCase() === 'pickup') {
    return [
      TagsTypes.RATING,
      TagsTypes.DISTANCE,
      TagsTypes.KOSHER,
      TagsTypes.IS_NEW,
      TagsTypes.ENVIRONMENT_FRIENDLY,
    ];
  }

  if (deliveryMethod?.toLowerCase() === 'delivery' && deliveryFee) {
    if (isMinOrderBeforeDeliveryFee) {
      return [
        TagsTypes.RATING,
        TagsTypes.DELIVERY_TIME,
        TagsTypes.MINIMUM_ORDER,
        TagsTypes.DELIVERY_FEE,
        TagsTypes.KOSHER,
        TagsTypes.IS_NEW,
        TagsTypes.ENVIRONMENT_FRIENDLY,
      ];
    }
    return [
      TagsTypes.RATING,
      TagsTypes.DELIVERY_TIME,
      TagsTypes.DELIVERY_FEE,
      TagsTypes.MINIMUM_ORDER,
      TagsTypes.KOSHER,
      TagsTypes.IS_NEW,
      TagsTypes.ENVIRONMENT_FRIENDLY,
    ];
  }

  if (isPooledOrderRestaurant) {
    return [TagsTypes.RATING, TagsTypes.KOSHER, TagsTypes.IS_NEW, TagsTypes.ENVIRONMENT_FRIENDLY];
  }

  // regular delivery (not pooled), with free delivery

  return [
    TagsTypes.RATING,
    TagsTypes.DELIVERY_TIME,
    TagsTypes.MINIMUM_ORDER,
    TagsTypes.DELIVERY_FEE,
    TagsTypes.KOSHER,
    TagsTypes.IS_NEW,
    TagsTypes.ENVIRONMENT_FRIENDLY,
  ];
};

const getTagsRows = ({deliveryMethod, isPooledOrderRestaurant, deliveryFee}: RowsConfigType, tags: TagsArray) => {
  if (deliveryMethod?.toLowerCase() === 'delivery') {
    if (isPooledOrderRestaurant) {
      const pooledOrderTagsList = tags.filter(tagItem => !tagItem.isPlaceholder);

      return {firstRowTags: formatTags(pooledOrderTagsList.slice(0, MAX_COUNT_FIRST_ROW_POOLED))};
    }

    // set defaults for number of tags in each raw for regular delivery
    let tagCountFirstRow = MAX_COUNT_FIRST_ROW_DELIVERY;
    let tagCountSecondRow = MAX_COUNT_SECOND_ROW_DELIVERY;

    // if it's a free delivery, we need to adjust how tags are splitted between raws
    if (deliveryFee === 0) {
      tagCountFirstRow = MAX_COUNT_FIRST_ROW_DELIVERY_FREE;
      tagCountSecondRow = MAX_COUNT_SECOND_ROW_DELIVERY_FREE;
    }

    const firstRowTags = tags.slice(0, tagCountFirstRow);

    const secondRowTags = tags.slice(tagCountFirstRow, tagCountFirstRow + tagCountSecondRow);

    return {firstRowTags: formatTags(firstRowTags), secondRowTags: formatTags(secondRowTags)};
  }

  // pickup
  const firstRowTags = tags.slice(0, MAX_COUNT_FIRST_ROW_PICKUP);
  const secondRowTags = tags.slice(
    MAX_COUNT_FIRST_ROW_PICKUP,
    MAX_COUNT_FIRST_ROW_PICKUP + MAX_COUNT_SECOND_ROW_PICKUP,
  );

  return {firstRowTags: formatTags(firstRowTags), secondRowTags: formatTags(secondRowTags)};
};

const defaultConfiguration: TagsConfiguration = {
  rendering: DEFAULT_TAGS_RENDERING,
  order: getOrder,
  rows: getTagsRows,
  isMinOrderBeforeDeliveryFee: false,
};

export default defaultConfiguration;
