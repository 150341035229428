import styled from 'styled-components';

import {ActionButton as BaseActionButton} from '~/shared/components/Button';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {flexCenter} from '~/shared/theme/FlexLayout';
import {getDishPriceWithDiscount} from '~/shared/utils/priceDiscountCalculator';

import DishPrice from '../DishPrice';

const Button = styled(BaseActionButton)`
  ${flexCenter}
  line-height: 1;
  min-width: 130px;
  width: 100%;
  margin-left: inherit;
  margin-right: inherit;
  border-radius: inherit;
  ${media.minTablet`
    width: 400px;   
    margin: 0;
    ${flipOnLTR`
      margin-right: 20px;
    `}
    border-radius: 3px;    
  `};
  box-shadow: ${({reverseColors}) => (reverseColors ? 'none' : '0 1px 3px 0 rgba(0, 0, 0, 0.3)')};
  border: ${({reverseColors, theme}) =>
    (reverseColors ? `1px solid ${theme.negativeButton.enabled.borderColor}` : 'inherit')};

  &:hover {
    opacity: 0.9;
    background-color: ${({reverseColors, theme}) => (reverseColors ? 'inherit' : theme.actionButton.enabled.hoverBg)};
    border: ${({reverseColors, theme}) => (reverseColors ? `1px solid ${theme.negativeButton.enabled.hover}` : null)};
  }

  &:active {
    opacity: 0.5;
  }
`;

type ActionButtonProps = {
  secondary?: boolean;
  text: string;
  showPrice?: boolean;
  priceText?: number;
  onClick: () => void;
  discountsData?: ReturnType<typeof getDishPriceWithDiscount>;
};

const ActionButton = ({secondary, text, showPrice, priceText, onClick, discountsData, ...props}: ActionButtonProps) => (
  <Button reverseColors={secondary} onClick={onClick} {...props}>
    {text}
    {showPrice && <DishPrice price={priceText!} addParentheses priceDataTestId="inSubmitDishPriceLabel" discountsData={discountsData} />}
  </Button>
);

export default ActionButton;
