import {useState, useEffect} from 'react';

import styled, {ThemeProvider} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

import actions, {setShouldAvoidPaymentsRefetch} from '~/shared/store/actions';
import {flipOnLTR} from '~/shared/theme/utils';
import {attachModal} from '~/shared/components/ReduxModal';
import AvailablePayments from '~/shared/components/AvailablePayments';
import {FastCheckoutSteps} from '~/shared/consts/checkoutConsts';
import {useCheckoutTheme} from '~/checkout';
import {selectAvailablePayments} from '~/shared/store/storeModules/payments/paymentsSelectors';
import store from '~/shared/store';

const Wrapper = styled.div`
  ${flipOnLTR`
    text-align: right;
  `}
`;

const PaymentsModal = ({closeModal, args: {returnToCheckoutOnClose}}) => {
  const dispatch = useDispatch();
  const theme = useCheckoutTheme();
  const [stepMode, setStepMode] = useState(FastCheckoutSteps.AVAILABLE_PAYMENTS);

  const availablePayments = useSelector(selectAvailablePayments);

  useEffect(() => {
    if (availablePayments?.length === 0) {
      setStepMode(FastCheckoutSteps.ADD_CREDIT_CARD);
    } else {
      setStepMode(FastCheckoutSteps.AVAILABLE_PAYMENTS);
    }
  }, [availablePayments]);

  const handleOnClose = () => {
    if (returnToCheckoutOnClose) {
      dispatch(actions.setCurrentModal('checkout_modal'));
    } else {
      closeModal();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <AvailablePayments stepMode={stepMode} onClose={handleOnClose} />
      </Wrapper>
    </ThemeProvider>
  );
};

PaymentsModal.hideCloseButton = true;

// handleOnClose func inside the component doesn't close the modal, and switches the currentModal in redux
// if the modal actually was closed, the returnToCheckoutOnClose scenario won't reach the checkout component
// the action setShouldAvoidPaymentsRefetch should be invoked manually to reset addPayments flow
PaymentsModal.onClose = () => store.dispatch(setShouldAvoidPaymentsRefetch(false));

attachModal('payments_modal', PaymentsModal);
