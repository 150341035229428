/* eslint-disable no-param-reassign, class-methods-use-this, no-restricted-globals, no-console */

import {logger} from './logger';
import {ValueOrNull, ValueType} from './config';

export class FeatureOverride {
  private readonly url: URL | undefined;

  constructor(urlString: string) {
    try {
      this.url = new URL(urlString);
    } catch (error: unknown) {
      logger.logWarn(
        `[FeatureOverride] Invalid URL string '${urlString}'. Check your settings for the plugin`,
        {error},
      );
    }
  }

  getOverride(key: string, type?: ValueType): ValueOrNull {
    if (!this.url) return null;

    const params = this.url.searchParams;
    const paramValue = params.get(`jet-fm-${key}`);

    // const paramValue = this.url.searchParams.get(`jet-fm-${key}`);
    if (!paramValue) return null;

    if (!type) {
      type = this.determineType(paramValue);
    }

    try {
      let value: ValueOrNull = paramValue;
      if (type === ValueType.Bool) value = this.stringToBoolean(paramValue);
      if (type === ValueType.Int) value = this.stringToNumber(paramValue);

      console.log(`[FeatureOverride] The Feature '${key}' has been overridden with '${value}'.`);

      return value;
    } catch (error: unknown) {
      const message = error instanceof Error ? error.message : 'Unknown error';

      console.log(
        `[FeatureOverride] The Feature '${key}' has an override value '${paramValue}' but cannot be casted to '${type}'. [${message}]`,
      );

      return null;
    }
  }

  private determineType(value: string): ValueType {
    if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
      return ValueType.Bool;
    }
    if (Number.isInteger(Number(value))) {
      return ValueType.Int;
    }
    return ValueType.String;
  }

  private stringToBoolean(value: string): boolean {
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;

    throw new Error('Cast error: Not a boolean');
  }

  private stringToNumber(value: string) {
    const number = Number(value);

    if (isNaN(number)) throw new Error('Cast error: Not a number');

    return number;
  }
}

export default FeatureOverride;
