import {isNumber} from 'lodash';

import {Address, AddressFromServer, IAddress, IClientAddress, isRemoteAddress, LocalAddress} from '../store/models';

export const addressKeyFromParts = ({
  cityId = 0,
  streetId = 0,
  houseNumber = '0',
  addressId,
}: Partial<AddressFromServer>) => {
  return [cityId, streetId, houseNumber, addressId].filter(value => isNumber(value) || !!value).join('-');
};

export const mapAddressFromServerOrCookies = <T extends Partial<IAddress>>(address: T): T & {addressKey: string} => {
  const addressKey = addressKeyFromParts(address);

  return {...address, addressKey};
};

export const addressPartsFromKey = (addressKey?: string) => {
  if (!addressKey) return;
  const [cityId, streetId, houseNumber, addressId] = addressKey.split('-');
  const res: AddressPartsFromKey = {
    cityId: Number(cityId),
    streetId: Number(streetId),
    houseNumber: String(houseNumber),
    addressId: addressId ? Number(addressId) : undefined,
    addressKey: String(addressKey),
  };
  return res;
};

export type AddressPartsFromKey = {
  addressId?: Address['addressId'] | undefined;
} & {
  [K in 'cityId' | 'streetId' | 'houseNumber' | 'addressKey']: Address[K];
};

export const isAddressKeyContainAddressId = (key: string) => !!key.split('-')[3];

const getAddressDisplayComparisonKey = (address: IClientAddress) =>
  // for displaying, we compare streetName and not streetId since some addresses
  // are the same in principle and display name, but different in ids,
  // for the sake of creation of custom rules for different customers located on the same street.
  `${address.cityId}_${address.streetName}_${address.houseNumber}`;

export const areAddressesEqual = (
  address: Address | LocalAddress,
  parts?: AddressPartsFromKey,
  ignoreAddressId?: boolean,
) =>
  parts &&
  address.cityId === parts.cityId &&
  address.streetId === parts.streetId &&
  address.houseNumber === parts.houseNumber &&
  (ignoreAddressId || !parts.addressId || (isRemoteAddress(address) && address.addressId === parts.addressId));

export const findAddressByAddressKey = (
  addresses: Array<Address | LocalAddress> = [],
  addressKey?: string,
  ignoreAddressId = false,
) => {
  if (!addressKey) return;

  const parts = addressPartsFromKey(addressKey);
  return addresses.find(a => areAddressesEqual(a, parts, ignoreAddressId));
};

export const removeAddressIdFromAddressKey = (addressKey: string) => addressKey.split('-').slice(0, 3).join('-');

export function getBestMatchAddress({
  addressKey,
  remoteAddresses,
  localAddress,
}: {
  addressKey: string;
  remoteAddresses: Address[];
  localAddress?: LocalAddress;
}) {
  const remoteAddress =
    findAddressByAddressKey(remoteAddresses, addressKey) || findAddressByAddressKey(remoteAddresses, addressKey, true);

  if (remoteAddress) {
    return remoteAddress;
  }

  if (!localAddress) return;

  const localAddressComparisonKey = getAddressDisplayComparisonKey(localAddress);
  return remoteAddresses.find(a => getAddressDisplayComparisonKey(a) === localAddressComparisonKey) || localAddress;
}
