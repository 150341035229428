import {trackEvent} from '~/shared/services/analytics';
import {
  AGE_RESTRICTED_ANALYTICS,
  DeliveryMethod,
} from '~/shared/consts/restaurantConsts';
import {pushRoute, replaceRoute} from '~/shared/router';
import {getMenuOrDishPagePath} from '~/shared/services/urlService';
import {signInModalOrPage} from '~/shared/services/navigation';
// TODO: move types to corresponding folder (DishWithSub -> dish types)
import {
  DishWithSub,
  selectCategoriesListOfCurrentRestaurant,
  selectCheckoutErrors,
  selectRemoteAddresses,
} from '~/shared/store/selectors';
import store from '~/shared/store';
import actions from '~/shared/store/actions';
import {DishFromCurrentRestaurant, getDishTotalPrice} from '~/shared/utils/billingLinesCalculation';
import {ACTION_MADE_FROM_ENUM} from '~/shared/utils/ageRestriction';

import {Coupon, IShoppingCart, OrderPermits, RestaurantFromGet, ShoppingCartDish, User} from '../store/models';
import ManagerProvider from '../managers/ManagerProvider';

interface CheckoutProps {
  billingLines: IShoppingCart['billingLines'];
  dishes: ShoppingCartDish[] | null;
  dishesWithSubs: DishWithSub;
  userData: User | null;
  currentCoupon: Coupon | null;
  deliveryMethod: DeliveryMethod;
  isDeliveringToCurrentAddress: boolean;
  permits?: OrderPermits;
  restaurant?: RestaurantFromGet;
  isReorder?: boolean;
}

export interface OnCheckoutOpenProps extends CheckoutProps {
  setCurrentModal: (...args: any) => void;
  shouldOpenModal: boolean;

  shouldShowAgeConfirm: boolean;
  setActionMadeFrom: (actionMadeFrom: ACTION_MADE_FROM_ENUM) => void;
  isShoppingCartPage?: boolean;
}

export const onCheckoutOpen = async (props: OnCheckoutOpenProps) => {
  const {
    setCurrentModal,
    userData,
    restaurant,
    shouldOpenModal,
    dishes,
    dishesWithSubs,
    shouldShowAgeConfirm,
    setActionMadeFrom,
    isReorder,
    isShoppingCartPage,
  } = props;

  if (!userData) {
    store.dispatch(actions.clearPayments());

    const menuOrDishPage =
      getMenuOrDishPagePath({
        restaurantId: restaurant?.id,
        restaurantName: restaurant?.name,
      }) || '/';

    return signInModalOrPage({
      mode: 'signUp',
      returnUrl: menuOrDishPage,
      successReturnUrl: `${menuOrDishPage}?openCheckout=true`,
      onSuccess: async newUserData => {
        await store.dispatch(actions.getAvailablePayments());
        onCheckoutOpen({...props, userData: newUserData || null});
      },
    });
  }

  const orderError = selectCheckoutErrors(store.getState());
  if (orderError) {
    setCurrentModal('checkout_error_message_modal', {
      ...orderError,
      shouldShowAgeConfirm: Boolean(shouldShowAgeConfirm),
    });
    if (orderError.errorMessageCodes.includes('your_order_is_not_over_the_required_minimum') || orderError.errorMessageCodes.includes('your_order_is_not_over_the_required_minimum')) {
      trackEvent('hasViewedMinimumOrderPopup');
    }
    return;
  }

  if (shouldShowAgeConfirm) {
    setActionMadeFrom(ACTION_MADE_FROM_ENUM.MENU);
    if (shouldOpenModal) {
      setCurrentModal('age_confirm_modal', {
        onConfirm: () => {
          onCheckoutOpen({...props, shouldShowAgeConfirm: false});
        },
      });
      return;
    }

    pushRoute('/confirm-age');
    return;
  }

  const remoteAddresses = selectRemoteAddresses(store.getState());
  const userDoesntHaveRemoteAddress = remoteAddresses.length === 0;

  const categoriesList = selectCategoriesListOfCurrentRestaurant(store.getState());

  const productPrice = (dish: ShoppingCartDish) => {
    const dishForPrice: DishFromCurrentRestaurant = {
      choices: dishesWithSubs[dish.dishId].choices,
      dishPrice: dish.dishPrice ? dish.dishPrice : 0,
    };
    return getDishTotalPrice(dishForPrice, dish.choices, 1);
  };

  const currentProducts = dishes?.map(dish => ({
    productID: dish.dishId,
    productPrice: dish.choices ? productPrice(dish) : dish.dishPrice,
    productName: dish.dishName,
    productQuantity: dish.quantity,
    productCategory: categoriesList.find(item => item.categoryId === dish.categoryId)?.categoryName,
    productVariant: dishesWithSubs?.[dish.dishId].ageRestricted ? AGE_RESTRICTED_ANALYTICS : '',
  }));

  trackEvent('hasBeginCheckout', currentProducts);

  if (isReorder) {
    dishes?.map(async dish => {
      await ManagerProvider.addAllPaymentsForReorder(dish);
    });
  }

  if (!shouldOpenModal || userDoesntHaveRemoteAddress) {
    if (isShoppingCartPage) {
      replaceRoute('/checkout');
    }
    if (!isShoppingCartPage) {
      pushRoute('/checkout');
    }
    return;
  }

  setCurrentModal('checkout_modal');
};

export const removeItemsFromCart = async (unavailableDishesIds: UnavailableDishId[]) => {
  unavailableDishesIds.forEach(async unavailableDishId => {
    await ManagerProvider.removeDish(unavailableDishId);
  });
};

export type UnavailableDishId = ShoppingCartDish['shoppingCartDishId'];
