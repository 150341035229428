import {tail, omit, cloneDeep, isEmpty} from 'lodash';

import {DELIVERY_RULES, DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {MutatedFutureOrderAvailableDatesAndTimes, OrderDateAndTime} from '~/shared/store/models/FutureOrderAvailableDatesAndTimes';
import {isCurrentTimeKeyAsap as isTimeKeyAsap} from '~/shared/utils/general';
import {ActiveRulesData} from '~/shared/utils/restaurants/getActiveDeliveryRules';

import {FORM_ORDER_TYPE} from './MwOrderTypeMenu';

export const getInitialDeliveryMethod = (
  currentDeliveryMethod: DeliveryMethods,
  isPickupEnabled: boolean,
  isDeliveryEnabled: boolean,
) => {
  const isCurrentPickupDeliveryMethodValid = currentDeliveryMethod === DeliveryMethods.PICKUP && isPickupEnabled;
  const isCurrentDeliveryDeliveryMethodValid = currentDeliveryMethod === DeliveryMethods.DELIVERY && isDeliveryEnabled;

  if (isCurrentPickupDeliveryMethodValid) {
    return DeliveryMethods.PICKUP;
  }

  if (isCurrentDeliveryDeliveryMethodValid) {
    return DeliveryMethods.DELIVERY;
  }

  if (currentDeliveryMethod === DeliveryMethods.PICKUP && !isPickupEnabled && isDeliveryEnabled) {
    return DeliveryMethods.DELIVERY;
  }

  if (currentDeliveryMethod === DeliveryMethods.DELIVERY && !isDeliveryEnabled && isPickupEnabled) {
    return DeliveryMethods.PICKUP;
  }

  return currentDeliveryMethod;
};

export const getInitialDeliveryRule = (isFutureAvailable: boolean, isPooledAvailable: boolean, isAsapAvailable: boolean) => {
  if (isAsapAvailable) {
    return DELIVERY_RULES.ASAP;
  }
  if (isPooledAvailable) {
    return DELIVERY_RULES.POOL;
  }
  if (isFutureAvailable) {
    return DELIVERY_RULES.FUTURE;
  }

  return DELIVERY_RULES.ASAP;
};

export const removeAsapFromFutureOrderTimes = (propertyToRemove: string, arr?: MutatedFutureOrderAvailableDatesAndTimes[]) => {
  if (!arr || arr.length === 0 || !Array.isArray(arr[0]?.times) || arr[0].times.length === 0) {
    return arr;
  }

  if (!isTimeKeyAsap(arr[0]?.times[0]?.timeKey)) {
    return arr;
  }

  const newArr = cloneDeep(arr);
  newArr[0].times = tail(arr[0].times);
  newArr[0].timesWithTimeRanges = omit(newArr[0].timesWithTimeRanges, propertyToRemove);

  return newArr;
};

export const getSDFOInitialDayAndTime = (isCurrentTimeKeyAsap: boolean, currentOrderDateAndTime?: OrderDateAndTime, availableFutureDatesAndTimes?: MutatedFutureOrderAvailableDatesAndTimes[]): OrderDateAndTime | undefined => {
  const firstAvailableTime = availableFutureDatesAndTimes?.[0]?.times?.[0];
  const secondAvailableTime = availableFutureDatesAndTimes?.[0]?.times?.[1];
  const firstDate = availableFutureDatesAndTimes?.[0];
  
  // if asap the SDFO should be initiated with the second available option
  if (isCurrentTimeKeyAsap && secondAvailableTime && currentOrderDateAndTime) {
    return {...currentOrderDateAndTime, orderTime: secondAvailableTime};
  }

  if (!isEmpty(currentOrderDateAndTime)) {
    return currentOrderDateAndTime;
  }

  if (isTimeKeyAsap(firstAvailableTime?.timeKey) && firstDate && secondAvailableTime) {
    return {orderDate: firstDate, orderTime: secondAvailableTime};
  }

  if (firstDate && firstAvailableTime) {
    return {orderDate: firstDate, orderTime: firstAvailableTime};
  }
};

export const getNewDeliveryPrice = (orderType: FORM_ORDER_TYPE, availableDeliveryRules: ActiveRulesData, futureDeliveryFee?: number): number | undefined => {
  if (orderType === DELIVERY_RULES.FUTURE) {
    return futureDeliveryFee;
  }

  if (orderType === DELIVERY_RULES.POOL) {
    return availableDeliveryRules?.Pool?.[0]?.deliveryFee;
  }

  if (orderType === DELIVERY_RULES.ASAP) {
    return availableDeliveryRules?.Asap?.[0]?.deliveryFee;
  }

  if (orderType === DeliveryMethods.PICKUP) {
    return 0;
  }
};
