import {useCallback, useState} from 'react';

import {isEqual} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';

import {addApprovedPaymentCallout, getAvailablePayments, getLinkMoneycardToUserRequests} from '~/shared/store/actions';
import {selectLinkMoneycardRequests} from '~/shared/store/selectors';
import {LinkMoneycardToUserRequest} from '~/shared/store/models';
import {PaymentRequestStatusTypes} from '~/shared/consts/checkoutConsts';
import store from '~/shared/store';
import {LocalStorageKeys} from '~/shared/consts/localStorageConsts';
import useInterval from '~/shared/hooks/useInterval';

const POLLING_INTERVAL_TIME = 30000;

const getCurrentPendingRequestIds = () => {
  try {
    const currentPendingRequestIds = JSON.parse(
      localStorage.getItem(LocalStorageKeys.PENDING_LINK_PAYMENT_REQUEST_IDS) as string,
    );
    return currentPendingRequestIds;
  } catch (error) {
    return undefined;
  }
};

const setCurrentPendingRequestIds = (ids: string[]) =>
  localStorage.setItem(LocalStorageKeys.PENDING_LINK_PAYMENT_REQUEST_IDS, JSON.stringify(ids));

export const onPolling = async (requests: LinkMoneycardToUserRequest[]) => {
  if (!requests || !requests.length) {
    return;
  }

  const pendingIds = requests
    .filter(({status}) => status.toLowerCase() === PaymentRequestStatusTypes.REQUESTED.toLowerCase())
    .map(({requestToken}) => requestToken);

  const currentLocalPendingIds = getCurrentPendingRequestIds();
  const requestHasChanged = !isEqual(pendingIds, currentLocalPendingIds);

  if (requestHasChanged && pendingIds) {
    setCurrentPendingRequestIds(pendingIds);

    const approved = requests.filter(
      ({status}) => status.toLowerCase() === PaymentRequestStatusTypes.APPROVED.toLowerCase(),
    );
    if (approved && approved.length) {
      await store.dispatch(getAvailablePayments());
      store.dispatch(addApprovedPaymentCallout(approved));
    }
  }
};

const usePollingMoneyCard = (enabled = true) => {
  const dispatch = useDispatch();
  const pendingRequests = useSelector(selectLinkMoneycardRequests);
  const [keepPolling, setKeepPolling] = useState<boolean>(enabled);

  const polling = useCallback(async () => {
    await dispatch(getLinkMoneycardToUserRequests());
    await onPolling(pendingRequests);

    const arePengingRequests =
      pendingRequests &&
      pendingRequests.some(({status}) => status.toLowerCase() === PaymentRequestStatusTypes.REQUESTED.toLowerCase());

    setKeepPolling(arePengingRequests);
  }, [dispatch, pendingRequests]);

  useInterval({
    callback: polling,
    delay: keepPolling && enabled ? POLLING_INTERVAL_TIME : 0,
  });
};

export default usePollingMoneyCard;
