import {transform, camelCase} from 'lodash';
import {css, Styles} from 'styled-components';

export const breakpoints = {
  smallMobile: 360,
  mobile: 480,
  largeMobile: 570,
  tablet: 768,
  largeTablet: 1004,
  desktop: 1024,
  largeDesktop: 1281,
};

const createMediaQuery =
  (minMax: 'min' | 'max' | 'base', breakpoint: number) =>
  <P extends object>(...args: Styles<P>) =>
      css`
      @media (${minMax === 'base' ? '' : `${minMax}-`}width: ${breakpoint}px) {
        ${css(...args)}
      }
    `;

export const media = {
  ...transform<typeof breakpoints, MediaQueriesBase>(breakpoints, (result, breakpoint, breakpointName) => {
    (['min', 'max'] as const).forEach(minMax => {
      result[camelCase(`${minMax} ${breakpointName}`) as keyof MediaQueriesBase] = createMediaQuery(
        minMax,
        // removing 1 from max breakpoints so they will not overlap with min breakpoints
        minMax === 'max' ? breakpoint - 1 : breakpoint,
      );
    });
  }),
  initial: createMediaQuery('base', breakpoints.mobile),
  minIphoneX: createMediaQuery('min', 375),
};

type MediaQueryCreator = typeof createMediaQuery;
type MediaQuery = ReturnType<MediaQueryCreator>;
type MediaQueriesBase = {
  [K in keyof typeof breakpoints]: MediaQuery;
} & {
  [K in keyof typeof breakpoints as `min${Capitalize<K>}`]: MediaQuery;
} & {
  [K in keyof typeof breakpoints as `max${Capitalize<K>}`]: MediaQuery;
};
