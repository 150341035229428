import {memo} from 'react';

import styled from 'styled-components';

import {media} from '~/shared/theme/utils';
import Map from '~/shared/components/Maps';
import {secureOpenWindow} from '~/shared/utils/general';

const MapContainer = styled.div`
  height: 400px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  ${media.minLargeDesktop`
    height: 450px;
 `}
`;

const onMarkerClick = (latitude, longitude) => {
  secureOpenWindow(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`, '_blank');
};

const ScooberMap = ({
  restaurantLatitude,
  restaurantLongitude,
  userLatitude,
  userLongitude,
  showAllMarkers,
  isBigIcon,
}) => (
  <MapContainer>
    <Map
      onRestaurantMarkerClick={() => onMarkerClick(restaurantLatitude, restaurantLongitude)}
      onUserMarkerClick={() => onMarkerClick(userLatitude, userLongitude)}
      restaurantLatitude={restaurantLatitude}
      restaurantLongitude={restaurantLongitude}
      userLatitude={userLatitude}
      userLongitude={userLongitude}
      showAllMarkers={showAllMarkers}
      isBigIcon={isBigIcon}
    />
  </MapContainer>
);

export default memo(ScooberMap);
