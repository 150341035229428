import {memo} from 'react';

import styled from 'styled-components';

import {OrderSummaryView} from '~/orderSummary';
import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import {attachModal} from '~/shared/components/ReduxModal';

const Root = styled(ClickOutsideDiv)`
  background-color: ${({theme}) => theme.colors.gray000};
  max-width: 504px;
  border-radius: 2px;
  box-shadow: ${({theme}) => theme.shadows.shadow1};

  &:first-child {
    overflow-y: hidden;
  }
`;

type OrderSummaryModalProps = {
  closeModal: () => void;
  args?: {
    hideReorderButton?: boolean;
  };
};

const OrderSummaryModal = ({closeModal, args = {}}: OrderSummaryModalProps) => {
  const {hideReorderButton} = args;
  const handleCloseModal = (e: Event) => {
    e?.preventDefault();
    closeModal();
  };

  return (
    <Root onClickOutside={handleCloseModal}>
      <OrderSummaryView onCloseModal={closeModal} hideReorderButton={hideReorderButton} />
    </Root>
  );
};

type StaticTypes = Partial<{hideCloseButton: boolean}>;

const EnhancedOrderSummaryModal: React.FC<OrderSummaryModalProps> & StaticTypes = memo(OrderSummaryModal);

EnhancedOrderSummaryModal.hideCloseButton = true;

attachModal('order_summary_modal', EnhancedOrderSummaryModal);
