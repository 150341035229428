import styled from 'styled-components';

import {flexCenter, flexCenterVertically} from '~/shared/theme/FlexLayout';
import {media} from '~/shared/theme/media';
import {body16Bold, body18Normal} from '~/shared/theme/typography';
import {getLocalizationService} from '~/shared/services/localisationService';

export const OPEN_CHECKOUT_BUTTON_HEIGHT = 50;

const Wrapper = styled.div<{fixed: boolean}>`
  ${body18Normal};
  position: ${({fixed}) => (fixed ? 'fixed' : 'relative')};
  bottom: 0;
  left: 0;
  height: ${OPEN_CHECKOUT_BUTTON_HEIGHT}px;
  width: 100%;
  ${flexCenterVertically};
  justify-content: space-between;
  cursor: pointer;
  z-index: ${({theme}) => theme.zIndex.openCartButton};
  background-color: ${({theme}) => theme.colors.surfacePrimaryAction};
  ${media.minLargeMobile`
    border-radius: 0;
  `};
`;

const ButtonText = styled.span`
  ${body16Bold};
  line-height: 22px;
  display: inline;
  color: ${({theme}) => theme.colors.surfacePrimaryActionText};
  margin-right: 8px;
  margin-left: 8px;
`;

const Title = styled.div`
  width: 100%;
  ${flexCenter};
`;

type OpenCartButtonProps = {
  onPayment: () => void;
  fixed?: boolean;
};

const OpenCheckoutButton = ({onPayment, fixed = true}: OpenCartButtonProps) => {
  const {t} = getLocalizationService();

  return (
    <Wrapper onClick={onPayment} fixed={fixed}>
      <Title>
        <ButtonText>{t('checkout_page')}</ButtonText>
      </Title>
    </Wrapper>
  );
};

export default OpenCheckoutButton;
