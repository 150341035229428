import {
  ActiveRulesData,
  EMPTY_RULES_DATA,
} from '~/shared/utils/restaurants/getActiveDeliveryRules';
import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';
import {SHOPPING_CART_DELIVERY_TYPE} from '~/shared/utils/restaurants/deliveryOptions/types';

const filterUnavailableRules = (allRules: ActiveRulesData, rulesToFilter?: DELIVERY_RULES[]) => {
  const filteredOptions = {
    ...EMPTY_RULES_DATA,
    ...allRules,
  };

  if (rulesToFilter) {
    rulesToFilter.forEach(optionToFilter => {
      filteredOptions[optionToFilter] = null;
    });
  }

  return filteredOptions;
};

const AVAILABLE_RULES_BY_SHOPPING_CART_DELIVERY_TYPE: Record<
  SHOPPING_CART_DELIVERY_TYPE,
  (allActiveRules: ActiveRulesData) => ActiveRulesData
> = {
  [SHOPPING_CART_DELIVERY_TYPE.ASAP_AND_POOLED]: allActiveRules => filterUnavailableRules(allActiveRules, [DELIVERY_RULES.FUTURE]),
  [SHOPPING_CART_DELIVERY_TYPE.ASAP_AND_FUTURE]: allActiveRules => filterUnavailableRules(allActiveRules, [DELIVERY_RULES.POOL]),
  [SHOPPING_CART_DELIVERY_TYPE.SDFO]: allActiveRules => filterUnavailableRules(allActiveRules, [DELIVERY_RULES.POOL]),
  [SHOPPING_CART_DELIVERY_TYPE.POOL_ONLY]: allActiveRules => filterUnavailableRules(allActiveRules, [DELIVERY_RULES.ASAP, DELIVERY_RULES.FUTURE]),
  [SHOPPING_CART_DELIVERY_TYPE.ASAP_ONLY]: allActiveRules => filterUnavailableRules(allActiveRules, [DELIVERY_RULES.POOL, DELIVERY_RULES.FUTURE]),
  [SHOPPING_CART_DELIVERY_TYPE.FUTURE]: allActiveRules => filterUnavailableRules(allActiveRules, [DELIVERY_RULES.POOL, DELIVERY_RULES.ASAP]),
  [SHOPPING_CART_DELIVERY_TYPE.DEFAULT]: allActiveRules => filterUnavailableRules(allActiveRules, [DELIVERY_RULES.POOL, DELIVERY_RULES.FUTURE]),
};

const getAvailableDeliveryRules = (allActiveRules: ActiveRulesData, shoppingCartDeliveryType: SHOPPING_CART_DELIVERY_TYPE) => {
  return AVAILABLE_RULES_BY_SHOPPING_CART_DELIVERY_TYPE[shoppingCartDeliveryType](allActiveRules);
};

export default getAvailableDeliveryRules;
