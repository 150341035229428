import evaluateRestriction from './evaluateRestriction';
import {FeatureConfig, Value} from './config';
import {Context} from './settings';

export const getDefaultValue = (feature: FeatureConfig, context: Context): Value | null => {
  const matchingRule = feature.defaultValueRules.find(rule =>
    evaluateRestriction(rule.restrictions, context),
  );

  return matchingRule ? matchingRule.value : null;
};
