import {makeReducer, composeReducers} from '../../redux-toolbelt';

import {toggleContrastMode, toggleOutlieMode} from './a11yActions';

interface A11yState {
  contrast: boolean;
  outline: boolean;
}

export default composeReducers<A11yState>({
  contrast: makeReducer(toggleContrastMode, currentMode => !currentMode, {defaultState: false as boolean}),
  outline: makeReducer(toggleOutlieMode, currentMode => !currentMode, {defaultState: false as boolean}),
});
