import {memo} from 'react';

import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {selectCurrentRestaurant} from '~/shared/store/selectors';
import {getLocalizationService} from '~/shared/services/localisationService';
import Textarea from '~/shared/components/Textarea';
import {media} from '~/shared/theme/media';

const TextareaRemarks = styled(Textarea)<{error: boolean}>`
  margin-top: 16px;
  max-width: 100%;
  border-radius: 4px;
  resize: none;
  padding: 0 8px;
`;

const OrderRemarksWrapper = styled.div`
  padding: 0 16px;
  ${media.minLargeTablet`
    padding: unset;
  `}
`;

const RemarkLabel = styled.label`
  display: block;
  margin-top: 16px;
  color: ${({theme}) => theme.colors.secondary};
  font-weight: bold;
`;

type OrderRemarksType = {
  userRemarksError: {id: string; errorDesc: string} | null;
  orderRemarkTextareaValue: string;
  setOrderRemarkTextareaValue: (value: string) => void;
};

const OrderRemarks = ({userRemarksError, orderRemarkTextareaValue, setOrderRemarkTextareaValue}: OrderRemarksType) => {
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const {t} = getLocalizationService();

  return (
    <OrderRemarksWrapper>
      <RemarkLabel htmlFor="remarks-textarea">
        {currentRestaurant?.orderRemarks?.title || t('address_comments')}
      </RemarkLabel>
      <TextareaRemarks
        id="remarks-textarea"
        error={Boolean(userRemarksError?.errorDesc)}
        placeholder={t('insert_here')}
        rows={2}
        value={orderRemarkTextareaValue}
        onChange={e => setOrderRemarkTextareaValue(e.currentTarget.value)}
      />
    </OrderRemarksWrapper>
  );
};

export default memo(OrderRemarks);
