import {merge} from 'lodash';

import {SupportedLanguageType} from '~/shared/consts/commonConsts';

import colors from './colors';
import shadows from './shadows';
import {breakpoints} from './media';

const zIndex = {
  supportAndA11yBadge: 1001,
  header: 128,
  menuSubHeader: 127,
  filterbox: 126,
  activeOrder: 126,
  openCartButton: 125,
  scooberLogo: 100,
  languageChangeButton: 1,
  subHeader: -1,
  bottomSheet: 1003,
};

export const baseTheme = {
  zIndex,
  breakpoints,
  MobileHeaderSidesPadding: 12,
  bodyMargins: [0, 20, 43, 121],
  colors,
  shadows,
  backgroundColor: colors.darkBackground,
  lineColor: colors.primary,
  actionButton: {
    enabled: {
      color: colors.primaryText,
      backgroundColor: colors.surfacePrimaryAction,
      backgroundColorDark: colors.secondary,
      hoverBg: colors.surfacePrimaryActionDark,
    },
    invert: {
      color: colors.surfacePrimaryAction,
      borderColor: colors.gray500,
    },
    disabled: {
      color: colors.primaryText,
      backgroundColor: colors.gray500,
    },
    fontSize: '14px',
  },
  negativeButton: {
    enabled: {
      color: colors.surfacePrimaryAction,
      backgroundColor: 'transparent',
      hover: colors.surfacePrimaryAction,
      borderColor: colors.gray500,
    },
  },
  callOut: {
    backgroundColor: colors.surfacePrimaryAction,
    color: colors.primaryText,
  },
  modal: {
    backgroundColor: colors.darkBackground,
    overlayBackgroundColor: 'rgba(0, 0, 0, 0.5)',
    textColor: colors.secondary,
    subHeader: colors.surfacePrimaryAction,
    diagonalHeaderColor: colors.primary,
    diagonalBackgroundColor: colors.darkBackground,
    diagonalBorderColor: colors.primaryText,
    defaultHeight: 670,
    titleColor: colors.primary,
    textColorSecondary: colors.secondary,
    button: {
      color: colors.secondaryText,
      backgroundColor: colors.secondary,
    },
  },
  couponsModal: {
    subHeadersColor: colors.secondary,
  },
  loginModal: {
    headerBackgroundColor: colors.primary,
    headerBackgroundColorDesktop: colors.darkBackground,
    headerTextColor: colors.primaryText,
    backgroundColor: colors.surface,
  },
  homepage: {
    primary: colors.primary,
    secondary: colors.secondary,
    textColor: colors.primaryText,
    paragraph: colors.gray100,
    advantagesBg: colors.surfacePrimaryActionLight,
    header: {
      background: colors.surface,
      govLinkTextColor: colors.surfacePrimaryAction,
    },
    tenBisCard: {
      backgroundColor: colors.darkBackground,
    },
    aboutUs: {
      backgroundColor: colors.primary,
      textColor: colors.primaryText,
    },
    restaurantPage: {
      backgroundColor: colors.darkBackground,
      textColor: colors.secondary,
      headerColor: colors.primary,
    },
    actionButton: {
      enabled: {
        color: colors.primaryText,
        backgroundColor: colors.secondary,
        hoverBg: colors.secondary,
      },
      mobile: {
        backgroundColor: colors.darkBackground,
        color: colors.gray100,
      },
    },
  },
  header: {
    textColor: colors.primaryText,
    backgroundColor: colors.primary,
    height: 72,
    actionMenu: {
      icon: {
        backgroundColor: colors.primary,
        hover: colors.primary,
        active: colors.primary,
      },
      dropDown: {
        textColor: colors.secondary,
        backgroundColor: colors.surface,
        separator: colors.gray500,
        hover: {
          textColor: colors.primaryText,
          backgroundColor: colors.secondary,
        },
      },
    },
  },
  joinHeader: {
    height: 224,
    ribbonHeight: 142,
  },
  scroller: {
    backgroundColor: colors.surface,
    mobileBackgroundColor: colors.background,
  },
  footer: {
    height: 146,
    color: {
      compAdminColor: 'red',
      linkColor: colors.secondaryText,
      backgroundColor: colors.secondary,
    },
  },
  subHeader: {
    height: 50,
    button: {
      color: '#000',
      backgroundColor: colors.darkBackground,
    },
    backgroundColor: colors.surface,
    marginFromSubheader: 1,
  },
  reorderDropdown: {
    listWidth: 320,
    listHeight: 480,
  },
  searchDropDown: {
    header: colors.secondary,
    backgroundColor: colors.surface,
    separator: colors.secondary,
    textColor: colors.secondary,
    descriptionText: colors.text,
    list: {
      result: {
        labelColor: colors.text,
        valueColor: colors.secondary,
        separator: colors.gray500,
      },
      hover: {
        backgroundColor: colors.surfacePrimaryActionLight,
        color: colors.secondary,
        labelColor: colors.gray100,
        dishSearchColor: colors.gray910,
      },
      selected: {
        backgroundColor: colors.darkBackground,
      },
    },
  },
  addressFinder: {
    resultsContainer: {
      borderWidth: 1,
      borderColor: '#F0F0F0',
      backgroundColor: colors.surface,
    },
    suggestionItem: {
      backgroundColor: {
        regularColor: colors.surface,
        activeColor: colors.surfacePrimaryActionLight,
      },
    },
  },
  addressDropdown: {
    tabs: {
      active: {
        color: colors.primaryText,
        backgroundColor: colors.secondary,
      },
      inactive: {
        color: colors.secondary,
        backgroundColor: colors.darkBackground,
      },
    },
    list: {
      currentAddress: {
        backgroundColor: colors.darkBackground,
        labelColor: colors.text,
        valueColor: colors.secondary,
      },
      address: {
        backgroundColor: colors.surface,
        labelColor: colors.text,
        valueColor: colors.secondary,
      },
      selected: {
        backgroundColor: colors.surface,
        labelColor: colors.text,
        valueColor: colors.secondary,
      },
      hover: {
        backgroundColor: colors.surfacePrimaryActionLight,
        color: colors.secondary,
        labelColor: colors.gray100,
      },
      button: {
        backgroundColor: colors.surfacePrimaryAction,
        color: colors.primaryText,
      },
    },
    form: {
      textColor: '#000',
      backgroundColor: colors.background,
      titleColor: colors.secondary,
      subTitle: colors.secondary,
    },
    actionButton: {
      enabled: {
        color: colors.primaryText,
        backgroundColor: colors.surfacePrimaryAction,
      },
      disabled: {
        color: colors.primaryText,
        backgroundColor: colors.gray500,
      },
    },
  },
  orderHistory: {
    section: {
      header: {
        textColor: colors.primaryText,
        backgroundColor: colors.secondary,
      },
      button: {
        textColor: colors.primaryText,
        backgroundColor: colors.surfacePrimaryAction,
      },
      link: {
        textColor: colors.surfacePrimaryAction,
      },
    },
    textColor: colors.secondary,
  },
  filters: {
    width: 305,
    height: 760,
    ml: 20,
    activatedFilterBackgroundColor: colors.surface,
    section: {
      body: {
        radio: {
          size: '16px',
          bulletColor: colors.primary,
          borderColor: colors.gray500,
          bulletSize: '10px',
        },
      },
    },
  },
  loading: {
    spinnerColor: colors.primary,
    spinnerBackgroundColor: 'rgba(255, 255, 255, 0.8)',
    spinnerBackgroundRadius: 10,
  },
  restaurants: {
    itemGroup: {
      background: colors.surface,
      headerTextColor: colors.secondary,
      subHeaderTextColor: colors.text,
      accent: colors.secondary,
      textColor: colors.secondary,
      popular: colors.success,
      new: colors.primary,
    },
    newItem: {
      backgroundColor: colors.surface,
      headerHeight: 130,
      titleColor: colors.secondary,
      textColor: colors.text,
      logoBackground: colors.surface,
      logoShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), inset 0 1px 3px 0 rgba(0, 0, 0, 0.2)',
      closedRestaurant: colors.secondaryText,
    },
    item: {
      nameTextColor: colors.secondary,
      borderColor: colors.gray500,
      open: {
        backgroundColor: colors.surface,
      },
      close: {
        backgroundColor: colors.surface,
        textColor: colors.gray500,
      },
      addressTextColor: colors.secondary,
      cuisineListTextColor: colors.text,
      DeliveryRemarks: colors.text,
      deliveryFieldTextColor: '#000',
      deliveryFieldBackgroundColor: colors.background,
      infoLabel: colors.text,
      InfoValue: colors.text,
      InfoValueFastDelivery: colors.secondary,
      reviewsBackground: colors.darkBackground,
      ratingColor: colors.secondary,
      starColor: colors.primary,
    },
    spaceBetweenHeaderAndRestaurants: {mobile: 8, desktop: 20},
    poolOverMinimum: colors.primaryText,
    poolUnderMinimum: colors.primary,
    poolNeutral: colors.text,
    poolNeutralBackground: colors.gray900,
    poolOverMinimumBackground: colors.successDark,
  },
  menu: {
    header: {
      height: {
        desktop: 300,
        mobile: 170,
      },
    },
    restaurantInfo: {
      padding: {
        desktop: 240,
        mobile: 100,
      },
    },
    categories: {
      width: 850,
      height: {
        mobile: 116,
        desktop: 56,
      },
      hoverBg: colors.secondary,
      active: colors.primary,
      inactive: colors.secondary,
      title: colors.secondary,
      subTitle: colors.text,
      borderColor: colors.darkBackground,
      backgroundColor: colors.surface,
      dropDown: {
        backgroundHoverColor: colors.surfacePrimaryActionLight,
        backgroundActiveColor: colors.darkBackground,
        color: colors.secondary,
      },
    },
    details: {
      title: colors.secondary,
      nameColor: colors.secondary,
      cuisineListColor: colors.text,
      linkColor: colors.surfacePrimaryAction,
      infoLabelColor: {
        desktop: colors.secondary,
        mobile: colors.text,
      },
      infoValueColor: {
        desktop: colors.secondary,
        mobile: colors.secondary,
      },
      borderColor: colors.gray500,
      actionLink: colors.surfacePrimaryAction,
      deliveryInfoColor: colors.darkBackground,
    },
    dish: {
      backgroundColor: colors.surface,
      nameColor: colors.secondary,
      descriptionColor: colors.text,
    },
    dishModal: {
      textColor: colors.secondary,
      priceColor: colors.primaryText,
      amountBorder: colors.gray500,
      amountText: colors.surfacePrimaryAction,
    },
    reviews: {
      author: colors.secondary,
      borderColor: colors.gray500,
      date: colors.text,
      textColor: colors.secondary,
    },
  },
  facebookButton: {
    background: colors.secondary,
    text: colors.secondaryText,
  },
  googleButton: {
    background: colors.surface,
    text: colors.secondary,
  },
  facebookSignUp: {
    text: colors.secondary,
    profilePictureBorder: colors.primaryText,
  },
  userActionPage: {
    textColor: colors.primaryText,
  },
  activeMoneyCardModal: {
    textColor: colors.secondary,
    background: colors.darkBackground,
    input: {
      border: colors.gray500,
      background: colors.surface,
      error: colors.error,
      textColor: colors.text,
    },
  },
  linkMoneycardRequestLandingPage: {
    textColor: colors.primaryText,
    headerBackground: colors.primary,
  },
  tipTextField: {
    color: colors.gray000,
  },
  restaurantTag: {
    fontSize: '14px',
  },
  tagColorsBackground: {
    textColor: colors.primaryText,
    DiscountCoupon: colors.plate1.background,
    New: colors.plate2.background,
    NoDeliveryFee: colors.orange10,
    Promoted: colors.plate6.background,
    PromotedMacabbi: colors.plate7.background,
    PromotedGov: colors.plate8.background,
    PoopedOrderOverTheMinimum: colors.successDark,
    PoopedOrderUnderTheMinimum: colors.darkBackground,
  },
  tagColorsForeground: {
    defaultColor: colors.text,
    textColor: colors.primaryText,
    DiscountCoupon: colors.plate1.color,
    New: colors.plate2.color,
    NoDeliveryFee: colors.contentBrand,
    DeliveryFeeWithDiscount: colors.contentBrand,
    Promoted: colors.plate6.color,
    PromotedMacabbi: colors.plate7.color,
    PromotedGov: colors.plate8.color,
    PoopedOrderOverTheMinimum: colors.primaryText,
    PoopedOrderUnderTheMinimum: colors.text,
  },
  tagBoldness: {
    NoDeliveryFee: true,
    DeliveryFeeWithDiscount: true,
  },
  shoppingCart: {
    futureOrderHeight: 94,
    ageRestrictionDisclaimerHeight: 50,
    padding: {
      top: 20,
      bottom: 20,
    },
    textColor: colors.secondary,
    descriptionColor: colors.text,
    amountCounterFontSize: 14,
    container: {
      backgroundColor: colors.surface,
    },
    badgeColor: colors.secondary,
    collapseIconColor: colors.surfacePrimaryAction,
    toggleBtnHeight: 70,
    billingLineHeight: 35,
    continueToPaymentContainerHeight: 50,
    borderColor: colors.darkBackground,
    paymentButtonHeight: 82,
    billingLines: {
      minHeight: 106,
      lineHeight: 20,
    },
    billingLine: {
      discountColor: colors.primary,
    },
    dishBtn: {
      color: colors.surfacePrimaryAction,
      disabled: colors.text,
    },
  },
  userReport: {
    general: {
      background: colors.surface,
      markedRowBackground: colors.gray500,
      markedRowText: colors.secondary,
      text: colors.text,
      header: {
        text: colors.primaryText,
        background: colors.secondary,
      },
    },
    showOrder: {
      borderLineColor: colors.darkBackground,
      background: colors.surface,
      text: colors.text,
      subHeaderBackground: colors.darkBackground,
    },
    debitRequestsTable: {
      buttonBorderColor: colors.gray500,
      buttonColor: colors.surfacePrimaryAction,
    },
    moneyCardDetails: {
      text: colors.text,
      numberText: colors.secondary,
      balanceNumberText: colors.primary,
      background: colors.surface,
    },
    sendDebitTransferRequestModal: {
      input: {
        color: colors.secondary,
        borderColor: colors.gray500,
        backgroundColor: colors.surface,
        paymentIconColor: colors.text,
      },
      linkColor: colors.surfacePrimaryAction,
      wantToSplitLabelColor: colors.text,
      errorColor: colors.error,
    },
    debitTransferRequestsReceivedModal: {
      text: colors.secondary,
      infoValueTextColor: colors.text,
      buttons: {
        approve: {
          background: colors.surfacePrimaryAction,
          text: colors.primaryText,
        },
        cancel: {
          borderColor: colors.gray500,
          text: colors.surfacePrimaryAction,
        },
      },
    },
    dateSelection: {
      text: colors.secondary,
      backgroundColor: colors.surface,
    },
    totalsTable: {
      text: colors.secondary,
      markedRowBackground: colors.gray500,
    },
    filterDropDown: {
      textColor: colors.secondary,
      arrowColor: colors.secondary,
    },
    transactionTable: {
      menu: {
        backgroundColor: colors.surface,
        buttonHoverBackgroundColor: colors.surfacePrimaryActionLight,
        text: colors.text,
      },
      settings: {
        height: 300,
        headerHeight: 40,
        rowHeight: 40,
      },
      rowBorderColor: colors.gray500,
      background: {
        row: colors.surface,
        rowCollapsed: colors.surface,
        header: colors.secondary,
      },
      text: {
        general: colors.text,
        header: colors.primaryText,
        collapseButton: colors.surfacePrimaryAction,
      },
    },
  },
  sharedHeader: {
    height: {
      mobile: 58,
      rest: 70,
    },
  },
  shared: {
    backLink: {
      textColor: colors.surfacePrimaryAction,
    },
  },
  checkout: {
    textColor: colors.secondary,
    simpleModeActiveColor: colors.primary,
    headerBackground: colors.surface,
    addCreditCardButton: {
      color: colors.surfacePrimaryAction,
      borderColor: colors.gray500,
    },
    errors: {
      errorText: colors.error,
      actionLink: colors.surfacePrimaryAction,
    },
    paymentMethodLine: {
      additionalPriceButtonTextColor: colors.surfacePrimaryActionText,
      disabledPayment: colors.gray300,
    },
    availablePayments: {
      backgroundColor: colors.surface,
      paymentBackgroundColor: colors.darkBackground,
      noResultMessage: colors.secondary,
      paymentBackGroundColor: colors.darkBackground,
      linkBtnColor: colors.surfacePrimaryAction,
      borderColor: colors.gray500,
      formError: colors.error,
      submitSuccess: colors.success,
    },
    otl: {
      textColor: colors.secondary,
      addCreditCardColor: colors.surfacePrimaryAction,
    },
    phoneCode: {
      labelText: colors.secondary,
    },
    backgroundColor: colors.darkBackground,
    header: {
      height: 56,
    },
    dish: {
      name: {
        textColor: colors.surfacePrimaryAction,
        fontSize: '14px',
      },
      price: {
        textColor: colors.secondary,
        fontSize: '14px',
      },
    },
  },
  checkoutErrorModal: {
    backgroundColor: colors.darkBackground,
    input: {
      color: colors.text,
    },
    button: {
      backgroundColor: colors.surfacePrimaryAction,
    },
  },
  orderSuccess: {
    body: {
      width: 550,
      headerTextColor: colors.secondary,
      textColor: colors.text,
      linkColor: colors.surfacePrimaryAction,
    },
    shareOrder: {
      header: {
        textColor: colors.secondary,
      },
      phone: {
        backgroundColor: colors.surface,
        borderColor: colors.gray500,
        textColor: colors.secondary,
      },
      button: {
        active: {
          backgroundColor: colors.secondary,
        },
        disabled: {
          backgroundColor: colors.gray500,
          textColor: colors.secondaryText,
        },
      },
      success: {
        textColor: colors.success,
      },
    },
  },
  checkbox: {
    markColor: colors.primary,
    width: '15px',
    height: '15px',
    borderColor: colors.gray500,
    on: {
      textColor: colors.primaryText,
    },
    off: {
      textColor: '#000',
    },
  },
  updateUserDetails: {
    logoutTextColor: colors.surfacePrimaryAction,
  },
  input: {
    borderColor: colors.gray500,
    error: {
      textColor: colors.error,
      borderColor: colors.error,
    },
  },
  restaurantReview: {
    highlightedStarAndLabelColor: colors.primary,
    dimmedStarAndLabelColor: colors.gray500,
  },
  successModal: {
    buttonWidth: '400px',
  },
  inputModal: {
    header: {
      backgroundColor: colors.secondaryText,
      textColor: colors.secondary,
    },
    body: {
      backgroundColor: colors.darkBackground,
      submitButtonTextColor: colors.surfacePrimaryAction,
    },
  },
  cityPage: {
    header: {
      height: {
        mobile: 170,
        desktop: 300,
      },
    },
    cityInfo: {
      showMore: colors.surfacePrimaryAction,
      backgroundColor: colors.surface,
      padding: {
        mobile: 150,
        desktop: 45,
      },
    },
  },
  groupPage: {
    header: {
      height: {
        mobile: 170,
        desktop: 300,
      },
    },
    groupInfo: {
      showMore: colors.surfacePrimaryAction,
      backgroundColor: colors.surface,
      padding: {
        mobile: 150,
        desktop: 45,
      },
    },
  },
  carouselHeight: {
    mobile: 359,
    desktop: 400,
    desktopHighlight: 432,
    mobileHighlight: 416,
  },
  dummyTracker: {
    innerCircle: {
      strokeColor: colors.background,
    },
    progressCircle: {
      strokeColor: colors.primary,
    },
    timeUnitLabel: {
      color: colors.gray950,
    },
  },
};

const withoutHeaderTheme = {
  header: {
    height: {
      mobile: 0,
      rest: 0,
    },
  },
  subHeader: {
    height: 0,
  },
  checkout: {
    header: {
      height: 0,
    },
  },
};

const createTheme = (options: {
  direction: 'ltr' | 'rtl';
  shouldHideHeader: boolean;
  lng: SupportedLanguageType['key'];
}) => {
  options.direction = options.direction || 'ltr';
  const mergedTheme = options.shouldHideHeader ? merge({}, baseTheme, withoutHeaderTheme) : baseTheme;

  return {
    ...mergedTheme,
    isLTR: options.direction === 'ltr',
    ...options,
  };
};

export default createTheme;
