import {createSelector} from 'reselect';

import {AppState} from '../../configStore';

const selectCompanyAddressesRoot = (state: AppState) => state.companyAddresses;

export const selectCompanyAddresses = createSelector(
  selectCompanyAddressesRoot,
  companyAddresses => companyAddresses.data,
);
