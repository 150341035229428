import {memo} from 'react';

import styled from 'styled-components';

import {PriceLabel, PriceLabelWithStrikethrough} from '~/shared/components/PriceLabels';
import {BillingLineWithDiscountsData} from '~/shared/store/models/ShoppingCart/IShoppingCart';
import {FlexCenterVertically} from '~/shared/theme/FlexLayout';
import LogoIcon from '~/assets/images/icons/10bisPlusIcon.svg?react';
import {BillingLineType} from '~/shared/consts/checkoutConsts';
import FreeDeliveryTag from '~/shared/components/FreeDeliveryTag';

const StyledLogoIcon = styled(LogoIcon)`
  margin: 0 2px 2px;
`;

const StyledPriceLabel = styled(PriceLabel)<{isDeliveryFee?: boolean}>`
  ${({isDeliveryFee, theme}) => isDeliveryFee && `
    font-weight: 700;
    color: ${theme.colors.contentBrand};
  `}
`;

const DiscountPriceLabel = ({
  billingLine,
  priceDataTestId,
  sign,
  priceBeforeDiscountTestId,
  isUserBenefitsHighlightEnabled,
}: {
  billingLine: BillingLineWithDiscountsData;
  priceDataTestId?: string;
  priceBeforeDiscountTestId?: string;
  sign?: string;
  isUserBenefitsHighlightEnabled: boolean;
}) => {
  if (!billingLine.discountsData) {
    return null;
  }

  const isDeliveryFee = billingLine.type === BillingLineType.DeliveryFee;

  if (isDeliveryFee && billingLine.discountsData.priceAfterDiscount === 0) {
    return (
      <FreeDeliveryTag showIcon={isUserBenefitsHighlightEnabled} />
    );
  }

  const showHighlightsOnDeliveryFee = isDeliveryFee && isUserBenefitsHighlightEnabled;

  return (
    <>
      <PriceLabelWithStrikethrough priceDataTestId={priceBeforeDiscountTestId} price={billingLine.discountsData.price} grey />
      <FlexCenterVertically>
        {showHighlightsOnDeliveryFee && <StyledLogoIcon />}
        <StyledPriceLabel
          price={billingLine.discountsData.priceAfterDiscount}
          priceDataTestId={priceDataTestId ? `${priceDataTestId}_${billingLine.type}` : undefined}
          type={billingLine.type}
          sign={sign}
          isDeliveryFee={showHighlightsOnDeliveryFee}
        />
      </FlexCenterVertically>
    </>
  );
};

export default memo(DiscountPriceLabel);
