import styled from 'styled-components';

import Button from '~/shared/components/Button';

export const SubmitButton = styled(Button)<{disabled: boolean}>`
  margin-top: 16px;
  height: 48px;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  background-color: ${({theme, disabled}) =>
    (disabled ? theme.colors.gray500 : theme.actionButton.enabled.backgroundColor)};
  color: ${({theme}) => theme.actionButton.enabled.color};
  cursor: ${({disabled}) => disabled && 'not-allowed'};
`;
