import {createSelector} from 'reselect';

import {SupportedLanguages} from '~/shared/consts/commonConsts';
import {getSlug} from '~/shared/utils/urls';

import {AppState} from '../../configStore';
import {Cuisine} from '../../models';

const selectCuisines = (state: AppState) => state.cuisines;

const selectCuisinesDataRoot = createSelector(selectCuisines, cuisines => cuisines.data);

export const selectCuisinesData = createSelector(selectCuisinesDataRoot, cuisinesData => {
  if (!cuisinesData) {
    throw new Error('No cuisines was found in the store');
  }

  return cuisinesData.map<Cuisine>(cuisine => ({
    ...cuisine,
    id: cuisine.id.toLowerCase(),
  }));
});

export const selectCuisinesById = createSelector(selectCuisinesData, allCuisines =>
  allCuisines.reduce<Record<string, Cuisine>>(
    (all, cuisine) => ({
      ...all,
      [cuisine.id]: cuisine,
    }),
    {},
  ),
);

export const selectAllCuisinesBySlug = createSelector(selectCuisinesData, cuisines =>
  cuisines.reduce<CuisinesBySlug>((allCuisines, cuisine) => {
    const cuisineKeysLabelHoldingCuisine = SupportedLanguages.reduce<CuisinesBySlug>(
      (prev, {key: languageKey}) => ({
        ...prev,
        [getSlug(cuisine.names[languageKey])]: cuisine,
      }),
      {},
    );

    return {
      ...allCuisines,
      ...cuisineKeysLabelHoldingCuisine,
    };
  }, {}),
);

type CuisinesBySlug = Record<string, Cuisine>;
