import {useState, useRef, useMemo, memo} from 'react';

import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {selectUserLoading} from '~/shared/store/selectors';
import {loginWithProvider, OAuthProviders} from '~/shared/services/auth';
import {pushRoute, useLocation} from '~/shared/router';
import {CentralLoading} from '~/shared/components/Loaders';
import {createLogger} from '~/shared/logging';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {trackEvent} from '~/shared/services/analytics';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {LayoutWithDoubleHeader} from '~/shared/components/LayoutWithHeader';
import {FormNamesEnum} from '~/shared/services/analytics/analyticsModels';

import {
  convertQueryOrPropToStartingScreenKey,
  getStartingModeFromStartingScreenKey,
  openLoginScreenHandler,
  openProviderScreenHandler,
  openSignUpScreenHandler,
} from './utils/facebookLoginOrSignUpHelper';
import FacebookLoginOrSignUp from './ProviderLoginOrSignUp';
import SignUp from './SignUp';
import Login from './Login';
import {LoginOrRegisterScreenKey, LoginProvider, AuthFlowStep, ViewModes} from './consts';

const logger = createLogger('LoginOrRegister');

const screens = {
  login: {
    key: LoginOrRegisterScreenKey.LOGIN,
    view: Login,
    modeName: ViewModes.SIGN_IN_MODE,
  },
  signUp: {
    key: LoginOrRegisterScreenKey.SIGN_UP,
    view: SignUp,
    modeName: ViewModes.SIGN_UP_MODE,
  },
  provider: {
    key: LoginOrRegisterScreenKey.PROVIDER,
    view: FacebookLoginOrSignUp,
  },
};

const LoginOrRegisterContainer = styled.div`
  ${flexColumn};
  -webkit-overflow-scrolling: touch;
  ${({pageView}) =>
    !pageView &&
    `
    overflow: auto;
    &&& {
      width: 505px;
    }
  `}
`;

const LoginOrRegister = props => {
  const {currentScreenKey} = props;
  const screen = screens[currentScreenKey];
  const {view: Component, mode} = screen;

  return <Component {...props} mode={mode} />;
};

const LoginOrRegisterWrapper = ({pageView, mode, onSuccess, backButtonUrl, closeModal, isStrongLogin}) => {
  const isUserLoading = useSelector(selectUserLoading);
  const location = useLocation();

  const [currentScreenKey, setCurrentScreenKey] = useState(convertQueryOrPropToStartingScreenKey(location.routeParams.mode || mode) || screens.login.key);
  const providerDetails = useRef();
  const [loginProvider, setLoginProvider] = useState(LoginProvider.TENBIS);
  const [providerViewMode, setProviderViewMode] = useState(getStartingModeFromStartingScreenKey(currentScreenKey));
  const [authFlowStep, setAuthFlowStep] = useState(AuthFlowStep.INITIAL);
  const [verificationDetails, setVerificationDetails] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialLoginEmail, setInitialLoginEmail] = useState();
  const [isFireRegisterEvent, setIsFireRegisterEvent] = useState(false);

  const isRedirectedToStepRef = useRef(false);

  const isLoading = useMemo(() => isUserLoading || isSubmitting, [isUserLoading, isSubmitting]);

  const openLoginScreen = ({email, getAuthCodeForProviderConnect, nextProvider, providerProps} = {}) => openLoginScreenHandler({
    email,
    getAuthCodeForProviderConnect,
    loginProvider: nextProvider || loginProvider,
    setVerificationDetails,
    setLoginProvider,
    setInitialLoginEmail,
    setCurrentScreenKey,
    setProviderViewMode,
    setAuthFlowStep,
    providerProps,
    providerDetails,
  });

  const openSignUpScreen = () => openSignUpScreenHandler({
    loginProvider,
    setCurrentScreenKey,
    setProviderViewMode,
    setLoginProvider,
  });

  const openProviderScreen = ({providerProps, nextLoginProvider, nextScreen}) => openProviderScreenHandler({
    setLoginProvider,
    setCurrentScreenKey,
    providerProps,
    providerDetails,
    nextLoginProvider,
    setProviderViewMode,
    nextScreen,
  });

  const onSubmit = async (values, linkType) => {
    try {
      setIsSubmitting(true);
      const userDetails = await ManagerProvider.login(values);
      if (onSuccess) {
        onSuccess(userDetails);
      }
    } catch (error) {
      const errorType = 'login failed';
      logger.error(errorType, {error, fingerprint: errorType});
      if (linkType) {
        trackEvent('hasFormError', {
          formType: '2FA Login Form',
          formErrorType: error?.message,
          linkType,
        });
      }

      trackEvent('formErrorMessageView', {
        formName: linkType === '2FA - SMS' ? FormNamesEnum.OTP_LOGIN : FormNamesEnum.PASSWORD_LOGIN,
        errorType: error?.message,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onBackButtonClick = () => {
    if (pageView) {
      return pushRoute(backButtonUrl, {keepAllQueries: false});
    }
    closeModal();
  };

  const openFacebook = async () => {
    if (currentScreenKey === screens.signUp.key) {
      trackEvent('hasClickedFacebookLogin_Signup');
    } else if (currentScreenKey === screens.login.key) {
      trackEvent('hasClickedFacebookLogin_Login');
    }

    const {shouldConnectAccount, providerUserId: facebookUserId, providerAccessToken: facebookUserAccessToken, encryptedId, email} = await loginWithProvider(OAuthProviders.FACEBOOK);

    if (shouldConnectAccount) {
      openProviderScreen({
        providerProps: {
          facebookUserId,
          facebookUserAccessToken,
          shouldConnectAccount: true,
          email,
        },
        nextLoginProvider: LoginProvider.FACEBOOK,
      });

      return;
    }

    onSubmit({facebookUserId, facebookUserAccessToken, userToken: encryptedId});
  };

  const onSubmitWithGoogleConnect = () => {
    onSubmit({});
  };

  return (
    <LoginOrRegisterContainer pageView={pageView}>
      {isLoading && <CentralLoading />}
      <LayoutWithDoubleHeader
        onClick={onBackButtonClick}
        titleKey="luckily_hunger_brought_you_back"
        secondTitleKey="good_food_is_waiting_for_you_just_around_the_corner"
        showBackArrow={false}
        firstTabActive={providerViewMode === ViewModes.SIGN_IN_MODE}
        onFirstTabClick={() => {
          // login
          trackEvent('hasClickedIHaveAnAccount');
          openLoginScreen();
          setIsFireRegisterEvent(false);
        }}
        onSecondTabClick={() => {
          trackEvent('hasClickedCreateAccount');
          openSignUpScreen();
          if (!isFireRegisterEvent) {
            trackEvent('hasViewedRegistrationPopup');
            setIsFireRegisterEvent(true);
          }
        }}
        hideHeader={isStrongLogin}
      >
        <LoginOrRegister
          {...{
            loginProvider,
            authFlowStep,
            setAuthFlowStep,
            providerViewMode,
            isSubmitting,
            setIsSubmitting,
            initialLoginEmail,
            loading: isLoading,
            currentScreenKey,
            openLoginScreen,
            pageView,
            openSignUpScreen,
            onSubmit,
            onSuccess,
            onBackButtonClick,
            openFacebook,
            isStrongLogin,
            openProviderScreen,
            onSubmitWithGoogleConnect,
            ...(providerDetails.current || {}),
            isRedirectedToStepRef,
            verificationDetails,
            setVerificationDetails,
          }}
        />
      </LayoutWithDoubleHeader>
    </LoginOrRegisterContainer>
  );
};

export default memo(LoginOrRegisterWrapper);
