import styled from 'styled-components';

import {flexStart} from '~/shared/theme/FlexLayout';
import {body18Normal, body20Bold} from '~/shared/theme/typography';

export const ModalParagraphContainer = styled.div`
  ${flexStart};
  color: ${({theme}) => theme.modal.textColor};
  flex-flow: column;
  width: 100%;
`;

export const ModalParagraphHeader = styled.h4`
  ${body20Bold};
  line-height: 1.4;
  color: ${({theme}) => theme.modal.textColor};
  margin: 10px 0;
`;

export const ModalParagraphHeaderInline = styled(ModalParagraphHeader)`
  display: inline;
`;

export const ModalParagraphText = styled.p`
  ${body18Normal};
  line-height: 1.42;
  margin: 10px 0;
  letter-spacing: 0.5px;
  width: 100%;
`;

export const ModalParagraphTextInline = styled(ModalParagraphText)`
  display: inline;
`;
