import styled from 'styled-components';

import {flexCenterVertically, flexColumnCenterVertically} from '~/shared/theme/FlexLayout';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';

export const PageHeaderPrimaryBar = styled.div`
  height: 100%;
  ${flexColumnCenterVertically};
  flex: 1;
  ${flipOnLTR`
    margin-right: 8px;
  `}
`;

export const PageHeaderSecondaryBar = styled.div`
  align-self: center;
  height: 100%;
  ${flexCenterVertically};
`;
