import {memo} from 'react';

import {useDispatch} from 'react-redux';
import styled from 'styled-components';

import ButtonBase from '~/shared/components/Button';
import {setIsOrderTypeMenuOpen} from '~/shared/store/actions';
import SingleRuleETABox from '~/shared/components/ShoppingCart/components/ShoppingCartHeader/SingleRuleETABox';
import {StyledArrow} from '~/shared/components/ShoppingCart/components/ShoppingCartHeader/Shared';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {media} from '~/shared/theme/media';
import {Skeleton, SkeletonLoader} from '~/shared/components/Loaders';
import useIsShoppingCartLoading from '~/shared/hooks/useIsShoppingCartLoading';
import {trackEvent} from '~/shared/services/analytics';
import {convertGapToMargin} from '~/shared/theme/convertGapToMargin';

import FutureOrderDisclaimer from '../FutureOrder/FutureOrderDisclaimer';

import useCurrentRuleData from './useCurrentRuleData';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Button = styled(ButtonBase)<{$isDisabled: boolean; enableGrayFilter: boolean}>`
  display: flex;
  border-radius: 10px;
  border: 1px solid ${({theme}) => theme.colors.gray800};
  padding: 8px 12px;
  width: 100%;
  pointer-events: ${({$isDisabled}) => ($isDisabled ? 'none' : 'inherit')};
  color: ${({theme}) => theme.colors.secondary};
`;

const GrayScale = styled.div<{isEnabled: boolean}>`
  ${({isEnabled, theme}) => isEnabled && `
    color: ${theme.colors.gray300};
    filter: grayscale(1);
  `}
`;

const ArrowContainer = styled.div`
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.25s linear;
  ${({theme}) => convertGapToMargin('0 2px', theme.isLTR)}
  ${flipOnLTR`
    margin-right: 6px;
  `}
`;

const ArrowText = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${({theme}) => theme.colors.surfaceTitle};
`;

const MobileMenuButtonRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CollapseButton = styled.div`
  display: flex;
  align-items: center;
  ${media.minTablet`
    margin-bottom: 10px;
  `}
`;

const OrderTypeButtonSkeleton = styled(Skeleton)`
  height: 32px;
`;

const OrderTypeButtonLoader = () => {
  return (
    <Button>
      <OrderTypeButtonSkeleton />
    </Button>
  );
};

type ReturnTypeOfUseCurrentRuleData = ReturnType<typeof useCurrentRuleData>;

const ButtonContent = ({hideArrow, isClosed, arrowText, etaBoxText, isTempClosed}: {
  hideArrow: ReturnTypeOfUseCurrentRuleData['isButtonEnabled'];
  isClosed: ReturnTypeOfUseCurrentRuleData['isActiveNow'];
  arrowText: ReturnTypeOfUseCurrentRuleData['arrowText'];
  etaBoxText?: ReturnTypeOfUseCurrentRuleData['etaBoxText'];
  isTempClosed?: ReturnTypeOfUseCurrentRuleData['isTempClosed'];
}) => {
  return (
    <MobileMenuButtonRoot>
      <GrayScale isEnabled={Boolean(isClosed)}>
        <SingleRuleETABox
          isClosed={isClosed}
          etaBoxText={etaBoxText}
          isTempClosed={isTempClosed}
          hideTitle
          inline
        />
      </GrayScale>
      {!hideArrow && (
        <CollapseButton>
          <ArrowContainer>
            {arrowText && <ArrowText>{arrowText}</ArrowText>}
            <StyledArrow $inline={!isClosed} />
          </ArrowContainer>
        </CollapseButton>
      )}
    </MobileMenuButtonRoot>
  );
};

const OrderTypeButton = ({isCheckout}: {
  isCheckout?: boolean;
}) => {
  const dispatch = useDispatch();
  const {
    isButtonEnabled,
    isActiveNow,
    arrowText,
    etaBoxText,
    isTempClosed,
    orderTypeString,
    showFutureOrderDisclaimer,
  } = useCurrentRuleData({isCheckout});
  const isLoading = useIsShoppingCartLoading();

  const onClick = () => {
    if (!isButtonEnabled) {
      return;
    }
    dispatch(setIsOrderTypeMenuOpen(true));
    trackEvent('nlxOrderType_click', {
      pageSource: isCheckout ? 'checkout' : 'menu',
      original_orderType: orderTypeString,
    });
  };

  return (
    <SkeletonLoader LoaderComponent={OrderTypeButtonLoader} shouldShowLoader={isLoading}>
      <Root>
        <Button
          $isDisabled={!isButtonEnabled}
          onClick={onClick}
        >
          <ButtonContent
            hideArrow={!isButtonEnabled}
            isClosed={!isActiveNow}
            isTempClosed={isTempClosed}
            arrowText={arrowText}
            etaBoxText={etaBoxText}
          />
        </Button>
        {showFutureOrderDisclaimer && <FutureOrderDisclaimer shouldShowCancelDisclaimer />}
      </Root>
    </SkeletonLoader>
  );
};

export default memo(OrderTypeButton);
