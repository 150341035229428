import {useSelector} from 'react-redux';

import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {
  selectIsScooberJobCanceled,
  selectIsTrackerUrlEmpty,
  selectOrderSuccessData,
  selectUserLastOrderScooberJob,
} from '~/shared/store/selectors';

export default function useIsFoodTransferDetailsMargin() {
  const {trackerUrl} = useSelector(selectUserLastOrderScooberJob) || EMPTY_OBJECT;
  const {restaurant} = useSelector(selectOrderSuccessData) || EMPTY_OBJECT;
  const isStatusCanceled = useSelector(selectIsScooberJobCanceled);
  const isTrackerUrlEmpty = useSelector(selectIsTrackerUrlEmpty);

  return trackerUrl || isStatusCanceled || (restaurant?.isScoober && (!trackerUrl || isTrackerUrlEmpty));
}
