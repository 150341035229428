import {useCallback, useEffect, useState} from 'react';

const useOptimisticDeliveryFee = <S = any>(source: S): [S, (newVal: S) => void] => {
  const [currentValue, setCurrentValue] = useState<S>(source);

  const setNewValue = useCallback((newValue: S) => {
    setCurrentValue(newValue);
  }, []);

  useEffect(() => {
    setCurrentValue(state => {
      if (state === source) {
        return state;
      }

      return source;
    });
  }, [source]);

  return [currentValue, setNewValue];
};

export default useOptimisticDeliveryFee;
