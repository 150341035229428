// eslint-disable-next-line no-restricted-imports
import Tooltip from 'rc-tooltip';
import {TooltipProps} from 'rc-tooltip/lib/Tooltip';
import styled, {CSSProperties} from 'styled-components';

const ModifedTooltip = styled(Tooltip).attrs(
  ({theme: {direction}, overlayStyle}: {theme: Required<{direction: string}>; overlayStyle: CSSProperties}) => ({
    overlayStyle: {direction, ...overlayStyle},
  }),
)``;

const ToolTipWrapper = ({children, ...props}: TooltipProps) => <ModifedTooltip {...props}>{children}</ModifedTooltip>;

export default ToolTipWrapper;
