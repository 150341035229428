import {Interpolation, css, Styles, ExecutionContext} from 'styled-components';
import {isArray} from 'lodash';

// If used outside of a styled component, a context containing `theme` must be passed explicitly, e.g:
// ${flipOnLTR`
//   padding-right: 8px;
// }`({theme})};

const textReplace = (text: string) => text.replace(/left|right/g, str => (str === 'right' ? 'left' : 'right'));
const replaceIfText = <T = unknown>(res: Interpolation<T>) => (typeof res === 'string' ? textReplace(res) : res);

const flip = <P extends object>(executionContext: ExecutionContext<P>, styles: Styles<P>) => {
  const {isLTR} = executionContext.theme;
  if (!isLTR) return css(...styles);
  const boundedStyles = styles.map(style =>
    (typeof style === 'function' ? () => replaceIfText(style(executionContext)) : style),
  ) as Styles<P>;

  const newStringTemplateArr = boundedStyles.map(style =>
    (!isTemplateStringsArray(style)
      ? style
      : style.map(text => text.replace(/left|right/g, str => (str === 'right' ? 'left' : 'right')))),
  ) as Styles<P>;
  return css(...newStringTemplateArr);
};

export function flipOnLTR<P extends object = DefaultProps>(...styles: Styles<P>) {
  return (executionContext: ExecutionContext<P>) => flip(executionContext, styles);
}

const createRotateFunction =
  (mode: 'onLTR' | 'onRTL') =>
  <P = unknown>({theme: {isLTR}}: ExecutionContext<P>) => {
    const shouldRotate = mode === 'onLTR' ? isLTR : !isLTR;
    return shouldRotate
      ? css`
          transform: rotateY(180deg);
        `
      : '';
  };

export const rotateOnLTR = createRotateFunction('onLTR');
export const rotateOnRTL = createRotateFunction('onRTL');

function isTemplateStringsArray<P>(s: Styles<P>[number]): s is TemplateStringsArray {
  return isArray(s);
}

type DefaultProps = {[key: string]: any};
