import {memo, useMemo} from 'react';

import {useSelector} from 'react-redux';

import {shouldShowUserReport} from '~/shared/config';
import useChat from '~/shared/hooks/useChat';
import useOpenSignIn from '~/shared/hooks/useOpenSignIn';
import {logout} from '~/shared/services/auth';
import {selectUserData} from '~/shared/store/selectors';

import ActionMenu from '../ActionMenu';

const PageHeaderActionMenu = ({shouldShowExit = true}) => {
  const actionMenuLinks = useActionMenuLinks(shouldShowExit);

  return <ActionMenu actionMenuLinks={actionMenuLinks} />;
};

export default memo(PageHeaderActionMenu);

function useActionMenuLinks(shouldShowExit = true) {
  const {openLogin} = useOpenSignIn();
  const userData = useSelector(selectUserData);
  const allowedToSeeNewUserReport = useMemo(
    () => !userData?.userEnabledFeatures?.includes('DisabledG13UserReport') && shouldShowUserReport,
    [userData],
  );

  const {openChat} = useChat();
  return useMemo(
    () => [
      {
        textKey: 'connect',
        onClick: () => openLogin(),
        isHighlighted: false,
        openInNewWindow: false,
        compAdminOnly: false,
        loggedInUserOnly: false,
        loggedOutUserOnly: true,
        isAvailable: true,
        isVisibleOnMobile: true,
        toggleMenu: true,
      },
      {
        textKey: 'eat_outside',
        url: 'Restaurants/Search/Sitting',
        isHighlighted: true,
        openInNewWindow: false,
        companyUserOnly: true,
        loggedInUserOnly: true,
        isAvailable: true,
        isVisibleOnMobile: false,
        toggleMenu: false,
        useLegacy: true,
      },
      {
        textKey: 'reorder',
        url: '/reorder',
        isHighlighted: false,
        openInNewWindow: false,
        loggedInUserOnly: true,
        isAvailable: true,
        isVisibleOnMobile: true,
        isVisibleOnTablet: true,
        showOnMobileOnly: true,
        toggleMenu: true,
        useLegacy: false,
      },
      {
        textKey: 'user_info',
        url: 'Account/UserProfileDetails',
        isHighlighted: false,
        openInNewWindow: false,
        loggedInUserOnly: true,
        isAvailable: true,
        isVisibleOnMobile: true,
        toggleMenu: false,
        useLegacy: true,
      },
      {
        textKey: 'credit_cards',
        url: 'Account/UserCCList',
        isHighlighted: false,
        openInNewWindow: false,
        loggedInUserOnly: true,
        isAvailable: true,
        isVisibleOnMobile: true,
        toggleMenu: false,
        useLegacy: true,
      },
      {
        textKey: '10bis_card',
        url: 'Account/UserAccountManagement',
        isHighlighted: false,
        openInNewWindow: false,
        loggedInUserOnly: true,
        isAvailable: true,
        isVisibleOnMobile: true,
        toggleMenu: false,
        useLegacy: true,
      },
      {
        textKey: 'charges_report',
        url: allowedToSeeNewUserReport ? '/user-report?dateBias=0' : 'Account/UserReport',
        useLegacy: !allowedToSeeNewUserReport,
        isHighlighted: false,
        openInNewWindow: false,
        loggedInUserOnly: true,
        isAvailable: true,
        isVisibleOnMobile: true,
        toggleMenu: false,
      },
      {
        textKey: 'private_area',
        url: 'Account/ServiceArea',
        isHighlighted: false,
        openInNewWindow: false,
        loggedInUserOnly: true,
        isAvailable: true,
        isVisibleOnMobile: true,
        toggleMenu: false,
        useLegacy: true,
      },
      {
        textKey: 'table_for_two',
        url: 'TableForTwo',
        isHighlighted: false,
        openInNewWindow: true,
        loggedInUserOnly: false,
        isAvailable: true,
        isVisibleOnMobile: true,
        toggleMenu: false,
        useLegacy: true,
      },
      {
        textKey: 'become_a_courier',
        url: 'https://www.10bis.co.il/courier?utm_source=mainsite&utm_medium=referral&utm_content=header',
        openInNewWindow: true,
        isVisibleOnMobile: true,
        isAvailable: true,
      },
      {
        textKey: 'live_chat_with_service_representative',
        onClick: () => openChat(),
        isHighlighted: false,
        openInNewWindow: false,
        compAdminOnly: false,
        loggedInUserOnly: false,
        isAvailable: true,
        isVisibleOnMobile: true,
        toggleMenu: true,
      },
      {
        textKey: 'enter_company_representative_interface',
        url: 'CompAdmin/',
        isHighlighted: false,
        openInNewWindow: false,
        compAdminOnly: true,
        loggedInUserOnly: true,
        isAvailable: true,
        isVisibleOnMobile: true,
        toggleMenu: false,
        useLegacy: true,
      },
      {
        textKey: 'the_order_has_arrived_send_message',
        url: 'CompAdmin/Reports/ThinDailyByUserReport',
        isHighlighted: false,
        openInNewWindow: true,
        compAdminOnly: true,
        loggedInUserOnly: true,
        isAvailable: true,
        isVisibleOnMobile: true,
        toggleMenu: false,
        useLegacy: true,
      },
      {
        ...(shouldShowExit && {
          textKey: 'exit',
          onClick: () => logout(),
          isHighlighted: false,
          openInNewWindow: false,
          compAdminOnly: false,
          loggedInUserOnly: false,
          isAvailable: true,
          isVisibleOnMobile: true,
          toggleMenu: false,
        }),
      },
    ],
    [allowedToSeeNewUserReport, openChat, openLogin, shouldShowExit],
  );
}
