import styled from 'styled-components';

import {DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {Link} from '~/shared/router';
import {getLocalizationService} from '~/shared/services/localisationService';
import {media} from '~/shared/theme/media';
import {formatAddress} from '~/shared/services/addressHelper';
import {RestaurantBusinessTypeName} from '~/shared/store/models';

import Disclaimer from '../Disclaimer';

import {defaultPaddingCss} from './orderSummaryStyle';
import Section from './parts/Section';

const DeliverySection = styled(Section)`
  ${defaultPaddingCss};
`;
const PickupSection = styled(Section)`
  ${defaultPaddingCss};
`;

const StyledPhone = styled(Link)`
  color: ${({theme}) => theme.colors.secondary};
`;

const DisabledOnDesktopDiv = styled.div`
  ${media.minMobile`
    pointer-events: none;
  `};
`;

const DeliveryDetails = ({restaurant, address: addressToSend, hasMargin, remarks, notesForClient}) => {
  const isGroceriesStore = restaurant?.businessType === RestaurantBusinessTypeName.GroceryStore;
  const {t} = getLocalizationService();

  const storeOrResLabel = isGroceriesStore ? t('store_name') : t('restaurant_name');

  return (
    <>
      <DeliverySection titleHasMargin={hasMargin} title={t('delivery_details')}>
        {`${storeOrResLabel}: ${restaurant?.restaurantName}`}
        <DisabledOnDesktopDiv data-test-id="restaurantPhone">
          {`${t('phone_number_for_information')}: `}
          <StyledPhone href={`tel:${restaurant?.restaurantPhone}`} target="_blank">
            {restaurant?.restaurantPhone}
          </StyledPhone>
        </DisabledOnDesktopDiv>
        {`${t('address_for_delivery')}: ${addressToSend?.streetName} ${addressToSend?.houseNumber}, ${addressToSend?.cityName} ${remarks ? ` - ${remarks}` : ''}`}
      </DeliverySection>
      {notesForClient && (
        <Disclaimer showInfoIcon>
            {notesForClient}
        </Disclaimer>
      )}
    </>
  );
};

const PickupDetails = ({restaurant, address, hasMargin}) => {
  const {t, currentLanguageKey} = getLocalizationService();
  const isCurrentAddressIsDinningRoom = address?.shiftId;

  const fullUserAddress = formatAddress({address, lng: currentLanguageKey});

  return (
    <PickupSection titleHasMargin={hasMargin} title={t('restaurant_details')}>
      {`${t('restaurant_name_pickup')}: ${restaurant?.restaurantName}`}
      {isCurrentAddressIsDinningRoom
        ? `${t('address')}: ${fullUserAddress}`
        : `${t('restaurant_address')}: ${restaurant?.fullAddress}`}
      {!isCurrentAddressIsDinningRoom && `${t('phone_number_for_information')}: ${restaurant?.restaurantPhone}`}
    </PickupSection>
  );
};

const FoodTransferDetails = ({deliveryMethod, isDonation, ...props}) =>
  (isDonation ? null : deliveryMethod.toLowerCase() === DeliveryMethods.PICKUP ? (
    <PickupDetails {...props} />
  ) : (
    <DeliveryDetails {...props} />
  ));

export default FoodTransferDetails;
