import styled from 'styled-components';

import {media, flipOnLTR} from '~/shared/theme/utils';
import Input from '~/common/components/Input';
import Textarea from '~/shared/components/Textarea';
import {flexColumn, flexSpace} from '~/shared/theme/FlexLayout';
import {Link as _Link} from '~/shared/router';
import {subheader26Bold} from '~/shared/theme/typography';

export const Container = styled.div`
  ${flexColumn};
  ${media.minMobile`
    ${flexSpace};
    flex-direction: row;
  `}
`;

export const Root = styled.div`
  min-height: 383px;
  padding: 24px;
  ${flexColumn};
  justify-content: flex-start;
  direction: ${({theme: {isLTR}}) => (isLTR ? 'ltr' : 'rtl')};
  ${flipOnLTR`
    text-align: right;
  `}
  color: ${({theme}) => theme.userReport.debitTransferRequestsReceivedModal.text};

  &&& {
    overflow: hidden;
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
    max-height: calc(100vh - 40px);
  }

  ${media.minLargeTablet`

    &&& {
      overflow: hidden;
      width: 100%;
      height: 100%; 
    }
  `}
`;

export const Title = styled.div`
  ${subheader26Bold}
  margin-bottom: 28px;
`;

export const FormWrapper = styled.div`
  margin-top: 15px;
`;

export const Inline = styled.div`
  display: flex;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.minMobile`
    margin-bottom: 0;
  `}
`;

export const InnerFieldWrapper = styled(Inline)`
  ${flexColumn};
  width: 100%;
  ${({flexStart}) => flexStart && 'align-self: flex-start;'}
  ${media.minMobile`
    width: inherit;
  `}
`;

export const Label = styled.div`
  font-weight: bold;
`;

export const ModifiedTextArea = styled(Textarea)`
  height: 72px;
  border: ${({theme}) => `1px solid ${theme.userReport.sendDebitTransferRequestModal.input.borderColor}`};
  border-radius: 1px;
  background-color: ${({theme}) => theme.userReport.sendDebitTransferRequestModal.input.backgroundColor};
  padding: 5px;
  width: 100%;
  ${media.minMobile`
    width: 624px;
  `}
`;

export const AmountWrapper = styled.div`
  &::after {
    content: '₪';
    display: block;
    position: absolute;
    font-size: 15px;
    left: 5px;
    top: 8px;
    color: ${({theme}) => theme.userReport.sendDebitTransferRequestModal.input.paymentIconColor};
  }
`;

export const ModifiedInput = styled(Input)`
  border: ${({theme}) => `1px solid ${theme.userReport.sendDebitTransferRequestModal.input.borderColor}`};
  border-radius: 1px;
  color: ${({theme}) => theme.userReport.sendDebitTransferRequestModal.input.color};
  background-color: ${({theme}) => theme.userReport.sendDebitTransferRequestModal.input.backgroundColor};
  padding: 5px;
  height: 40px;
  width: 100%;
  direction: ltr;
  text-align: left;
  padding-left: 5px;
  ${media.minMobile`
    width: 304px;
  `}

  &[type='email'] {
    ${flipOnLTR`margin-left: 17px;`}
  }

  &[type='number'] {
    padding-left: 20px;
  }
`;

export const Link = styled(_Link)`
  color: ${({theme}) => theme.userReport.sendDebitTransferRequestModal.linkColor};
  text-decoration: underline;
  ${flipOnLTR`
    margin-right: 3px;
  `}
`;

export const ButtonsWrapper = styled.div`
  margin-top: 30px;
  ${flexSpace};
`;

export const Button = styled.button`
  width: 304px;
  height: 48px;
  border-radius: 1px 0 0 1px;
  background-color: ${({theme}) => theme.userReport.debitTransferRequestsReceivedModal.buttons.approve.background};
  color: ${({theme}) => theme.userReport.debitTransferRequestsReceivedModal.buttons.approve.text};
  ${({
    theme: {
      userReport: {
        debitTransferRequestsReceivedModal: {
          buttons: {
            cancel: {borderColor, text},
          },
        },
      },
    },
    cancel: isCancelButton,
  }) =>
    isCancelButton &&
    `
    border: 1px solid ${borderColor};
    background: transparent;
    color: ${text};
  `}
`;

export const WantToSplitLabel = styled.div`
  color: ${({theme}) => theme.userReport.sendDebitTransferRequestModal.wantToSplitLabelColor};
`;

export const FieldError = styled.div`
  color: ${({theme}) => theme.userReport.sendDebitTransferRequestModal.errorColor};
`;
