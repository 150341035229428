import {createStore, combineReducers, applyMiddleware, Store, AnyAction, Action} from 'redux';
import thunk from 'redux-thunk';

import apiService from '~/shared/services/apiService';
import ManagerProvider from '~/shared/managers/ManagerProvider';

import {composeReducers} from './redux-toolbelt';
import {persistStoreReducer, persistStoreMiddleware, mergeStorePersistData} from './persistStore';
import {reducers} from './storeModules';

type EnhancedStore<S = any, A extends Action = AnyAction> = Store<S, A> & {
  dispatchThunk: DispatchThunk;
};
type DispatchThunk = <A extends Action, T>(action: A) => Promise<T>;

export type AppState = {
  [Property in keyof typeof reducers]: ReturnType<typeof reducers[Property]>;
};
export type AppStore = EnhancedStore<AppState, AnyAction> & {observe: ReturnType<typeof createObserveStore>};

const thunkExtraArgument = {
  apiService,
  ManagerProvider,
};

export type ThunkExtraArgument = typeof thunkExtraArgument;

export default function configStore() {
  const bindMiddlewares = () => {
    const middlewares = [thunk.withExtraArgument(thunkExtraArgument), persistStoreMiddleware];
    if (process.env.NODE_ENV !== 'production' || process.env.ALLOW_SOURCE_MAPS_IN_PROD === 'true') {
      // Use this to enable redux trace (may impair performance)
      // const {composeWithDevTools} = require('redux-devtools-extension');
      // return composeWithDevTools({trace: true})(applyMiddleware(...middlewares));
      const {composeWithDevTools} = require('redux-devtools-extension/logOnly');
      return composeWithDevTools(applyMiddleware(...middlewares));
    }
    return applyMiddleware(...middlewares);
  };

  const mainReducer = composeReducers(combineReducers(reducers), persistStoreReducer);

  const store: AppStore = createStore(mainReducer, bindMiddlewares());

  Object.assign(thunkExtraArgument, {store});

  store.observe = createObserveStore(store);
  store.dispatchThunk = store.dispatch as DispatchThunk;

  mergeStorePersistData(store);

  return store;
}

function createObserveStore(store: AppStore) {
  return <S extends AppState[keyof AppState]>(
    {selector, runOnInitialState}: {selector: (state: AppState) => S; runOnInitialState?: boolean},
    onChange: (currState: S, prevState?: S) => void,
  ) => {
    let currentState = selector(store.getState());

    function handleChange() {
      const nextState = selector(store.getState());
      if (nextState === currentState) {
        return;
      }
      const prevState = currentState;
      currentState = nextState;
      onChange(currentState, prevState);
    }

    const unsubscribe = store.subscribe(handleChange);

    if (runOnInitialState) {
      onChange(currentState, undefined);
    }

    return unsubscribe;
  };
}
