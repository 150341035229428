import {useCallback} from 'react';

const useChat = (isScriptMounted?: boolean) => {
  const isAvailable = isScriptMounted && !!window.Intercom;
  
  const openChat = useCallback(() => {
    window.Intercom('show');
  }, []);

  return {isAvailable, openChat};
};

export default useChat;
