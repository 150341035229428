import {ShoppingCartDish} from '~/shared/store/models';
import {DishWithSub} from '~/shared/store/selectors';

export enum ACTION_MADE_FROM_ENUM {
  CHECKOUT = 'checkout',
  MENU = 'menu',
}

export const isDishAgeRestricted = (
  allRestaurantDishes: DishWithSub,
  currentShoppingCartDish: ShoppingCartDish,
): boolean => {

  const currentDish = allRestaurantDishes[currentShoppingCartDish.dishId];
  if (!currentDish) {
    return false;
  }

  if (currentDish.ageRestricted) {
    return true;
  }

  return currentShoppingCartDish.choices.some(choice => {
    return choice.subsChosen.some(({subId}) => {
      return currentDish.choices.some(currentDishChoice => {
        const sub = currentDishChoice.subs?.find(currentSub => currentSub.subId === subId);
        if (sub && sub.restrictionAge) {
          return true;
        }
        return false;
      });
    });
  });
};
