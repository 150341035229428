/* eslint-disable no-confusing-arrow */
import {memo, useEffect, useState} from 'react';

import styled from 'styled-components';
import {useDispatch} from 'react-redux';

import CloseIconComponent from '~/assets/images/icons/close-popup.svg?react';
import CloseIconMobileComponent from '~/assets/images/icons/closeMobileModal.svg?react';
import {attachModal} from '~/shared/components/ReduxModal';
import {getLocalizationService} from '~/shared/services/localisationService';
import actions from '~/shared/store/actions';
import {media} from '~/shared/theme/media';
import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import {handleRefreshToken} from '~/shared/services/auth';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {useIsMinDesktop} from '~/shared/hooks/deviceInfo';
import {loadTenbisCredit} from '~/shared/services/bffService/api';
import {trackEvent} from '~/shared/services/analytics';
import {TenBisCreditEventTypes} from '~/shared/services/analytics/analyticsModels';
import {ApiError, is401Error} from '~/shared/services/apiErrorService';
import {isAxiosResponse} from '~/shared/services/bffService/api/interfaces/responses';

import StatusMessage from './StatusMessage';
import {MessageStatuses} from './shared';
import ChargeForm from './ChargeForm';

const Root = styled(ClickOutsideDiv)`
  &&& {
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
  }

  display: flex;
  flex-direction: column;

  &:first-child {
    overflow-y: hidden;
    background-color: ${({theme}) => theme.colors.surface};
  }

  ${media.minMobile`
    &&& {
      max-height: unset;
      min-height: 380px;
      height: fit-content;
    }

    max-width: 483px;
    justify-content: space-between;
    border-radius: 2px;
    box-shadow: ${({theme}) => theme.shadows.shadow1};
  `}
`;

const Header = styled.div`
  padding: 16px;
  background-color: ${({theme}) => theme.colors.surface};
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  ${media.minMobile`
    border-bottom: 1px solid ${({theme}) => theme.colors.gray500};
    justify-content: space-between;
  `}
`;

const Title = styled.h3`
  color: ${({theme}) => theme.colors.text2};
  font-weight: 700;
  font-size: 14px;
  ${media.minMobile`
    font-size: 18px;
  `}
`;

const CloseIcon = styled(CloseIconComponent)`
  cursor: pointer;
  height: 16px;
  width: 16px;
  border-radius: 10px;
`;

const CloseIconMobile = styled(CloseIconMobileComponent)`
  position: absolute;
  cursor: pointer;
  ${flipOnLTR`
    left: 16px;
  `}
`;

type ITenbisChardModal = {
  args: {
    availableBalance: number;
    moneycardId: string;
    setOrderListData: () => Promise<void>;
  };
};

export const TenbisCreditChargeModal = memo(({args}: ITenbisChardModal) => {
  const {availableBalance, moneycardId, setOrderListData} = args;
  const [amount, setAmount] = useState<number | string>(availableBalance);
  const [validationError, setValidationError] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [status, setStatus] = useState<MessageStatuses>(MessageStatuses.initial);
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>();

  const dispatch = useDispatch();
  const isDesktop = useIsMinDesktop();

  const {t} = getLocalizationService();

  const handleValidation = (value: number | string) => {
    if (Number(value) === 0) {
      setDisabled(true);
      return;
    }

    if (Number(value) > Number(availableBalance)) {
      setValidationError(true);
      return;
    }

    setValidationError(false);
    setDisabled(false);
  };

  const handleInputChange = (value: string) => {
    // enforce only numbers, one decimal point and only 2 numbers after the decimal point and don't have 0 at the begining
    const newValue = value.replace(/[^0-9.]+|(\.\d{2})\d*|(\.)[\d.]*\.|^0+|^0(?=\.)/g, '$1$2').replace(/^(?!\d)/, '0');

    setAmount(newValue);
    handleValidation(newValue);
  };

  const handleCloseModal = (e: Event) => {
    e?.preventDefault();
    dispatch(actions.setCurrentModal(null));
  };

  const handleCreditAnalytics = (eventType: TenBisCreditEventTypes, errorType?: string) => {
    const eventData = {
      availableBalance: Number(availableBalance),
      transferBalance: Number(amount),
    };

    trackEvent('handleTenBisCredit', {
      type: eventType,
      eventData,
      errorType: errorType || apiErrorMessage,
    });

    if (eventType === TenBisCreditEventTypes.TRY_AGAIN) {
      setApiErrorMessage(null);
    }
  };

  const toInitialStatus = () => {
    setStatus(MessageStatuses.initial);
    handleCreditAnalytics(TenBisCreditEventTypes.EDIT);
  };

  const toVerifyStatus = () => {
    setStatus(MessageStatuses.verify);
    handleCreditAnalytics(TenBisCreditEventTypes.CONTINUE);
  };

  const handleFailedTransfer = (errorType?: string) => {
    setStatus(MessageStatuses.error);
    handleCreditAnalytics(TenBisCreditEventTypes.FAILED, errorType);
  };

  const handleSubmit = async (eventType: TenBisCreditEventTypes) => {
    const data = {
      amount,
      moneycardIdToCharge: moneycardId,
    };

    handleCreditAnalytics(eventType);

    try {
      setStatus(MessageStatuses.loading);
      const res = await loadTenbisCredit(data);
      if (!isAxiosResponse(res)) {
        if (!res) {
          handleFailedTransfer(t('load_remain_to_credit_failed'));
          return;
        }

        if (res?.code === 'INVALID_REQUEST') {
          setApiErrorMessage(res?.description);
          handleFailedTransfer(res?.description);
          return;
        }
      }

      await setOrderListData();

      setStatus(MessageStatuses.success);
      handleCreditAnalytics(TenBisCreditEventTypes.SUCCESS);
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, handleSubmit);
        return;
      }
      const err = error as ApiError;
      setApiErrorMessage(err.message);
      handleFailedTransfer(err.message);
 
      throw error;
    }
  };

  useEffect(() => {
    if (availableBalance === 0) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  }, [availableBalance]);

  return (
    <Root onClickOutside={handleCloseModal}>
      <Header>
        <Title id="modal-title">{t('load_balance_to_10bis')}</Title>
        {isDesktop ? (
          <CloseIcon alt={t('close')} onClick={handleCloseModal} />
        ) : (
          <CloseIconMobile alt={t('close')} onClick={handleCloseModal} />
        )}
      </Header>

      {status ? (
        <StatusMessage handleCloseModal={handleCloseModal} handleSubmit={handleSubmit} toInitialStatus={toInitialStatus} status={status} amount={amount} />
      ) : (
        <ChargeForm
          status={status}
          availableBalance={availableBalance}
          amount={amount}
          validationError={validationError}
          disabled={validationError || disabled}
          toVerifyStatus={toVerifyStatus}
          handleInputChange={handleInputChange}
        />
      )}
    </Root>
  );
});

attachModal('tenbisCreditChargeModal', TenbisCreditChargeModal);
