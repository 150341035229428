// eslint-disable-next-line no-restricted-imports
import citiesData from '~/static/jsons/cities.json';
import {SupportedLanguages, SupportedLanguageType} from '~/shared/consts/commonConsts';
import {getSlug} from '~/shared/utils/urls';

const cities: City[] = citiesData;
// cached list of cities keyed by X
const citiesByCityId = cities.reduce<Record<string, City>>(
  (allCities, city) => ({...allCities, [city.cityId]: city}),
  {},
);

const citiesBySlug = cities.reduce<Record<string, City>>((allCities, city) => {
  let cityNameKeysHoldingCity = {};

  SupportedLanguages.forEach(({key: languageKey}) => {
    cityNameKeysHoldingCity = {
      ...cityNameKeysHoldingCity,
      [getSlug(city.cityName[languageKey])]: city,
    };
  });

  return {
    ...allCities,
    ...cityNameKeysHoldingCity,
  };
}, {});

export const getCityByCityId = (incCityId: string) => citiesByCityId[incCityId];

export const getNearbyCitiesByCityId = (incCityId: string) => {
  const city = getCityByCityId(incCityId);

  if (!city) {
    return;
  }

  const {nearbyCities} = city;
  return nearbyCities;
};

export const getCityNameByCityId = (incCityId: string, wantedLanguage: SupportedLanguageType['key']) => {
  const city = getCityByCityId(incCityId);

  if (!city) {
    return;
  }

  const {cityName} = city;
  return cityName[wantedLanguage];
};

export const getCityByCityName = (incCityName: string) => citiesBySlug[getSlug(incCityName)];

export interface City {
  cityId: string;
  cityName: {
    [K in SupportedLanguageType['key']]: string;
  };
  nearbyCities: string[];
  addressKey: string;
}
