import {Context, GetContextFn} from './settings';

let contextGetter: GetContextFn | null = null;

function getContext(): Context | null {
  return contextGetter ? contextGetter() : null;
}

function setContextGetter(contextGetterFn: GetContextFn) {
  contextGetter = contextGetterFn;
}

export {getContext, setContextGetter};
