import styled from 'styled-components';

import {body16Normal, subheader24Bold} from '~/shared/theme/typography';

export const OrderCompleteHeader = styled.div<{showScooberTracker?: boolean}>`
  ${subheader24Bold}
  line-height: 32px;
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  ${({showScooberTracker}) => showScooberTracker && 'padding-top: 46px;'}
`;

export const OrderCompleteSubheader = styled.div`
  ${body16Normal}
  line-height: 32px;
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
`;
