import {Payment} from '~/shared/store/models';

export enum PaymentMethodTypes {
  CREDIT = 'Credit',
  CASH = 'Cash',
  MONEY_CARD = 'Moneycard',
  CREDIT_CARD = 'Creditcard',
  PAYPAL = 'PayPal',
  UNKNOWN = 'unknown',
}

export type PaymentMethod = EnumValueType<typeof PaymentMethodTypes>;

export type MoneycardAndCreditcard = {
  [PaymentMethodTypes.MONEY_CARD]: Payment<PaymentMethodTypes.MONEY_CARD> | null;
  [PaymentMethodTypes.CREDIT_CARD]: Payment<PaymentMethodTypes.CREDIT_CARD> | null;
};
