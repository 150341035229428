import {UiRestaurantsFilter} from '../store/storeModules/ui/uiReducer';

import {MINUTE} from './commonConsts';

const SCOOBER = 'scoober';

export const RESTRICTION_AGE = 18;
export const AGE_RESTRICTED_ANALYTICS = 'ageRestricted';
export const FUTURE_ORDER_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';

export enum RestaurantTagTypes {
  DISCOUNT_COUPON = 'DiscountCoupon',
  POOLED_ORDER_OVER_THE_MINIMUM = 'PoopedOrderOverTheMinimum',
  POOLED_ORDER_UNDER_THE_MINIMUM = 'PoopedOrderUnderTheMinimum',
  PROMOTED = 'Promoted',
  POPULAR = 'Popular',
  DELIVERY_TIME = 'DeliveryTime',
  DELIVERY_FEE = 'DeliveryFee',
  DELIVERY_FEE_WITH_DISCOUNT = 'DeliveryFeeWithDiscount',
  NO_DELIVERY_FEE = 'NoDeliveryFee',
  DISTANCE = 'Distance',
  ENVIRONMENT_FRIENDLY = 'EnvironmentFriendly',
  KOSHER = 'Kosher',
  NEW = 'New',
  RATING = 'Rating',
  MINIMUM_ORDER = 'MinOrder',
  ADDRESS = 'Address',
}
export type RestaurantTagType = EnumValueType<typeof RestaurantTagTypes>;

export enum RestaurantsFiltersSortOptionsTypes {
  SPECIAL = 'ten_bis_recommendation',
  SPECIALPICKUP = 'specialPickup',
  COUPONVALUE = 'couponValue',
  RANKING = 'ranking',
  MINIMUMORDER = 'minimumOrder',
  DELIVERYFEE = 'delivery_charge',
  DISTANCE = 'distance',
  DELIVERYTIME = 'delivery_time',
}
export type RestaurantsFiltersSortOptionsType = EnumValueType<typeof RestaurantsFiltersSortOptionsTypes>;

export enum RestaurantsGroupTypes {
  // TODO: - get rid of POOLED and NOT_POOLED values
  POOLED = 'pooled',
  NOT_POOLED_RESTAURANTS = 'notPooledRestaurants',
  OPEN = 'open',
  CLOSED = 'closed',
  GROUP = 'group',
  CLOSED_WITH_FUTURE_ORDER_STARTING_TOMORROW = 'closedWithFutureOrderStartingTomorrow',
  CHAIN = 'chain',
}
export type RestaurantsGroupType = EnumValueType<typeof RestaurantsGroupTypes>;

const RestaurantsFilterTypes = [
  {id: 'isVegan', type: 'veganFood', name: 'vegan', label: 'vegan', icon: 'vegan'},
  {id: 'isGlutenFree', type: 'glutenFree', name: 'gluten_free', label: 'gluten_free', icon: 'gluten_free'},
] as const;

export const RestaurantsFilterTypesMap = RestaurantsFilterTypes.reduce<RestaurantsFilterType>(
  (all, filterType) => ({
    ...all,
    [filterType.type]: filterType,
  }),
  {} as RestaurantsFilterType,
);
interface RestaurantsFilterType {
  id: keyof UiRestaurantsFilter;
  type: string;
  name: string;
  label: string;
  icon: string;
}

export const RestaurantsFilterTagsTypes: RestaurantsFilterTags = {
  Scoober: {
    id: 'isScoober',
    name: SCOOBER,
    label: 'scoober_filter_label',
  },
  FastDelivery: {
    id: 'freeDelivery',
    name: 'freeDelivery',
    label: 'free_delivery',
  },
  New: {
    id: 'newRestaurants',
    name: 'newRestaurants',
    label: 'New',
  },
  DiscountCoupon: {
    id: 'discountCoupon',
    name: 'discountCoupon',
    label: 'DiscountCoupon',
  },
  EnvironmentFriendly: {
    id: 'isEnvironmentFriendly',
    name: 'environmentFriendly',
    label: 'EnvironmentFriendly',
  },
  isKosher: {
    type: 'isKosher',
    id: 'isKosher',
    name: 'isKosher',
    label: 'kosher',
  },
  isNotKosher: {
    type: 'isNotKosher',
    id: 'isNotKosher',
    name: 'isNotKosher',
    label: 'not_kosher',
  },
};

type RestaurantsFilterTags = {
  [FilterId in RestaurantsFilterTagsTypesId as string]: {
    id: FilterId;
    name: string;
    label: string;
    type?: FilterId extends 'isKosher' | 'isNotKosher' ? FilterId : never;
  };
};
type RestaurantsFilterTagsTypesId =
  | 'isScoober'
  | 'freeDelivery'
  | 'newRestaurants'
  | 'discountCoupon'
  | 'isEnvironmentFriendly'
  | 'isKosher'
  | 'isNotKosher';

export const DefaultRestaurantsTagTypes = [
  'New',
  'DiscountCoupon',
  'EnvironmentFriendly',
  'isKosher',
  'isNotKosher',
] as const;

export enum DeliveryMethods {
  DELIVERY = 'delivery',
  PICKUP = 'pickup',
  SITTING = 'sitting',
  NOTRECOGNIZED = 'notrecognized',
}
export type DeliveryMethod = EnumValueType<typeof DeliveryMethods>;

export const DefaultRestaurantFilters: UiRestaurantsFilter = {
  cuisines: {},
  isKosher: false,
  isNotKosher: false,
  isVegan: false,
  isGlutenFree: false,
  fastDelivery: false,
  newRestaurants: false,
  discountCoupon: false,
  showStores: false,
};

export const CuisinesWithFoodPrefix = [
  'vegan',
  'italianpizzapasta',
  'asianfusion',
  'japanese',
  'organic',
  'glutenfree',
  'soulfood',
  'argentinean',
  'brazilian',
  'southamerican',
  'indian',
  'vietnamese',
  'greek',
  'mediterranean',
  'kosher',
  'badazKosher',
  'glatKosher',
  'latin',
  'malaysian',
  'orientalhumus',
  'mexican',
  'chinese',
  'sicilian',
  'spanish',
  'polish',
  'french',
  'cuban',
  'russian',
  'thai',
  'turkish',
] as const;

export const SearchTypes = {
  CATEGORY: 'category',
  DISH: 'dish',
  RESTAURANT: 'restaurant',
  CUISINE: 'cuisine',
};

export const MIN_REVIEWS_TO_DISPLAY_RATING = 30;
export const MIN_RANK_TO_SHOW = 0;
export const CATEGORIES_PATH = 'categories';
export const RESTAURANT_LIST_VALID_FOR = 5 * MINUTE;

export enum RestaurantsListContexts {
  MAIN = 'main',
  GROUP = 'group',
  CITY = 'city',
}

export enum DELIVERY_RULES {
  POOL = 'Pool',
  FUTURE = 'Future',
  ASAP = 'Asap',
}

export type RestaurantsListContext = EnumValueType<typeof RestaurantsListContexts>;

export const DiscountCompanyIds = [18087];

export const STORE_BY_BUSINESS_TYPE = 'STORE_BY_BUSINESS_TYPE';

export enum MiniFeedQueries {
  SHOW_STORES = 'showStores',
  COLLECTION_NAME = 'collectionName',
  CUISINE = 'cuisine'
}

export const MINI_FEED_QUERIES_LIST: MiniFeedQueries[] = [
  MiniFeedQueries.SHOW_STORES,
  MiniFeedQueries.COLLECTION_NAME,
  MiniFeedQueries.CUISINE,
];
