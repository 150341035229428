import styled, {css} from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {generatePriceLabel} from '~/shared/components/PriceLabels';
import {TipButtonValueTypes} from '~/shared/consts/checkoutConsts';
import {media} from '~/shared/theme/utils';
import RadioButton from '~/shared/components/RadioButton';
import {body14Normal} from '~/shared/theme/typography';
import {flexCenter} from '~/shared/theme/FlexLayout';
import {SkeletonLoader, Skeleton} from '~/shared/components/Loaders';

const activeButton = css`
  background-color: ${({theme}) => theme.colors.primary};
  color: ${({theme}) => theme.colors.primaryText};
  border: 0;
`;

const Wrapper = styled.div.attrs(({isActive}) => ({
  'data-test-id': 'addTipBtn',
  className: `${isActive ? 'white-bg-on-contrast-tip-button' : ''}`,
}))`
  cursor: pointer;
  ${body14Normal};
  ${flexCenter};
  border: 1px solid ${({theme}) => theme.colors.gray500};
  color: ${({theme}) => theme.colors.secondary};
  ${({isActive}) => isActive && activeButton};
  height: ${({theme}) => theme.checkout.elements.tipButton.height};
  width: 56px;
  ${media.minLargeTablet`
    width: 64px;
    height: 32px;
  `}
`;

const SkeletonButton = styled(Skeleton)`
  height: ${({theme}) => theme.checkout.elements.tipButton.height};
  width: 56px;
  ${media.minLargeTablet`
    width: 64px;
    height: 32px;
  `}
`;

const TipButton = ({value, valueType = TipButtonValueTypes.AMOUNT, isActive, onClick, isLoading, ...rest}) => {
  const {t} = getLocalizationService();
  const isPercent = valueType === TipButtonValueTypes.PERCENT;
  const label = generatePriceLabel({t, price: value, format: false, discount: isPercent});

  const handleFocus = () => {
    onClick(value, isPercent);
  };

  const handleOnChange = checked => {
    if (checked) {
      onClick(value, isPercent);
    }
  };

  const labelComponent = (
    <SkeletonLoader shouldShowLoader={isLoading} LoaderComponent={SkeletonButton}>
      <Wrapper {...rest} isActive={isActive} value={value} isChecked={isActive} onClick={handleFocus}>
        {label}
      </Wrapper>
    </SkeletonLoader>
  );

  return (
    <RadioButton
      {...rest}
      isActive={isActive}
      name="tips"
      id={`tips_${value}_${valueType}`}
      value={value}
      isChecked={isActive}
      onFocus={handleFocus}
      labelComponent={labelComponent}
      onChange={handleOnChange}
      display="block"
      inputProps={{
        'aria-label': label,
      }}
      hideInput
    />
  );
};

export default TipButton;
