import {Fragment, memo, useMemo, useState} from 'react';

import {useField} from 'react-final-form';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import Button from '~/shared/components/Button';
import Textarea from '~/shared/components/Textarea';
import {useIsMaxTablet, useIsMinTablet} from '~/shared/hooks/deviceInfo';
import {getLocalizationService} from '~/shared/services/localisationService';
import {
  selectDishesAdditionalDetails,
  selectIsCurrentRestaurantOfficeSupplies,
  selectCurrentRestaurant,
  selectIsGroceriesStore,
  selectCurrentCoupon,
} from '~/shared/store/selectors';
import {FlexColumn, FlexStart} from '~/shared/theme/FlexLayout';
import {media} from '~/shared/theme/media';
import {RestaurantBusinessTypeName} from '~/shared/store/models';
import {getDishPriceWithDiscount} from '~/shared/utils/priceDiscountCalculator';

import useTotalPrice from '../../../hooks/useTotalPrice';

import ActionButton from './ActionButton';

const Root = styled.div`
  padding: 10px 0 0;
  ${media.minTablet`
  	padding: 15px 20px;
	`}
`;

const Disclaimer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 0 20px;
  margin-bottom: 10px;
  align-items: center;
  ${media.minTablet`
    padding: 0 4px;
	`};
`;

const LinkButton = styled(Button)<{shouldShowDisclaimer?: boolean; isEditMode?: boolean}>`
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  text-decoration: underline;
  ${({shouldShowDisclaimer}) => !shouldShowDisclaimer && `
    margin-bottom: 16px;
	`}
  padding: 0 16px;
  ${media.minTablet`
		padding:0;
	`};
`;

const Content = styled(FlexColumn)<{shouldShowDisclaimer?: boolean}>`
  ${media.minTablet`
		flex-direction:row;
		justify-content: flex-end;
	`}

  ${({shouldShowDisclaimer}) => shouldShowDisclaimer && `
    button {
      flex: 1; 
    }
  `}
`;

const TextAreaWrapper = styled.div<{isEditMode: boolean}>`
  display: flex;
  padding: 8px 20px;
  ${media.minTablet`
    flex: 1;
    padding: 0;
    ${({isEditMode}) => isEditMode && `
      margin-bottom: 8px;
    `}
  `}
`;

const Notes = styled(Textarea)`
  resize: vertical;
  flex: 1;
`;

type DishFooterProps = {
  enableComment: boolean;
  isEditMode: boolean;
  onAddToCart: () => void;
  onRemoveDish: () => void;
};

const DishFooter = ({enableComment, isEditMode, onAddToCart, onRemoveDish}: DishFooterProps) => {
  const {t} = getLocalizationService();
  const dishesInOrder = useSelector(selectDishesAdditionalDetails);
  const isGroceriesStore = useSelector(selectIsGroceriesStore);
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const isOfficeSupplies = useSelector(selectIsCurrentRestaurantOfficeSupplies);
  const isMinTablet = useIsMinTablet();
  const currentCoupon = useSelector(selectCurrentCoupon);

  const {
    input: {value: notes, onChange: onChangeNotes},
  } = useField('dishNotes');

  const totalPrice = useTotalPrice();

  const discountsData = useMemo(() => getDishPriceWithDiscount(totalPrice, currentCoupon),
    [totalPrice, currentCoupon],
  );

  const [commentExpanded, setCommentExpanded] = useState(!!notes);

  const linkText = useMemo(() => {
    if (commentExpanded) {
      if (isOfficeSupplies) {
        return t('order_comment');
      }
      if (isGroceriesStore || currentRestaurant?.isVoucherEnabled) {
        return t('add_notes_to_the_item');
      }
      return t('dish_comment');
    }
    if (isOfficeSupplies) {
      return t('add_notes_to_the_order');
    }
    if (isGroceriesStore || currentRestaurant?.isVoucherEnabled) {
      return t('add_notes_to_the_item');
    }
    return t('add_notes_to_the_dish');
  }, [commentExpanded, currentRestaurant?.isVoucherEnabled, isGroceriesStore, isOfficeSupplies, t]);

  const showRemoveDishButton = dishesInOrder;
  const isVoucherStore = currentRestaurant?.businessType === RestaurantBusinessTypeName.VoucherCard;
  const isMaxTablet = useIsMaxTablet();
  const ActionsContainer = isMaxTablet ? FlexStart : Fragment;

  const shouldShowDisclaimer = enableComment && commentExpanded;

  const disclaimerBox = (
    <Disclaimer>
      {t('item_note_disclaimer', {restaurantType: (isGroceriesStore || isOfficeSupplies || currentRestaurant?.isVoucherEnabled) ? t('store') : t('restaurant')}).toLocaleLowerCase()}
    </Disclaimer>
  );
  const actionButtonText = useMemo(() => {
    if (isEditMode) {
      return t('save_changes');
    }
    if (isOfficeSupplies || isGroceriesStore || currentRestaurant?.isVoucherEnabled || isVoucherStore) {
      return t('add_item');
    }
    return t('add_dish');
  }, [isVoucherStore, currentRestaurant?.isVoucherEnabled, isEditMode, isGroceriesStore, isOfficeSupplies, t]);

  return (
    <Root>
      {enableComment && (
        <>
          <LinkButton
            isEditMode={isEditMode}
            shouldShowDisclaimer={shouldShowDisclaimer}
            testId="addDishRemarksBtn"
            onClick={() => {
            if (commentExpanded) {
              onChangeNotes(null);
            }
            setCommentExpanded(prev => !prev);
          }}
          >
            {linkText}
          </LinkButton>
          {shouldShowDisclaimer && disclaimerBox}
        </>
      )}

      {commentExpanded && isEditMode && (
        <>
          <TextAreaWrapper
            isEditMode={isEditMode}
          >
            <Notes
              value={notes}
              onChange={onChangeNotes}
              autoFocus
              aria-label={
                  (isOfficeSupplies || isGroceriesStore)
                    ? t('here_you_can_write_special_comment_for_the_item')
                    : t('here_you_can_write_special_comment_for_the_dish')
                }
            />
          </TextAreaWrapper>
        </>
      )}

      <Content shouldShowDisclaimer={shouldShowDisclaimer}>
        {commentExpanded && !isEditMode && (
          <TextAreaWrapper
            isEditMode={isEditMode}
          >
            <Notes
              value={notes}
              onChange={onChangeNotes}
              autoFocus
              aria-label={
              (isOfficeSupplies || isGroceriesStore)
                ? t('here_you_can_write_special_comment_for_the_item')
                : t('here_you_can_write_special_comment_for_the_dish')
            }
            />

          </TextAreaWrapper>
        )}
        <ActionsContainer>
          {isEditMode && showRemoveDishButton && (
            <ActionButton
              secondary
              text={(isOfficeSupplies || isGroceriesStore || isVoucherStore) ? t('remove_item') : t('remove_dish')}
              onClick={onRemoveDish}
            />
          )}

          <ActionButton
            data-test-id="submitDishBtn"
            text={actionButtonText}
            priceText={totalPrice}
            showPrice={isMinTablet || !isEditMode}
            onClick={onAddToCart}
            discountsData={discountsData}
          />
        </ActionsContainer>
      </Content>

    </Root>
  );
};

export default memo(DishFooter);
