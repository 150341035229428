// Todo: Rewrite as ts

import {createLogger} from '~/shared/logging';

import createInstance from './http.interceptors';

const logger = createLogger('http');

const axiosInstance = createInstance();

export async function makeRequest({url, method, headers, params, timeout = 30000, convertParams = true}) {
  try {
    const response = await axiosInstance.request({
      url,
      method,
      [method === 'post' ? 'data' : 'params']: params,
      timeout,
      headers,
      convertParams,
    });

    return response.data;
  } catch (error) {
    logger.error('request error', {
      url,
      method,
      params,
      message: error?.message,
      status: error?.status,
      data: error?.data,
    });
    return Promise.reject(error);
  }
}
