import moment, {Moment} from 'moment-timezone';

import {
  // @ts-expect-error config needs to be rewritten as ts
  restaurantsMaxMorningPoolCloseTimeHours,
  // @ts-expect-error config needs to be rewritten as ts
  restaurantsMaxMorningPoolCloseTimeMinutes,
  // @ts-expect-error config needs to be rewritten as ts
  restaurantsEstimatedPoolDeliveryTimeMarginsEnd,
  // @ts-expect-error config needs to be rewritten as ts
  restaurantsEstimatedPoolDeliveryTimeMarginsStart,
} from '~/shared/config';

export const getPooledEstimationTimes = (
  poolDateTimeDelivery: Moment,
): {
  estimatedPoolDeliveryTimeStart: Moment;
  estimatedPoolDeliveryTimeEnd: Moment;
  estimatedPoolDeliveryTime: string;
} => {
  const estimatedPoolDeliveryTimeStart = poolDateTimeDelivery
    .clone()
    .add(restaurantsEstimatedPoolDeliveryTimeMarginsStart, 'm');

  const estimatedPoolDeliveryTimeEnd = poolDateTimeDelivery
    .clone()
    .add(restaurantsEstimatedPoolDeliveryTimeMarginsEnd, 'm');

  const estimatedPoolDeliveryTime = `${estimatedPoolDeliveryTimeStart.format(
    'HH:mm',
  )}-${estimatedPoolDeliveryTimeEnd.format('HH:mm')}`;

  return {estimatedPoolDeliveryTimeStart, estimatedPoolDeliveryTimeEnd, estimatedPoolDeliveryTime};
};

export const getPooledGroupTimesInfo = (
  poolTimeStart: TimeSpan,
  poolTimeEnd: TimeSpan,
  poolTimeDelivery: TimeSpan,
  nowTime = moment(),
) => {
  const poolDateTimeEnd = nowTime
    .clone()
    .hour(poolTimeEnd.hours)
    .minutes(poolTimeEnd.minutes)
    .seconds(poolTimeEnd.seconds);

  const poolDateTimeStart = nowTime
    .clone()
    .hour(poolTimeStart.hours)
    .minutes(poolTimeStart.minutes)
    .seconds(poolTimeStart.seconds);

  const poolDateTimeDelivery = nowTime
    .clone()
    .hour(poolTimeDelivery.hours)
    .minutes(poolTimeDelivery.minutes)
    .seconds(poolTimeDelivery.seconds);

  const maxMorningPoolCloseTime = nowTime
    .clone()
    .hour(restaurantsMaxMorningPoolCloseTimeHours)
    .minutes(restaurantsMaxMorningPoolCloseTimeMinutes)
    .seconds(0);

  const beforePoolEnd = poolDateTimeEnd.isAfter(nowTime);
  const afterPoolStart = poolDateTimeStart.isBefore(nowTime);
  const isMorningPoolOrder = poolDateTimeDelivery.isSameOrBefore(maxMorningPoolCloseTime);

  const {estimatedPoolDeliveryTime, estimatedPoolDeliveryTimeStart, estimatedPoolDeliveryTimeEnd} =
    getPooledEstimationTimes(poolDateTimeDelivery);

  const poolEndTimeString = poolDateTimeEnd.format('HH:mm');

  return {
    beforePoolEnd,
    afterPoolStart,
    isMorningPoolOrder,
    poolDateTimeEnd,
    poolDateTimeStart,
    poolDateTimeDelivery,
    maxMorningPoolCloseTime,
    estimatedPoolDeliveryTimeStart,
    estimatedPoolDeliveryTimeEnd,
    estimatedPoolDeliveryTime,
    poolEndTimeString,
  };
};
