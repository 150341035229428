export const A11Y_LOCALSTORAGE_KEY_NAME = 'enabled_a11y_features';

export const PERSISTENT_STORE_LOCAL_STORAGE_KEY = 'persistStore_V_1.4';

export enum LocalStorageKeys {
  HAS_SEEN_REVIEW_TRANSACTION_ID = 'hasSeenReviewTransactionId',
  USER_SAVED_SEARCHES = 'userSavedSearches_V_2.0',
  APPLICATION_LANGUAGE = 'applicationLanguage',
  MONEYCARD_ACTIVATION_SNOOZE_TIME = 'MoneycardActivationSnoozeTime',
  PENDING_LINK_PAYMENT_REQUEST_IDS = 'pendingMoneycardRequestIds',
  USER_SESSION_TOKEN = 'stkn',
  DISMISSED_ACTIVE_ORDERS = 'dismissedActiveOrders',
  EXPERIMENTS_UUID = 'experimentsUUID',
  GUEST_FLOW_COUPON_MODAL_EXP_DATE = 'guestFlowCouponModalExpDate',
  LAST_RESTAURANT_CATEGORIES_OFFSETS = 'lastRestaurantCatagoriesOffsets',
  PROMOTION_BANNER_EXP_DATE = 'promotionBannerExpDate',
  LAST_OTL_REDIRECT_TIMESTAMP = 'lastOtlRedirectTimestamp',
}
