import {useCallback, useEffect} from 'react';

import {useSelector} from 'react-redux';

import {attachModal} from '~/shared/components/ReduxModal';
import {Dish} from '~/dish';
import {selectCategoriesListLoading} from '~/shared/store/selectors';
import {removeQueries, useRouteQuery} from '~/shared/router';

import Loader from './Loader';

interface IDishModalProps {
  closeModal: () => void;
  args: {
    dishId: number;
    shoppingCartDishId: number;
    onClose: () => void;
  };
}

const DishModal = ({closeModal, args: {dishId, shoppingCartDishId, onClose}}: IDishModalProps) => {
  const dishIdStringToNumber = dishId && Number(dishId);
  const shoppingCartDishIdStringToNumber = shoppingCartDishId && Number(shoppingCartDishId);
  const isCategoriesLoading = useSelector(selectCategoriesListLoading);
  const query = useRouteQuery();

  useEffect(() => {
    return () => {
      removeQueries(['dishId']);
    };
  }, []);

  // This hook supports modal close on browser back button click
  useEffect(() => {
    if (query && !query.dishId) {
      closeModal();
    }
  }, [query, closeModal]);

  const handleCloseModal = useCallback(() => {
    closeModal();
    if (onClose) {
      onClose();
    }
  }, [closeModal, onClose]);

  if (isCategoriesLoading) {
    return <Loader />;
  }

  return (
    <Dish
      dishId={dishIdStringToNumber}
      shoppingCartDishId={shoppingCartDishIdStringToNumber}
      onDone={handleCloseModal}
    />
  );
};

DishModal.modalType = 'autoWidth';
export default attachModal('dish_modal', DishModal);
