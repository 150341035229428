import {goBack} from '~/shared/router';
import {navigateToDefaultStartPage} from '~/shared/services/navigation';
import history from '~/shared/router/browserHistory';

import {DeleteAccountWarning} from '.';

const DeleteAccountPage = () => {
  const handleOnCancel = () => {
    if (!history.location.state) {
      navigateToDefaultStartPage();
      return;
    }
    goBack();
  };
  return <DeleteAccountWarning isFullSize onCancel={handleOnCancel} />;
};
export default DeleteAccountPage;
