import {isEmpty} from 'lodash';

import {DeliveryMethod, DeliveryMethods, RestaurantTagTypes} from '~/shared/consts/restaurantConsts';
import {RestaurantBusinessTypeName, RestaurantFromSearch} from '~/shared/store/models';

import {selectFilters} from '../index';

const filterRestaurantArr = (
  restaurants: RestaurantFromSearch[],
  {
    isKosher,
    isNotKosher,
    isVegan,
    isGlutenFree,
    newRestaurants,
    freeDelivery,
    discountCoupon,
    isEnvironmentFriendly,
    isScoober,
    cuisines,
    showStores,
  }: ReturnType<typeof selectFilters>,
  deliveryMethod: DeliveryMethod,
  isComanyAddressAndRestaurantWithoutVoucher?: boolean,
) => restaurants.filter(
  item => (isKosher ? item.isKosher : isNotKosher ? !item.isKosher : true) &&
      (!isVegan || item.isVegan) &&
      (!isEnvironmentFriendly || item.isEnvironmentFriendly) &&
      (!isScoober || item.isScoober || deliveryMethod === DeliveryMethods.PICKUP) &&
      (!isGlutenFree || item.isGlutenFree) &&
      (!freeDelivery || item.deliveryFee === 0 || (isComanyAddressAndRestaurantWithoutVoucher && item.hasActivePoolRule)) &&
      (!newRestaurants || item.tags.some(({type}) => type === RestaurantTagTypes.NEW)) &&
      (!discountCoupon || item.tags.some(({type}) => type === RestaurantTagTypes.DISCOUNT_COUPON)) &&
      (!cuisines ||
        isEmpty(cuisines) ||
        item.restaurantCuisineKeysList.some(type => cuisines[type.toLowerCase()] || cuisines[type])
      ) &&
      (!showStores || item.businessTypeName === RestaurantBusinessTypeName.GroceryStore),
);

export default filterRestaurantArr;
