import {memo, useCallback, useState} from 'react';

import styled from 'styled-components';
import {useField} from 'react-final-form';

import Checkbox from '~/shared/components/Checkbox';
import RadioButton from '~/shared/components/RadioButton';
import {Fieldset} from '~/shared/components/styled';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flexCenterVertically, flexColumn} from '~/shared/theme/FlexLayout';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {body14Bold, body16Bold} from '~/shared/theme/typography';
import AgeRestrictionBadge from '~/shared/components/AgeRestrictionBadge';
import {DishChoiceSub} from '~/shared/store/models/Dish/Dish';

import {DishType} from '../../modals/DishType';

const StyledRadioButton = styled(RadioButton)`
  & span {
    line-height: 15px;
    ${flipOnLTR`
      right: 0;
      margin-right: 4px;
    `};
  }

  &::before {
    top: 0;
  }

  &::after {
    top: 4px;
  }
`;

const StyledAgeRestrictionBadge = styled(AgeRestrictionBadge)`
  align-self: center;
  margin-top: 0;
  justify-content: flex-start;
  ${flipOnLTR`
    margin-left: 16px;
  `}
  ${media.minTablet`
    margin-top: 1px;
    align-self: flex-start;
  `}
`;

const Root = styled.div`
  ${flexColumn};
  flex: 1;
  padding: 0 20px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: initial;
  ${media.minTablet`
    min-height: 150px;
    max-height: 400px;
    justify-content: space-between;
  `};
`;

const Section = styled(Fieldset)`
  border: none;
  border-top: 1px solid ${({theme}) => theme.colors.darkBackground};
  flex-shrink: 0;
  ${flipOnLTR`
    text-align: right;
  `}
  ${media.minTablet`
    padding: 0;
    border: 0;
    margin-bottom: 20px;
  `}
`;

const Title = styled.legend`
  ${body14Bold};
  padding: 10px 0;
  color: ${({theme}) => theme.menu.dishModal.textColor};
  width: 100%;
  ${media.minTablet`
    padding-bottom: 0;
  `}
`;

const Separator = styled.hr`
  display: none;
  ${media.minTablet`
    display: block;
    width: 100%;
    ${body16Bold};
    border: none;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 0;
    padding-top: 0;
  `};
`;

const OptionsContainer = styled.div`
  ${flexColumn};
  ${media.minTablet`
    flex-direction: row;
    flex-wrap: wrap;
  `};
`;

const CounterLabel = styled.span<{bold?: boolean}>`
  color: ${({theme}) => theme.colors.gray200};
  font-weight: ${({bold}) => (bold ? 700 : 400)};
`;

const ComponentContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${({theme}) => theme.colors.darkBackground};
  display: flex;
  padding: 5px 0;
  min-height: 40px;
  ${flexCenterVertically};
  color: ${({theme}) => theme.menu.dishModal.textColor};

  label {
    padding: 0;
  }

  ${media.minTablet`
    width: calc(100% * (1/3));
    margin-bottom: 10px;
    border: 0;
    padding: 0;
    min-height: auto;
    align-items: initial;
    ${flipOnLTR`
      padding-left: 5px;
    `} 
  `};
`;

const PickingComponent = ({
  choiceId,
  subId,
  name,
  price,
  isForced,
  maxPicksAllowed,
  ageRestricted,
  onSelectedCountChange,
}: {
  choiceId: number;
  subId: number;
  name: string;
  price: number;
  isForced: boolean;
  maxPicksAllowed: number;
  ageRestricted: boolean;
  onSelectedCountChange: (newCount: number) => void;
}) => {
  const {t} = getLocalizationService();

  const label = `${name} ${price ? `(+${t('nis')}${price})` : ''}`;
  const Component = isForced ? StyledRadioButton : Checkbox;

  const {
    input: {value: subsValues, onChange: onChangeSubs},
  } = useField(`selectedChoices.choice-${choiceId}`);

  const isChecked = subsValues[`sub-${subId}`] || false;

  const onSubPick = useCallback(() => {
    if (isForced) {
      onChangeSubs({[`sub-${subId}`]: true});
    } else {
      const newSubs = {...subsValues, [`sub-${subId}`]: !subsValues[`sub-${subId}`]};
      const newSubsLength = Object.values(newSubs).filter(Boolean).length;
      if (newSubsLength <= maxPicksAllowed) {
        onChangeSubs(newSubs);
        onSelectedCountChange(newSubsLength);
      }
    }
  }, [isForced, maxPicksAllowed, onChangeSubs, subId, subsValues, onSelectedCountChange]);

  return (
    <ComponentContainer>
      <Component
        data-test-id={isForced ? 'dishSubRadio' : 'dishSubCheckbox'}
        label={label}
        id={`${choiceId}-${subId}`}
        value={isChecked}
        onValueChange={onSubPick}
        onChange={onSubPick}
        isChecked={isChecked}
        alignItems="flex-start"
        name={isForced ? `radio-group-${choiceId}` : ''}
        labelHorizontalMargin={4}
      >
        {label}
      </Component>
      {ageRestricted && <StyledAgeRestrictionBadge />}
    </ComponentContainer>
  );
};

const PickingOption = ({
  choiceId,
  subs,
  isForced,
  maxPicksAllowed,
  textBeforeChoosing,
  selectedChoices,
}: {
  choiceId: number;
  subs: DishChoiceSub[];
  textBeforeChoosing: string;
  isForced: boolean;
  maxPicksAllowed: number;
  selectedChoices: DishType['selectedChoices'][number] | null;
}) => {
  const [selectedCount, setSelectedCount] = useState<number>(selectedChoices?.subsChosen.length || 0);
  const {t} = getLocalizationService();

  // The func should be wrapped in useCallback because it is used in another useCallback in child component
  const onSelectedCountChange = useCallback((newCount: number) => {
    setSelectedCount(newCount);
  }, []);

  return (
    <Section key={choiceId}>
      <Title data-test-id="dishSubsGroupTileLabel">{textBeforeChoosing}</Title>
      {!isForced && subs.length > maxPicksAllowed && (
        <div>
          <CounterLabel>{t('choose_up_to', {count: maxPicksAllowed})}</CounterLabel> -{' '}
          <CounterLabel bold>
            {t('options_chosen', {
              maxOptions: maxPicksAllowed,
              optionsChosen: selectedCount,
            })}
          </CounterLabel>
        </div>
      )}
      <Separator />

      <OptionsContainer>
        {subs.map(({subId, name, price, ageRestricted}) => (
          <PickingComponent
            key={`${choiceId}-${subId}`}
            choiceId={choiceId}
            subId={subId}
            name={name}
            price={price}
            isForced={isForced}
            maxPicksAllowed={maxPicksAllowed}
            ageRestricted={ageRestricted}
            onSelectedCountChange={onSelectedCountChange}
          />
        ))}
      </OptionsContainer>
    </Section>
  );
};

const PickingOptions = ({choices, selectedChoices}: {choices: DishType['choices']; selectedChoices: DishType['selectedChoices']}) => (
  <Root>
    {choices?.map(({choiceId, textBeforeChoosing, subs, isForced, maxPicksAllowed}) => (
      <PickingOption
        choiceId={choiceId}
        subs={subs}
        textBeforeChoosing={textBeforeChoosing}
        isForced={isForced}
        maxPicksAllowed={maxPicksAllowed}
        // TODO: - refactor selectedChoices to a hashmap
        selectedChoices={selectedChoices.find(({choiceId: selectedChoiceId}) => selectedChoiceId === choiceId) || null}
        key={`picking_option_for_choice_${choiceId}`}
      />
    ))}
  </Root>
);

export default memo(PickingOptions);
