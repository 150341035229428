import apiService from '~/shared/services/apiService';
import {makeThunkAsyncActionCreator} from '~/shared/store/redux-toolbelt';
// @ts-expect-error config needs to be rewritten as ts
import {enableCityDescriptionFetchFromCdn} from '~/shared/config';
import {SeoCityDescriptionFromCdnState} from '~/shared/store/storeModules/seoCityDescriptionFromCdn/seoCityDescriptionFromCdnReducer';
import {createLogger} from '~/shared/logging';

const logger = createLogger('seoCityDescriptionFromCdnActions');

export const fetchSeoCityDescriptionFromCdn = makeThunkAsyncActionCreator<
  {cityId: string; cuisineId: string},
  SeoCityDescriptionFromCdnState['data']
>('fetchSeoCityDescriptionFromCdn', async ({cityId, cuisineId}) => {
  if (!enableCityDescriptionFetchFromCdn) {
    throw new Error('fetch from cdn flag is not enabled');
  } else if (!cityId) {
    throw new Error('missing city id');
  }

  let result;
  try {
    result = await apiService.fetchSeoCityDescriptionFromCdn({cityId, cuisineId});
  } catch (error) {
    const errorType = 'unable to fetch seo city description';
    logger.error(errorType, {error, fingerprint: errorType});
  }
  return result;
});
