export const EMPTY_ARRAY: [] = [];
export const EMPTY_OBJECT: Record<string, never> = {};

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = MINUTE * 60;

export const ENTER_KEY_CODE = 13;
export const ENTER_CODE = 'Enter';
export const TAB_KEY_CODE = 9;
export const ESC_KEY_CODE = 27;

export const USER_TOKEN_COOKIE_NAME = 'Authorization';
export const USER_TOKEN_REFRESH_COOKIE_NAME = 'RefreshToken';

// Don't change these for reasons of backwards compitability with the older site.
export const CONTEXT_COOKIE_NAME = 'WebApplication.Context';
export const LOCAL_COOKIE_NAME = 'WebApplication.Locale';
export const LAST_LAT_LNG_COOKIE_NAME = 'lastlatlng';

export const NEXT_CONTEXT_COOKIE_NAME = 'nextContext';

// Don't change these for reasons of backwards compitability with the older site.
export enum ContextCookieParamNames {
  shoppingCartGuid = 'ShoppingCartGuid',
}

export enum ContextLocaleParamNames {
  culture = 'Culture',
  languageKey = 'Language',
}

export enum NextContextCookieParamNames {
  shoppingCartGuidTimestamp = 'ShoppingCartGuidTimestamp',
}

export const RouteNameDictionary = {
  cityPage: {
    en: 'deliveries',
    he: encodeURIComponent('משלוחים'),
  },
};

export const DEFAULT_LANGUAGE_KEY = 'he';
export const DEFAULT_CULTURE = 'he-IL';

export const SupportedLanguages = [
  {key: 'he', icon: 'he', name: 'עברית', culture: 'he-IL'},
  {key: 'en', icon: 'en', name: 'English', culture: 'en-US'},
] as const;

export type SupportedLanguageType = typeof SupportedLanguages[number];

export const DefaultLanguage = SupportedLanguages[0];

export const TENBIS_PLUS = {
  FORM: 'https://comp.10bis.co.il/res-form',
  LANDING_PAGE: 'https://comp.10bis.co.il/newrestaurants',
  CORPORATES: 'https://comp.10bis.co.il/corporates-10bisplus',
};
