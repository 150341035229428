import {memo} from 'react';

import styled from 'styled-components';
import {noop} from 'lodash';

import Button from '~/shared/components/Button';
import {SDFOBoxMwProps} from '~/shared/components/ShoppingCart/components/ShoppingCartHeader/SameDayFutureOrder';
import {flipOnLTR} from '~/shared/theme/utils';
import Arrow from '~/assets/images/icons/drop_down_arrow.svg?react';
import {flexCenter} from '~/shared/theme/FlexLayout';

const Root = styled.div`
  display: flex;
  width: 100%;
  padding: 0 12px 16px 12px;
`;

const SDFOSelectedTime = styled(Button)<{isActive?: boolean}>`
  display: flex;
  color: ${({theme}) => theme.colors.text2};
  padding: 4px 12px;
  width: 100%;
  height: 36px;
  border-radius: 4px;
  ${({isActive, theme}) => (isActive ? `border: solid 1px ${theme.colors.surfaceTitle};` : `border: solid 1px ${theme.colors.gray800};`)}
`;

const ArrowWrapper = styled.div`
  ${flexCenter}
  height: 100%;
  ${flipOnLTR`
    margin-right: auto;
  `}
`;

const SDFOSelectedTimeShevron = styled(Arrow)<{$isActive?: boolean}>`
  transition: transform 0.25s linear;
  transform: rotate(${({$isActive}) => ($isActive ? 180 : 0)}deg);
`;

const SDFOBoxMw: React.FC<SDFOBoxMwProps> = ({children, isActive, disabled, onClick}) => (
  <Root>
    <SDFOSelectedTime isActive={isActive} onClick={disabled ? noop : onClick} className="toggle_sdfo_dropdown">
      {children}
      <ArrowWrapper>
        <SDFOSelectedTimeShevron $isActive={isActive}/>
      </ArrowWrapper>
    </SDFOSelectedTime>
  </Root>
);

export default memo(SDFOBoxMw);
