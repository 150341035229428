import {composeReducers, makeReducer} from '../../redux-toolbelt';

import {addErrorsToDialog, clearErrorsDialog} from './errorDialogDataActions';

type ErrorDialogDataState = DialogError[];

export default composeReducers<ErrorDialogDataState>(
  makeReducer<ErrorDialogDataState>(clearErrorsDialog, () => undefined, {defaultState: []}),
  makeReducer<ErrorDialogDataState, ReturnType<typeof addErrorsToDialog>['payload']>(
    addErrorsToDialog,
    (currentState, {payload}) => {
      if (!payload) return currentState;
      const {errorOrMessage, args} = payload;
      return [...currentState, {errorOrMessage, args}];
    },
    {defaultState: []},
  ),
);

export interface DialogError {
  errorOrMessage: string;
  args: Record<string, any>[];
}
