import styled from 'styled-components';

import {Title as HeaderTitle, HeaderWrapper, CloseButtonSVGIcon} from '~/shared/components/styled';
import {getLocalizationService} from '~/shared/services/localisationService';
import {CloseButton} from '~/shared/components/Modals';
import {body18Bold} from '~/shared/theme/typography';

const ModalHeaderWrapper = styled(HeaderWrapper)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`;

const CheckoutTitle = styled(HeaderTitle)`
  ${body18Bold};
`;

const CheckoutCloseButton = styled(CloseButton)`
  top: 12px;
`;

const ModalHeader = ({titleKey, onClick}) => {
  const {t} = getLocalizationService();

  return (
    <ModalHeaderWrapper>
      <CheckoutTitle id="modal-title" data-id={titleKey}>
        {t(titleKey)}
      </CheckoutTitle>
      <CheckoutCloseButton onClick={onClick} aria-label={t('close')}>
        <CloseButtonSVGIcon />
      </CheckoutCloseButton>
    </ModalHeaderWrapper>
  );
};

export default ModalHeader;
