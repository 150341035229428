import {createSelector} from 'reselect';
import {isEmpty} from 'lodash';
import moment from 'moment-timezone';

import {RestaurantBusinessTypeName} from '~/shared/store/models';
import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';
import {restaurantHasFutureRule} from '~/shared/utils/restaurants/tags/restaurantGlobalUtils';
import {
  getMutatedFutureOrderAvailableDatesAndTimes,
} from '~/shared/utils/restaurants/getMutatedFutureOrderAvailableDatesAndTimes';

import {AppState} from '../../configStore';

const selectRoot = (state: AppState) => state.currentRestaurant;

export const selectFetchedCurrentRestaurant = createSelector(
  selectRoot,
  currentRestaurant => currentRestaurant.remotelyFetched,
);
const selectFetchedCurrentRestaurantData = createSelector(
  selectFetchedCurrentRestaurant,
  fetchedCurrentRestaurant => fetchedCurrentRestaurant.data,
);

export const selectCurrentRestaurantIsLoading = createSelector(
  selectFetchedCurrentRestaurant,
  fetchedCurrentRestaurant => fetchedCurrentRestaurant.loading,
);

export const selectCurrentRestaurantIsLoaded = createSelector(
  selectFetchedCurrentRestaurant,
  fetchedCurrentRestaurant => fetchedCurrentRestaurant.loaded,
);

// restaurantFromSearch holds data from search that was converted to be RestaurantFromGet
// see setRestaurantFromSearch action in currentRestaurantActions.ts
const selectConvertedRestaurantFromSearch = createSelector(selectRoot, state => state.restaurantFromSearch);

/**
 * Checks if the id of the stored RestaurantFromGet is equal to the chosen RestaurantFromSearch is.
 * Returns the RestaurantFromGet if ids are equal
 * Returns the converted RestaurantFromSearch if not
 * Returns undefined if there is no data stored on the restaurant
 */
export const selectCurrentRestaurant = createSelector(
  selectFetchedCurrentRestaurantData,
  selectConvertedRestaurantFromSearch,
  (restaurantFromGet, convertedRestaurantFromSearch) => {
    if (!isEmpty(convertedRestaurantFromSearch) && convertedRestaurantFromSearch?.id !== restaurantFromGet?.id) {
      return convertedRestaurantFromSearch;
    }

    return restaurantFromGet;
  },
);

export const selectIsRestaurantFromGetReady = createSelector(
  selectCurrentRestaurantIsLoading,
  selectCurrentRestaurantIsLoaded,
  selectFetchedCurrentRestaurant,
  selectConvertedRestaurantFromSearch,
  (isLoading, isLoaded, fetchedRestaurant, convertedRestaurant) => {
    const isFetchCompleted = isLoaded && !isLoading;
    // If a user tries to load the app on the restaurant page, there will be nothing in convertedRestaurant
    // should return only the isLoaded state
    if (!convertedRestaurant) {
      return isFetchCompleted;
    }

    // In other cases the ids of the converted restaurant and fetched restaurant should also match
    return isFetchCompleted && fetchedRestaurant.data?.id === convertedRestaurant?.id;
  },
);

export const selectCurrentRestaurantId = createSelector(selectRoot, currentRestaurant => currentRestaurant.id);

export const selectCurrentRestaurantAbout = createSelector(
  selectFetchedCurrentRestaurant,
  fetchedCurrentRestaurant => fetchedCurrentRestaurant.about.data,
);
export const selectCurrentRestaurantFeatureList = createSelector(
  selectCurrentRestaurantAbout,
  currentRestaurantAbout => currentRestaurantAbout?.featuresList,
);

const selectCurrentRestaurantReviewsBase = createSelector(
  selectFetchedCurrentRestaurant,
  fetchedCurrentRestaurant => fetchedCurrentRestaurant.reviews,
);
export const selectCurrentRestaurantReviews = createSelector(
  selectCurrentRestaurantReviewsBase,
  currentRestaurantsReviews => currentRestaurantsReviews.data?.reviewsList,
);
export const selectCurrentRestaurantReviewsLoading = createSelector(
  selectCurrentRestaurantReviewsBase,
  currentRestaurantsReviews => currentRestaurantsReviews.loading,
);

export const selectCurrentRestaurantCoupons = createSelector(
  selectFetchedCurrentRestaurant,
  fetchedCurrentRestaurant => fetchedCurrentRestaurant.coupons.data,
);

export const selectCurrentRestaurantCouponsLoaded = createSelector(
  selectFetchedCurrentRestaurant,
  fetchedCurrentRestaurant => fetchedCurrentRestaurant.coupons.loaded,
);

export const selectCurrentRestaurantCouponsLoading = createSelector(
  selectFetchedCurrentRestaurant,
  fetchedCurrentRestaurant => fetchedCurrentRestaurant.coupons.loading,
);

export const selectCurrentRestaurantHasCoupons = createSelector(
  selectFetchedCurrentRestaurantData,
  fetchedCurrentRestaurantData =>
    fetchedCurrentRestaurantData?.discountCouponPercent && fetchedCurrentRestaurantData.discountCouponPercent > 0,
);

export const selectCurrentRestaurantHasTipEnabled = createSelector(
  selectFetchedCurrentRestaurantData,
  fetchedCurrentRestaurantData => fetchedCurrentRestaurantData?.isTipEnabled,
);

export const selectCurrentRestaurantTotalRank = createSelector(
  selectFetchedCurrentRestaurantData,
  fetchedCurrentRestaurantData => fetchedCurrentRestaurantData?.rank?.totalRank,
);

export const selectCurrentRestaurantReviewsNum = createSelector(
  selectFetchedCurrentRestaurantData,
  fetchedCurrentRestaurantData => fetchedCurrentRestaurantData?.rank?.numOfReviews,
);

export const selectIsCurrentRestaurantOfficeSupplies = createSelector(
  selectFetchedCurrentRestaurantData,
  fetchedRestaurantData => fetchedRestaurantData?.businessType === RestaurantBusinessTypeName.OfficeSupplies,
);

export const selectIsFutureOrderEnabled = createSelector(
  selectFetchedCurrentRestaurantData,
  fetchedRestaurantData => restaurantHasFutureRule(fetchedRestaurantData),
);

export const selectLastFetchedInfo = createSelector(selectRoot, root => root.lastFetchInfo);

export const selectFutureDeliveryDefaultTime = createSelector(selectFetchedCurrentRestaurantData, fetchedRestaurant => fetchedRestaurant?.futureDeliveryDefaultTime);

export const selectFutureOrderAvailableDatesAndTimes = createSelector(
  selectFetchedCurrentRestaurantData,
  fetchedRestaurant => getMutatedFutureOrderAvailableDatesAndTimes(fetchedRestaurant),
);

export const selectHasTodayFutureOption = createSelector(selectFutureOrderAvailableDatesAndTimes, availableFutureDatesAndTimes => availableFutureDatesAndTimes && availableFutureDatesAndTimes?.length > 0 && availableFutureDatesAndTimes[0]?.date === moment().format('DD/MM/YYYY'));

export const selectIsCurrentRestaurantPool = createSelector(selectFetchedCurrentRestaurantData, restaurantData =>
  Boolean(
    restaurantData?.deliveryRules?.find(delivery => delivery.type === DELIVERY_RULES.POOL) || restaurantData?.poolOrder,
  ),
);

export const selectIsCurrentRestaurantWithActivePool = createSelector(selectFetchedCurrentRestaurantData, restaurantData =>
  restaurantData?.deliveryRules?.some(delivery => delivery.type === DELIVERY_RULES.POOL && delivery.isActiveNow),
);

export const selectIsGroceriesStore = createSelector(
  selectFetchedCurrentRestaurantData,
  fetchedRestaurantData => fetchedRestaurantData?.businessType === RestaurantBusinessTypeName.GroceryStore,
);

export const selectCurrentRestaurantShoppingCartDeliveryType = createSelector(
  selectRoot,
  currentRestaurant => currentRestaurant.shoppingCartDeliveryType,
);
