import {useRef, useEffect} from 'react';

import moment from 'moment-timezone';

const useOnInactivity = (inactiveMs: number, cb: () => void) => {
  const leaveTabTime = useRef<moment.Moment>();

  useEffect(() => {
    const onVisibilityChange = () => {
      const landedOnTab = document.visibilityState === 'visible';
      const leftTab = !landedOnTab;
      if (leftTab) {
        leaveTabTime.current = moment();
      } else if (landedOnTab) {
        const shouldCb = leaveTabTime.current && moment().diff(leaveTabTime.current, 'milliseconds') > inactiveMs;
        if (shouldCb) {
          cb();
        }
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [inactiveMs, cb]);
};

export default useOnInactivity;
