import styled from 'styled-components';

import _AgeRestrictionDisclaimer from '~/shared/components/AgeRestrictionDisclaimer';
import BillingLinesDetails from '~/shared/components/OrderCompleteSections/BillingLinesDetails';
import LoyaltyProgram from '~/shared/components/OrderCompleteSections/LoyaltyProgram';
import {Section} from '~/shared/components/OrderCompleteSections/parts';
import PaymentsDetails from '~/shared/components/OrderCompleteSections/PaymentsDetails';
import {
  paddingCss,
  MOBILE_HORIZONTAL_PADDING,
  DESKTOP_HORIZONTAL_PADDING,
} from '~/shared/components/OrderCompleteSections/orderSummaryStyle';
import {getLocalizationService} from '~/shared/services/localisationService';
import {OrderSuccessData} from '~/shared/store/models';
import {body18Bold} from '~/shared/theme/typography';
import {media} from '~/shared/theme/utils';
import BarcodeOrderIdArea from '~/shared/components/OrderCompleteSections/BarcodeOrderIdArea';

const PaymentsBlockWrap = styled(Section)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${MOBILE_HORIZONTAL_PADDING}px;
  ${paddingCss};
  ${({showAgeRestriction}: {showAgeRestriction?: boolean}) =>
    showAgeRestriction &&
    `
      padding-bottom: ${DESKTOP_HORIZONTAL_PADDING}px;
  `}
`;

const DetailsHeader = styled.div`
  ${body18Bold};
  padding-bottom: 8px;
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
`;

const AgeRestrictionDisclaimer = styled(_AgeRestrictionDisclaimer)`
  position: absolute;
  top: 4px;
  height: 44px;
  left: -${MOBILE_HORIZONTAL_PADDING}px;
  right: -${MOBILE_HORIZONTAL_PADDING}px;
  ${media.minLargeTablet`
    left: -${DESKTOP_HORIZONTAL_PADDING}px;
    right: -${DESKTOP_HORIZONTAL_PADDING}px;
  `}
`;

const OrderPaymentsDetails = styled(PaymentsDetails)`
  ${paddingCss};
`;

type OrderSummaryPaymentsProps = {
  billingLines: any[];
  showAgeRestriction?: boolean;
  points: number;
  payments: OrderSuccessData['orderPayments'];
  barcodeOrderId?: number;
  isVoucherCard?: boolean;
  isBenefitsHighlightEnabled?: boolean;
};

const OrderSummaryPayments = ({
  billingLines,
  showAgeRestriction,
  points,
  payments,
  barcodeOrderId,
  isVoucherCard,
  isBenefitsHighlightEnabled,
}: OrderSummaryPaymentsProps) => {
  const {t} = getLocalizationService();

  return (
    <>
      {!isVoucherCard && (
        // @ts-expect-error TODO: Section -> needs to be rewritten with ts
        <PaymentsBlockWrap showAgeRestriction={showAgeRestriction}>
          <DetailsHeader>{t('order_summary')}</DetailsHeader>
          <BillingLinesDetails billingLines={billingLines} isBarCodeOrder={false} isBenefitsHighlightEnabled={isBenefitsHighlightEnabled} />
          {showAgeRestriction && <AgeRestrictionDisclaimer />}
          <LoyaltyProgram points={points} />
        </PaymentsBlockWrap>
      )}
      {barcodeOrderId && <BarcodeOrderIdArea barcodeOrderId={String(barcodeOrderId)} />}
      <OrderPaymentsDetails payments={payments} />
    </>
  );
};

export default OrderSummaryPayments;
