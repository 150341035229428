import {isEmpty, isString, isBoolean} from 'lodash';

export const requiredValidator = (input: string) => {
  const inputToCheck = input && isString(input) ? input.trim() : input;
  if (isEmpty(inputToCheck) && !isBoolean(inputToCheck)) {
    return 'required_field';
  }
  return undefined;
};

export const passwordValidator = (password: string) => {
  const isPasswordLengthValid = password && password.length >= 8 && password.length <= 25;
  const isContainsAtLeastOneDigit = /(?=.*\d)/.test(password);
  const isContainsAtLeasetOneLowerCaseChar = /(?=.*[a-z])/.test(password);
  const isContainsAtLeasetOneUpperCaseChar = /(?=.*[A-Z])/.test(password);

  if (
    !isContainsAtLeastOneDigit ||
    !isContainsAtLeasetOneLowerCaseChar ||
    !isContainsAtLeasetOneUpperCaseChar ||
    !isPasswordLengthValid
  ) {
    return 'the_password_must_be_8_characters_long_and_contain_capital_letters_and_numbers';
  }
  return undefined;
};

export const confirmPasswordValidator = ({password, confirmPassword}: {password: string; confirmPassword: string}) => {
  if (password !== confirmPassword) {
    return 'password_confirm_mismatch';
  }
  return undefined;
};

export const textInputValidator = () => {
  return undefined;
};

export const phoneNumberValidator = (phoneNumber: string) => {
  if (isEmpty(phoneNumber)) {
    return undefined;
  }
  if (phoneNumber.length < 9) {
    return 'minimum_phone_length';
  }

  const isValidNumber = /^(\d+-?)+\d+$/;
  if (!isValidNumber.test(phoneNumber)) {
    return 'digits_only';
  }

  return undefined;
};

export const emailValidator = (email: string) => {
  if (!email) return undefined;

  const isValidEmail =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  if (!isValidEmail.test(email.toLowerCase())) {
    return 'invalid_email';
  }

  return undefined;
};

export const composeValidators =
  (...validators: any[]) =>
    (value: any) =>
      validators.filter(validator => validator).reduce((error, validator) => error || validator(value), undefined);

export const validators = {
  requiredValidator,
  textInputValidator,
  passwordValidator,
  phoneNumberValidator,
  emailValidator,
  confirmPasswordValidator,
};
