import {useCallback, useState} from 'react';

const useStateFromNodeRef = (transform: (node: HTMLElement | null) => HTMLElement | null) => {
  const [node, setNode] = useState<HTMLElement | null>(null);
  const setRef = useCallback<(node: HTMLElement | null) => void>(newNode => {
    setNode(transform(newNode));
  }, [transform]);
  return [node, setRef];
};

export default useStateFromNodeRef;
