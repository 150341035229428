import {memo} from 'react';

import {useSelector} from 'react-redux';

import CouponBarCodeComponentView, {IBarCodeCouponComponent} from '~/shared/components/CouponBarCodeComponent';
import {useIsMinLargeTablet} from '~/shared/hooks/deviceInfo';
import {User} from '~/shared/store/models';
import {selectUserData} from '~/shared/store/selectors';

const CouponBarCodeComponent = ({
  restaurantName,
  barCode,
}: Omit<IBarCodeCouponComponent, 'userData' | 'minLargeTablet'>) => {
  const minLargeTablet = useIsMinLargeTablet();
  const userData: (User & {moneycardActivationRequired: boolean}) | null = useSelector(selectUserData);
  if (!userData) {
    return null;
  }

  return (
    <CouponBarCodeComponentView
      userData={userData}
      restaurantName={restaurantName}
      barCode={barCode}
      minLargeTablet={minLargeTablet}
    />
  );
};

export default memo(CouponBarCodeComponent);
