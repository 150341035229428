import {actions as modulesActions, restrictedSharedActions} from '../storeModules';

import * as globalActions from './globalActions';

export * from './globalActions';
export * from '../storeModules/actions.index';

export {restrictedSharedActions};

export default {
  ...globalActions,
  ...modulesActions,
};
