import {useEffect} from 'react';

import {getFocusableElements} from '~/shared/utils/general';

type UseAutoFocusArgs = {
  domEl?: HTMLElement;
  searchForFocusableChildren?: boolean;
  triggerCondition?: boolean;
};

const useAutoFocus = ({domEl, searchForFocusableChildren = true, triggerCondition = true}: UseAutoFocusArgs) => {
  useEffect(() => {
    if (!domEl || !triggerCondition) {
      return;
    }

    const cancelSearchFocusableElements = getFocusableElements({domEl, searchForFocusableChildren}, focusableElements => {
      focusableElements[0].focus();
    });

    return () => {
      cancelSearchFocusableElements();
    };
  }, [domEl, triggerCondition, searchForFocusableChildren]);
};

export default useAutoFocus;
