import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {media, flipOnLTR} from '~/shared/theme/utils';
import {Link, pushRoute} from '~/shared/router';
import {getCityPageUrl, replaceLegacyUrlToken} from '~/shared/services/urlService';
import {getCityByCityId} from '~/shared/services/citiesHelpers';
import {capitalFirstLetterAfterSpace} from '~/shared/utils/general';
import {getLocalizationService} from '~/shared/services/localisationService';
import useChat from '~/shared/hooks/useChat';
import {body12Normal, body13Normal} from '~/shared/theme/typography';
import {selectUserData} from '~/shared/store/selectors';
import instagramFooter from '~/assets/images/icons/instagram-footer.svg';
import facebookFooter from '~/assets/images/icons/facebook-footer.svg';
import {useIsMaxTablet} from '~/shared/hooks/deviceInfo';
import {trackEvent} from '~/shared/services/analytics';
import store from '~/shared/store';
import {setCurrentModal} from '~/shared/store/actions';
import ImageWithAlt from '~/shared/components/ImageWithAlt';

const Text = styled.div`
  color: ${({theme}) => theme.footer.color.linkColor};
  ${body12Normal};
  line-height: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${({theme, linkColorOnHover}) => linkColorOnHover || theme.colors.secondaryText};
  }
`;

const ExternalLink = styled(Link)`
  display: ${({sideBySide}) => (sideBySide ? 'inline-block' : 'block')};
  color: ${({theme}) => theme.colors.text};
  ${body13Normal};
  cursor: pointer;
  line-height: 18px;

  &:hover {
    text-decoration: underline;
    color: ${({theme}) => theme.colors.secondaryText};
  }
`;

const CityLink = styled(ExternalLink)`
  width: 50%;
  ${media.minLargeTablet`
    width: inherit;
  `}
`;

const IconsWrapper = styled.div`
  margin-top: 5px;
  height: 16px;
`;

const MAP_ICONS = {
  'instagram-footer': instagramFooter,
  'facebook-footer': facebookFooter,
};

const IconComponent = styled(ImageWithAlt)`
  width: 16px;
  height: 16px;
  ${flipOnLTR`
    margin-left: 8px; 
  `}
`;

const handleClick = (action, openChat, isMobileDevice) => {
  switch (action) {
    case 'openChat':
      openChat();
      break;
    case 'deleteAccount':
      trackEvent('hasClickedDeleteAccount', {identificationProcess: 0});

      if (isMobileDevice) {
        pushRoute('/delete-account');
        return;
      }
      store.dispatch(
        setCurrentModal('remove_account_modal', {
          hideCloseButton: true,
          onCancel: closeModal => closeModal(),
        }),
      );
      break;
    default:
      break;
  }
};

const FooterLink = ({className, showFor, href, icons, target, text, cityId, action, linkColorOnHover}) => {
  const {openChat} = useChat();
  const {t, currentLanguageKey} = getLocalizationService();
  const isMobileDevice = useIsMaxTablet();

  const user = useSelector(selectUserData);

  if (showFor && !(user && user[showFor])) {
    return null;
  }

  if (href) {
    const link = replaceLegacyUrlToken(href);

    return (
      <ExternalLink className={className} target={target || '_blank'} href={link} hard>
        <Text linkColorOnHover={linkColorOnHover}>{t(text)}</Text>
      </ExternalLink>
    );
  }

  if (icons) {
    return (
      <IconsWrapper className={className}>
        {icons.map(({iconName, href: iconHref, ariaLabel, alt}) => (
          <ExternalLink
            sideBySide
            target={target || '_blank'}
            key={iconHref}
            href={iconHref}
            aria-label={ariaLabel}
            hard
          >
            {MAP_ICONS[iconName] && <IconComponent src={MAP_ICONS[iconName]} alt={alt} />}
          </ExternalLink>
        ))}
      </IconsWrapper>
    );
  }

  if (cityId) {
    const city = getCityByCityId(cityId);
    const currentLanguageCityPageLink = getCityPageUrl({
      city,
      languageKey: currentLanguageKey,
    });
    const cityNameInCurrentLanguage = city.cityName[currentLanguageKey];

    return (
      <CityLink className={className} target={target || '_blank'} href={currentLanguageCityPageLink} hard>
        <Text linkColorOnHover={linkColorOnHover}>{capitalFirstLetterAfterSpace(cityNameInCurrentLanguage)}</Text>
      </CityLink>
    );
  }

  if (text === 'delete_account' && (!user || user?.isCompanyUser)) {
    return null;
  }

  return (
    <Text
      className={className}
      onClick={() => handleClick(action, openChat, isMobileDevice)}
      linkColorOnHover={linkColorOnHover}
    >
      {t(text)}
    </Text>
  );
};

export default FooterLink;
