import {memo} from 'react';

import Recaptcha from 'react-google-recaptcha';
import styled from 'styled-components';

import {recaptchaKey} from '~/shared/config';

import {useIsMinDesktop} from '../hooks/deviceInfo';

const Root = styled.div`
  margin-right: auto;
  margin-left: auto;
`;

const RecaptchaField = ({onChange}) => {
  const isMinDesktop = useIsMinDesktop();
  return (
    <Root>
      <Recaptcha
        sitekey={recaptchaKey}
        render="explicit"
        onChange={onChange}
        size={isMinDesktop ? 'normal' : 'compact'}
        hl="iw"
      />
    </Root>
  );
};

export default memo(RecaptchaField);
