import {useSelector} from 'react-redux';

import {
  selectCategoriesListLoading,
  selectCurrentRestaurant,
  selectDishesAdditionalDetails,
} from '~/shared/store/selectors';

const useIsShoppingCartLoading = () => {
  const dishes = useSelector(selectDishesAdditionalDetails);
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const isMenuLoading = useSelector(selectCategoriesListLoading);

  return !currentRestaurant || !dishes || isMenuLoading;
};

export default useIsShoppingCartLoading;
