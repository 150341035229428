import {memo, useCallback, useEffect} from 'react';

import styled from 'styled-components';
import {useDispatch} from 'react-redux';

import CloseIconComponent from '~/assets/images/icons/closeMark.svg?react';
import {attachModal} from '~/shared/components/ReduxModal';
import {getLocalizationService} from '~/shared/services/localisationService';
import actions from '~/shared/store/actions';
import {media} from '~/shared/theme/media';
import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import {ICarouselInfoButton} from '~/shared/components/CarouselInfoButton/CarouselInfoButtonView';
import {getPooledRestaurantsTimeData} from '~/shared/store/selectors/selectorUtils/carouselGroups';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {CloseButton} from '~/shared/components/Modals';

const Root = styled(ClickOutsideDiv)`
  &&& {
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
  }

  padding: 16px 16px 24px 16px;
  display: flex;
  flex-direction: column;

  &:first-child {
    background-color: ${({theme}) => theme.colors.surface};
  }

  ${media.minLargeTablet`
    &&& {
      max-height: 80vh;
      min-height: 380px;
      height: fit-content;
    }

    max-width: 483px;
    justify-content: space-between;
    border-radius: 2px;
    box-shadow: ${({theme}) => theme.shadows.shadow1};
  `}
`;

const Header = styled.div`
  padding: 16px 0 32px;
  background-color: ${({theme}) => theme.colors.surface};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: center;

  svg {
    ${({theme}) => `
      fill: ${theme.colors.text2};
    `}
  }
`;

const Title = styled.h3`
  color: ${({theme}) => theme.colors.text2};
  font-weight: 700;
  font-size: 22px;
  font-style: normal;
  text-align: center;
  width: 100%;
`;

const CloseIconButtonWrapper = styled(CloseButton)`
  margin-bottom: 16px;
  background-color: ${({theme}) => theme.colors.surface};
  height: 32px;
  width: 32px;
  position: unset;
`;

const CloseIcon = styled(CloseIconComponent)``;

const ContentGroup = styled.div<{last?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  ${({last}) => last && `
    margin-bottom: 32px;
  `}

  img {
    height: 120px;
    width: 120px;
    margin-bottom: 12px;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const InfoText = styled.div`
  width: 170px;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  color: ${({theme}) => theme.colors.text2};
`;

const FooterText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: 20px;
  color: ${({theme}) => theme.colors.text2};
`;

type IPreorderInfoModal = {
  args: ICarouselInfoButton;
};

export const PreorderInfoModal = memo((props: IPreorderInfoModal) => {
  const {
    imageCelebration,
    imageGroup,
    imageMinimum,
  } = props.args;

  const {t} = getLocalizationService();
  const dispatch = useDispatch();
  const pooledRestaurantsTimeData = getPooledRestaurantsTimeData();

  const handleCloseModal = useCallback(() => {
    dispatch(actions.setCurrentModal(null));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('resize', handleCloseModal);
    
    return () => {
      window.removeEventListener('resize', handleCloseModal);
    };
  }, [handleCloseModal]);

  return (
    <Root onClickOutside={() => {}}>
      <Header>
        <CloseIconButtonWrapper alt={t('close')} onClick={handleCloseModal}>
          <CloseIcon className="avoid-fill" />
        </CloseIconButtonWrapper>
        <Title id="modal-title">
          {pooledRestaurantsTimeData?.isMorningPoolOrder ?
            t('thinking_about_meal_lunch') :
            t('thinking_about_meal_dinner')}
        </Title>
      </Header>

      <Options>
        <ContentGroup>
          <ImageWithAlt src={imageGroup} noAlt />
          <InfoText>
            {t('ordering_till_xxx', {time: pooledRestaurantsTimeData?.poolEndTimeString})}
          </InfoText>
        </ContentGroup>

        <ContentGroup>
          <ImageWithAlt src={imageMinimum} noAlt />
          <InfoText />
          <InfoText>
            {t('redering_more_then_minimum')}
          </InfoText>
        </ContentGroup>

        <ContentGroup last>
          <ImageWithAlt src={imageCelebration} noAlt />
          <InfoText>
            {t('orders_will_come_together_in_xxxx', {timeRange: pooledRestaurantsTimeData?.estimatedPoolDeliveryTime})}
          </InfoText>
        </ContentGroup>
      </Options>

      <FooterText>
        {t('if_order_not_meet_minimum')}
      </FooterText>
    </Root>
  );
});

attachModal('preorderInfoModal', PreorderInfoModal);
