import {useState, useEffect, memo} from 'react';

import styled from 'styled-components';
import {useDispatch} from 'react-redux';

import {upsertRestaurantReview} from '~/shared/store/actions';
import {getLocalizationService} from '~/shared/services/localisationService';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {attachModal} from '~/shared/components/ReduxModal';
import Button from '~/shared/components/Button';
import DiagonalHeaderView from '~/shared/components/DiagonalHeaderView';
import {LocalStorageKeys} from '~/shared/consts/localStorageConsts';
import StarsRating from '~/shared/components/StarsRating';
import {getLegacySiteUrl} from '~/shared/services/urlService';
import {body13Normal, body14Normal, body15Bold, body18Bold} from '~/shared/theme/typography';
import {PopupModalBase, ModalContent} from '~/shared/components/Modals';
import {useIsMaxMobile} from '~/shared/hooks/deviceInfo';
import {handleRefreshToken} from '~/shared/services/auth';

const Root = styled(PopupModalBase)`
  &&& {
    overflow: auto;
  }
`;

const Title = styled.div`
  color: ${({theme}) => theme.colors.secondary};
  ${body15Bold};
  line-height: 18px;
  ${media.minTablet`
    ${body18Bold};
  `}
  ${({marginTop}) => marginTop && `margin-top: ${marginTop}px`};
`;
const RatingContainer = styled.div`
  margin: 25px auto 25px auto;
  direction: ltr;
  ${media.minTablet`
    width: 45%;
    margin: 28px auto 36px auto;
  `}
`;
const CommentContainer = styled.div`
  width: 100%;
  max-width: 420px;
  margin: auto;
`;
const Label = styled.div`
  color: ${({theme}) => theme.colors.secondary};
  ${body15Bold};
  text-align: center;
  ${({theme: {isLTR}}) => !isLTR && 'direction: rtl;'}
`;
const CommentInput = styled.textarea`
  margin: 3px 0 0 0;
  height: 65px;
  width: 100%;
  border: 1px solid ${({theme}) => theme.colors.gray300};
  background-color: ${({theme}) => theme.colors.surface};
  ${body14Normal};
  line-height: 18px;
  padding: 5px;
  ${flipOnLTR`
    text-align: right;
  `}
  font-family: inherit;

  ::placeholder {
    color: ${({theme}) => theme.colors.gray500};
  }
`;
const SubmitReview = styled(Button)`
  &&& {
    width: 100%;
    max-width: 420px;
  }

  ${body13Normal};
  height: 50px;
  background-color: ${({theme}) => theme.colors.secondary};
  color: ${({theme}) => theme.colors.secondaryText};
  border: none;
  margin: 0 auto 34px auto;
`;

const AddRestaurantReviewModal = ({
  args: {backgroundColor, restaurant: restaurantFromGet, transactionId, transactionType, review, onSubmit, isEditDisabled},
  closeModal,
}) => {
  const {t} = getLocalizationService();

  const isMaxMobile = useIsMaxMobile();

  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rating, setRating] = useState(review?.reviewRank || 0);
  const [comment, setComment] = useState(review?.reviewText || '');

  useEffect(() => {
    const hasSeenReviewTransactionId = window.localStorage.getItem(LocalStorageKeys.HAS_SEEN_REVIEW_TRANSACTION_ID);

    if (hasSeenReviewTransactionId !== transactionId) {
      window.localStorage.setItem(LocalStorageKeys.HAS_SEEN_REVIEW_TRANSACTION_ID, transactionId);
    }
  }, [transactionId]);

  const circleImageUrlAndAlt = {
    imageUrl: restaurantFromGet?.logoUrl,
    alt: restaurantFromGet?.name,
  };

  const backgroundImage = restaurantFromGet?.profileImageUrl
    ? restaurantFromGet.profileImageUrl.includes('http')
      ? restaurantFromGet.profileImageUrl
      : getLegacySiteUrl(restaurantFromGet.profileImageUrl)
    : null;

  const onCommentChange = inputEvent => setComment(inputEvent.target.value);

  const onSubmitRating = async () => {
    const oldReviewObj = review;
    setIsSubmitting(true);

    const newReview = {
      ranking: rating,
      restaurantId: restaurantFromGet.id,
      transactionId,
      transactionType: transactionType || 'web',
      comments: comment,
      ...((oldReviewObj?.reviewId && {reviewId: oldReviewObj.reviewId}) || {}),
    };
    try {
      const apiData = await dispatch(upsertRestaurantReview(newReview));

      const {data: resReviewId = null} = (typeof apiData !== 'undefined' && apiData) || {};
      setIsSubmitting(false);

      if (onSubmit) {
        onSubmit({
          resReviewId,
          reviewText: comment,
          ranking: rating,
        });
      }
    } catch (e) {
      handleRefreshToken(e, onSubmitRating);
    } finally {
      closeModal();
    }
  };

  return (
    <Root>
      <DiagonalHeaderView {...{backgroundColor, backgroundImage, circleImageProps: circleImageUrlAndAlt}}>
        <ModalContent>
          <Title id="modal-title">{`${t('how_was_your_order')}`}</Title>
          <Title marginTop={8}>{restaurantFromGet?.name}?</Title>

          <RatingContainer>
            <StarsRating {...{t, rating, onChange: setRating, isMaxMobile, isEditDisabled}} />
          </RatingContainer>

          <CommentContainer>
            {!isEditDisabled && <Label id="modal-content">{`${t('feel_like_adding_a_review')}`}</Label>}
            <CommentInput
              disabled={isEditDisabled}
              value={comment}
              onChange={onCommentChange}
              placeholder={`${t('i_wanted_to_say_that')}...`}
            />
          </CommentContainer>

          <SubmitReview disabled={rating === 0 || isSubmitting || isEditDisabled} onClick={onSubmitRating}>
            {t('send_feedback')}
          </SubmitReview>
        </ModalContent>
      </DiagonalHeaderView>
    </Root>
  );
};

AddRestaurantReviewModal.isErupt = true;

attachModal('addRestaurantReview', memo(AddRestaurantReviewModal));
