import styled, {css} from 'styled-components';

export const flexCenterHorizontally = css`
  display: flex;
  justify-content: center;
`;

export const FlexCenterHorizontally = styled.div`
  ${flexCenterHorizontally};
`;
