import styled, {css} from 'styled-components';

export const flexSpace = css`
  display: flex;
  justify-content: space-between;
`;

export const FlexSpace = styled.div`
  ${flexSpace}
`;
