import {TenBisAddressLocationFromServer} from '~/shared/store/models/Address/TenBisAddressLocation';

export default class TenBisAddressError extends Error {
  responseAddress?: ResponseAddress;

  constructor(message?: string, responseAddress?: ResponseAddress) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.responseAddress = responseAddress;
  }
}

type ResponseAddress = Omit<TenBisAddressLocationFromServer, 'latitude' | 'longitude'>;
