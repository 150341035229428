import styled from 'styled-components';
import {useSelector} from 'react-redux';

import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {flipOnLTR, rotateOnLTR} from '~/shared/theme/flipOnLTR';
import {body16Bold} from '~/shared/theme/typography';
import backButtonUrl from '~/assets/images/icons/white-basic-arrow-left.svg';
import {getLocalizationService} from '~/shared/services/localisationService';
import {selectCurrentRestaurant} from '~/shared/store/selectors';

import {PageHeaderPrimaryBar as PrimaryBar, PageHeaderSecondaryBar as SecondaryBar} from './styled.pageHeader';

const Row = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
`;

const RestaurantLogo = styled(ImageWithAlt)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  ${flipOnLTR`
    margin-left: 15px;
  `};
`;

const RestaurantDetails = styled.div`
  ${flexColumn};
  flex: 1;
  overflow: hidden;
  width: 0;
`;

const RestaurantName = styled.h1`
  ${body16Bold};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const RestaurantCuisines = styled.div`
  margin-top: -5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BackButton = styled(ImageWithAlt).attrs({
  src: backButtonUrl,
})`
  width: 24px;
  height: 24px;
  cursor: pointer;
  ${rotateOnLTR}
`;

const MenuPageHeaderForMobileAndTablet = ({onBackClick}) => {
  const {t} = getLocalizationService();
  const currentRestaurant = useSelector(selectCurrentRestaurant);

  return (
    <>
      <SecondaryBar onClick={onBackClick}>
        <BackButton alt={t('back')} />
      </SecondaryBar>
      <PrimaryBar>
        <Row>
          <RestaurantLogo src={currentRestaurant.logoUrl} alt={currentRestaurant.name} title={currentRestaurant.name} />
          <RestaurantDetails>
            <RestaurantName>{currentRestaurant.name}</RestaurantName>
            <RestaurantCuisines>
              {currentRestaurant.cuisineTypes && Object.values(currentRestaurant.cuisineTypes).join(', ')}
            </RestaurantCuisines>
          </RestaurantDetails>
        </Row>
      </PrimaryBar>
    </>
  );
};

export default MenuPageHeaderForMobileAndTablet;
