import {memo} from 'react';

import styled from 'styled-components';

import {getLocalizationService} from '../services/localisationService';
import {flexCenter} from '../theme/FlexLayout';

import _Disclaimer from './Disclaimer';

const Disclaimer = styled(_Disclaimer)`
  ${flexCenter};
  background-color: ${({theme}) => theme.colors.disclaimerSurface};
`;

const AgeRestrictionDisclaimer = ({className}: {className?: string}) => {
  const {t} = getLocalizationService();

  return (
    <Disclaimer className={className} showInfoIcon size="Wide">
      {t('age_restriction_disclaimer')}
    </Disclaimer>
  );
};

export default memo(AgeRestrictionDisclaimer);
