import moment from 'moment-timezone';

import {DELIVERY_RULES, DeliveryMethod, DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {MutatedFutureOrderAvailableDatesAndTimes} from '~/shared/store/models/FutureOrderAvailableDatesAndTimes';

export const getOrderTypeString = (deliveryMethod: DeliveryMethod, deliveryRule: DELIVERY_RULES | null) => {
  if (deliveryMethod === DeliveryMethods.PICKUP) {
    return 'pickup';
  }

  if (deliveryRule === DELIVERY_RULES.POOL) {
    return 'pooled';
  }

  return deliveryRule?.toLowerCase() || '';
};

export const getFormatedDate = (date: MutatedFutureOrderAvailableDatesAndTimes, time: MutatedFutureOrderAvailableDatesAndTimes['times'][number]) => {
  const [hours, minutes] = time.timeKey.split(':');
  return moment(date.date, 'DD/MM/YYYY')
    .hours(Number(hours))
    .minutes(Number(minutes))
    .format()
    .replace('+03:00', '');
};
