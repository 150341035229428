export enum AddressViewValuesMap {
  MAIN = 'main',
  ADD = 'add',
  EDIT = 'edit',
  EDIT_LOCAL = 'editLocal',
  ADD_COMPANY_ADDRESS = 'addCompanyAddress',
}
export type AddressViewType = EnumValueType<typeof AddressViewValuesMap>;

export enum LocationTypes {
  BUSINESS = 'business',
  RESIDENTIAL = 'residential',
  HOTEL = 'hotel',
}
export type LocationType = EnumValueType<typeof LocationTypes>;

export const COMMENT_MAX_LENGTH = 200;
export const APARTMENT_MAX_LENGTH = 5;
