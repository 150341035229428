import {AxiosResponse} from 'axios';

import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {AppState} from '~/shared/store/configStore';
import {selectUserDevice} from '~/shared/store/selectors';

import {isAxiosResponse} from './api/interfaces/responses';

enum AppTypes {
  WEB = 'web',
  MOBILE_WEB = 'mobileWeb',
}
export const getAppType = (state: AppState) => {
  const {isMinLargeTablet} = selectUserDevice(state) || EMPTY_OBJECT;
  return {'x-app-type': isMinLargeTablet ? AppTypes.WEB : AppTypes.MOBILE_WEB};
};

export const validateAxiosAndReturnResponse = <ApiResponse>(response: ApiResponse | AxiosResponse<ApiResponse>): ApiResponse => {
  if (isAxiosResponse<ApiResponse>(response)) {
    throw response;
  }
  return response;
};
