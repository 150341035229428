import {memo} from 'react';

import {getLocalizationService} from '~/shared/services/localisationService';
import {OrderSuccessData} from '~/shared/store/models';
import {getTimeRangeLabel, isValidTimeRangeString} from '~/shared/utils/timeRangeHelper';

import {OrderCompleteHeader, OrderCompleteSubheader} from '../styled';

type HeaderAndSubheaderProps = {
  arrivalOrderDate: OrderSuccessData['arrivalOrderDate'];
  arrivalOrderTime: OrderSuccessData['arrivalOrderTime'];
  arrivalOrderDateTimeText: OrderSuccessData['arrivalOrderDateTimeText'];
  restaurantName: OrderSuccessData['restaurant']['restaurantName'];
  minutesToRender: number | string | null;
  showScooberDummyTracker: boolean;
  isToday: boolean;
  isOverTwoHours: boolean;
  isExceeded: boolean;
};

export enum OrderCompleteSubheaderVariant {
  FUTURE_NOT_FOR_TODAY,
  OVER_TWO_HOURS,
  BELOW_TWO_HOURS,
  EXCEEDED
}

export const SUBHEADERS_MAP = {
  [OrderCompleteSubheaderVariant.FUTURE_NOT_FOR_TODAY]: 'scoober_order_complete_subtitle_future',
  [OrderCompleteSubheaderVariant.OVER_TWO_HOURS]: 'scoober_order_complete_subtitle_over_two_hours',
  [OrderCompleteSubheaderVariant.BELOW_TWO_HOURS]: 'scoober_order_complete_subtitle_below_two_hours',
  [OrderCompleteSubheaderVariant.EXCEEDED]: 'scoober_order_complete_subtitle_exceeded',
};

type TranslationKey = string;

export const getSubHeaderKey = ({isToday, isOverTwoHours, isExceeded}: {
  isToday: boolean;
  isOverTwoHours: HeaderAndSubheaderProps['isOverTwoHours'];
  isExceeded: boolean;
}): TranslationKey => {
  if (!isToday) {
    return SUBHEADERS_MAP[OrderCompleteSubheaderVariant.FUTURE_NOT_FOR_TODAY];
  }

  if (isExceeded) {
    return SUBHEADERS_MAP[OrderCompleteSubheaderVariant.EXCEEDED];
  }

  if (isOverTwoHours) {
    return SUBHEADERS_MAP[OrderCompleteSubheaderVariant.OVER_TWO_HOURS];
  }

  return SUBHEADERS_MAP[OrderCompleteSubheaderVariant.BELOW_TWO_HOURS];
};

const HeaderAndSubheader = ({
  isToday,
  showScooberDummyTracker,
  arrivalOrderDate,
  minutesToRender,
  restaurantName,
  arrivalOrderDateTimeText,
  isExceeded,
  isOverTwoHours,
}: HeaderAndSubheaderProps) => {
  const {t} = getLocalizationService();

  const subHeaderKey = getSubHeaderKey({isToday, isOverTwoHours, isExceeded});

  return (
    <div>
      <OrderCompleteHeader showScooberTracker={showScooberDummyTracker && !isExceeded}>
        {t(isExceeded ? 'scoober_order_complete_title_exceeded' : 'scoober_order_complete_title')}
      </OrderCompleteHeader>
      <OrderCompleteSubheader>
        {t(subHeaderKey, {
          arrivalOrderDateTimeText: isValidTimeRangeString(arrivalOrderDateTimeText) ?
            getTimeRangeLabel(arrivalOrderDateTimeText) :
            arrivalOrderDateTimeText,
          minutesToRender,
          restaurantName,
          arrivalOrderDate,
        })}
      </OrderCompleteSubheader>
    </div>
  );
};

export default memo(HeaderAndSubheader);
