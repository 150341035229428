import {transform} from 'lodash';
import URI from 'urijs';

type GenericQueryType = string;
type TrueQueryType = 'true';

const trueQueryValue = undefined as unknown as TrueQueryType;
const genericQueryValue = undefined as unknown as GenericQueryType;

export const CAMPAIGN_QUERIES = {
  utm_source: genericQueryValue,
  utm_medium: genericQueryValue,
  utm_campaign: genericQueryValue,
  utm_content: genericQueryValue,
};

export const filterQueriesParams = {
  /** When navigating to the restaurant list page, filters the list by returning "gluten free" restaurants  */
  isGlutenFree: trueQueryValue,

  /** When navigating to the restaurant list page, filters the list by returning "vegan" restaurants  */
  isVegan: trueQueryValue,

  /** When navigating to the restaurant list page, filters the list by returning "kosher" restaurants  */
  isKosher: trueQueryValue,

  /** When navigating to the restaurant list page, filters the list by returning "non kosher" restaurants  */
  isNotKosher: trueQueryValue,

  /** When navigating to the restaurant list page, filters the list by cuisine type */
  cuisineType: genericQueryValue,

  /** When navigating to the restaurant list page, filters the list by returning "free delivery" restaurants */
  freeDelivery: trueQueryValue,

  /** When navigating to the restaurant list page, filters the list by returning "free delivery" restaurants */
  isFreeDelivery: trueQueryValue,

  /** When navigating to the restaurant list page, filters the list by returning new restaurants */
  newRestaurants: trueQueryValue,

  /** When navigating to the restaurant list page, filters the list by returning restaurants with a discount coupon */
  discountCoupon: trueQueryValue,

  /** When navigating to the restaurant list page, filters the list by returning restaurants with a discount coupon */
  isDiscountCoupon: trueQueryValue,

  /** When navigating to the restaurant list page, filters the list by returning "environment friendly" restaurants */
  isEnvironmentFriendly: trueQueryValue,

  /** When navigating to the restaurant list page, filters the list by returning "scoober" restaurants */
  freeDeliveryWithTracker: trueQueryValue,
} as const;

export const supportedQueriesParams = {
  /** Used for initiating modal after refreshToken request failed*/
  disconnectModal: genericQueryValue,

  /** Used for sending authentication on embedded coupon page */
  authorization: genericQueryValue,

  /** Used for force address change to company address  */
  forceCompanyAddressId: genericQueryValue,

  /** Used for force delivery method change in company address */
  forceDeliveryMethod: genericQueryValue,

  /** Used in edit dish */
  shoppingCartDishId: genericQueryValue,

  /** Used in the menu pages to shows the about modal */
  about: trueQueryValue,

  /** Used for the mobile app. When in 'Embedded' mode, there's no support chat and A11y widget */
  embedded: trueQueryValue,

  /** Added on the first redirect to order success after checkout. Indicates if the user landed on the page for the first time after checkout. */
  firstRedirectToOrderSuccess: trueQueryValue,

  /** Used in user-report to decide which month to show relative to the current one */
  dateBias: genericQueryValue,

  /** Used in info pages to determines if to hide the header of the page*/
  hideHeader: trueQueryValue,

  /** If present in the query (no matter what value is set), it causes tha app to load without the header */
  shouldHideHeader: genericQueryValue,

  /** Used to display the login\signup modal. Opens when the login form is active */
  openLogOnModal: trueQueryValue,

  /** Used when clicking a link in the "forgot password" mail, after the 20 mins timeout has passed */
  forgotPasswordTokenExpired: trueQueryValue,

  /** Used with signin queries to determine the mode of sign in (i.e., login, signin) */
  mode: genericQueryValue,

  /** Used to set a coupon in the checkout pages. If it is present in the query, it is added as a coupon to the user */
  couponCode: genericQueryValue,

  /** Used to open the checkout */
  showCheckout: trueQueryValue,

  /** Used to pass request token, during link money card approval flow */
  t: genericQueryValue,

  /** Used from emails after order to open the app with the restaurant review modal (where you rank it 0-5) */
  transactionId: genericQueryValue,

  /** Used from emails after order to open the app with the restaurant review modal (where you rank it 0-5) */
  resId: genericQueryValue,

  /** Used in several flows where the users are sent to login before they can continue. Used in case of failure in login or when successReturnUrl does not exist*/
  returnUrl: genericQueryValue,

  /** Used to decide which url to return to, relative to the root */
  rootReturnUrl: genericQueryValue,

  /** Used in several flows where the users are sent to login before they can continue. Used in case of a succesfull login */
  successReturnUrl: genericQueryValue,

  /** Used to specify the dish id when navigating to the dish page */
  dishId: genericQueryValue,

  /** Used to specify if to show specific order in the user report */
  showOrder: genericQueryValue,

  /** Unknown use. Probably not used */
  couponValue: genericQueryValue,

  /** Unknown use. Probably not used */
  openCheckout: trueQueryValue,

  /** Unknown use. Probably not used */
  restaurantTag: genericQueryValue,

  /** Used to indicate that the login page / modal is in strong login mode:
   * https://tenbis.visualstudio.com/TenBis/_workitems/edit/71241
   */
  strongLogin: trueQueryValue,

  /** Used for opening tenbis credit modal as a deaplink capability in the user report page */
  openTenbisChargeModal: trueQueryValue,

  /** Used in restaurant list to indicate selected cuisine filter on MW */
  cuisine: genericQueryValue,

  /** Used in restaurant list to indicate selected stores filter on MW */
  showStores: trueQueryValue,

  /** Used in restaurant list to indicate selected collection */
  collectionName: genericQueryValue,

  /** Used in /join to indicate the relevant Company */
  pageType: genericQueryValue,

  /** Used in MenuPage to determine default init value */
  orderViewTag: genericQueryValue,
  ...CAMPAIGN_QUERIES,
  ...filterQueriesParams,
} as const;

/** available queries - add to the list to be able to use others */
export type SupportedQueryParamsType = typeof supportedQueriesParams;
export type SupportedQueryParamsKeys = Array<keyof typeof supportedQueriesParams>;

export const supportedQueryParamsCasingMapping = transform<string, Record<string, string>>(
  supportedQueriesParams,
  (result, queryRightCasingValue, queryRightCasingKey) => {
    result[queryRightCasingKey.toLowerCase()] = queryRightCasingKey;
  },
);

export function convertQueryKeyToRightCasing(query: string): string {
  return supportedQueryParamsCasingMapping[query.toLowerCase()];
}

export function convertLocationToRightCasing(location: Location) {
  // lowercase & normalize location
  const normalizedCurrentHref = new URI(location.pathname.toLowerCase() + location.search + location.hash).normalize();

  const queryParamsFromLocation = normalizedCurrentHref.search(true);

  const keys = Object.keys(queryParamsFromLocation);

  const newObj = keys.reduce(
    (accumulator, currentKey) => {
      const key = convertQueryKeyToRightCasing(currentKey) || currentKey;
      accumulator[key] = queryParamsFromLocation[currentKey];
      return accumulator;
    },
    {} as Record<string, URI.QueryDataMap[string]>,
  );

  normalizedCurrentHref.search(newObj);

  return normalizedCurrentHref.absoluteTo(location.origin).toString();
}
