import {UserVoucher} from '../store/models/User/UserVouchers';

type PrintCouponProps = {
  restaurantName: string;
  barCode?: UserVoucher;
  direction: 'ltr' | 'rtl';
  isGovCompany: boolean;
  barcodeSumText: string;
  firstName: string;
  lastName: string;
  t: (key: string, params?: object | undefined) => string;
};

function printCoupon({
  restaurantName,
  barCode,
  direction,
  isGovCompany,
  barcodeSumText,
  firstName,
  lastName,
  t,
}: PrintCouponProps) {
  // eslint-disable-next-line
  const printWindow = window.open('', 'PRINT');
  if (!barCode) {
    return;
  }
  const isLtr = direction === 'ltr';
  printWindow?.document.write(`
    <html>
    <head>
      <style>
      .container {
        width: 550px;
        margin: auto;
        padding-top: 50px;
        text-align: center;
        padding-right: 18px;
        direction: ${direction};
      }
      div {
        line-height: 1.6;
      }
      .barCodeStatement {
        padding-bottom: 20px;
      }
      .barCodeDetails {
        display: flex;
        justify-content: center;
      }
      .barCodeSumText {
        margin-right: ${isLtr ? '8px' : '0'};
        margin-left: ${!isLtr ? '8px' : '0'};
      }
      .barCodeSubheaderSection {
        font-size: 18px;
        font-weight: bold;
        padding-right: 18px;
      }
      </style>
    </head>
    <body onload="(function(){window.focus(); window.print();}())">
        <div class="container">
          <div class="barCodeSubheaderSection">${t('coupon_details')}</div>
          <div>${t('coupon_to_xxx_chain_in_the_amount_of_xxx', {
    chainName: restaurantName,
    totalAmount: barCode.amount,
  })}</div>
          <div>${t('order_success_coupon_remarks', {validDate: barCode.validDate})}</div>
          <div>${t('order_user_name')}: <b>${firstName} ${lastName}</b></div>
          <div class="barCodeStatement">${t('voucher_is_personal_statement')}</div>

          <img src=${barCode.barCodeImgUrl} height="150" width="350"/>
          <div class="barCodeNumber">${barCode.barCodeNumber}</div>
          <div class="barCodeStatement barCodeDetails">
            <div class="barCodeSumText">${t('coupon')}: ${barcodeSumText}</div>
            <div>(${t('validity')}: ${barCode?.validDate?.replaceAll('/', '.')})</div>
          </div>

          <div>${t('bar_code_must_be_printed')}</div>
          ${t('list_of_chains_is_listed')} ${isGovCompany ? t('in_accordance_with') : ''} ${t(
  'this_order_can_not_be_canceled',
)}
        </div>
    </body>
    </html>`);
  printWindow?.document.close(); // necessary for IE >= 10
  return true;
}
export default printCoupon;
