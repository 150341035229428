import {useEffect} from 'react';

const useDocumentEventHandler = <EventKey extends keyof DocumentEventMap>(
  eventName: EventKey,
  callback: (event: DocumentEventMap[EventKey]) => void,
) => {
  useEffect(() => {
    const keyEventsHandler = (event: DocumentEventMap[EventKey]) => {
      callback(event);
    };
    document.addEventListener(eventName, keyEventsHandler, false);
    return () => {
      document.removeEventListener(eventName, keyEventsHandler, false);
    };
  }, [callback, eventName]);
};

export default useDocumentEventHandler;
