import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {SHOPPING_CART_DELIVERY_TYPE} from '~/shared/utils/restaurants/deliveryOptions';
import AsapPooledToggler from '~/shared/components/AsapPooledToggler';
import FutureOrder from '~/shared/components/FutureOrder/FutureOrder';
import {DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {
  selectCurrentDeliveryMethod,
  selectCurrentRestaurantShoppingCartDeliveryType,
} from '~/shared/store/selectors';
import {RestaurantBusinessTypeName} from '~/shared/store/models';

import SingleRuleETABox from './SingleRuleETABox';
import AsapAndFuture from './AsapAndFuture';
import SameDayFutureOrder from './SameDayFutureOrder';

const Root = styled.div`
  padding: 16px 16px 0;
`;

export const HeaderComponentContainer = styled.div<{noPadding?: boolean}>`
  ${({noPadding}) => (!noPadding ? 'padding: 16px 16px 0;' : '')}
`;

interface IShoopingCartHeader {
  isCheckout?: boolean;
  showHeader?: boolean;
  hideTitle?: boolean;
  showFutureOrderValidationError?: boolean;
  setIsFutureOrderTimesNotSelected?: (value: boolean) => void;
  businessType?: RestaurantBusinessTypeName;
}

type ShoppingCartDeliveryHeaderComponents = Record<
  SHOPPING_CART_DELIVERY_TYPE,
  (props: IShoopingCartHeader) => JSX.Element | null | undefined
>;

const SHOPPING_CART_DELIVERY_HEADER_COMPONENTS: ShoppingCartDeliveryHeaderComponents = {
  [SHOPPING_CART_DELIVERY_TYPE.FUTURE]: ({setIsFutureOrderTimesNotSelected, showFutureOrderValidationError, isCheckout}) => (
    <>
      <HeaderComponentContainer noPadding={isCheckout}>
        <FutureOrder
          setIsFutureOrderTimesNotSelected={setIsFutureOrderTimesNotSelected}
          futureOnly
          isCheckout={!!isCheckout}
          showFutureOrderValidationError={showFutureOrderValidationError}
          shouldShowHeader
        />
      </HeaderComponentContainer>
    </>
  ),
  [SHOPPING_CART_DELIVERY_TYPE.ASAP_ONLY]: props => (
    <HeaderComponentContainer noPadding={props?.isCheckout}>
      <SingleRuleETABox {...props} />
    </HeaderComponentContainer>
  ),
  [SHOPPING_CART_DELIVERY_TYPE.POOL_ONLY]: props => (
    <HeaderComponentContainer noPadding={props?.isCheckout}>
      <SingleRuleETABox {...props} />
    </HeaderComponentContainer>
  ),
  [SHOPPING_CART_DELIVERY_TYPE.ASAP_AND_FUTURE]: ({isCheckout, hideTitle}) => (
    <HeaderComponentContainer id="asap-and-future" noPadding={isCheckout}>
      <AsapAndFuture isCheckout={isCheckout} hideTitle={hideTitle} />
    </HeaderComponentContainer>
  ),
  [SHOPPING_CART_DELIVERY_TYPE.ASAP_AND_POOLED]: ({isCheckout, hideTitle}) => (
    <HeaderComponentContainer noPadding={isCheckout}>
      <AsapPooledToggler isCheckout={isCheckout} hideTitle={hideTitle} />
    </HeaderComponentContainer>
  ),
  [SHOPPING_CART_DELIVERY_TYPE.SDFO]: ({isCheckout, hideTitle}) => (
    <HeaderComponentContainer noPadding={isCheckout}>
      <SameDayFutureOrder isCheckout={isCheckout} hideTitle={hideTitle} />
    </HeaderComponentContainer>
  ),
  [SHOPPING_CART_DELIVERY_TYPE.DEFAULT]: () => null,
};

const ShoppingCartHeader = ({isCheckout, showHeader, setIsFutureOrderTimesNotSelected, showFutureOrderValidationError, businessType}: IShoopingCartHeader) => {
  const shoppingCartDeliveryType = useSelector(selectCurrentRestaurantShoppingCartDeliveryType);
  const currentDeliveryMethod = useSelector(selectCurrentDeliveryMethod);

  if (!shoppingCartDeliveryType || businessType === RestaurantBusinessTypeName.VoucherCard) {
    return null;
  }

  const props = {isCheckout, hideTitle: isCheckout && !showHeader, setIsFutureOrderTimesNotSelected, showFutureOrderValidationError};

  if (currentDeliveryMethod === DeliveryMethods.PICKUP) {
    return <Root><SingleRuleETABox {...props} hideTitle /></Root>;
  }

  return <>{SHOPPING_CART_DELIVERY_HEADER_COMPONENTS[shoppingCartDeliveryType](props)}</>;
};

export default ShoppingCartHeader;
