import {isEqual} from 'lodash';

import {createLogger} from '~/shared/logging';
import actions, {restrictedSharedActions} from '~/shared/store/actions';
import store from '~/shared/store';
import {
  selectCurrentAddressKey,
  selectCurrentCoupon,
  selectCurrentDeliveryMethod,
  selectFutureOrderAvailableDatesAndTimes,
  selectOrderPermit,
  selectSettingDataInStoreAccordingToApiResponse,
  selectShoppingCart,
} from '~/shared/store/selectors';
import {ApiServiceFunction} from '~/shared/services/apiService/apiServiceConfig';
import apiService, {ApiResponse} from '~/shared/services/apiService';
import {OrderData, ReOrderResponse} from '~/shared/store/models';
import {wait} from '~/shared/utils/general';
import {DeliveryMethod, DeliveryMethods} from '~/shared/consts/restaurantConsts';
import OrderManagerHelper from '~/shared/managers/OrderManager/OrderManagerHelper';
import {ApiError} from '~/shared/services/apiErrorService';

type QueRequestResponse = ApiResponse<OrderData | ReOrderResponse | ApiError>;

const logger = createLogger('StateInOrderUpdateQueue');

const DEBOUNCE_MS = 500;
let requestsQueuePendingRequest: Promise<QueRequestResponse | void> = Promise.resolve();
const requestsTimeoutsMap = new Map();

let lastRequestId = 0;
let lastSuccessfulResponseId = 0;
let lastSuccessfulResponse: ApiResponse<OrderData | ReOrderResponse> | null;

const OrderUpdateQueue = {
  async isIdle() {
    return new Promise<void>(resolve => {
      if (!store.getState().shoppingCart.isDirty) {
        resolve();
        return;
      }

      const unsubscribe = store.observe({selector: state => selectShoppingCart(state).isDirty}, currentIsDirty => {
        if (!currentIsDirty) {
          resolve();
          unsubscribe();
        }
      });
    });
  },

  // Todo: debounce seems unnecessary - never actually passed as true to any function eventually using addRequest
  // If so - lastRequestId also seems redundant - as every request awaits the pending one to resolve
  addRequest: async <T extends OrderData | ReOrderResponse>({
    apiFunction,
    apiFunctionArg,
    debounce: debounceRequest,
    // TODO: maybe this should be true for all requests
    // TASK: will be solved when we move to stateless api
    updatePayments: shouldUpdatePayments = true,
  }: AddRequestPayload<T>) => {
    const initialState = store.getState();
    if (selectSettingDataInStoreAccordingToApiResponse(initialState)) {
      logger.warn('wont update store in order because a change was caused by response from data in order.');
      return;
    }

    lastRequestId++;
    const requestId = lastRequestId;

    store.dispatch(actions.setDirtyShoppingCart(true));

    const handleRequest = async () => {
      const response = await makeApiRequest({apiFunction, apiFunctionArg});
      const isLastRequest = requestId === lastRequestId;

      if (isSuccessfulResponse(response) && requestId > lastSuccessfulResponseId) {
        lastSuccessfulResponse = response;
        lastSuccessfulResponseId = requestId;
      }

      if (isLastRequest) {
        if (lastSuccessfulResponse) {
          const {data: responseData} = lastSuccessfulResponse;

          OrderUpdateQueue.updateStoreAccordingToApiRes({
            serverResData: isReorderResponse(responseData) ? responseData.orderData : responseData,
            shouldUpdatePayments,
          });

          lastSuccessfulResponse = null;
        }

        store.dispatch(actions.setDirtyShoppingCart(false));
      }

      return response;
    };

    if (debounceRequest) {
      if (requestsTimeoutsMap.has(apiFunction)) {
        clearTimeout(requestsTimeoutsMap.get(apiFunction));
      }

      const setDataInOrderTimeout = setTimeout(() => {
        requestsQueuePendingRequest = requestsQueuePendingRequest.then(handleRequest, handleRequest);
      }, DEBOUNCE_MS);

      requestsTimeoutsMap.set(apiFunction, setDataInOrderTimeout);

      return;
    }

    requestsQueuePendingRequest = requestsQueuePendingRequest.then(handleRequest, handleRequest);

    return requestsQueuePendingRequest as Promise<ApiResponse<T>>;
  },

  updateStoreAccordingToApiRes: ({
    serverResData,
    shouldUpdatePayments,
  }: {
    serverResData: OrderData;
    shouldUpdatePayments: boolean;
  }) => {
    store.dispatch(actions.settingDataInStoreAccordingToApiResponse(true));
    updateShoppingCartAccordingToApiRes(serverResData);
    if (shouldUpdatePayments) {
      updatePaymentsAccordingToApiRes(serverResData);
    }
    store.dispatch(actions.settingDataInStoreAccordingToApiResponse(false));
  },
};

function isReorderResponse(res: OrderData | ReOrderResponse): res is ReOrderResponse {
  return 'orderData' in res;
}

interface AddRequestPayload<T> {
  apiFunction: ApiServiceFunction<T>;
  apiFunctionArg?: Record<string, any>;
  debounce?: boolean;
  updatePayments?: boolean;
}

// #region utils
function makeApiRequest<T>({
  apiFunction,
  apiFunctionArg,
  attempt = 0,
}: MakeApiRequestPayload<T>): ReturnType<ApiServiceFunction<T>> {
  return apiFunction.call(apiService, apiFunctionArg).then(response => {
    if (!response.success && attempt < 2) {
      return wait(1000).then(() => {
        return makeApiRequest({apiFunction, apiFunctionArg, attempt: attempt + 1});
      });
    }

    return response;
  });
}
interface MakeApiRequestPayload<T> {
  apiFunction: ApiServiceFunction<T>;
  apiFunctionArg?: Record<string, any>;
  attempt?: number;
}

function updatePaymentsAccordingToApiRes(serverResData: OrderData) {
  if (!serverResData || !store) {
    return null;
  }

  const {getState, dispatch} = store;
  const state = getState();

  if (!isEqual(serverResData.payments, state.payments.orderPayments)) {
    dispatch(actions.setOrderPayments(serverResData.payments));
  }
}

async function updateShoppingCartAccordingToApiRes(serverResData: OrderData) {
  if (!serverResData) return null;

  const {getState, dispatch} = store;
  const state = getState();
  
  if (state.order?.notesForClient !== serverResData.notesForClient) {
    dispatch(actions.setNotesForClient(serverResData.notesForClient));
  }

  const permits = serverResData?.permits;
  if (permits && !isEqual(permits, selectOrderPermit(state))) {
    store.dispatch(actions.setOrderPermit(serverResData.permits));
  }

  const serverResBillingLines = serverResData.shoppingCart.billingLines;
  if (OrderManagerHelper.getIsBillingsUpdateNeeded(serverResBillingLines)) {
    store.dispatch(restrictedSharedActions.setBillingLines(serverResBillingLines));
  }

  const serverResDishList = serverResData.shoppingCart.dishToSubmitList;
  const currentDishList = getState().shoppingCart.dishes;
  const shouldUpdateDishesInStore = !isEqual(serverResDishList, currentDishList);

  if (shouldUpdateDishesInStore) {
    dispatch(restrictedSharedActions.setDishes(serverResDishList));
  }

  const serverResponseCoupon = serverResData.discountCoupon;
  const currentCoupon = selectCurrentCoupon(getState());
  const shouldUpdateDiscountCouponInStore = !isEqual(serverResponseCoupon, currentCoupon);

  if (shouldUpdateDiscountCouponInStore) {
    dispatch(actions.setCurrentCoupon(serverResponseCoupon));
  }

  const currentDeliveryMethod = selectCurrentDeliveryMethod(getState());
  const deliveryMethodFromServer = serverResData.deliveryMethod.toLowerCase() as DeliveryMethod;
  if (deliveryMethodFromServer && currentDeliveryMethod !== deliveryMethodFromServer) {
    if (deliveryMethodFromServer === DeliveryMethods.NOTRECOGNIZED) {
      dispatch(actions.setCurrentDeliveryMethod(null));
    } else {
      dispatch(actions.setCurrentDeliveryMethod(deliveryMethodFromServer));
      const addressKey = selectCurrentAddressKey(state);
      if (addressKey) {
        await dispatch(actions.searchRestaurantsIfNeeded({deliveryMethod: deliveryMethodFromServer, addressKey}));
      }
    }
  }

  const availableFutureDatesAndTimes = selectFutureOrderAvailableDatesAndTimes(getState());
  if (availableFutureDatesAndTimes) {
    const orderDate = availableFutureDatesAndTimes.find(dateObject => dateObject.date === serverResData?.orderDataOrderDateAndTime?.desiredDateStr);
    const orderTime = orderDate?.times?.find(time => time.timeKey === serverResData?.orderDataOrderDateAndTime?.desiredTimeStr);
    if (!orderDate || !orderTime) {
      return;
      
    }
    store.dispatch(actions.setCurrentOrderDateAndTime({orderDate, orderTime}));
  }

  // TODO: handle different address. change address?
  // TASK: will be solved when we move to stateless sever.
}
// #endregion

function isSuccessfulResponse<T>(res: ApiResponse<T | ApiError>): res is ApiResponse<T> {
  return res.success;
}

export default OrderUpdateQueue;
