import {memo} from 'react';

import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';

import {selectDialogErrors} from '~/shared/store/selectors';
import ButtonBase from '~/shared/components/Button';
import {Modal} from '~/shared/components/Modals';
import {clearErrorsDialog} from '~/shared/store/actions';
import {body13Normal} from '~/shared/theme/typography';
import {flexColumn} from '~/shared/theme/FlexLayout';

const Content = styled.div`
  width: 100%;
  direction: ltr;
  ${flexColumn};
  padding: 10px;

  &&& {
    height: 90vh;
  }
`;

const Button = styled(ButtonBase)`
  text-decoration: underline;
  ${body13Normal};
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  width: 100px;
  align-self: center;
`;

const DeveloperErrorModal = () => {
  const dispatch = useDispatch();
  const dialogErrors = useSelector(selectDialogErrors);

  const dialogContent = dialogErrors.length > 0 ? JSON.stringify(dialogErrors, null, '\t') : null;

  const closeModalDialog = () => {
    dispatch(clearErrorsDialog());
  };

  return (
    <Modal isOpen={dialogContent} hideCloseButton closeModal={closeModalDialog}>
      <Content>
        <h3 style={{color: 'red', textAlign: 'center'}}>Critical Errors</h3>
        <textarea defaultValue={dialogContent} disabled style={{flex: 1}} />
        <Button onClick={closeModalDialog}>Close</Button>
      </Content>
    </Modal>
  );
};

export default memo(DeveloperErrorModal);
