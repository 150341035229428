import {get, maxBy, orderBy, uniqBy, without} from 'lodash';

import {createLogger} from '~/shared/logging';
import {
  selectCurrentDeliveryMethod,
  selectCurrentRestaurantHasTipEnabled,
  selectUserData,
  selectShoppingCart,
  selectShoppingCartGuidTimestamp,
  selectCurrentCoupon,
  selectSettingDataInStoreAccordingToApiResponse,
} from '~/shared/store/selectors';
import actions, {
  setCurrentOrderDateAndTime as setCurrentOrderDateAndTimeAction,
  setCurrentDeliveryMethod,
  setOrderPayments,
  setCurrentCoupon,
  getAvailableCouponsForOrder,
  restrictedSharedActions,
  setCurrentModal,
  setDirtyShoppingCart,
  setInitialOrder,
} from '~/shared/store/actions';
import apiService from '~/shared/services/apiService';
import store from '~/shared/store';
import {AddressPartsFromKey} from '~/shared/utils/address';
import {mergeToQueryStringCookie} from '~/shared/utils/cookies';
import {
  CONTEXT_COOKIE_NAME,
  ContextCookieParamNames,
  NEXT_CONTEXT_COOKIE_NAME,
  NextContextCookieParamNames,
} from '~/shared/consts/commonConsts';
import {DELIVERY_RULES, DeliveryMethod, DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {Address, IShoppingCart, LocalAddress, Payment, ReOrderResponse, ShoppingCartDish} from '~/shared/store/models';
import {IShoppingCartBillingLine} from '~/shared/store/models/ShoppingCart/IShoppingCart';
import {MutatedFutureOrderAvailableDatesAndTimes} from '~/shared/store/models/FutureOrderAvailableDatesAndTimes';
import {ApiError, is401Error} from '~/shared/services/apiErrorService';
import {handleRefreshToken} from '~/shared/services/auth';
import {isCurrentTimeKeyAsap} from '~/shared/utils/general';
import {trackEvent} from '~/shared/services/analytics';

import {analyticsReachedRestaurantMinimum} from '../ManagerProvider/ManagerProviderHelper';

import OrderUpdateQueue from './OrderUpdateQueue';
import OrderManagerHelper from './OrderManagerHelper';

const logger = createLogger('OrderManager');

const OrderManager = {
  isIdle: async () => {
    logger.verbose('waiting for manager to be idle');
    await OrderUpdateQueue.isIdle();
    logger.verbose('manager is idle');
  },

  getOrderData: async () => {
    logger.verbose('getting latest order data');
    const initialDataInOrder = await OrderUpdateQueue.addRequest({
      apiFunction: apiService.getOrderData,
    });

    if ((initialDataInOrder?.data.deliveryMethod || '').toLowerCase() === DeliveryMethods.SITTING) {
      const dataInOrderAfterChangingDeliveryMethod = await OrderManager._setDeliveryMethodInOrder(
        DeliveryMethods.DELIVERY,
      );
      return dataInOrderAfterChangingDeliveryMethod?.data;
    }

    return initialDataInOrder?.data;
  },

  async setOrderDateAndTime(
    orderDate?: MutatedFutureOrderAvailableDatesAndTimes,
    orderTime?: MutatedFutureOrderAvailableDatesAndTimes['times'][number],
  ) {
    const state = store.getState();

    if (!orderDate?.date || !orderTime) {
      store.dispatch(setCurrentOrderDateAndTimeAction(undefined));
      throw new Error('bad orderDate and orderTime');
    }

    const deliveryMethod = selectCurrentDeliveryMethod(state);
    store.dispatch(setCurrentOrderDateAndTimeAction({orderDate, orderTime}));

    try {
      await this._addFutureOrder({
        orderDate: orderDate.date,
        orderTime: orderTime.timeKey,
        orderTimeRange: orderTime.timeRange,
        deliveryMethod,
      });
    } catch (error) {
      store.dispatch(setCurrentOrderDateAndTimeAction(undefined));

      logger.verbose('unexpected future order error', {error});
      throw new Error('unexpected future order error');
    }
  },

  // eslint-disable-next-line class-methods-use-this
  setShoppingCartGuid: (shoppingCartGuid: string) => {
    store.dispatch(restrictedSharedActions.setShoppingCartGuid({shoppingCartGuid}));
    mergeToQueryStringCookie(CONTEXT_COOKIE_NAME, {
      [ContextCookieParamNames.shoppingCartGuid]: shoppingCartGuid,
    });
    mergeToQueryStringCookie(NEXT_CONTEXT_COOKIE_NAME, {
      [NextContextCookieParamNames.shoppingCartGuidTimestamp]: selectShoppingCartGuidTimestamp(store.getState()),
    });
  },

  setUserInOrder: async () => {
    const user = selectUserData(store.getState());
    if (user) {
      await OrderUpdateQueue.addRequest({apiFunction: apiService.setUserInOrder});
    }
  },

  reorder: async (orderId: number, options?: {addDishesAnyway?: boolean; deliveryRuleType?: DELIVERY_RULES}) => {
    try {
      const reorderRes = await OrderUpdateQueue.addRequest({
        apiFunction: apiService.reorder,
        apiFunctionArg: {
          orderId,
          addDishesAnyway: options?.addDishesAnyway,
          deliveryRuleType: options?.deliveryRuleType,
        },
      });

      if (!reorderRes?.success) {
        throw reorderRes;
      }

      store.dispatch(setInitialOrder({
        isInitialOrder: false,
        restaurantId: reorderRes.data.restaurantId,
      }));
      analyticsReachedRestaurantMinimum();

      return reorderRes?.data;
    } catch (reOrderResponse: any) {
      if (is401Error(reOrderResponse)) {
        const response = await handleRefreshToken(reOrderResponse as ApiError, () => OrderManager.reorder(orderId, options)) as ReOrderResponse;
        return response;
      }

      if (options?.addDishesAnyway) {
        return reOrderResponse.data as ReOrderResponse;
      }

      store.dispatch(
        setCurrentModal('restaurantReorderModal', {
          allMenuItems: reOrderResponse.data?.previousOrderDishes,
          unavailableMenuItems: reOrderResponse.data?.unavailableMenuItems,
          orderId,
          hideCloseButton: true,
        }),
      );
    }
  },

  changeDeliveryMethod: async (newDeliveryMethodFromArgs: string, force?: boolean) => {
    logger.verbose('trying to change delivery method', {newDeliveryMethodFromArgs});
    const newDeliveryMethod = OrderManagerHelper.getFinalDeliveryMethod(newDeliveryMethodFromArgs, force);
    if (!newDeliveryMethod) return;

    store.dispatch(setCurrentDeliveryMethod(newDeliveryMethod));
    await OrderManager._setDeliveryMethodInOrder(newDeliveryMethod);
    return newDeliveryMethod;
  },

  setAddressInOrder: async (address: Address | LocalAddress | AddressPartsFromKey) => {
    await OrderUpdateQueue.addRequest({
      apiFunction: apiService.setAddressInOrder,
      apiFunctionArg: address,
    });
  },

  chooseAndSetBestDiscountCouponValueInOrder: async ({includeUserCoupons}: {includeUserCoupons: boolean}) => {
    try {
      // make sure to getAvailableCouponsForOrder before chooseAndSetBestDiscountCoupon
      await store.dispatchThunk(getAvailableCouponsForOrder());
      return OrderUpdateQueue.addRequest({
        apiFunction: apiService.chooseAndSetBestDiscountCouponValueInOrder,
        apiFunctionArg: {includeUserCoupons},
      });
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, OrderManager.chooseAndSetBestDiscountCouponValueInOrder, {includeUserCoupons});
      }
    }
  },

  setRestaurantInOrder: async (
    restaurantId: number,
    options?: {
      deliveryRuleType?: DELIVERY_RULES | null;
    },
  ) => {
    const apiFunctionArg: Record<string, any> = {restaurantId};

    if (options?.deliveryRuleType) {
      apiFunctionArg.deliveryRuleType = options.deliveryRuleType;
    }

    return OrderUpdateQueue.addRequest({
      apiFunction: apiService.setRestaurantInOrder,
      apiFunctionArg,
    });
  },

  changeDishes: async ({
    dishList: rawDishList,
    debounce = false,
  }: {
    dishList: ShoppingCartDish[];
    debounce?: boolean;
  }) => {
    const {lastShoppingCartDishId: lastShoppingCartDishIdFromStore} = selectShoppingCart(store.getState());
    let lastShoppingCartDishId = Math.max(
      lastShoppingCartDishIdFromStore,
      maxBy(rawDishList, 'shoppingCartDishId')?.shoppingCartDishId || 0,
    );

    const dishListWithShoppingCartGuid = rawDishList.map(dish => {
      if (dish.shoppingCartDishId) return dish;
      lastShoppingCartDishId++;
      return {...dish, shoppingCartDishId: lastShoppingCartDishId};
    });

    const dishList = orderBy(dishListWithShoppingCartGuid, 'shoppingCartDishId');
    store.dispatch(restrictedSharedActions.updateLastShoppingCartDishId(lastShoppingCartDishId));
    store.dispatch(restrictedSharedActions.setDishes(dishList));
    OrderManager._optimisticallyUpdateBillingLines();

    return OrderUpdateQueue.addRequest({
      apiFunction: apiService.setDishListInShoppingCart,
      apiFunctionArg: {dishList},
      debounce,
    });
  },

  setPermitCodeInOrder: async (permitCode: string) => {
    logger.verbose('requesting permit for code', {permitCode});
    return OrderUpdateQueue.addRequest({
      apiFunction: apiService.setPermitCodeInOrder,
      apiFunctionArg: {permitCode},
    });
  },

  changeOrderPayments: ({payments, debounce = false}: {payments: Payment[]; debounce?: boolean}) => {
    const paymentsWithoutZeroSum = payments.filter(({sum}) => sum > 0);
    logger.verbose('changing payments', {paymentsWithoutZeroSum});
    store.dispatch(setOrderPayments(paymentsWithoutZeroSum));
    return OrderUpdateQueue.addRequest({
      apiFunction: apiService.setPaymentsInOrder,
      apiFunctionArg: {payments: paymentsWithoutZeroSum},
      debounce,
    });
  },

  changeTipAmount: async ({tipAmount, debounce = false}: {tipAmount: number; debounce?: boolean}) => {
    const state = store.getState();
    const {billingLines} = state.shoppingCart;
    const hasTipEnabled = selectCurrentRestaurantHasTipEnabled(state);
    const tipBillingLine = billingLines.find(({type}) => type === 'Tip');

    if (!hasTipEnabled && tipAmount === tipBillingLine?.amount) return;

    logger.verbose('changing tip amount', {tipAmount});

    store.dispatch(
      restrictedSharedActions.setBillingLines(
        uniqBy(
          [
            ...without(billingLines, tipBillingLine || ({} as IShoppingCart['billingLines'][number])),
            {...tipBillingLine, type: 'Tip', amount: tipAmount},
          ],
          'type',
        ),
      ),
    );

    const promiseResultIfNotDebounced = await OrderUpdateQueue.addRequest({
      apiFunction: apiService.setTipInOrder,
      apiFunctionArg: {tipAmount},
      debounce,
    });

    await store.dispatchThunk(actions.getAvailablePayments());

    return promiseResultIfNotDebounced;
  },

  _addFutureOrder: async ({orderDate, orderTime, deliveryMethod, orderTimeRange}: {
    orderDate: string;
    orderTime: string;
    deliveryMethod: DeliveryMethod;
    orderTimeRange?: string;
  }) => {
    return OrderUpdateQueue.addRequest({
      apiFunction: apiService.setFutureTimeInOrder,
      apiFunctionArg: {
        orderDate,
        orderTime: isCurrentTimeKeyAsap(orderTime) ? DELIVERY_RULES.ASAP.toUpperCase() : orderTime,
        orderTimeRange,
        deliveryMethod,
      },
    });
  },
  _setDeliveryMethodInOrder: async (deliveryMethod: DeliveryMethod) =>
    OrderUpdateQueue.addRequest({
      apiFunction: apiService.setDeliveryMethodInOrder,
      apiFunctionArg: {deliveryMethod},
    }),

  // eslint-disable-next-line class-methods-use-this
  _optimisticallyUpdateBillingLines: () => {
    if (selectSettingDataInStoreAccordingToApiResponse(store.getState())) {
      logger.warn(
        'wont optimistically update billing lines skipped because settingDataInStoreAccordingToApiResponse is on',
      );
      return;
    }
    const billingLines = OrderManagerHelper.getCalculatedBillingLines();
    if (OrderManagerHelper.getIsBillingsUpdateNeeded(billingLines as IShoppingCartBillingLine[])) {
      store.dispatch(restrictedSharedActions.setBillingLines(billingLines));
    }
  },

  changeCoupon: async ({couponCode, force = false}: {couponCode: string | null; force?: boolean}) => {
    const currentCoupon = selectCurrentCoupon(store.getState());
    if (!force && couponCode === currentCoupon?.code) {
      logger.verbose('[changeCoupon] Wont change coupon because its the same as the current one', {couponCode});
      return;
    }

    try {
      const setCouponResponse = await OrderUpdateQueue.addRequest({
        apiFunction: apiService.setCouponInOrder,
        apiFunctionArg: {couponCode},
      });

      const couponError = get(setCouponResponse, 'errors[0].errorDesc');
      if (couponError) {
        return {error: couponError};
      }

      const newCoupon = setCouponResponse?.data?.discountCoupon;
      if (newCoupon) {
        store.dispatch(setCurrentCoupon(newCoupon));
        trackEvent('resDiscount_checkoutChange', {resDiscountId: currentCoupon?.id || null, newCouponId: newCoupon.id});
      }

      await store.dispatchThunk(actions.getAvailablePayments());
    } catch (error: any) {
      store.dispatch(setDirtyShoppingCart(false));
      return {error: error?.message};
    }
  },
};

export default OrderManager;
