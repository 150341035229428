import {ValidationErrors} from 'final-form';

import {ShoppingCartDish} from '~/shared/store/models';

import {EncodedChoicesType, EncodedSubChoiceType, DishType} from '../modals/DishType';

export const encodeChoices = (selectedChoices: DishType['selectedChoices']) =>
  selectedChoices.reduce<EncodedChoicesType>((all, {choiceId, subsChosen}) => {
    all[`choice-${choiceId}`] = subsChosen.reduce<EncodedSubChoiceType>((allChosen, {subId}) => {
      allChosen[`sub-${subId}`] = true;
      return allChosen;
    }, {});
    return all;
  }, {});

export const decodeChoices = (mapChoices: EncodedChoicesType): ShoppingCartDish['choices'] =>
  Object.entries(mapChoices).reduce<ShoppingCartDish['choices']>((arrChoices, [choiceKey, subsChosen]) => {
    const choiceId = parseInt(choiceKey.replace('choice-', ''), 10);

    const subs = Object.entries(subsChosen || {})
      .filter(([, value]) => !!value)
      .map(([subKey]) => {
        const subId = parseInt(subKey.replace('sub-', ''), 10);
        return {subId};
      });
    arrChoices.push({choiceId, subsChosen: subs});
    return arrChoices;
  }, []);

export const validateForceChoice = (choices: DishType['choices'], mapChoices: EncodedChoicesType): ValidationErrors =>
  ((choices || []).every(
    ({isForced, choiceId}) =>
      !isForced ||
      (mapChoices[`choice-${choiceId}`] && Object.values(mapChoices[`choice-${choiceId}`]).filter(Boolean).length > 0),
  )
    ? undefined
    : {selectedChoices: 'invalid choices'});
