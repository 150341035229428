import {MutableRefObject} from 'react';

import {CodeResponse, useGoogleLogin} from '@react-oauth/google';
import styled from 'styled-components';

import {loginWithProvider, OAuthProviders} from '~/shared/services/auth';
import Button from '~/shared/components/Button';
import {getLocalizationService} from '~/shared/services/localisationService';
import googleIconSrc from '~/shared/components/SignIn/googleIcon.svg';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {flipOnLTR} from '~/shared/theme/utils';
import {flexCenter} from '~/shared/theme/FlexLayout';
import {checkFreeEmail} from '~/shared/components/SignIn/utils/authHelpers';

import {LoginProvider} from './consts';

const GoogleLoginButton = styled(Button).attrs(() => ({
  tabIndex: 0,
}))`
  ${flexCenter}
  cursor: pointer;
  width: 100%;
  color: ${({theme}) => theme.googleButton.text};
  font-weight: 700;
  background-color: ${({theme}) => theme.googleButton.background};
  border: 1px solid ${({theme}) => theme.colors.gray500};
  min-height: 50px;
  margin-bottom: 16px;

  &:hover,
  &:focus {
    background-color: ${({theme}) => theme.colors.lightBackground};
  }
`;

const GoogleIcon = styled(ImageWithAlt)`
  width: 20px;
  ${flipOnLTR`
    margin-right: 8px;
  `}
`;

export interface GoogleSectionProps {
  openProviderScreen: (options: {
    providerProps: Record<string, string | number | undefined>;
    nextLoginProvider: LoginProvider;
  }) => void;
  openLoginScreen?: (options: {
    email?: string;
    getAuthCodeForProviderConnect: boolean;
    nextProvider?: LoginProvider;
    providerProps?: Record<string, string | number | undefined>;
  }) => void;
  onSubmit?: () => void;
  isRedirectedToStepRef?: MutableRefObject<boolean>;
}

const GoogleSection = ({openProviderScreen, openLoginScreen, onSubmit, isRedirectedToStepRef}: GoogleSectionProps) => {
  const {t} = getLocalizationService();

  const login = useGoogleLogin({
    flow: 'auth-code',
    redirect_uri: 'postmessage',
    onSuccess: async (authResponse: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => {
      const {
        email,
        fullName,
        providerAccessToken: googleCode,
        shouldConnectAccount,
      } = (await loginWithProvider(OAuthProviders.GOOGLE, authResponse)) || {};

      const isFree = email && await checkFreeEmail(email);

      if (shouldConnectAccount) {
        if (isFree) {
          openProviderScreen({
            providerProps: {email, googleCode, fullName},
            nextLoginProvider: LoginProvider.GOOGLE,
          });
          return;
        }
        if (!isFree && openLoginScreen) {
          if (isRedirectedToStepRef) {
            isRedirectedToStepRef.current = true;
          }
          openLoginScreen({
            email,
            getAuthCodeForProviderConnect: true,
            nextProvider: LoginProvider.GOOGLE,
            providerProps: {email, googleCode, fullName},
          });
          return;
        }
      }

      if (onSubmit) {
        onSubmit();
      }
    },
    scope: 'openid email profile',
  });

  return (
    <GoogleLoginButton onClick={login} aria-label={t('quick_register_with_xxx_aria_label', {companyName: 'Google'})}>
      {t('continue_with_xxx', {companyName: 'Google'})}
      <GoogleIcon src={googleIconSrc} alt={t('google')} />
    </GoogleLoginButton>
  );
};

export default GoogleSection;
