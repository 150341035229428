import {memo, useState} from 'react';

import styled from 'styled-components';
import {Form} from 'react-final-form';
import {isNumber, toNumber} from 'lodash';

import {getLocalizationService} from '~/shared/services/localisationService';
import {flipOnLTR} from '~/shared/theme/utils';
import {LongButton} from '~/shared/components/Modals/InfoModals';
import FormField from '~/shared/components/FormField';
import Button from '~/shared/components/Button';
import {trackEvent} from '~/shared/services/analytics';
import {InputWrapper, ErrorText, IconError, FieldWrapper, ResendResError} from '~/shared/components/loginStyled';
import useCanResendCode from '~/shared/utils/useCanResendCode';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {useResetScrollToTopOnPageMount} from '~/shared/hooks';
import useFormAutoFocus from '~/shared/hooks/useFormAutoFocus';
import {SkeletonLoader} from '~/shared/components/Loaders';
import {SkeletonButton} from '~/shared/components/Loaders/components';

const PhoneVerificationRoot = styled.div`
  width: 100%;
  max-width: 513px;
  ${flexColumn}
  flex: 1 0 auto;
  ${flipOnLTR`
    text-align: right;
  `};
`;
const FormContentRoot = styled.div`
  ${flexColumn}
`;

const Title = styled.div`
  font-weight: 300;
  text-align: center;
  margin-top: 20px;
`;

const BrandingPageTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  color: ${({theme}) => theme.colors.secondary};
`;

const PhoneNumberSpan = styled.span`
  white-space: nowrap;
`;

const PhoneNumber = styled.div`
  font-weight: 300;
  text-align: center;
`;

const AdditionButtons = styled.div`
  margin-top: ${({pageStyle}) => (pageStyle ? 30 : 120)}px;
  ${flexColumn}
`;

const FormWrapper = styled.form``;

const StyledButton = styled(Button)`
  text-align: center;
  text-decoration: underline;
  font-size: 13px;
  line-height: 30px;
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  cursor: pointer;

  &&& {
    ${({isDisabled, theme}) =>
    isDisabled &&
      `
      color: ${theme.colors.gray500};
      background: transparent;
    `}
  }
`;

const getPhoneNumberWithDash = phone => `${phone.slice(0, 3)}-${phone.slice(3)}`;

const validate = fields => {
  const errors = {};

  if (
    !fields.authenticationCode ||
    fields.authenticationCode.length !== 5 ||
    !isNumber(toNumber(fields.authenticationCode))
  ) {
    errors.authenticationCode = 'activation_code_must_be_exact_5_digits';
  }
  return errors;
};

const PhoneVerificationSection = ({
  onSubmit,
  signUpCredentials,
  authenticationToken,
  onCancel,
  onSendActivationCode,
  activationCodeError: fatherActivationCodeError,
  sendActivationCodeError,
  title,
  pageStyle,
  withBorder,
  clearActivationCodeErrorOnFocus,
}) => {
  const [isRequestPending, setIsRequestPending] = useState(false);
  const {t} = getLocalizationService();
  const {isResendButtonDisabled, setIsResendButtonDisabled} = useCanResendCode();
  const formWrapRef = useFormAutoFocus();

  const onSubmitInner = ({authenticationCode}) => {
    setIsRequestPending(true);
    onSubmit({
      ...signUpCredentials,
      authenticationToken,
      authenticationCode,
    }, () => {
      setIsRequestPending(false);
    });
  };

  const onResendActivationCodeClick = () => {
    if (isResendButtonDisabled) {
      return;
    }

    setIsResendButtonDisabled(true);
    onSendActivationCode(signUpCredentials);
  };

  function sendTrackOnSubmitClick() {
    trackEvent('hasClickedLogin', {linkType: 'Registration - SMS Verification'});
  }

  useResetScrollToTopOnPageMount();

  return (
    <PhoneVerificationRoot className="phone-verification">
      {!title && (
        <>
          <Title>{t('you_should_receive_a_message_with_a_code_to_the_number')}</Title>
          <PhoneNumber>{getPhoneNumberWithDash(signUpCredentials.cellPhone)}</PhoneNumber>
        </>
      )}
      {title && (
        <BrandingPageTitle>{title} <PhoneNumberSpan>{getPhoneNumberWithDash(signUpCredentials.cellPhone)}</PhoneNumberSpan></BrandingPageTitle>
      )}
      <Form
        onSubmit={onSubmitInner}
        validate={validate}
        render={({handleSubmit, errors, pristine, active}) => {
          const activationCodeError = !pristine && !active && (errors.authenticationCode || fatherActivationCodeError);
          return (
            <FormWrapper onSubmit={handleSubmit} ref={formWrapRef}>
              <FormContentRoot>
                <FieldWrapper className="field-wrapper">
                  <InputWrapper className="input-wrapper">
                    <FormField
                      name="authenticationCode"
                      required
                      type="text"
                      shouldShowError={false}
                      placeholder={t('enter_your_verification_code')}
                      withBorder={withBorder}
                      className="activation-code-form-field"
                      onFocus={clearActivationCodeErrorOnFocus}
                    />
                    {activationCodeError && (pageStyle ? (
                      <IconError>{t(activationCodeError)}</IconError>
                      ) : (
                        <ErrorText>{t(activationCodeError)}</ErrorText>
                      )
                    )}
                  </InputWrapper>
                </FieldWrapper>
                <SkeletonLoader LoaderComponent={SkeletonButton} shouldShowLoader={isRequestPending}>
                  <LongButton darkMode type="submit" onClick={() => sendTrackOnSubmitClick()}>
                    {t('confirm')}
                  </LongButton>
                </SkeletonLoader>
              </FormContentRoot>
            </FormWrapper>
          );
        }}
      />
      <AdditionButtons className="addition-buttons" pageStyle>
        <StyledButton onClick={onCancel}>{t('update_phone_number')}</StyledButton>
        <StyledButton onClick={onResendActivationCodeClick} isDisabled={isResendButtonDisabled}>
          {t('i_did_not_receive_send_again')}
        </StyledButton>
        {sendActivationCodeError && <ResendResError>{sendActivationCodeError}</ResendResError>}
      </AdditionButtons>
    </PhoneVerificationRoot>
  );
};

export default memo(PhoneVerificationSection);
