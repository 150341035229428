import {memo} from 'react';

import styled from 'styled-components';

import {flipOnLTR} from '~/shared/theme/utils';

import {getLocalizationService} from '../services/localisationService';
import {Dish, MeasurementEnum} from '../store/models';
import {body14Normal} from '../theme/typography';

import {PriceLabel} from './PriceLabels';

const DishPricePerWeightWrapper = styled.div`
  display: flex;
  ${body14Normal}
  color: ${({theme}) => theme.colors.gray300};
  line-height: 16px;
`;

const StyledPriceLabel = styled(PriceLabel)`
  ${flipOnLTR`
    margin-left: 4px;
  `}
`;

type DishPricePerWeightProps = {className?: string} &
  Pick<Dish, 'pricePerMeasurementUnit' | 'measurementUnitScale' | 'measurementUnit'>;

const DishPricePerWeight = ({className, pricePerMeasurementUnit, measurementUnit, measurementUnitScale}: DishPricePerWeightProps) => {
  const {t} = getLocalizationService();

  if (!pricePerMeasurementUnit || !measurementUnit || !measurementUnitScale || measurementUnit === MeasurementEnum.NONE) {
    return null;
  }
  
  return (
    <DishPricePerWeightWrapper className={className} data-test-id="DishPricePerWeight">
      <StyledPriceLabel price={pricePerMeasurementUnit}/>
      {`/ ${measurementUnitScale} ${t(measurementUnit.toLowerCase())}`}
    </DishPricePerWeightWrapper>
  );
};

export default memo(DishPricePerWeight);
