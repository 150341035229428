import {memo} from 'react';

import {useSelector} from 'react-redux';

import {attachModal} from '~/shared/components/ReduxModal';
import {getLocalizationService} from '~/shared/services/localisationService';
import {selectUserData} from '~/shared/store/selectors';
import DiagonalHeaderView from '~/shared/components/DiagonalHeaderView';
import UpdateUserDetails from '~/common/components/UpdateUserDetails';
import {ModalContent} from '~/shared/components/Modals';

const UpdateUserDetailsModal = () => {
  const userData = useSelector(selectUserData);
  const {t} = getLocalizationService();

  const title = userData.userRequiredActions?.updateUserDetailsForm?.headerText || t('update_personal_info');
  if (userData) {
    return (
      <DiagonalHeaderView titleId="modal-title" title={title}>
        <ModalContent>
          <UpdateUserDetails />
        </ModalContent>
      </DiagonalHeaderView>
    );
  }
  return null;
};

const EnhanceUpdateUserDetailsModal = memo(UpdateUserDetailsModal);

EnhanceUpdateUserDetailsModal.hideCloseButton = true;

attachModal('update_user_details', EnhanceUpdateUserDetailsModal);
