import {ReactNode} from 'react';

import styled from 'styled-components';

import ImageWithAlt from '~/shared/components/ImageWithAlt';
import infoIcon from '~/assets/images/icons/infoIcon.svg';
import {flipOnLTR} from '~/shared/theme/utils';

const Container = styled.div<{spaceFromTop?: number}>`
  height: 100%;
  font-size: 13px;
  margin-top: ${({spaceFromTop}) => spaceFromTop || 0}px;
`;

const EstimatedDeliveryContainer = styled(Container)``;

const Content = styled(Container)<{size?: IDisclaimer['size']}>`
  background-color: ${({theme}) => theme.colors.disclaimerSurface};
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  padding-top: 12px;
  line-height: normal;
  ${flipOnLTR`
    padding-left: 8px;
  `};
`;

const InfoIconDiv = styled.div<{size: IDisclaimer['size']}>`
  display: flex;
  align-self: ${({size}) => (size === 'Narrow' ? 'flex-start' : 'center')};
  ${({size}) =>
    size === 'Narrow' &&
    `
    padding-top: 4px;
  `}
  ${flipOnLTR`
    padding-right: 16px;
    padding-left: 8px;
  `}
`;

const InfoIcon = styled(ImageWithAlt).attrs({src: infoIcon})`
  height: 12px;
`;

const DisclaimerBody = styled(Content)`
  padding: 0;
  color: ${({theme}) => theme.colors.secondary};
  line-height: 20px;
  margin-top: 0;
`;

export interface IDisclaimer {
  showInfoIcon?: boolean;
  title?: string;
  className?: string;
  children?: ReactNode;
  variant?: 'Info' | 'Neutral';
  size?: 'Wide' | 'Narrow';
}

const Disclaimer = ({className, showInfoIcon, title, children, size = 'Narrow'}: IDisclaimer) => {
  return (
    <Container className={className}>
      {title && <EstimatedDeliveryContainer>{title}</EstimatedDeliveryContainer>}

      {children && (
        <Content size={size}>
          {showInfoIcon && (
            <InfoIconDiv size={size}>
              <InfoIcon src={infoIcon} noAlt />
            </InfoIconDiv>
          )}
          <DisclaimerBody>{children}</DisclaimerBody>
        </Content>
      )}
    </Container>
  );
};

export default Disclaimer;
