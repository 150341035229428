import {Address, LocalAddress, RestaurantFromGet} from '~/shared/store/models';
import {getRestaurantStatus, RESTAURANT_STATUS_MAP} from '~/shared/utils/restaurants/restaurantStatus';
import {
  getCurrentRestaurantShoppingCartDeliveryType,
  getDefaultRuleByShoppingCartDeliveryType,
} from '~/shared/utils/restaurants/deliveryOptions';
import {getActiveDeliveryRules} from '~/shared/utils/restaurants/getActiveDeliveryRules';
import {CollectionOrderTypeViewTags} from '~/shared/store/models/Carousel';
import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';

export const getDeliveryRule = ({
  newRestaurant,
  currentAddress,
  orderViewTag,
}: {
  newRestaurant: RestaurantFromGet;
  currentAddress?: Address | LocalAddress;
  orderViewTag?: CollectionOrderTypeViewTags;
}) => {
  const resStatus = getRestaurantStatus(newRestaurant);
  if (resStatus === RESTAURANT_STATUS_MAP.CLOSED) {
    return null;
  }

  const shoppingCartDeliveryType = getCurrentRestaurantShoppingCartDeliveryType(newRestaurant, currentAddress);
  const activeRules = getActiveDeliveryRules(newRestaurant, currentAddress);

  return getDefaultRuleByShoppingCartDeliveryType(shoppingCartDeliveryType, {
    restaurant: newRestaurant,
    activeRules,
    orderViewTag,
  });
};

export const shouldChangeRestaurant = ({
  currentRestaurant,
  isDifferentRestaurantId,
  isCurrentRestaurantLoading,
  orderViewTag,
  force,
  allowDeliveryRuleChange,
  isDifferentAddress,
  currentDeliveryRule,
  currentAddress,
}: {
  currentRestaurant: RestaurantFromGet | undefined;
  currentAddress: Address | LocalAddress | undefined;
  currentDeliveryRule: DELIVERY_RULES | null;
  isDifferentRestaurantId: boolean;
  isCurrentRestaurantLoading: boolean;
  orderViewTag?: CollectionOrderTypeViewTags;
  force: boolean;
  allowDeliveryRuleChange?: boolean;
  isDifferentAddress: boolean;
}) => {
  if (isDifferentRestaurantId || isDifferentAddress || force || !currentRestaurant) {
    return true;
  }

  // While the restaurant is loading, no need to change restaurant again
  if (isCurrentRestaurantLoading) {
    return false;
  }

  const defaultDeliveryRule = getDeliveryRule({
    newRestaurant: currentRestaurant,
    currentAddress,
    orderViewTag,
  });

  return allowDeliveryRuleChange && currentDeliveryRule !== defaultDeliveryRule;
};
