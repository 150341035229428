import {memo} from 'react';

import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {getLocalizationService} from '~/shared/services/localisationService';
import {body14Bold, body14Normal} from '~/shared/theme/typography';
import ButtonBase from '~/shared/components/Button';
import DiscountImg from '~/assets/images/icons/discount-coupon.svg?react';
import {FlexCenterVertically} from '~/shared/theme/FlexLayout';
import {media} from '~/shared/theme/media';
import {selectA11yContrast} from '~/shared/store/selectors';

const Root = styled(FlexCenterVertically)`
  background-color: ${({theme}) => theme.colors.orange10};
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 4px;
  line-height: 20px;
  margin: 12px 16px 0;
  ${media.minLargeTablet`
    margin: 12px 0 0;
  `}
`;

const InfoSection = styled.div``;

const Title = styled.div`
  ${body14Bold}
  margin-bottom: 2px;
`;

const Body = styled.div`
  ${body14Normal}
  margin-bottom: 8px;
`;

const Button = styled(ButtonBase)`
  ${body14Bold}
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  line-height: 18px;
`;

interface CouponAutoChangedDisclaimerProps {
  onSelectCouponClick: () => void;
}

const CouponAutoChangedDisclaimer = ({onSelectCouponClick}: CouponAutoChangedDisclaimerProps) => {
  const {t} = getLocalizationService();
  const isContrastMode = useSelector(selectA11yContrast);

  return (
    <Root>
      <InfoSection>
        <Title>{t('you_pay_lass')}</Title>
        <Body>{t('we_activated_a_better_coupon_for_you')}</Body>
        <Button onClick={onSelectCouponClick}>{t('change_coupon')}</Button>
      </InfoSection>
      {!isContrastMode && <DiscountImg />}
    </Root>
  );
};

export default memo(CouponAutoChangedDisclaimer);
