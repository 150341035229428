import {memo, useMemo} from 'react';

import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {createLogger} from '~/shared/logging';

const logger = createLogger('DummyTracker');

// for circumference calculation we use diameter of 95px minus stroke-width 4px x2
const TRACKER_CIRCUMFERENCE = (95 - (4 * 2)) * Math.PI;
// Containers

const TrackerContainer = styled.div`
  position: absolute;
  bottom: -${47.5 - 16}px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const TrackerBody = styled.div`
  width: 95px;
  height: 95px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({theme}) => theme.zIndex.languageChangeButton};
`;

// Tracker circle progress components

const CirclesSvgContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const CirclesSvg = styled.svg`
  transform: rotate(-90deg);
`;

const InnerCircle = styled.circle`
  stroke: ${(({theme}) => theme.dummyTracker.innerCircle.strokeColor)};
`;

const ProgressCircle = styled.circle`
  stroke: ${(({theme}) => theme.dummyTracker.progressCircle.strokeColor)};
  transition: stroke-dashoffset 0.5s linear 0.6s;
`;

// Time labels components

const TimeLabels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  z-index: ${({theme}) => theme.zIndex.languageChangeButton};
`;

const MinutesLabel = styled.span`
  font-size: 20px;
  font-weight: 700;
`;

const TimeUnitLabel = styled.span`
  line-height: 19px;
  color: ${({theme}) => theme.dummyTracker.timeUnitLabel.color};
`;

const DummyTracker = ({minutesToRender, totalEta, isOverTwoHours}: {
  minutesToRender: number | string | null;
  totalEta: number;
  isOverTwoHours: boolean;
}) => {
  const {t} = getLocalizationService();

  const offset = useMemo(() => {
    if (isOverTwoHours) {
      return 0;
    }

    if (typeof minutesToRender !== 'number') {
      logger.error('incompatible type of minutesToRender received');
      return 0;
    }

    const percent = ((minutesToRender * 100) / totalEta) / 100;
    return TRACKER_CIRCUMFERENCE - (percent * TRACKER_CIRCUMFERENCE);
  }, [minutesToRender, totalEta, isOverTwoHours]);

  if (typeof minutesToRender === 'number' && minutesToRender <= 0) {
    return null;
  }

  return (
    <TrackerContainer>
      <TrackerBody>
        <CirclesSvgContainer>
          <CirclesSvg width={95} height={95}>
            <InnerCircle strokeWidth={4} fill="white" r={43.5} cx={47.5} cy={47.5} />
            <ProgressCircle
              strokeWidth={4}
              className="yellow-stroke"
              fill="transparent"
              r={43.5}
              cx={47.5}
              cy={47.5}
              strokeDasharray={`${TRACKER_CIRCUMFERENCE} ${TRACKER_CIRCUMFERENCE}`}
              strokeDashoffset={offset}
            />
          </CirclesSvg>
        </CirclesSvgContainer>

        <TimeLabels>
          <MinutesLabel>{minutesToRender}</MinutesLabel>
          {!isOverTwoHours && <TimeUnitLabel>{t('dummy_tracker_minutes_time_unit')}</TimeUnitLabel>}
        </TimeLabels>
      </TrackerBody>
    </TrackerContainer>
  );
};

export default memo(DummyTracker);
