import {memo, useState, useCallback} from 'react';

import styled from 'styled-components';

import useOnInactivity from '~/shared/hooks/useOnInactivity';
import {getLocalizationService} from '~/shared/services/localisationService';

const IframeContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: ${({theme}) => theme.colors.surface};
`;

const StyledIframe = styled.iframe`
  border: 0;
  background-color: 'transparent';
  width: 100%;
  height: 585px;
`;

const SCOOBER_MAP_RELOAD_AFTER_INACTIVITY_MS = 30000;

const ScooberTracker = memo(({trackerUrl}) => {
  const {currentLanguageKey} = getLocalizationService();

  const [invalidateIframeKey, setInvalidateIframeKey] = useState(0);

  const invalidateIframe = useCallback(() => setInvalidateIframeKey(key => key + 1), []);

  useOnInactivity(SCOOBER_MAP_RELOAD_AFTER_INACTIVITY_MS, invalidateIframe);

  const scooberUrlByCurrentLang = trackerUrl.replace(/lang=[A-Za-z]+/, `lang=${currentLanguageKey}`);

  return (
    <IframeContainer>
      <StyledIframe key={invalidateIframeKey} src={scooberUrlByCurrentLang} />
    </IframeContainer>
  );
});

export default ScooberTracker;
