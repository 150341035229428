import {api} from '~/shared/services/bffService';

import {makeThunkAsyncActionCreator} from '../../redux-toolbelt';

export const getChains = makeThunkAsyncActionCreator<never, any>('getChains', async () => {
  try {
    const result = await api.fetchRestaurantGroups({type: 'chain'});

    if (result?.restaurantGroupList) {
      return result?.restaurantGroupList;
    }
  } catch (error: any) {
    if (error?.message) {
      throw new Error(error.message);
    }
    throw new Error('failed to run getChains');
  }
});
