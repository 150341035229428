import {useContext, useMemo} from 'react';

import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {ActionButton} from '~/shared/components/Button';
import {RippleLoader} from '~/shared/components/Loaders';
import {flexCenter} from '~/shared/theme/FlexLayout';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {BillingLineType} from '~/shared/consts/checkoutConsts';

import ShoppingCart from './ShoppingCart';

const PaymentContainer = styled.div`
  ${flexCenter};
  padding: 16px;
`;

const PaymentActionButton = styled(ActionButton)`
  border-radius: 1px;
  box-shadow: none;

  &&& {
    width: 100%;
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  ${flipOnLTR`
    right: 10%;
  `};
  align-self: flex-end;
`;

type PaymentsButtonProps = {
  onPayment: () => void;
  disabled: boolean;
};

const ProceedToPaymentButton = ({onPayment, disabled}: PaymentsButtonProps) => {
  const {t} = getLocalizationService();
  const {isDirtyShoppingCart, currentRestaurant, billingLines, isMenuLoading} = useContext(ShoppingCart.Context);

  const totalToChargePrice = useMemo(
    () => billingLines?.find(({type}: {type: BillingLineType}) => type === 'TotalToCharge')?.amount,
    [billingLines],
  );

  const isPaymentDisabled = disabled || isDirtyShoppingCart || !currentRestaurant || isMenuLoading;

  return (
    <PaymentContainer>
      <PaymentActionButton
        full
        onClick={onPayment}
        disabled={isPaymentDisabled}
        aria-label={t('shopping_cart_continue_to_payment_aria_label', {totalPrice: totalToChargePrice || 0})}
        testId="proceedToCheckoutBtn"
      >
        {isDirtyShoppingCart && (
          <LoaderContainer>
            <RippleLoader size={32} />
          </LoaderContainer>
        )}
        <div>{t('continue_to_payment')}</div>
      </PaymentActionButton>
    </PaymentContainer>
  );
};

export default ProceedToPaymentButton;
