import styled from 'styled-components';

import {Skeleton} from '~/shared/components/Loaders';
import {media} from '~/shared/theme/media';

const SkeletonButton = styled(Skeleton)`
  width: 100%;
  height: 50px;
  ${media.minLargeTablet`
    margin-bottom: 16px;
  `}
`;

export default SkeletonButton;
