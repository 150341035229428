import {ReactNode, useCallback, useEffect, useState} from 'react';

import {CSSObject} from '@emotion/serialize';
// eslint-disable-next-line no-restricted-imports
import BaseSelect, {Props, components, OptionTypeBase, StylesConfig, ValueContainerProps} from 'react-select';

const indicatorSeparator = (): CSSObject => ({backgroundColor: 'inherit'});

export const DropDownValueContainer = ({children, ...props}: ValueContainerProps<OptionTypeBase, false>) => (
  <components.ValueContainer {...props}>{children}</components.ValueContainer>
);

type DropDownProps = Props<OptionTypeBase, false> & {
  name: string;
  children?: ReactNode;
  styles?: StylesConfig<OptionTypeBase, false>;
};

const DropDown = ({children, styles, name, ...props}: DropDownProps) => {
  if (!name) {
    throw new Error('to support ssr with react-select components, please pass a name to each instance of Select.');
  }

  const [isOpen, setIsOpen] = useState(false);

  const dropDownElId = `${name}_dropdown`;

  useEffect(() => {
    const dropdownElement = document.getElementById(dropDownElId);
    const isAriaExpanded = dropdownElement?.getAttribute?.('aria-expanded');

    if (!isAriaExpanded || !isOpen) {
      dropdownElement?.setAttribute('aria-expanded', 'false');
    }
    if (isOpen) {
      dropdownElement?.setAttribute('aria-expanded', 'true');
    }
  }, [dropDownElId, isOpen]);

  const onMenuOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onMenuClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const myStyles: StylesConfig<OptionTypeBase, false> = {
    ...styles,
    control: (base, controlProps) => ({
      ...(styles?.control?.(base, controlProps) || base),
      boxShadow: 'none',
      borderColor: '#cccccc !important',
      cursor: 'pointer',
    }),

    // @ts-expect-error react-select is using CSSObject of emotion for TS. it doesn't includes transform
    dropdownIndicator: base => ({
      ...base,
      transition: 'all .2s ease',
      transform: isOpen ? 'rotate(180deg)' : null,
    }),
    option: (base, optionProps) => ({
      ...(styles?.option?.(base, optionProps) || base),
      backgroundColor: optionProps.isSelected ? '#F8F5F2' : 'white',
      color: '#0a3847',
    }),
    indicatorSeparator,
  };

  return (
    <BaseSelect
      {...props}
      id={dropDownElId}
      name={name}
      isOpen={isOpen}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      styles={myStyles}
    >
      {children}
    </BaseSelect>
  );
};

export default DropDown;
