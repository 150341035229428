import {MutableRefObject, useCallback, useMemo, useState} from 'react';

import {useSelector} from 'react-redux';

import {
  orderToSubmitSelector,
  selectCurrentCoupon,
  selectCheckoutPaymentsIfExists,
  selectUserData,
  selectIsShoppingCartHasAgeRestrictionDishOrSub,
  selectShouldOpenModal,
  selectDinningRoomNoPackingRequired,
  selectCurrentRestaurant,
  selectOrderRemarks,
} from '~/shared/store/selectors';
import actions, {resetRestaurantsFilter} from '~/shared/store/actions';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {pushRoute} from '~/shared/router';
import {trackEvent} from '~/shared/services/analytics';
import {getLocalizationService} from '~/shared/services/localisationService';
import {roundDecimal} from '~/shared/utils/general';
import {getCompanyDetails, timeValidation} from '~/shared/services/payments';
import store from '~/shared/store';
import {selectIsAgeConfirmed} from '~/shared/store/storeModules/ageRestriction/ageRestrictionSelectors';
import {ACTION_MADE_FROM_ENUM} from '~/shared/utils/ageRestriction';
import {Address, CheckoutPayment, IShoppingCart, isRemoteAddress, OrderSubmitError} from '~/shared/store/models';
import {PaymentMethod} from '~/shared/consts/paymentConsts';

import useCheckoutAddress from './useCheckoutAddress';

const createRemarksRegex = (prefix?: string, maxLength?: number) =>
  (!prefix || !maxLength
    ? new RegExp('^.+$')
    : new RegExp(
      `^(?!${prefix.toUpperCase()}|${prefix.toLowerCase()}.*$)(.){${maxLength}}$|^${prefix.toUpperCase()}(.){${maxLength}}$|^${prefix.toLowerCase()}(.){${maxLength}}$`,
    ));

interface UseCheckoutSubmittionProps {
  addressRef: MutableRefObject<HTMLFormElement | undefined>;
  addressFormValuesRef: MutableRefObject<Partial<Address>>;
}

interface ICheckoutError {
  errorDesc: string;
  id?: string;
}

const useCheckoutSubmittion = ({addressRef, addressFormValuesRef}: UseCheckoutSubmittionProps) => {
  const {t} = getLocalizationService();
  const {getState, dispatch, dispatchThunk} = store;
  const userData = useSelector(selectUserData);
  const checkoutPayments = useSelector(selectCheckoutPaymentsIfExists);
  const billingLines = useSelector((state: {shoppingCart?: IShoppingCart}) => state.shoppingCart?.billingLines);
  const currentCoupon = useSelector(selectCurrentCoupon);
  const dinningRoomNoPackingRequired = useSelector(selectDinningRoomNoPackingRequired);
  const currentRestaurant = useSelector(selectCurrentRestaurant);

  const {currentAddress, isAddressWithoutId} = useCheckoutAddress();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [preSubmitErrors, setPreSubmitErrors] = useState<ICheckoutError[] | null>(null);
  const [validationErrors, seValidationErrors] = useState<ICheckoutError[] | null>(null);
  const [paymentsRemarksValues, setPaymentsRemarksValues] = useState<Record<string, string>>({});
  const [showPaymentsRemarksError, setPaymentsRemarksError] = useState(false);
  const orderRemarkValue = useSelector(selectOrderRemarks);

  const [orderFailure, setOrderFailure] = useState<{
    cardId?: number;
    paymentMethod?: string;
    actionSuggestionType?: string;
  } | null>(null);

  const validUserRemarks = Boolean(
    !currentRestaurant?.orderRemarks?.isVisible ||
    !currentRestaurant?.orderRemarks?.isRequired ||
    (currentRestaurant?.orderRemarks?.isRequired &&
      currentRestaurant?.orderRemarks?.isVisible &&
      orderRemarkValue !== ''),
  );

  const setOrderRemarkValue = useCallback((value: string) => {
    seValidationErrors(null);
    dispatch(actions.setOrderRemarks(value));
  }, [dispatch]);

  const isShoppingCartHasAgeRestrictionDishOrSub = useSelector(selectIsShoppingCartHasAgeRestrictionDishOrSub);
  const isAgeConfirmed = useSelector(selectIsAgeConfirmed);

  const shouldOpenModal = useSelector(selectShouldOpenModal);
  const shouldShowAgeConfirm = !isAgeConfirmed && isShoppingCartHasAgeRestrictionDishOrSub;

  const paymentMethodNames = useMemo(
    () =>
      checkoutPayments?.reduce(
        (result: PaymentMethod[], {paymentMethod, isTenbisCredit, sum}) => {
          if (sum > 0) {
            return result.concat(isTenbisCredit ? '10bis_credit' as PaymentMethod : paymentMethod);
          }
          return result;
        },
        [],
      ),
    [checkoutPayments],
  );

  const subTotalAmount = billingLines?.find(({type}: {type: string}) => type === 'SubTotal')?.amount;

  const paymentRemarkConfiguration = useMemo(() => {
    const userHasCompanyPatch = userData?.userEnabledFeatures.includes('ShowRemarksOnPayments');
    if (userHasCompanyPatch) {
      if (!currentAddress) {
        return null;
      }
      const {startTime, endTime, prefix, maxLength, inputValidationRegex, customErrorMessage, customInputPlaceholder} =
        getCompanyDetails({
          addressCompanyId: isRemoteAddress(currentAddress) ? currentAddress.addressCompanyId : undefined,
          companyId: userData?.companyId,
        });

      const isValidTime = timeValidation({startTimeString: startTime, endTimeString: endTime});

      return isValidTime
        ? {
            prefix,
            maxLength,
            inputValidationRegex,
            customErrorMessage,
            customInputPlaceholder,
          }
        : null;
    }

    return null;
  }, [userData, currentAddress]);

  const handleTipChange = useCallback(
    ({value, isPercentage}: {value: number; isPercentage: boolean}) => {
      const tipValue = roundDecimal(
        isPercentage && subTotalAmount ? Number(subTotalAmount * (value / 100)) : Number(value),
      );
      return ManagerProvider.changeTipAmount({
        tipAmount: tipValue,
        debounce: false,
      });
    },
    [subTotalAmount],
  );

  const changeOrUpdateAddress = useCallback(async () => {
    const enhancedAddress = {
      ...(currentAddress || {}),
      ...(addressFormValuesRef.current || {}),
      comments: addressFormValuesRef.current?.comments || '',
      phone01: addressFormValuesRef.current?.phone01 || userData?.cellphone || '',
    } as Address;

    try {
      if (isAddressWithoutId) {
        const newAddressWithId: Address = await dispatchThunk(actions.insertAddress(enhancedAddress));
        // using changeAddress from ManagerProvider for the setAddressInOrder request (setAddress).
        await ManagerProvider.changeAddress({
          address: newAddressWithId,
          force: true,
          searchRestaurants: false,
        });
      } else if (addressFormValuesRef.current) {
        await ManagerProvider.updateAddress(enhancedAddress);
      }
    } catch (e: unknown) {
      setPreSubmitErrors(e as ICheckoutError[]);
    }
  }, [addressFormValuesRef, currentAddress, dispatchThunk, isAddressWithoutId, userData?.cellphone]);

  const handleCheckoutSubmit = useCallback(
    async ({shouldSkipAgeRestriction}: {shouldSkipAgeRestriction: boolean}) => {
      if (!validUserRemarks) {
        seValidationErrors([
          {
            id: 'user_remarks',
            errorDesc: t('required_field'),
          },
        ]);
        return;
      }
      dispatch(actions.setOrderRemarks(orderRemarkValue || null));

      setIsSubmitting(true);
      setPreSubmitErrors(null);

      if (addressRef.current) {
        const addressFormValid = addressRef.current.checkValidity?.();

        if (addressFormValid) {
          await changeOrUpdateAddress();
        } else {
          setPreSubmitErrors([{errorDesc: t('checkout_address_component_error')}]);
          setIsSubmitting(false);
          return;
        }
      }

      trackEvent('hasSubmittedOrder', {
        paymentMethod: paymentMethodNames.join(),
        basketValue: subTotalAmount,
        voucherCode: currentCoupon?.isActive ? currentCoupon?.code : null,
        voucherValue: currentCoupon?.isActive ? currentCoupon?.couponValueForOrder : null,
      });

      if (currentRestaurant?.showPackingOption) {
        trackEvent('hasCheckedOutDiscountCampus', {
          dinningRoomNoPackingRequired,
        });
      }

      if (paymentRemarkConfiguration) {
        const {prefix, maxLength, inputValidationRegex} = paymentRemarkConfiguration;
        const paymentRemarksRegexValidate = createRemarksRegex(prefix, maxLength);
        const isInvalidRemarks = checkoutPayments.some(payment => {
          const remark = paymentsRemarksValues[`${payment.cardId}_${payment.paymentMethod}`];
          if (payment.sum && (!remark || !remark.match(inputValidationRegex || paymentRemarksRegexValidate))) {
            return true;
          }
          return false;
        });

        if (isInvalidRemarks) {
          setPaymentsRemarksError(true);
          setIsSubmitting(false);
          return;
        }

        setPaymentsRemarksError(false);
      }

      if (shouldShowAgeConfirm && !shouldSkipAgeRestriction) {
        dispatch(actions.setActionMadeFrom(ACTION_MADE_FROM_ENUM.CHECKOUT));
        if (shouldOpenModal) {
          dispatch(
            actions.setCurrentModal('age_confirm_modal', {
              onConfirm: () => {
                handleCheckoutSubmit({shouldSkipAgeRestriction: true});
              },
            }),
          );
          setIsSubmitting(false);
          return;
        }
        pushRoute('/confirm-age');
        return;
      }

      try {
        await ManagerProvider.changeOrderPayments({
          payments: checkoutPayments,
        });
        // TODO check why updateOrderData is executing changeOrderPayments (it happened twice)
        // TASK: https://tenbis.visualstudio.com/TenBis/_workitems/edit/72599
        await ManagerProvider.updateOrderData(paymentRemarkConfiguration, paymentsRemarksValues);

        const state = getState();
        const orderToSubmit = orderToSubmitSelector(state);

        const {data} = await ManagerProvider.submitOrder(orderToSubmit) || {};

        if (!data) {
          throw new Error('no data from submit order');
        }

        const {orderData} = data;

        trackEvent('hasOrderedFromFilterResult', {orderData});
        // clean up filters after the event was sent
        store.dispatch(resetRestaurantsFilter());
        return pushRoute(`/order-success/${orderData.orderId}?firstRedirectToOrderSuccess=true`);
      } catch (error) {
        const {submitOrderFailurePaymentMethod, submitOrderFailurePaymentId, submitOrderFailureActionSuggestionType} = (
          error as OrderSubmitError
        )?.data || {};

        if (submitOrderFailurePaymentMethod || submitOrderFailurePaymentId || submitOrderFailureActionSuggestionType) {
          setOrderFailure({
            cardId: submitOrderFailurePaymentId,
            paymentMethod: submitOrderFailurePaymentMethod,
            actionSuggestionType: submitOrderFailureActionSuggestionType,
          });
        }
        setIsSubmitting(false);
      }
    },
    [
      validUserRemarks,
      dispatch,
      orderRemarkValue,
      addressRef,
      paymentMethodNames,
      subTotalAmount,
      currentCoupon?.isActive,
      currentCoupon?.code,
      currentCoupon?.couponValueForOrder,
      currentRestaurant?.showPackingOption,
      paymentRemarkConfiguration,
      shouldShowAgeConfirm,
      checkoutPayments,
      paymentsRemarksValues,
      getState,
      t,
      changeOrUpdateAddress,
      dinningRoomNoPackingRequired,
      shouldOpenModal,
    ],
  );

  interface SetPaymentsRemarksPros {
    value: string;
    cardId: number;
    paymentMethod: CheckoutPayment;
  }

  const setPaymentsRemarks = ({value, cardId, paymentMethod}: SetPaymentsRemarksPros) => {
    if (cardId >= 0 && paymentMethod) {
      setPaymentsRemarksValues({
        ...paymentsRemarksValues,
        [`${cardId}_${paymentMethod}`]: value || '',
      });

      setPaymentsRemarksError(false);
      return;
    }

    // for mobile useage - as we the same value for all payments.
    let newPaymentsRemark = {};
    checkoutPayments.forEach(payment => {
      newPaymentsRemark = {
        ...newPaymentsRemark,
        [`${payment.cardId}_${payment.paymentMethod}`]: value,
      };
    });

    setPaymentsRemarksValues(newPaymentsRemark);
    setPaymentsRemarksError(false);
  };

  return {
    isSubmitting,
    validationErrors,
    preSubmitErrors,
    orderFailure,
    subTotalAmount,
    showPaymentsRemarksError,
    paymentRemarkConfiguration,
    paymentsRemarksValues,
    handleCheckoutSubmit,
    handleTipChange,
    setPreSubmitErrors,
    setPaymentsRemarks,
    setOrderFailure,
    orderRemarkValue,
    setOrderRemarkValue,
  };
};

export default useCheckoutSubmittion;
