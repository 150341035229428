import {memo} from 'react';

import {noop} from 'lodash';
import styled from 'styled-components';

import IncreaseIcon from '~/assets/images/icons/pluseSignBig.svg?react';
import DecreaseIcon from '~/assets/images/icons/minusSignBig.svg?react';
import {flexCenterVertically} from '~/shared/theme/FlexLayout';
import Button from '~/shared/components/Button';
import {getLocalizationService} from '~/shared/services/localisationService';

type TSize = 'small' | 'big';

const Root = styled.div<{size: TSize}>`
  width: 100%;
  height: 100%;
  ${flexCenterVertically};
  border: ${({theme}) => `1px solid ${theme.menu.dishModal.amountBorder}`};
  justify-content: space-between;
  flex-direction: ${({theme: {isLTR}}) => (isLTR ? 'row' : 'row-reverse')};
  ${({size}) => (size === 'big' ? `
    padding: 8px;
    height: 40px;
    width: 100px;
    border-radius: 32px;
  ` : `
    border-radius: 16px;
    width: 74px;
    height: 26px;
  `)}
`;

const Amount = styled.div<{fontSize?: number}>`
  text-align: center;
  color: ${({theme}) => theme.colors.secondary};
  font-size: ${({fontSize}) => fontSize || 16}px;
  font-weight: bold;
`;

const AmountButton = styled(Button).attrs(({disableTabIndex}: {disableTabIndex?: boolean}) => ({
  tabIndex: disableTabIndex ? -1 : 0,
}))<{disableTabIndex?: boolean}>`
  cursor: pointer;
  display: flex;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
    background-color: transparent !important;
    color: inherit;
  }
`;

const IncreaseButton = styled(AmountButton)`
  path {
    fill: ${({disabled, theme: {colors}}) => (disabled ? colors.gray500 : colors.surfacePrimaryAction)};
  }
`;

const DecreaseButton = styled(AmountButton)`
  rect,
  path {
    fill: ${({disabled, theme: {colors}}) => (disabled ? colors.gray500 : colors.surfacePrimaryAction)};
  }
`;

type AmountCounterProps = {
  className?: string;
  quantity: number;
  increaseQuantity: () => void;
  decreaseQuantity: () => void;
  fontSize?: number;
  isIncreaseQuantityDisbled?: boolean;
  disableTabIndex?: boolean;
  itemName: string;
  size: 'small' | 'big';
};

const AmountCounter = ({
  className,
  quantity,
  increaseQuantity,
  decreaseQuantity,
  fontSize,
  isIncreaseQuantityDisbled,
  disableTabIndex,
  itemName,
  size = 'small',
}: AmountCounterProps) => {
  const {t} = getLocalizationService();
  const isDecreaseButtonDisabled = quantity === 1;
  const removeDishAreaLabel = `${t('reduce_number_of_items', {dishName: itemName})}`;
  return (
    <Root size={size} className={className}>
      <DecreaseButton
        testId="decreaseQuantityBtn"
        disableTabIndex={disableTabIndex}
        onClick={isDecreaseButtonDisabled ? noop : decreaseQuantity}
        disabled={isDecreaseButtonDisabled}
        aria-label={removeDishAreaLabel}
      >
        <DecreaseIcon />
      </DecreaseButton>
      <Amount role="alert" fontSize={fontSize} data-test-id="quantityLabel">
        {quantity}
      </Amount>
      <IncreaseButton
        testId="increaseQuantityBtn"
        disableTabIndex={disableTabIndex}
        onClick={isIncreaseQuantityDisbled ? noop : increaseQuantity}
        disabled={isIncreaseQuantityDisbled}
        aria-label={`${t('add')} ${itemName}`}
      >
        <IncreaseIcon />
      </IncreaseButton>
    </Root>
  );
};

export default memo(
  AmountCounter,
  (
    {
      quantity: prevQuantity,
      fontSize: prevFontSize,
      isIncreaseQuantityDisbled: prevIsIncreaseQuantityDisbled,
      disableTabIndex: prevDisableTabIndex,
    },
    {quantity, fontSize, isIncreaseQuantityDisbled, disableTabIndex},
  ) =>
    quantity === prevQuantity &&
    fontSize === prevFontSize &&
    isIncreaseQuantityDisbled === prevIsIncreaseQuantityDisbled &&
    disableTabIndex === prevDisableTabIndex,
);
