import {useCallback} from 'react';

import {paypalEnvironment} from '~/shared/config';
import {createLogger} from '~/shared/logging';
import {onPayPalAuthorized} from '~/shared/services/payPalHelper';

// Todo: rewrite as .ts and move onPayPalAuthorized inside useOnPaypalAuthorized (delete onPayPalAuthorized.ts)

const logger = createLogger('PayPalButton');

const payPalStyles = {
  small: {
    color: 'white',
    shape: 'rect',
    size: 'small',
    tagline: 'false',
    label: 'paypal',
    height: 40,
  },
  large: {
    color: 'white',
    shape: 'rect',
    size: 'responsive',
    tagline: 'false',
    label: 'paypal',
    height: 40,
  },
  primary: {
    color: 'blue',
    shape: 'rect',
    size: 'responsive',
    tagline: 'false',
    label: 'paypal',
    height: 40,
  },
};

const authorizeClient = async ({clientToken}) => {
  const braintreeClient = await import(
    /* webpackChunkName: "paypal" */ /* webpackMode: "lazy" */ 'braintree-web/client'
  );
  return new Promise((resolve, reject) => {
    braintreeClient.create(
      {
        authorization: clientToken,
      },
      (clientErr, clientInstance) => {
        if (clientErr) {
          reject(clientErr);
          return;
        }
        resolve(clientInstance);
      },
    );
  });
};

const collectDeviceData = async ({clientInstance}) => {
  const dataCollector = await import(
    /* webpackChunkName: "paypal" */ /* webpackMode: "lazy" */ 'braintree-web/data-collector'
  );
  return new Promise((resolve, reject) => {
    dataCollector.create(
      {
        client: clientInstance,
        paypal: true,
      },
      (err, dataCollectorInstance) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(dataCollectorInstance.deviceData);
      },
    );
  });
};

const payPalCheckoutInstance = async ({clientInstance}) => {
  const paypalCheckout = await import(
    /* webpackChunkName: "paypal" */ /* webpackMode: "lazy" */ 'braintree-web/paypal-checkout'
  );
  return new Promise((resolve, reject) => {
    paypalCheckout.create(
      {
        client: clientInstance,
      },
      (paypalCheckoutErr, paypalCheckoutInstance) => {
        if (paypalCheckoutErr) {
          reject(paypalCheckoutErr);
          return;
        }
        resolve(paypalCheckoutInstance);
      },
    );
  });
};

const paypalButtonRender = async ({
  currentLanguageKey,
  buttonId,
  paypalCheckoutInstance,
  onAuthorized,
  onError,
  variant,
  deviceDataPromise,
  onPaypalButtonRender,
}) => {
  const elementExists = document.getElementById(buttonId);
  if (!elementExists) {
    logger.log(`Trying to render paypal button to button with id - ${buttonId} that doesn't exist`);
    return;
  }

  const paypal = await import(/* webpackChunkName: "paypal" */ /* webpackMode: "lazy" */ 'paypal-checkout');

  onPaypalButtonRender();

  paypal.Button.render(
    {
      env: paypalEnvironment,
      locale: currentLanguageKey === 'he' ? 'he_IL' : 'en_US',
      commit: false, // This will add the transaction amount to the PayPal button
      style: payPalStyles[variant],
      onError: err => {
        onError(err);
      },
      onAuthorize: data => {
        paypalCheckoutInstance.tokenizePayment(data).then(async payload => {
          const deviceData = await deviceDataPromise;
          onAuthorized({payload, deviceData});
        });
      },
      payment: () => {
        return paypalCheckoutInstance.createPayment({
          flow: 'vault', // Required
          amount: 10.0, // Required
          currency: 'ILS', // Required
        });
      },
    },
    buttonId,
  );
};

export async function connectPayPalButton({
  clientToken,
  buttonId,
  onAuthorized,
  onError,
  onPaypalButtonRender,
  variant,
  currentLanguageKey,
}) {
  const clientInstance = await authorizeClient({clientToken});

  const deviceDataPromise = collectDeviceData({clientInstance});

  const paypalCheckoutInstance = await payPalCheckoutInstance({clientInstance});

  paypalButtonRender({
    currentLanguageKey,
    buttonId,
    paypalCheckoutInstance,
    onAuthorized,
    onError,
    variant,
    deviceDataPromise,
    onPaypalButtonRender,
  });
}

export const useOnPaypalAuthorized = ({onSuccess, onError}) => {
  return useCallback(
    async ({payload, deviceData}) => onPayPalAuthorized({payload, deviceData, onSuccess, onError}),
    [onError, onSuccess],
  );
};
