import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';

const shouldChangeOrder = ({
  shouldChangeDeliveryMethod,
  formOrderType,
  initialOrderType,
  isSelectedSameDate,
  isSelectedSameTime,
}: {
  shouldChangeDeliveryMethod: boolean;
  formOrderType: DELIVERY_RULES | 'pickup';
  initialOrderType: DELIVERY_RULES | 'pickup';
  isSelectedSameDate: boolean;
  isSelectedSameTime: boolean;
}) => {
  if (shouldChangeDeliveryMethod) {
    return true;
  }

  if (formOrderType === initialOrderType && formOrderType !== DELIVERY_RULES.FUTURE) {
    return false;
  }

  return !(formOrderType === initialOrderType &&
    formOrderType === DELIVERY_RULES.FUTURE &&
    isSelectedSameDate && isSelectedSameTime);
};

export default shouldChangeOrder;
