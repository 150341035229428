import {memoize} from 'lodash';

import {trackEvent} from '~/shared/services/analytics';
import apiService from '~/shared/services/apiService';
import {FormNamesEnum} from '~/shared/services/analytics/analyticsModels';
import {createLogger} from '~/shared/logging';
import {getLocalizationService} from '~/shared/services/localisationService';
import {LOGIN_SCREEN_TYPES} from '~/shared/components/SignIn/consts';

const logger = createLogger('onSubmitEmailHandler');

const isErrorWithMessage = (error: unknown): error is {message: string} =>
  !!error &&
  typeof error === 'object' &&
  'message' in error &&
  typeof error.message === 'string';

type OnSubmitEmailHandlerDeps = {
  setResErrors?: (errors: string | null) => void;
  email?: string;
  setEmailEntry?: (email: string) => void;
  shouldSendTrackEvent: boolean;
  linkType?: string;
  setCurrentLoginScreen?: (screen: string) => void;
  setVerificationDetails?: (args: {
    authenticationToken: string;
    isPhoneMethod: boolean;
    lastFourPhoneDigits: string;
  }) => void;
};

export const onSubmitEmailHandler = async ({
  setResErrors,
  email,
  setEmailEntry,
  shouldSendTrackEvent,
  linkType,
  setCurrentLoginScreen,
  setVerificationDetails,
}: OnSubmitEmailHandlerDeps): Promise<{
  isPasswordScreen: boolean;
} | undefined> => {
  const {t} = getLocalizationService();

  setResErrors?.(null);
  if (!email) {
    logger.error('onSubmitEmail received empty email', email);
    return;
  }

  setEmailEntry?.(email);
  if (shouldSendTrackEvent) {
    // preventing re-sending auth code to send login track.
    trackEvent('hasClickedLogin', {linkType});
  }

  try {
    const {data} = await apiService.getUserAuthenticationDataAndSendAuthenticationCodeToUser({email});

    const isPasswordScreen = data.authenticationMethod === 'Password';
    const isPhoneMethod = data.codeAuthenticationData.sendingMethod === 'Phone';

    setCurrentLoginScreen?.(isPasswordScreen ? LOGIN_SCREEN_TYPES.password : LOGIN_SCREEN_TYPES.verification);

    setVerificationDetails?.({
      authenticationToken: data.codeAuthenticationData.authenticationToken,
      isPhoneMethod,
      lastFourPhoneDigits: data.codeAuthenticationData.lastFourPhoneDigits,
    });

    return {
      isPasswordScreen,
    };
  } catch (error) {
    const formErrorType = isErrorWithMessage(error) ? error?.message : t('something_went_wrong');
    trackEvent('hasFormError', {
      formType: '2FA Login Form',
      formErrorType,
      linkType,
    });
    trackEvent('formErrorMessageView', {
      formName: FormNamesEnum.LOGIN,
      errorType: formErrorType,
    });
    const errorType = 'Error while fetching getUserAuthenticationDataAndSendAuthenticationCodeToUser';
    logger.error(errorType, {email, e: error, fingerprint: errorType});
    setResErrors?.(formErrorType);
  }
};

export const checkFreeEmail = memoize(async (emailAddress: string) => {
  try {
    const response = await apiService.isEmailFree({emailAddress});
    return response?.data;
  } catch (error) {
    return false;
  }
}, (emailAddress: string) => emailAddress);
