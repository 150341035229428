/* eslint-disable camelcase */
import {pickBy} from 'lodash';

import {createLogger} from '~/shared/logging';

import {IAddress} from '../store/models';

const logger = createLogger('geocoder');

export async function geocode(googleGeoCoderParams: GeocodeParams) {
  logger.log('Geocode using a browser script', {googleGeoCoderParams});

  if (typeof window === 'undefined') {
    logger.error('geocoder must run in browser');
    return;
  }

  if (!window.google?.maps?.Geocoder) {
    logger.error('no geocoder in window');
    return;
  }

  return new Promise<google.maps.GeocoderResult | undefined>((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(googleGeoCoderParams, (results, status) => {
      if (status === 'OK' || status === 'ZERO_RESULTS') {
        const result = results?.[0];
        logger.log('Result of geocode using a browser script', {result, status, googleGeoCoderParams});
        resolve(result);
        return;
      }
      logger.error('geocoder failed', {results, status, googleGeoCoderParams});
      reject();
    });
  });
}

export function mapGeocoderResult(result: UnPromisify<ReturnType<typeof geocode>>) {
  if (!result) return;
  return pickBy(
    {
      latitude:
        typeof result.geometry.location.lat === 'function'
          ? result.geometry.location.lat()
          : result.geometry.location.lat,
      longitude:
        typeof result.geometry.location.lng === 'function'
          ? result.geometry.location.lng()
          : result.geometry.location.lng,
      cityName: result.address_components.find(c => c.types.some(t => t === 'locality'))?.long_name,
      streetName: result.address_components.find(c => c.types.some(t => t === 'route'))?.long_name,
    },
    Boolean,
  ) as MapGeoCoderResult;
}

export async function getExtraGeoCoderDataForAddress(
  address: Partial<Pick<IAddress, 'cityName' | 'streetName' | 'houseNumber'>>,
) {
  const {cityName, streetName, houseNumber} = address;
  const addressString = [cityName, streetName, houseNumber].filter(Boolean).join(', ');

  const result = await geocode({address: addressString});

  return mapGeocoderResult(result);
}

export function addressFromLatLng({lat, lng}: GeocodeLocationParams) {
  return geocode({location: {lat, lng}});
}

export const getCurrentGeolocationPlace = () =>
  new Promise<GeolocationPlace>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      async pos => {
        const response = await addressFromLatLng({lat: pos.coords.latitude, lng: pos.coords.longitude});

        try {
          if (!response?.formatted_address) {
            throw new Error('location not detected.');
          }
          resolve({
            ...response,
            name: response.formatted_address,
          });
        } catch (err) {
          reject(err);
        }
      },
      err => reject(err),
      {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      },
    );
  });

export const isLocationAccessPermitted = async () => {
  if (!window.navigator?.permissions?.query) {
    return true;
  }
  try {
    const res = await window.navigator.permissions.query({name: 'geolocation'});
    return res?.state !== 'denied';
  } catch (e) {
    logger.error(new Error('window.navigator.permissions failed'), {e});
    return true;
  }
};

type GeocodeParams = {address: string} | {location: GeocodeLocationParams};

interface GeocodeLocationParams {
  lat: number;
  lng: number;
}

export type MapGeoCoderResult = Partial<{
  latitude: number;
  longitude: number;
  cityName: string;
  streetName: string;
}>;

export type GeolocationPlace = google.maps.GeocoderResult & {name: string};

type UnPromisify<T> = T extends Promise<infer A> ? A : T;
