/**
 * Note, these values map the data from CDN, they are not JS types.
 */
export enum ValueType {
  Bool = 'bool',
  Int = 'int',
  String = 'string',
}

export type Value = string | number | boolean | string[];
export type ValueOrNull = Value | null;

export interface Variant {
  weight: number;
  value: string | number | boolean;
  experimentKey: string;
  compositeKey?: string;
  experimentVariant: 'variant' | 'control' | string;
}

export declare enum Op {
  And = 'and',
  Or = 'or',
  Eq = 'eq',
  Neq = 'neq',
  In = 'in',
  Nin = 'nin',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
}

export type RestrictionProperty = string;
export type RestrictionValue = string;
export interface Restriction {
  [Op.And]?: Restriction[];
  [Op.Or]?: Restriction[];
  [Op.Eq]?: RestrictionValue;
  [Op.Neq]?: RestrictionValue;
  [Op.In]?: RestrictionValue[];
  [Op.Nin]?: RestrictionValue[];
  [Op.Gt]?: RestrictionValue;
  [Op.Gte]?: RestrictionValue;
  [Op.Lt]?: RestrictionValue;
  [Op.Lte]?: RestrictionValue;
  property: RestrictionProperty;
}

export interface Rule {
  restrictions: Restriction;
  value: Value;
  audienceFraction: number;
  variants: Variant[];
}

export declare enum BucketBy {
  AnonUserId = 'AnonUserId',
}
export interface LogicDetail {
  bucketBy: BucketBy;
  prefix: string;
  rules: Rule[];
}

export declare enum LogicType {
  IdHash = 'IdHash',
}

export interface EvaluationLogic {
  type: LogicType;
  detail: LogicDetail;
}

export interface FeatureConfig {
  key: string;
  valueType: ValueType;
  defaultValueRules: Rule[];
  evaluationLogic: EvaluationLogic;
  tags?: string[];
}

export type FeatureConfigs = Record<string, FeatureConfig>;

export type Feature = Record<string, ValueOrNull>;
export type Features = Feature[];

export interface Config {
  features: FeatureConfig[];
  createdAt: string;
}

export interface VersionedConfig {
  config: Config;
  versionId: string;
}
