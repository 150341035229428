import {PropsWithChildren, useMemo} from 'react';

import styled, {keyframes} from 'styled-components';
import {isUndefined} from 'lodash';

const primaryColor = '#f3f3f3';
const secondaryColor = '#e3e3e3';

const animationKeyFramesRTL = keyframes`
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-25%);
  }
`;

const animationKeyFramesLTR = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

type SkeletonElementProps = {
  width?: number;
  height?: number;
  marginTop?: number;
  marginBottom?: number;
  isCircle?: boolean;
};

export const Skeleton = styled.div<SkeletonElementProps>`
  position: relative;
  overflow: hidden;
  width: ${({width}) => (width ? `${width}px` : '100%')};
  height: ${({height}) => (height ? `${height}px` : '100%')};
  border-radius: ${({isCircle}) => (isCircle ? '50%' : 0)};
  ${({marginTop}) => marginTop && `margin-top: ${marginTop}px;`}
  ${({marginBottom}) => marginBottom && `margin-bottom: ${marginBottom}px;`}
  background: ${primaryColor};
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 300%;
    transform: translateX(-100%);
    background-image: linear-gradient(
      to right,
      ${primaryColor} 0%,
      ${secondaryColor} 20%,
      ${primaryColor} 40%,
      ${primaryColor} 100%
    );
    background-repeat: no-repeat;
    animation-name: ${({theme}) => (theme.isLTR ? animationKeyFramesLTR : animationKeyFramesRTL)};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }
`;

export interface SkeletonLoaderProps extends Record<string, any> {
  shouldShowLoader?: boolean;
  LoaderComponent: any;
}

// in the future use https://reactjs.org/docs/concurrent-mode-suspense.html instead.
const SkeletonLoader = ({
  shouldShowLoader,
  LoaderComponent,
  children,
  ...loaderProps
}: PropsWithChildren<SkeletonLoaderProps>) => {
  const loaderElement = useMemo(() => {
    const LoaderToRender = LoaderComponent || Skeleton;
    return <LoaderToRender {...loaderProps} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LoaderComponent, ...Object.values(loaderProps)]);

  if (isUndefined(shouldShowLoader)) {
    console.error('SkeletonLoader is missing \'shouldShowLoader\' prop', {children}); // eslint-disable-line no-console
    return <></>;
  }

  if (shouldShowLoader) {
    return loaderElement;
  }

  return <>{children}</>;
};

export default SkeletonLoader;
