import {FinalFormCheckboxAdapter} from '../Checkbox';
import Select from '../Select';
import RecaptchaField from '../RecaptchaField';
import {validators} from '../../utils/validators';

import FormInput from './FormInput';

const passwordBase = {inputType: 'password', component: FormInput};
const telBase = {inputType: 'tel', component: FormInput};

const getFieldInputByType = type => {
  switch (type) {
    case 'email':
      return {
        inputType: 'email',
        component: FormInput,
        inputValidator: validators.emailValidator,
        autoComplete: 'email',
      };
    case 'password':
    case 'newPassword':
      return {...passwordBase, inputValidator: validators.passwordValidator, autoComplete: 'new-password'};
    case 'confirmPassword':
      return {...passwordBase, inputValidator: validators.confirmPasswordValidator, autoComplete: 'new-password'};
    case 'currentPassword':
      return {...passwordBase, autoComplete: 'current-password'};
    case 'CellPhone':
    case 'cellPhone':
    case 'phone':
      return {...telBase, inputValidator: validators.phoneNumberValidator, autoComplete: 'tel'};
    case 'textWithTelInput':
      return {...telBase, inputValidator: validators.textInputValidator};
    case 'text':
    case 'string':
      return {inputType: 'text', component: FormInput, inputValidator: validators.textInputValidator};
    case 'textarea':
      return {inputType: 'textarea', component: FormInput};
    case 'checkbox':
    case 'wantPromotion':
      return {inputType: 'text', component: FinalFormCheckboxAdapter, inputValidator: validators.textInputValidator};
    case 'select':
    case 'SelectList':
      return {inputType: 'select', component: Select, inputValidator: validators.textInputValidator};
    case 'recaptcha':
      return {component: RecaptchaField};
    default:
      return {inputType: 'text', component: FormInput, inputValidator: validators.textInputValidator};
  }
};

export default getFieldInputByType;
