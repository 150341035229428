import {useSelector} from 'react-redux';

import {getLocalizationService} from '~/shared/services/localisationService';
import {
  selectCurrentRestaurant,
  selectCurrentRestaurantAbout,
  selectIsCurrentRestaurantOfficeSupplies,
  selectIsGroceriesStore,
} from '~/shared/store/selectors';
import {RestaurantBusinessTypeName} from '~/shared/store/models';

import AboutSectionBase from './AboutSectionBase';

const sanitizeToParagraph = (text, separator) =>
  text?.split(separator).map(line => (line.trim().length ? <p key={line}>{line}</p> : null));

const AboutRestaurantSection = () => {
  const {t} = getLocalizationService();
  const isGroceriesStore = useSelector(selectIsGroceriesStore);
  const restaurantData = useSelector(selectCurrentRestaurant);
  const restaurantAboutData = useSelector(selectCurrentRestaurantAbout);
  const isOfficeSupplies = useSelector(selectIsCurrentRestaurantOfficeSupplies);
  const isVoucherCard = restaurantData?.businessType === RestaurantBusinessTypeName.VoucherCard;

  if (!restaurantAboutData.aboutRestaurant && !restaurantAboutData.restaurantKoshernessLevel) {
    return null;
  }
  
  const title = (() => {
    if (isOfficeSupplies) {
      return t('about_the_business');
    }
    if (restaurantData?.isVoucherEnabled || isGroceriesStore || isVoucherCard) {
      return t('about_the_store');
    }
    return t('about_the_restaurant');
  })();

  return (
    <>
      {restaurantData && (
        <AboutSectionBase
          title={title}
          content={sanitizeToParagraph(restaurantAboutData.aboutRestaurant, '<br />')}
          boldContent={
            restaurantAboutData.restaurantKoshernessLevel &&
            t('kosher_level', {level: restaurantAboutData.restaurantKoshernessLevel})
          }
          withBottomPadding
        />
      )}
    </>
  );
};

export default AboutRestaurantSection;
