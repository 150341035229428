import axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';

import {createLogger} from '~/shared/logging';
// @ts-expect-error config needs to be rewritten as ts
import {bffApiBaseUrl, isProxyBFF} from '~/shared/config';
import store from '~/shared/store';

import {getLocalizationService} from '../localisationService';
import {createApiError} from '../apiErrorService';

import {getAppType} from './util';

const logger = createLogger('bff api service');

const bffPrefix = 'api/';
const baseURL = isProxyBFF ? `/${bffPrefix}` : `${bffApiBaseUrl}/${bffPrefix}`;

interface AxiosOptions {
  baseURL: string;
  withCredentials: boolean;
  credentials: string;
}

const axiosOptions: AxiosOptions = {
  baseURL,
  withCredentials: true,
  credentials: 'same-origin',
};

const createInstance = () => {
  const instance = axios.create(axiosOptions);

  instance.interceptors.request.use(config => {
    const state = store.getState();
    const {currentLanguageKey} = getLocalizationService();

    if (config.headers) {
      config.headers = {
        ...config.headers,
        ...getAppType(state),
        language: currentLanguageKey,
      };
    }

    return config;
  });

  instance.interceptors.response.use(
    result => {
      if (result.status === 200 || result.status === 201) {
        return result?.data || result;
      }
      return result;
    },
    async (error: AxiosError) => {
      const apiError = createApiError(error);
      logger.error(apiError);

      return Promise.reject(apiError);
    },
  );

  return instance;
};

const api: AxiosInstance = createInstance();

export const get = <T>(url: string, params?: Record<string, any>, config?: Record<string, any>): Promise<T | AxiosResponse<T>> =>
  api.get(url, {params, ...config});

export const put = <T>(url: string, data?: Record<string, any>, config?: Record<string, any>): Promise<T | AxiosResponse<T>> =>
  api.put(url, data, config);

export const post = <T>(url: string, data?: Record<string, any>, config?: Record<string, any>): Promise<T | AxiosResponse<T>> =>
  api.post(url, data, config);

export const apiDelete = <T>(url: string, data?: Record<string, any>, config?: Record<string, any>): Promise<T | AxiosResponse<T>> =>
  api.delete(url, {data, ...config});

export const patch = <T>(
  url: string,
  params?: Record<string, any>,
  body?: any,
  config?: Record<string, any>,
): Promise<T | AxiosResponse<T>> => api.patch(url, body, {params, ...config});
