// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function log({loggerName, logLevel, errorOrMessage, args}) {
  // There's currently no convenient way to add logs to new relic
  // window.newrelic?.addToTrace(errorOrMessage, {
  //   loggerName,
  //   logLevel,
  //   args,
  // });
}

export function logError({error: rawError, loggerName, args}) {
  // manually throw the error to create stack trace for IE11
  // https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/noticeerror-browser-agent-api/#browser-limits
  try {
    throw rawError;
  } catch (error) {
    window.newrelic?.noticeError(error, {
      loggerName,
      args,
    });
  }
}
