import {useMemo, useEffect, Fragment} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {attachModal} from '~/shared/components/ReduxModal';
import {getLocalizationService} from '~/shared/services/localisationService';
import {media, flipOnLTR} from '~/shared/theme/utils';
import Button from '~/shared/components/Button';
import {flexCenterVertically, flexColumn} from '~/shared/theme/FlexLayout';
import {body14Bold, body18Bold} from '~/shared/theme/typography';
import {CloseButton} from '~/shared/components/Modals';
import {CloseButtonSVGIcon} from '~/shared/components/styled';
import {trackEvent} from '~/shared/services/analytics';
import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {setDirtyShoppingCart} from '~/shared/store/actions';
import {selectCurrentRestaurantOrderRule} from '~/shared/store/selectors';

const Root = styled(ClickOutsideDiv)`
  &&& {
    background-color: ${({theme}) => theme.colors.darkBackground};
    width: 100vw;
    max-height: 100%;
    height: 100%;
  }

  border-radius: 2px;
  box-shadow: ${({theme}) => theme.shadows.shadow1};
  ${media.minLargeTablet`
  &&& {
      background-color: ${({theme}) => theme.colors.surface};
      width: 600px;
      height: auto;
  }
      text-align: center;     
  `}
`;

const DialogHeader = styled.div`
  padding: 12px 16px 12px 16px;
  box-shadow: ${({theme}) => theme.shadows.modalHeaderShadow};
  background-color: ${({theme}) => theme.colors.surface};
  ${media.minLargeTablet`    
      padding: 12px 0 12px 0;
  `}
`;

const ContentWrapper = styled.div`
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
`;

const DishesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
`;

const DialogHeaderText = styled.div`
  ${body18Bold};
  color: ${({theme}) => theme.colors.secondary};
  text-align: center;
`;

const CloseDialogButton = styled(CloseButton)`
  top: 10px;
  ${flipOnLTR`
    left: 8px; 
  `}
  ${media.minLargeTablet`
  ${flipOnLTR`
    left: 16px; 
  `}
`}
`;

const TitleText = styled.div`
  ${body14Bold};
  margin: 0 auto;
  padding: 40px;
  color: ${({theme}) => theme.colors.secondary};
  text-align: center;
  ${media.minLargeTablet`
    width: 100%;
`}
`;

const Dishes = styled.div`
  margin: 0 16px 40px 16px;
  background-color: ${({theme}) => theme.colors.surface};
  padding-right: 16px;
  padding-left: 16px;
  border: solid 1px ${({theme}) => theme.colors.gray600};
  overflow: auto;
  max-height: 270px;
  ${media.minLargeTablet`
    max-height: 280px;
    margin: 0 40px 40px;
`}
`;

const Buttons = styled.div`
  ${flexColumn};
  flex-direction: column-reverse;
  justify-content: center;
  padding: 0 16px 57px;
  ${media.minLargeTablet`
    flex-direction: row;
    padding: 0 40px 40px;
  `};
`;

const CancelReorderButton = styled(Button)`
  background-color: ${({theme}) => theme.colors.surface};
  width: 100%;
  min-height: 50px;
  border-radius: 1px;
  border: 1px solid ${({theme}) => theme.colors.gray500};
  font-weight: bold;
  text-align: center;
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  ${flipOnLTR`
    margin-left: 0;
  `}
  margin-top: 16px;
  ${media.minLargeTablet`
    width: 270px;
    ${flipOnLTR`
      margin-left: 16px;
    `}
    margin-top: 0;
  `}
`;

const ContinueButton = styled(CancelReorderButton)`
  margin: 0;
  border: solid 1px ${({theme}) => theme.colors.gray500};
  background-color: ${({theme}) => theme.colors.surfacePrimaryAction};
  color: ${({theme}) => theme.colors.surfacePrimaryActionText};
`;

const Dish = styled.div`
  color: ${({theme}) => theme.colors.secondary};
  padding-top: 7px;
  padding-bottom: 7px;
  ${flexCenterVertically};
  text-decoration: ${({crossed}) => (crossed === true ? 'line-through' : 'none')};
`;

const DishQuantity = styled(Dish)`
  ${flipOnLTR`
    margin-left: 14px; 
  `}
`;

const DishName = styled(Dish)`
  margin-right: 8px;
`;

const DishLine = styled.div`
  height: 1px;
  border: solid 1px ${({theme}) => theme.colors.gray600};
`;

const MENU_ITEMS_TYPE = {
  DISH: 'Dish',
  SUB: 'Sub',
};

const RestaurantReorderModal = ({closeModal, args: {allMenuItems, unavailableMenuItems, orderId}}) => {
  const {t} = getLocalizationService();
  const dispatch = useDispatch();
  const currentDeliveryRule = useSelector(selectCurrentRestaurantOrderRule);

  const isDishesExists = Boolean(
    allMenuItems?.length && unavailableMenuItems?.length && allMenuItems.length > unavailableMenuItems.length,
  );

  const menuItems = useMemo(
    () =>
      allMenuItems.map(item => {
        if (
          unavailableMenuItems.find(
            unItem =>
              (unItem.menuItemType === MENU_ITEMS_TYPE.DISH && unItem.menuItemId === item.id) ||
              (unItem.menuItemType === MENU_ITEMS_TYPE.SUB && unItem.dishAncestorId === item.id),
          )
        ) {
          return {...item, unavailable: true};
        }
        return {...item};
      }),
    [allMenuItems, unavailableMenuItems],
  );

  const onCancelClick = () => {
    trackEvent('hasReorderPopupUnavailable', {
      linkType: 'Cancel reorder',
    });
    closeModal();
    dispatch(setDirtyShoppingCart(false));
  };

  const onCloseClick = () => {
    if (isDishesExists) {
      trackEvent('hasReorderPopupUnavailable', {
        linkType: 'Close',
      });
    }
    closeModal();
    dispatch(setDirtyShoppingCart(false));
  };

  useEffect(() => {
    if (!isDishesExists) {
      trackEvent('hasReorderPopupAllUnavailable');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onContinueClick = () => {
    trackEvent('hasReorderPopupUnavailable', {
      linkType: 'Continue without dishes',
    });
    ManagerProvider.reorder(orderId, {addDishesAnyway: true, deliveryRuleType: currentDeliveryRule?.type});
    closeModal();
  };

  return (
    <Root onClickOutside={onCloseClick}>
      <DialogHeader>
        <CloseDialogButton onClick={onCloseClick}>
          <CloseButtonSVGIcon />
        </CloseDialogButton>
        <DialogHeaderText id="modal-title">
          {isDishesExists ? t('unavailable_items_title') : t('no_option_to_reorder')}
        </DialogHeaderText>
      </DialogHeader>
      <ContentWrapper>
        <DishesWrapper>
          <TitleText>
            {isDishesExists ? t('unavailable_items_description') : t('items_in_the_order_are_not_available')}
          </TitleText>
          {isDishesExists && (
            <Dishes>
              {menuItems?.length > 0 &&
                menuItems.map((dish, index) => (
                  <Fragment key={dish.id}>
                    <Dish crossed={dish.unavailable}>
                      <DishQuantity>{dish.quantity} x</DishQuantity>
                      <DishName>{dish.name}</DishName>
                    </Dish>
                    {index < menuItems.length - 1 && <DishLine />}
                  </Fragment>
                ))}
            </Dishes>
          )}
        </DishesWrapper>

        {isDishesExists ? (
          <Buttons>
            <CancelReorderButton onClick={onCancelClick}>{t('cancel_reorders')}</CancelReorderButton>
            <ContinueButton onClick={onContinueClick}>{t('continue_without_dishes')}</ContinueButton>
          </Buttons>
        ) : (
          <Buttons>
            <ContinueButton onClick={onCloseClick}>{t('accept')}</ContinueButton>
          </Buttons>
        )}
      </ContentWrapper>
    </Root>
  );
};

RestaurantReorderModal.hideCloseButton = true;
RestaurantReorderModal.modalType = 'autoWidth';
attachModal('restaurantReorderModal', RestaurantReorderModal);
