import {memo, useMemo, useState, useEffect} from 'react';

import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {noop} from 'lodash';

import {getLocalizationService} from '~/shared/services/localisationService';
import {AddCreditCardModes} from '~/shared/consts/checkoutConsts';
import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';
import {
  selectAvailablePayments,
  selectCheckoutPaymentsIfExists,
  selectIsAvailablePaymentsLoaded,
  selectUserData,
} from '~/shared/store/selectors';
import {openPaymentsModalOrPage} from '~/shared/services/navigation';
import AddCreditCard from '~/shared/components/AddCreditCard';
import PayPalButton from '~/shared/components/PayPalButton';
import {trackEvent} from '~/shared/services/analytics';
import defaultBlueIconUrl from '~/assets/images/icons/cards/defaultBlue.svg';
import {SkeletonLoader, Skeleton} from '~/shared/components/Loaders';
import {useIsMaxLargeMobile} from '~/shared/hooks/deviceInfo';
import {
  AddPaymentButton,
  PaymentContainer,
  AddPaymentButtonWithAvailablePayments,
} from '~/shared/components/PaymentButton';

import {ButtonsAndInputContainer} from './styled';

const modeTypes = {
  INITIAL_STATE_WITHOUT_AVAILABLE_PAYMENT: 'initialWithoutCreditCards',
  INITIAL_STATE_WITH_AVAILABLE_PAYMENT: 'initialWithCreditCards',
  ADD_CREDIT_CARD: 'addCreditCard',
};

const modeComponents = {
  [modeTypes.INITIAL_STATE_WITHOUT_AVAILABLE_PAYMENT]: ({
    setCurrentMode,
    fullwidth,
    showAsMainCTA,
    borderRadius,
    analyticsLinkType: linkType,
  }) => {
    const {t} = getLocalizationService();
    const isMaxLargeMobile = useIsMaxLargeMobile();

    return (
      <AddPaymentButton
        onClick={() => {
          trackEvent('hasClickedAddCreditCard', {linkType});
          setCurrentMode(modeTypes.ADD_CREDIT_CARD);
        }}
        label={isMaxLargeMobile ? t('add_extra_credit_card_mobile') : t('add_extra_credit_card')}
        fullWidth={fullwidth}
        showAsMainCTA={showAsMainCTA}
        borderRadius={borderRadius}
        showBorder={!showAsMainCTA}
      />
    );
  },
  [modeTypes.INITIAL_STATE_WITH_AVAILABLE_PAYMENT]: ({returnToCheckoutOnClose, fullwidth, openAddPaymentsStep}) => {
    const {t} = getLocalizationService();
    return (
      <AddPaymentButtonWithAvailablePayments
        showAsLink
        onClick={() => {
          trackEvent('hasClickedAddPaymentMethod');
          if (openAddPaymentsStep) {
            openAddPaymentsStep();
          } else {
            openPaymentsModalOrPage(returnToCheckoutOnClose);
          }
        }}
        icon={defaultBlueIconUrl}
        fullWidth={fullwidth}
      >
        {`${t('add_payment')}`}
      </AddPaymentButtonWithAvailablePayments>
    );
  },
  [modeTypes.ADD_CREDIT_CARD]: ({setCurrentMode}) => {
    const {t} = getLocalizationService();
    return (
      <AddCreditCard
        onSuccess={() => {
          trackEvent('hasSuccessfullyAddedCreditCard');
          setCurrentMode(modeTypes.INITIAL_STATE_WITH_AVAILABLE_PAYMENT);
        }}
        onClose={() => {
          setCurrentMode(modeTypes.INITIAL_STATE_WITHOUT_AVAILABLE_PAYMENT);
        }}
        onSubmit={noop}
        mode={AddCreditCardModes.ONE_LINE}
        submitButtonText={t('save')}
      />
    );
  },
};

const SkeletonForMobileDevice = styled(Skeleton)`
  margin: 0 16px;
  width: calc(100% - 32px);
`;

const LoaderComponent = () => {
  const isMaxLargeMobile = useIsMaxLargeMobile();
  const SkeletonComponent = isMaxLargeMobile ? SkeletonForMobileDevice : Skeleton;

  return <SkeletonComponent height={40} />;
};

const CheckoutAddPaymentsState = ({isLoading, returnToCheckoutOnClose, onError, onSuccess, openAddPaymentsStep}) => {
  const availablePayments = useSelector(selectAvailablePayments);
  const isAvailablePaymentsLoaded = useSelector(selectIsAvailablePaymentsLoaded);
  const checkoutPayments = useSelector(selectCheckoutPaymentsIfExists);
  const userData = useSelector(selectUserData);

  const userAvailablePaymentMethods = useMemo(() => {
    return availablePayments?.filter(
      ({paymentMethod, userId}) =>
        (paymentMethod === PaymentMethodTypes.CREDIT_CARD && userId === userData?.userId) ||
        (paymentMethod === PaymentMethodTypes.MONEY_CARD && userId === userData?.userId) ||
        (paymentMethod === PaymentMethodTypes.PAYPAL && userId === userData?.userId),
    );
  }, [availablePayments, userData]);

  const analyticsLinkType = useMemo(() => {
    const filteredPayments = availablePayments?.filter(({paymentMethod}) => paymentMethod !== PaymentMethodTypes.CASH);
    return !filteredPayments?.length ? 'One Line' : 'Payments Modal';
  }, [availablePayments]);

  const userHasOnlyCashPayment = useMemo(() => {
    return checkoutPayments?.length === 1 && checkoutPayments[0].paymentMethod === PaymentMethodTypes.CASH;
  }, [checkoutPayments]);

  const initialMode = !userAvailablePaymentMethods
    ? modeTypes.INITIAL_STATE_WITH_AVAILABLE_PAYMENT
    : modeTypes.INITIAL_STATE_WITHOUT_AVAILABLE_PAYMENT;

  const [currentMode, setCurrentMode] = useState(initialMode);
  const [shouldShowPaypalButton, setShouldShowPaypalButton] = useState(false);

  useEffect(() => {
    if (isAvailablePaymentsLoaded) {
      setShouldShowPaypalButton(true);
    }
  }, [isAvailablePaymentsLoaded]);

  useEffect(() => {
    if (userAvailablePaymentMethods?.length > 0) {
      setCurrentMode(modeTypes.INITIAL_STATE_WITH_AVAILABLE_PAYMENT);
    } else {
      setCurrentMode(modeTypes.INITIAL_STATE_WITHOUT_AVAILABLE_PAYMENT);
    }
  }, [userAvailablePaymentMethods]);

  const ComponentByMode = modeComponents[currentMode];

  return (
    <SkeletonLoader shouldShowLoader={!isAvailablePaymentsLoaded || isLoading} LoaderComponent={LoaderComponent}>
      <ButtonsAndInputContainer>
        {currentMode !== modeTypes.INITIAL_STATE_WITHOUT_AVAILABLE_PAYMENT && (
          <ComponentByMode
            {...{
              setCurrentMode,
              fullwidth: true,
              returnToCheckoutOnClose,
              openAddPaymentsStep,
              showAsMainCTA: !userHasOnlyCashPayment,
            }}
          />
        )}
        {currentMode === modeTypes.INITIAL_STATE_WITHOUT_AVAILABLE_PAYMENT && (
          <>
            <ComponentByMode
              {...{
                setCurrentMode,
                fullwidth: false,
                returnToCheckoutOnClose,
                openAddPaymentsStep,
                showAsMainCTA: !userHasOnlyCashPayment,
                borderRadius: shouldShowPaypalButton,
                analyticsLinkType,
              }}
            />
            {shouldShowPaypalButton && (
              <PaymentContainer>
                <PayPalButton {...{variant: 'primary', onError, onSuccess}} />
              </PaymentContainer>
            )}
          </>
        )}
      </ButtonsAndInputContainer>
    </SkeletonLoader>
  );
};

export default memo(CheckoutAddPaymentsState);
