import {Logger} from './logger';
import {Config} from './config';

export interface CdnSettings {
  key?: string;
  host?: string;
  scope: string;
  environment: string;
  versionId?: string;
  poll?: boolean;
  pollInterval?: number;
  logger?: Logger;
}

export const enum Modes {
  Disabled = 'disabled',
}

export type GetMode = () => Modes.Disabled;

export interface Context {
  mode?: GetMode;
  anonUserId: string;
  restrictionsProviders: Map<string, () => string>;
}

export type GetContextFn = () => Context | null;
export type OnUpdatedFn = () => void;
export type OnTrackFn = (key: string, variantName: string) => void;

export interface FmSettings {
  keyPrefix: string;
  cdn?: CdnSettings;
  initialConfigAsJson?: Config | string;
  onUpdated?: OnUpdatedFn;
  onTrack?: OnTrackFn;
  getContext: GetContextFn;
  urlString?: string;
  logger: Logger;
}
