import {memo, useMemo} from 'react';

import {snakeCase} from 'lodash';
import styled from 'styled-components';

import DeliveryDiscountPriceLabel from '~/shared/components/DiscountPriceLabel';
import {PriceLabel} from '~/shared/components/PriceLabels';
import {calculateBillingLinesForDisplay} from '~/shared/services/payments';
import {getLocalizationService} from '~/shared/services/localisationService';
import {
  flexCenterHorizontally,
  flexColumn,
  flexSpace,
  FlexSpace,
} from '~/shared/theme/FlexLayout';
import {Skeleton} from '~/shared/components/Loaders';
import {CouponValueType} from '~/shared/store/models';
import {HIDDEN_BILLING_LINES_WITH_PERCENT_COUPON} from '~/shared/consts/discounts';

import {CouponsState} from '../store/storeModules/coupons/couponsReducer';
import {BillingLineWithDiscountsData, IShoppingCartBillingLine} from '../store/models/ShoppingCart/IShoppingCart';
import {BillingLineType} from '../consts/checkoutConsts';

const PriceContainer = styled.div`
  ${flexCenterHorizontally};
  align-items: flex-end;
`;

const BillingLine = styled.div<{
  isBold: boolean;
  shouldAddSpaceAndBorder: boolean;
  isOnlyTotalToCharge: boolean;
}>`
  width: 100%;
  font-weight: ${({isBold}) => (isBold ? 'bold' : 'normal')};
  line-height: 20px;
  margin-bottom: 2px;
  ${({shouldAddSpaceAndBorder, theme}) =>
    shouldAddSpaceAndBorder &&
    `
    border-top: 1px solid ${theme.shoppingCart.borderColor};
    padding-top: 16px;
    margin-top: 16px;
  `};
  ${({isOnlyTotalToCharge}) =>
    isOnlyTotalToCharge &&
    `
    margin-top: 0;
    padding-top: 0;
    border: 0;
  `}
`;

const BillingLineInnerWrapper = styled.div.attrs(({renderA11yAttributes}: {renderA11yAttributes: boolean}) => ({
  ...(renderA11yAttributes && {'aria-live': 'assertive'}),
}))<{
  renderA11yAttributes: boolean;
  isDiscount: boolean;
  disablePaddings: boolean;
}>`
  ${flexSpace}
  align-items: center;
  color: ${({isDiscount, theme}) => isDiscount && theme.shoppingCart.billingLine.discountColor};
  padding: ${({disablePaddings}) => (disablePaddings ? '0px' : '0 16px')};
`;

const BillingSkeleton = styled(Skeleton)<{lineWidth?: number}>`
  height: 18px;
  width: ${({lineWidth}) => lineWidth || 60}px;
  margin-bottom: 4px;
`;

const BILLING_LINES_SKELETON_WIDTH = [160, 64, 64, 96];
const BillingLinesSkeletons = () => (
  <>
    {BILLING_LINES_SKELETON_WIDTH.map((lineWidth, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <FlexSpace key={index}>
        <BillingSkeleton lineWidth={lineWidth} />
        <BillingSkeleton />
      </FlexSpace>
    ))}
  </>
);

const DiscountPrice = ({
  billingType,
  currentCoupon,
}: {
  billingType: IShoppingCartBillingLine['type'];
  currentCoupon: CouponsState['currentCoupon'];
}) => {
  const {t, tKeyExists} = getLocalizationService();

  if (billingType === BillingLineType.DiscountCoupon && currentCoupon) {
    if (currentCoupon.description) {
      return <>{tKeyExists(currentCoupon.description) ? t(currentCoupon.description) : currentCoupon.description}</>;
    }
    return (
      <PriceContainer>
        {t('discountCoupon')}&nbsp;
        <PriceLabel price={currentCoupon.discountValue} discount={currentCoupon.valueType === 'Percent'} />
      </PriceContainer>
    );
  }

  return <>{t(snakeCase(billingType))}</>;
};

export interface IBillingLinesView {
  currentCoupon: CouponsState['currentCoupon'];
  disableTotalDesign?: boolean;
  isLoading: boolean;
  billingLines: BillingLineWithDiscountsData[];
  currentModalName: string;
  className?: string;
  isUserBenefitsHighlightEnabled?: boolean;
}

const BillingLinesContainer = styled.div`
  ${flexColumn};
  border-top: 1px solid ${({theme}) => theme.shoppingCart.borderColor};
  padding: 16px 0;
`;

const PRICE_BEFORE_DISCOUNT_DATA_TEST_ID = 'priceBeforeDiscount';
const PRICE_DATA_TEST_ID = 'price';

const Price = ({billingLine, isUserBenefitsHighlightEnabled}: {
  billingLine: BillingLineWithDiscountsData;
  isUserBenefitsHighlightEnabled?: boolean;
}) => {
  if (!billingLine.discountsData) {
    return (
      <PriceContainer>
        <PriceLabel price={billingLine.amount} type={billingLine.type} priceDataTestId={`${PRICE_DATA_TEST_ID}_${billingLine.type}`}/>
      </PriceContainer>
    );
  }

  return (
    <PriceContainer>
      <DeliveryDiscountPriceLabel
        billingLine={billingLine}
        priceDataTestId={PRICE_DATA_TEST_ID}
        priceBeforeDiscountTestId={PRICE_BEFORE_DISCOUNT_DATA_TEST_ID}
        isUserBenefitsHighlightEnabled={!!isUserBenefitsHighlightEnabled}
      />
    </PriceContainer>
  );
};

const BillingLines = ({
  billingLines,
  currentCoupon,
  disableTotalDesign = false,
  isLoading,
  currentModalName,
  className,
  isUserBenefitsHighlightEnabled,
}: IBillingLinesView) => {
  const billingLinesToShow = useMemo(() => calculateBillingLinesForDisplay(billingLines), [billingLines]);
  const isCheckoutModalOpen = currentModalName === 'checkout_modal';

  if (isLoading) {
    return <BillingLinesSkeletons />;
  }

  if (!billingLinesToShow.length) {
    return null;
  }

  return (
    <BillingLinesContainer className={className}>
      {billingLinesToShow.map(billingLine => {
        const billingType = billingLine.type;
        if (
          (billingType === BillingLineType.Tip && !billingLine.priority) ||
          (currentCoupon?.valueType === CouponValueType.PERCENT && HIDDEN_BILLING_LINES_WITH_PERCENT_COUPON.includes(billingType))
        ) {
          return;
        }

        const isBillingTypeIsTotalToCharge = billingType === BillingLineType.TotalToCharge;

        return (
          <BillingLine
            tabIndex={0}
            key={billingType}
            isBold={isBillingTypeIsTotalToCharge}
            shouldAddSpaceAndBorder={!disableTotalDesign && isBillingTypeIsTotalToCharge}
            isOnlyTotalToCharge={billingLinesToShow.length === 1}
          >
            <BillingLineInnerWrapper
              disablePaddings={disableTotalDesign}
              isDiscount={
                billingType === BillingLineType.DiscountCoupon || billingType === BillingLineType.DeliveryDiscount
              }
              renderA11yAttributes={!isCheckoutModalOpen && isBillingTypeIsTotalToCharge}
              // renderA11yAttributes: this attr (aria-live: 'assertive') will notify on every final price change (adding dish, removing dish etc...),
              // except the checkout modal, the new checkout has it own billingLines that gets effected by its own form.
            >
              <DiscountPrice {...{billingType, currentCoupon}} />
              <Price billingLine={billingLine} isUserBenefitsHighlightEnabled={isUserBenefitsHighlightEnabled} />
            </BillingLineInnerWrapper>
          </BillingLine>
        );
      })}
    </BillingLinesContainer>
  );
};

export default memo(BillingLines);
