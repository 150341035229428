import {createContext, Dispatch, SetStateAction} from 'react';

import {ApiResponse} from '~/shared/services/apiService';
import {User} from '~/shared/store/models';

import {UpdateUserDetailsFields} from './UpdateUserDetails';

export type SendAndHandleRequestType = (params: {
  userDetails: UpdateUserDetailsFields;
  isResetPasswordType?: boolean | undefined;
  setResErrors: Dispatch<SetStateAction<string | null>>;
}) => Promise<void>;

export interface UpdateUserContextProps {
  userData:
    | (User & {
        moneycardActivationRequired: boolean;
      })
    | null;
  logger: {
    [x: string]: (errorOrMessage: any, ...args: any[]) => void;
  };
  logout: (redirectTo?: string | undefined) => void;
  getActivationTokenAndSendActivationCodeToUser: (params: {
    cellPhone: string;
    email: string;
    isRegistrationMode: boolean;
  }) => Promise<ApiResponse<unknown>>;
  sendAndHandleRequest: SendAndHandleRequestType;
  onTermsOfUseClick: () => void;
  onPrivacyPolicyClick: () => void;
}

export const UpdateUserContext = createContext<UpdateUserContextProps>({} as UpdateUserContextProps);
