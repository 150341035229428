import {memo} from 'react';

import styled, {useTheme} from 'styled-components';
import {noop} from 'lodash';

import {getLocalizationService} from '~/shared/services/localisationService';
import {InfoModal} from '~/shared/components/Modals';
import {attachModal} from '~/shared/components/ReduxModal';
import {body13Bold} from '~/shared/theme/typography';
import errorIcon from '~/assets/images/icons/error.svg';

const Content = styled.div`
  padding: 10px;
  text-align: center;
`;

const Text = styled.p`
  min-height: 40px;
  margin-bottom: 30px;
  text-align: center;
`;

const Button = styled.button`
  height: 48px;
  width: 230px;
  ${body13Bold};
  background-color: ${({theme}) => theme.actionButton.enabled.backgroundColor};
  color: ${({theme}) => theme.actionButton.enabled.color};
`;

export const ErrorModal = memo(({args: {text, title, btnText, onClickOutside = noop, onClose}, closeModal}) => {
  const {colors} = useTheme();
  const {t} = getLocalizationService();

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      closeModal();
    }
  };

  return (
    <InfoModal
      header={t(title || 'error')}
      headerBgColor={colors.error}
      headerFontSize={16}
      hideCloseButton
      alignStart
      bold
      icon={errorIcon}
      onClickOutside={onClickOutside}
    >
      <Content>
        <Text id="modal-title">{t(text)}</Text>
        <Button onClick={handleClose}>{t(btnText || 'ok')}</Button>
      </Content>
    </InfoModal>
  );
});

attachModal('errorModal', ErrorModal);
