import {isEmpty} from 'lodash';

import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';
import {RestaurantFromGet} from '~/shared/store/models';

export const restaurantHasFutureRule = (restaurant?: RestaurantFromGet) => {
  return (restaurant?.availableFutureDatesAndTimes &&
      restaurant?.availableFutureDatesAndTimes?.length > 0) ||
    !isEmpty(restaurant?.deliveryRules.find(d => d.type === DELIVERY_RULES.FUTURE));
};
