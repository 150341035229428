import {useEffect} from 'react';

import {useSelector} from 'react-redux';

import {PageContainer} from '~/shared/components/styled';
import {navigateToDefaultStartPage, navigateToMenuOrDishPage} from '~/shared/services/navigation';
import {selectActionMadeFrom} from '~/shared/store/storeModules/ageRestriction/ageRestrictionSelectors';
import {isDishAgeRestricted} from '~/shared/utils/ageRestriction';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {
  selectCurrentRestaurant,
  selectCurrentRestaurantIsLoaded,
  selectDishesAdditionalDetails,
  selectDishesWithSubs,
} from '~/shared/store/selectors';
import {pushRoute} from '~/shared/router';
import {CentralLoading} from '~/shared/components/Loaders';

import AgeConfirm from './AgeConfirm';

const _removeAllAgeRestrictedDishesFromShoppingCart = (allRestaurantDishes, shoppingCartDishes) => {
  shoppingCartDishes?.forEach(currentShoppingCartDish => {
    const isAgeRestricted = isDishAgeRestricted(allRestaurantDishes, currentShoppingCartDish);
    if (isAgeRestricted) {
      ManagerProvider.removeDish(currentShoppingCartDish.shoppingCartDishId);
    }
  });
};

const AgeConfirmPage = () => {
  const actionMadeFrom = useSelector(selectActionMadeFrom);
  const allRestaurantDishes = useSelector(selectDishesWithSubs);
  const shoppingCartDishes = useSelector(selectDishesAdditionalDetails);
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const isCurrentRestaurantLoaded = useSelector(selectCurrentRestaurantIsLoaded);

  const isUserLandedOnPage = !actionMadeFrom;

  useEffect(() => {
    if (!isUserLandedOnPage || !isCurrentRestaurantLoaded) {
      return;
    }
    if (currentRestaurant) {
      navigateToMenuOrDishPage({
        restaurantId: currentRestaurant.id,
        restaurantName: currentRestaurant.name,
        routerOptions: {keepAllQueries: false, permanent: false},
      });
    } else {
      navigateToDefaultStartPage();
    }
  }, [currentRestaurant, isUserLandedOnPage, isCurrentRestaurantLoaded]);

  const onApprove = () => {
    if (actionMadeFrom === 'menu') {
      if (currentRestaurant) {
        navigateToMenuOrDishPage({
          restaurantId: currentRestaurant.id,
          restaurantName: currentRestaurant.name,
          routerOptions: {keepAllQueries: false, permanent: false},
          query: {openCheckout: true},
        });
      }
    } else if (actionMadeFrom === 'checkout') {
      pushRoute('/checkout');
    }
  };

  const onCancel = () => {
    _removeAllAgeRestrictedDishesFromShoppingCart(allRestaurantDishes, shoppingCartDishes);
    if (currentRestaurant) {
      navigateToMenuOrDishPage({
        restaurantId: currentRestaurant.id,
        restaurantName: currentRestaurant.name,
        routerOptions: {keepAllQueries: false, permanent: false},
      });
    }
  };

  if (!isCurrentRestaurantLoaded) {
    return <CentralLoading />;
  }

  return (
    <PageContainer>
      <AgeConfirm onCancel={onCancel} onApprove={onApprove} />
    </PageContainer>
  );
};

export default AgeConfirmPage;
