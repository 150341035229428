import Cookies from 'js-cookie';
import URI from 'urijs';
import {omit} from 'lodash';

// @ts-expect-error config needs to be rewritten as ts
import {isHTTP} from '~/shared/config';
import {
  CONTEXT_COOKIE_NAME,
  LOCAL_COOKIE_NAME,
  NEXT_CONTEXT_COOKIE_NAME,
  ContextCookieParamNames,
  ContextLocaleParamNames,
  NextContextCookieParamNames,
  LAST_LAT_LNG_COOKIE_NAME,
} from '~/shared/consts/commonConsts';

import {createLogger} from '../logging';

type QueryData = {
  [key: string]: string;
};

type QueryCreationData = QueryData | Record<string, number>;

type LocaleCookie = {
  culture: string;
  languageKey: string;
};

const useSecureCookies = !isHTTP;
const logger = createLogger('Cookie');

export const removeCookie = Cookies.remove;

export const getCookie = (key: string) => {
  const cookies = Cookies.get();
  return cookies[key];
};

export const setCookie = (key: string, value: string) => {
  Cookies.set(key, value, {secure: useSecureCookies, expires: 365});
  const settedCookie = Cookies.get(key);
  if (!settedCookie) {
    logger.error('Cookie was not set', {key, secure: useSecureCookies});
  }
};

export const mergeToQueryStringCookie = (key: string, queryObj: QueryCreationData) => {
  const cookie = getCookie(key);
  const parsedCookie = URI.parseQuery(cookie || '');
  const newCookie = URI.buildQuery({
    ...parsedCookie,
    ...queryObj,
  });
  setCookie(key, newCookie);
};

export const removeFromQueryStringCookie = (key: string, keysToRemove: string[]) => {
  const cookie = getCookie(key);
  if (!cookie) {
    return;
  }
  const parsedCookie = URI.parseQuery(cookie || '');
  const newCookie = URI.buildQuery(omit(parsedCookie, keysToRemove));
  setCookie(key, newCookie);
};

export function parseSiteCookies(rawCookies: QueryData) {
  const {
    [CONTEXT_COOKIE_NAME]: contextCookieQuery,
    [NEXT_CONTEXT_COOKIE_NAME]: nextContextCookieQuery,
  } = rawCookies;

  const parsedContextCookie = URI.parseQuery(contextCookieQuery || '');

  const {[ContextCookieParamNames.shoppingCartGuid]: shoppingCartGuid} = parsedContextCookie;

  const parsedNextContextCookie = URI.parseQuery(nextContextCookieQuery || '');

  const {[NextContextCookieParamNames.shoppingCartGuidTimestamp]: shoppingCartGuidTimestamp} = parsedNextContextCookie;

  return {
    contextCookie: {
      shoppingCartGuid,
    },
    nextContextCookie: {
      shoppingCartGuidTimestamp,
    },
  };
}

export function getParsedSiteCookies() {
  const rawCookies = Cookies.get();
  return parseSiteCookies(rawCookies);
}

export function parseLocaleCookies(rawCookies: QueryData): LocaleCookie {
  const {[LOCAL_COOKIE_NAME]: contextCookieQuery} = rawCookies;
  const parsedContextCookie = URI.parseQuery(contextCookieQuery || '');
  const {[ContextLocaleParamNames.culture]: culture, [ContextLocaleParamNames.languageKey]: languageKey} =
    parsedContextCookie;

  return {
    culture,
    languageKey,
  };
}

export function getParsedLocaleCookies() {
  const rawCookies = Cookies.get();
  return parseLocaleCookies(rawCookies);
}

export const getLatLngFromCookie = () => {
  const latLngCookie = getCookie(LAST_LAT_LNG_COOKIE_NAME);
  if (latLngCookie) {
    return JSON.parse(latLngCookie);
  }
  return null;
};

export const setCurrentLanguageCookie = ({culture, languageKey}: LocaleCookie) => {
  mergeToQueryStringCookie(LOCAL_COOKIE_NAME, {
    [ContextLocaleParamNames.culture]: culture,
    [ContextLocaleParamNames.languageKey]: languageKey,
  });
};
