type ComparableObject = Record<any, any>;

export const shallowCompare = (a: ComparableObject, b: ComparableObject) => {
  if ((!!a && typeof a !== 'object') || (!!b && typeof b !== 'object')) {
    return false;
  }

  if (a === b) {
    return true;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) {
    return false;
  }

  return !keysA.some(key => a[key] !== b[key]);
};
