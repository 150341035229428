import {RestaurantCategories} from '~/shared/store/models';
import {getLocalizationService} from '~/shared/services/localisationService';

import {CategoryDish, Choice, Sub} from '../../models/Restaurant/RestaurantMenuCategories';
import {selectCurrentRestaurant, selectIsGroceriesStore} from '../selectors.index';
import store from '../..';

export type MenuProps = {
  categories: RestaurantCategories[];
};

const transformMenuCategories = (resId: number, menu: MenuProps) => {
  const {t} = getLocalizationService();
  const state = store.getState();
  const isGroceriesStore = selectIsGroceriesStore(state);
  const isVoucher = selectCurrentRestaurant(state)?.isVoucherEnabled;

  const categories = [
    {
      categoryDesc: '',
      categoryId: 0,
      categoryName: (isGroceriesStore || isVoucher) ? t('popular_dishes_groceries') : t('popular_dishes'),
      categoryType: 'Popular',
      dishes: [],
    },
    ...menu.categories,
  ] as RestaurantCategories[];

  menu.categories.forEach(cat => {
    cat.dishes?.map(dish => {
      if (dish.popular && !categories[0].dishes?.some(currDish => currDish.id === dish.id)) {
        categories[0]?.dishes?.push?.(dish);
      }
      return dish;
    });
  });

  const transformSubs = (subs: Sub[] | undefined) => {
    return subs?.map(sub => {
      return {
        ...sub,
        subId: sub.id,
      };
    });
  };

  const transformChoices = (choices: Choice[] | undefined) => {
    return choices?.map(choice => {
      return {
        ...choice,
        choiceId: choice.id,
        isForced: choice.forced,
        subs: transformSubs(choice.subs),
      };
    });
  };

  const transformDishes = (dishes: CategoryDish[] | undefined) => {
    return dishes?.map(dish => {
      return {
        ...dish,
        dishName: dish.name,
        dishDescription: dish.description,
        dishId: dish.id,
        dishImageUrl: dish.imageUrl,
        dishPopularityScore: dish.popularityScore,
        dishPrice: dish.price,
        isPopularDish: dish.popular,
        choices: transformChoices(dish.choices),
      };
    });
  };

  const categoriesList = categories
    .map(cat => {
      if (cat.categoryType?.toLowerCase() === 'popular' && cat.dishes?.length) {
        cat.dishes?.sort((a, b) => b.popularityScore - a.popularityScore);
      }

      return {
        categoryId: cat.id || cat.categoryId,
        categoryName: cat.name || cat.categoryName,
        categoryDesc: cat.description || cat.categoryDesc,
        categoryType: 'None',
        categoryIconUrl: cat.iconUrl,
        dishList: transformDishes(cat.dishes),
      };
    })
    .filter(cat => cat && cat?.dishList?.length);

  return {
    categoriesList,
    resId,
  };
};

export default transformMenuCategories;
