import {DeliveryRuleModel} from '~/shared/store/models/Restaurant/RestaurantFromGet';

import {DELIVERY_RULES} from '../consts/restaurantConsts';
import {RestaurantFromGet, RestaurantFromSearch} from '../store/models';

// @TypeGuard: validating if the restaurant object is type of RestaurantFromGet(true)
export const isRestaurantFromGet = (
  restaurant: RestaurantFromSearch | RestaurantFromGet,
): restaurant is RestaurantFromGet =>
  (restaurant as RestaurantFromGet).id !== undefined && ('deliveryRules' || 'pickupRule') in restaurant;

export const convertRestaurant = (restaurantFromSearch?: RestaurantFromSearch): Partial<RestaurantFromGet> | null => {
  // we can't get to the exact type of RestaurantFromGet from RestaurantFromSearch, so we use Partial<RestaurantFromGet>
  if (!restaurantFromSearch) {
    return null;
  }

  return {
    id: restaurantFromSearch.restaurantId,
    name: restaurantFromSearch.restaurantName,
    logoUrl: restaurantFromSearch.restaurantLogoUrl,
    profileImages: undefined,
    tempClosedReason: restaurantFromSearch.tempClosedReason,
    announcement: restaurantFromSearch.announcement,
    cuisineTypes: restaurantFromSearch.restaurantCuisineTypes,
    isScoober: restaurantFromSearch.isScoober,
    asapDeliveryTimeInMinutes: restaurantFromSearch.deliveryTimeInMinutes,
    discountCouponPercent: restaurantFromSearch.discountPercent,
    poolOrder: null,
    pickupRule: null,
    isVoucherEnabled: restaurantFromSearch.voucherRestaurant,
    deliveryRules: [] as RestaurantFromGet['deliveryRules'],
    rank: {
      totalRank: restaurantFromSearch.reviewsRankDecimal,
      numOfReviews: restaurantFromSearch.numOfReviews,
    },
    // Do not set the following property in the conversion, it should stay undefined while the RestaurantFromGet
    // is not ready
    // Changing this requires refactor of:
    // selectIsRestaurantFromGetReady - src/shared/store/storeModules/currentRestaurant/currentRestaurantSelectors.ts

    // availableFutureDatesAndTimes: []
  };
};

// restaurant.deliveryRules type = DELIVERY_RULES.FUTURE, 'isActiveNow' will always return false.
export const isAnyDeliveryEnabled = (restaurant: RestaurantFromGet) =>
  Boolean(
    restaurant.deliveryRules?.length &&
      (restaurant.deliveryRules.some(({type}) => type === DELIVERY_RULES.FUTURE) ||
        restaurant.deliveryRules.some(({isActiveNow}) => isActiveNow)),
  );

// DeliveryRuleModel typeguard
export const isDeliveryRuleModel = (rule?: unknown): rule is DeliveryRuleModel =>
  !!rule && typeof rule === 'object' && (
    'deliveryFeeAfterDiscount' in rule ||
    'deliveryFeeBeforeDiscount' in rule ||
    'deliveryFee' in rule
  );
