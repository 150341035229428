import {LocalStorageKeys} from '~/shared/consts/localStorageConsts';
import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';

import {CheckoutPayment, IShoppingCart, LinkMoneycardToUserRequest, Payment, User} from '../../models';
import {makeActionCreator, makeThunkAsyncActionCreator} from '../../redux-toolbelt';
import {selectShoppingCart} from '../shoppingCart/shoppingCartSelectors';
import {selectUserData} from '../user';

import {PaymentsState} from './paymentsReducer';
import {selectCheckoutPayments} from './paymentsSelectors';

export const getAvailablePayments = makeThunkAsyncActionCreator<never, GetAvailablePaymentsReturnType>(
  'getAvailablePayments',
  async (_payload, {store, apiService}) => {
    const availablePayments = await apiService.getAvailablePayments();

    const userData = selectUserData(store.getState());
    const {data} = availablePayments;

    const transformedData = data.map(payment => ({
      ...payment,
      creditCardType: payment.creditCardType.toLowerCase(),
    }));

    return {availablePayments: transformedData, userData};
  },
);
export interface GetAvailablePaymentsReturnType {
  availablePayments: PaymentsState['availablePayments']['data'];
  userData: User | null;
}

export const addPayment = makeActionCreator<'addPayment', CheckoutPayment>('addPayment');
export const updatePayment = makeActionCreator<'updatePayment', CheckoutPayment>('updatePayment');
export const clearPayments = makeActionCreator<'clearPayments'>('clearPayments');

export const removePayment = makeThunkAsyncActionCreator<CheckoutPayment['cardId'], void>(
  'removePayment',
  async (cardId, {store}) => {
    const state = store.getState();

    const checkoutPayments: CheckoutPayment[] = selectCheckoutPayments(state);
    const activeCheckoutPayments = checkoutPayments.filter(payment => !payment.isDisabled);

    if (activeCheckoutPayments.length === 1) {
      const shoppingCart: IShoppingCart = selectShoppingCart(state);
      const orderSum = shoppingCart.billingLines.find(({type}) => type === 'TotalToCharge')?.amount;
      store.dispatch(updatePayment({...activeCheckoutPayments[0], sum: orderSum || 0}));
    }
  },
);

export const setPayments = makeActionCreator<'setPayments', CheckoutPayment[]>('setPayments');

export const getLinkMoneycardToUserRequests = makeThunkAsyncActionCreator<
  never,
  PaymentsState['linkMoneycardRequests']['data']
>('getLinkMoneycardToUserRequests', async (_payload, {apiService}) => {
  const requestsIds = JSON.parse(localStorage.getItem(LocalStorageKeys.PENDING_LINK_PAYMENT_REQUEST_IDS) as string);

  if (requestsIds && requestsIds.length) {
    const {data: linkMoneycardRequests} = await apiService.getLinkMoneycardToUserRequests({
      requestsTokens: requestsIds,
    });
    return (
      linkMoneycardRequests &&
      linkMoneycardRequests.map(p => ({
        ...p,
        userName: p.moneycardUserName,
        creditCardType: 'none',
        paymentMethod: PaymentMethodTypes.MONEY_CARD,
      }))
    );
  }
});

export const setOrderPayments = makeActionCreator<'setOrderPayments', Payment[]>('setOrderPayments');

export const addApprovedPaymentCallout = makeActionCreator<'addApprovedPaymentCallout', LinkMoneycardToUserRequest[]>(
  'addApprovedPaymentCallout',
);
export const removeApprovedPaymentCallout = makeActionCreator<'removeApprovedPaymentCallout', never>(
  'removeApprovedPaymentCallout',
);

export const setShouldAvoidPaymentsRefetch = makeActionCreator<'setShouldAvoidPaymentsRefetch'>('setShouldAvoidPaymentsRefetch');
