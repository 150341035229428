import {combineReducers} from 'redux';
import {isEmpty, omit, pickBy} from 'lodash';

import {AddressViewType} from '~/shared/consts/addressConsts';
import {
  DefaultRestaurantFilters,
  DeliveryMethods,
  RestaurantsFiltersSortOptionsType,
  RestaurantsFiltersSortOptionsTypes,
} from '~/shared/consts/restaurantConsts';
import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {shallowCompare} from '~/shared/utils/shallowCompare';

import {makeReducer} from '../../redux-toolbelt';
import {Address} from '../../models';

import {
  resetRestaurantsFilter,
  setAddressView,
  setCuisineFilter,
  setCuisinesFilter,
  setEditedAddress,
  setIsActiveOrderBannerShown,
  setPreferencesFilter,
  setRestaurantsFilter,
  setRestaurantsSortBy,
  setSearchMode,
  showOrderHistoryToggle,
  toggleRestaurantsFiltersFullScreen,
  setIsOrderTypeMenuOpen,
  setShowSelectTimeErrorMessage,
  setShowStoresMobileFilter,
  setMobileMiniFeedFilterEnabled,
} from './uiActions';

export interface UiState {
  restaurants: UiRestaurants;
  mobile: UiMobile;
  addressDropdown: UiAddressDropdown;
  searchMode: boolean;
  isActiveOrderBannerShown: boolean;
}

export default combineReducers<UiState>({
  restaurants: combineReducers({
    filter: makeReducer<UiState['restaurants']['filter'], any>(
      {
        [setRestaurantsFilter.TYPE]: (state, {payload}: ReturnType<typeof setRestaurantsFilter>) => {
          const activePreferences = Object.keys(pickBy(omit(payload, 'cuisines')));
          const activeCuisine = Object.keys(payload?.cuisines || {})?.[0];

          return {
            ...state,
            ...payload,
            lastFilterTypeSelected: activePreferences.length
              ? activePreferences[activePreferences.length - 1]
              : activeCuisine && 'kitchenType',
          };
        },
        [resetRestaurantsFilter.TYPE]: state => ({
          ...DefaultRestaurantFilters,
          lastFilterTypeSelected: state.lastFilterTypeSelected,
        }),
        [setCuisinesFilter.TYPE]: (state, {payload: cuisines}: ReturnType<typeof setCuisinesFilter>) => {
          if (!cuisines || isEmpty(cuisines)) {
            return state;
          }

          const businessCuisines = cuisines.reduce((result, cId) => {
            result[cId] = true;
            return result;
          }, {} as Record<string, boolean>);

          // same cuisines payload protection
          if (state.cuisines && shallowCompare(state.cuisines, businessCuisines)) {
            return state;
          }

          return {
            ...state,
            cuisines: !isEmpty(businessCuisines) ? businessCuisines : EMPTY_OBJECT,
            lastFilterTypeSelected: 'kitchenType',
          };
        },
        [setCuisineFilter.TYPE]: (state, {payload: cuisine}: ReturnType<typeof setCuisineFilter>) => {
          if (!cuisine) {
            return state;
          }

          const isCuisineActive = cuisine in (state.cuisines || {});

          return {
            ...state,
            cuisines: isCuisineActive ? EMPTY_OBJECT : {[cuisine]: true},
            lastFilterTypeSelected: 'kitchenType',
          };
        },
        [setPreferencesFilter.TYPE]: (state, {payload: preferenceId}: ReturnType<typeof setPreferencesFilter>) =>
          (preferenceId
            ? {
                ...state,
                [preferenceId]: !state[preferenceId],
                lastFilterTypeSelected: preferenceId,
              }
            : state),
        [setShowStoresMobileFilter.TYPE]: (currentState, {payload}: {payload?: boolean}) => ({
          ...currentState,
          showStores: !!payload,
        }),
      },
      {
        defaultState: DefaultRestaurantFilters,
      },
    ),
    sortBy: makeReducer<UiState['restaurants']['sortBy']>(
      {
        [setRestaurantsSortBy.TYPE]: (state, {payload: sort}: ReturnType<typeof setRestaurantsSortBy>) => {
          return sort;
        },
        [resetRestaurantsFilter.TYPE]: (_, {payload: deliveryMethod}) => {
          return deliveryMethod === DeliveryMethods.PICKUP ? RestaurantsFiltersSortOptionsTypes.DISTANCE : RestaurantsFiltersSortOptionsTypes.SPECIAL;
        },
      },
      {
        defaultState: RestaurantsFiltersSortOptionsTypes.SPECIAL,
      },
    ),
    showOrderHistory: makeReducer(showOrderHistoryToggle, state => !state, {defaultState: false as boolean}),
  }),
  mobile: combineReducers({
    showRestaurantsFilters: makeReducer<
      UiState['mobile']['showRestaurantsFilters'],
      ReturnType<typeof toggleRestaurantsFiltersFullScreen>['payload']
    >(toggleRestaurantsFiltersFullScreen, state => !state, {defaultState: false}),
    isOrderTypeMenuOpen: makeReducer<UiState['mobile']['isOrderTypeMenuOpen'], UiState['mobile']['isOrderTypeMenuOpen']>(setIsOrderTypeMenuOpen, {defaultState: false}),
    showSelectTimeErrorMessage: makeReducer<UiState['mobile']['showSelectTimeErrorMessage']>({
      [setShowSelectTimeErrorMessage.TYPE]: (_, {payload}) => payload,
      [setIsOrderTypeMenuOpen.TYPE]: () => false,
    }, {defaultState: false}),
    miniFeedFilterEnabled: makeReducer<UiState['mobile']['miniFeedFilterEnabled']>({
      [setMobileMiniFeedFilterEnabled.TYPE]: (_, {payload}) => !!payload,
      [resetRestaurantsFilter.TYPE]: () => false,
    }, {defaultState: false}),
  }),
  addressDropdown: combineReducers({
    editedAddress: makeReducer(setEditedAddress),
    addressView: makeReducer(setAddressView),
  }),
  searchMode: makeReducer(setSearchMode, {defaultState: false as boolean}),
  isActiveOrderBannerShown: makeReducer(setIsActiveOrderBannerShown, {defaultState: false as boolean}),
});

interface UiRestaurants {
  filter: UiRestaurantsFilter;
  sortBy: RestaurantsFiltersSortOptionsType;
  showOrderHistory: boolean;
}

export type UiRestaurantsFilter = Partial<{
  isVegan: boolean;
  isKosher: boolean;
  isNotKosher: boolean;
  isGlutenFree: boolean;
  freeDelivery: boolean;
  fastDelivery: boolean;
  newRestaurants: boolean;
  discountCoupon: boolean;
  isEnvironmentFriendly: boolean;
  isScoober: boolean;
  lastFilterTypeSelected: string;
  cuisines: Record<string, boolean>;
  // This option currently used in mobile version only
  showStores: boolean;
}>;

interface UiMobile {
  showRestaurantsFilters: boolean;
  isOrderTypeMenuOpen: boolean;
  showSelectTimeErrorMessage: boolean;
  miniFeedFilterEnabled: boolean;
}

interface UiAddressDropdown {
  editedAddress: Address;
  addressView: AddressViewType | null;
}
