import styled from 'styled-components';

import {PriceLabel} from '~/shared/components/PriceLabels';
import {getLocalizationService} from '~/shared/services/localisationService';
import {media} from '~/shared/theme/media';
import AgeRestrictionBadge from '~/shared/components/AgeRestrictionBadge';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';

import Section, {OrderDetailsDivider} from './parts/Section';
import _InfoWrapper from './parts/InfoWrapper';
import Quantity from './parts/Quantity';
import {defaultPaddingCss} from './orderSummaryStyle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  width: 100%;
`;

const DishRoot = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  flex: 0 1 auto;
`;
const DishPriceAndTitleRow = styled.div`
  display: flex;
`;

const StyledAgeRestrictionBadge = styled(AgeRestrictionBadge)`
  flex-shrink: 0;
  margin: 4px 8px;
`;

const DishInfoWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  ${flipOnLTR`
    margin-left: auto;
  `}
`;

const DishName = styled.div`
  max-width: 280px;
  display: flex;
  align-items: flex-start;
  font-weight: 700;
  ${media.maxMobile`
    max-width: 180px;
  `}
`;

const DishDescription = styled.div`
  margin-top: 3px;
  max-width: 280px;
  color: ${({theme}) => theme.colors.text};
  ${media.maxMobile`
    max-width: 180px;
  `}
`;
const CutleryRemarks = styled(DishDescription)`
  color: ${({theme}) => theme.colors.secondary};
  line-height: 24px;
`;

const AssignedUser = styled(CutleryRemarks)`
  margin-bottom: 3px;
`;

const DishRemarks = styled(CutleryRemarks)`
  ${flipOnLTR`left: 20px;`}
  ${({marginBottom}) => marginBottom && 'margin-bottom:12px'};
`;

const OrderDetailsSection = styled(Section)`
  ${defaultPaddingCss}
`;

const InfoWrapper = styled(_InfoWrapper)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledOrderDetailsDivider = styled(OrderDetailsDivider)`
  ${({isLastItem}) => isLastItem && 'margin-bottom: initial;'}
`;

const OrderDetails = ({className, dishes, showDishDetails = false}) => {
  const {t} = getLocalizationService();

  return (
    <OrderDetailsSection title={t('your_order')} className={className}>
      {dishes?.map((dish, idx) => {
        const {
          dishPrice,
          subsPrice,
          quantity,
          dishName,
          dishId,
          ageRestricted,
          assignedUserName,
          restrictionAge,
          description,
          remarks,
        } = dish;

        const isLastItem = idx === dishes.length - 1;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Container key={`${dishId}-${idx}`} data-test-id={`dish_item_${idx}`}>
            <DishRoot>
              <DishPriceAndTitleRow>
                <Quantity>
                  <div>{quantity}</div>
                  <div>x</div>
                </Quantity>
                <DishInfoWrapper>
                  <InfoWrapper>
                    <DishName>
                      {dishName}
                      {ageRestricted && <StyledAgeRestrictionBadge restrictionAge={restrictionAge} />}
                    </DishName>
                    {showDishDetails && <DishDescription>{description}</DishDescription>}
                    {showDishDetails && assignedUserName && <AssignedUser>{assignedUserName}</AssignedUser>}
                  </InfoWrapper>
                  {remarks && showDishDetails && (
                    <DishRemarks data-test-id={`dish_item_${idx}_note`} marginBottom={!isLastItem}>
                      {t('remarks')}: {remarks}
                    </DishRemarks>
                  )}
                </DishInfoWrapper>
              </DishPriceAndTitleRow>
              <PriceLabel price={quantity * (dishPrice + subsPrice)} margin={5} />
            </DishRoot>
            <StyledOrderDetailsDivider isLastItem={isLastItem} />
          </Container>
        );
      })}
    </OrderDetailsSection>
  );
};

export default OrderDetails;
