import moment, {unitOfTime} from 'moment-timezone';

import {createLogger} from '../logging';

const logger = createLogger('time utils');

export const getTimeDiffObject = ({fromMoment = moment(), toMoment}: {
  fromMoment?: moment.Moment;
  toMoment: moment.Moment;
}) => {
  const timeDiff = toMoment.diff(fromMoment);

  const hours = moment.duration(timeDiff).hours();
  const minutes = moment.duration(timeDiff).minutes();
  const seconds = moment.duration(timeDiff).seconds();
  return {hours, minutes, seconds};
};

export const formatTimeLeft = ({hours, minutes, seconds}: {hours: number; minutes: number; seconds: number}) => {
  const hoursToTargetDateTime = hours < 10 && hours >= 0 ? `0${hours.toString()}` : hours.toString();
  const minutesToTargetDateTime = minutes < 10 && minutes >= 0 ? `0${minutes.toString()}` : minutes.toString();
  const secondsToTargetDateTime = seconds < 10 && seconds >= 0 ? `0${seconds.toString()}` : seconds.toString();
  return {hoursToTargetDateTime, minutesToTargetDateTime, secondsToTargetDateTime};
};

export const isAfterTimeInLocalStorage = ({localStorageKey}: {localStorageKey: string}) => {
  const localStorageValue = window.localStorage.getItem(localStorageKey);
  if (localStorageValue) {
    const snoozeTime = JSON.parse(localStorageValue);
    const isAfterSnoozeTime = !snoozeTime || moment().isAfter(snoozeTime);
    return isAfterSnoozeTime;
  }
  return true;
};

export const isTimestampExpired = (
  timestamp: number,
  expirationDuration: unitOfTime.DurationConstructor,
  timeUnit: number | string = 'm',
) => timestamp && moment(Number(timestamp)).add(expirationDuration, timeUnit).isBefore(moment());

export const convert12hTo24hFormat = (time: string) => {
  // arg time = '1:30 PM'
  // expected return 13:30

  // Extract hours, minutes, and AM/PM part using a regular expression
  const match = time.match(/(\d+):(\d+) (\w+)/);

  if (!match) {
    logger.error('[convert12hTo24hFormat]: Format failed, arg has to be 12H format.');
    return time;
  }

  let hours = parseInt(match[1], 10);
  const minutes = match[2];
  const ampm = match[3];

  // Convert hours for PM times not equal to 12
  if (ampm === 'PM' && hours !== 12) {
    hours += 12;
  }

  // Convert 12 AM to 0 hours
  if (ampm === 'AM' && hours === 12) {
    hours = 0;
  }

  // Format hours and minutes to ensure two digits
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};
