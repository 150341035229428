import styled, {css} from 'styled-components';

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexCenter = styled.div`
  ${flexCenter};
`;
